import React from "react";
import { GroupBase } from "react-select";
import { IndicatorsContainerProps } from "react-select/dist/declarations/src/components/containers";
import styled from "styled-components";
import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUpIcon } from "../../../assets/icons/chevron-up.svg";

const StyledChevronIcon = styled(ChevronDownIcon)`
  width: ${(props) => props.theme.spacing1};
`;

const CustomSwitcherArrow = (
  indicatorsContainerProps: IndicatorsContainerProps<
    { label: string; value: string },
    false,
    GroupBase<{ label: string; value: string }>
  >
): JSX.Element => {
  indicatorsContainerProps.selectProps.menuIsOpen;
  return (
    <div {...indicatorsContainerProps.innerProps}>
      <StyledChevronIcon
        as={
          indicatorsContainerProps.selectProps.menuIsOpen
            ? ChevronUpIcon
            : ChevronDownIcon
        }
      />
    </div>
  );
};

export default CustomSwitcherArrow;
