import { breedSizeType } from "../../types/dogTypes";

const isBreedSize = (
  // eslint-disable-next-line
  param: any
): param is breedSizeType => {
  return ["toy", "small", "medium", "large", "giant"].includes(param);
};

export default isBreedSize;
