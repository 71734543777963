import {
  Category,
  IngredientMetaNutrientsNoIdNoOwnerId,
} from "../types/ingredientTypes";

const getEmptyIngredient = (
  name: string
): IngredientMetaNutrientsNoIdNoOwnerId => {
  const category: Category = "custom";

  const returnValue = {
    name: name,
    defaultUnit: "gram",
    defaultUnitShort: null,
    source: null,
    category: category,

    // Calories
    calories: null,

    // Macronutrients
    protein: null,
    fat: null,
    carbohydrate: null,
    water: null,
    ash: null,

    // Amino acids
    tryptophan: null,
    threonine: null,
    isoleucine: null,
    leucine: null,
    lysine: null,
    methionine: null,
    cystine: null,
    phenylalanine: null,
    tyrosine: null,
    valine: null,
    arginine: null,
    histidine: null,

    // Fatty acids
    linoleicAcid: null,
    alphaLinoleicAcid: null,
    arachidonicAcid: null,
    epa: null,
    dha: null,

    // Carbohydrates
    fiber: null,

    // Minerals
    calcium: null,
    iron: null,
    magnesium: null,
    phosphorus: null,
    potassium: null,
    chloride: null,
    sodium: null,
    zinc: null,
    copper: null,
    manganese: null,
    selenium: null,
    iodine: null,

    // Vitamins
    thiaminB1: null,
    riboflavinB2: null,
    niacinB3: null,
    pantothenicAcidB5: null,
    vitaminB6: null,
    folicAcidB9: null,
    choline: null,
    vitaminB12: null,
    vitaminA: null,
    vitaminE: null,
    cholecalciferolD3: null,
    phylloquinoneK: null,
  };

  return returnValue;
};

export default getEmptyIngredient;
