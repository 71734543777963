import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult } from "react-query";
import { BACKEND_URL } from "../../constants/constants";

interface Input {
  priceId: string;
}

const useCreateStripeCheckout = (): UseMutationResult<
  void,
  AxiosError,
  Input
> => {
  return useMutation(async (data) => {
    const response = await axios.post<{ url: string }>(
      `${BACKEND_URL}/api/billing/checkout`,
      data
    );

    window.location.href = response.data.url;
  });
};

export default useCreateStripeCheckout;
