import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import { RecipeNoIdIngredientInstances } from "../../types/recipeTypes";

interface returnValue {
  id: string;
  name: string;
}

const useCreateRecipe = (
  dogId: string | undefined | null
): UseMutationResult<
  returnValue | void,
  AxiosError,
  RecipeNoIdIngredientInstances
> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      if (dogId) {
        const response = await axios.post<void>(
          `${BACKEND_URL}/api/dogs/${dogId}/recipes`,
          data
        );
        return response.data;
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["recipes", dogId], { exact: true });
        queryClient.invalidateQueries(["recipes"], {
          exact: true,
        });
      },
    }
  );
};

export default useCreateRecipe;
