import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
const ScrollToTop = (): null => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.style.scrollBehavior = "auto";
    document.documentElement.scrollTo({ top: 0 });
    document.documentElement.style.scrollBehavior = "smooth";
  }, [pathname]);

  return null;
};

export default ScrollToTop;
