import React, { useContext } from "react";
import styled from "styled-components";
import DogBannersContext from "../../../../contexts/dogBannersContext/DogBannersContext";
import VerificationTokenMessageContext from "../../../../contexts/verificationTokenMessageContext/VerificationTokenMessageContext";
import Banner from "../../../shared/Banner";
import SmallText from "../../../shared/text/SmallText";

interface Props {
  changed: string[];
  clearChanged: () => void;
  nutrientReqsNameBannerPart: string | null;
  setNutrientReqsNameBannerPart: React.Dispatch<
    React.SetStateAction<string | null>
  >;
}

const DogBannersArea = ({
  changed,
  clearChanged,
  nutrientReqsNameBannerPart,
  setNutrientReqsNameBannerPart,
}: Props): JSX.Element | null => {
  const dogBannersContextValue = useContext(DogBannersContext);
  const verificationTokenMessageContextValue = useContext(
    VerificationTokenMessageContext
  );
  if (
    (!dogBannersContextValue ||
      dogBannersContextValue?.dogBanners.length === 0) &&
    changed.length === 0 &&
    (!verificationTokenMessageContextValue ||
      !verificationTokenMessageContextValue.verificationTokenMessage) &&
    nutrientReqsNameBannerPart === null
  ) {
    return null;
  }

  let changedText = null;
  if (changed.length > 0) {
    let middlePart = "";
    if (changed.length === 1) {
      middlePart = changed[0];
    } else {
      for (let i = 0; i < changed.length - 2; i++) {
        middlePart += changed[i] + ", ";
      }
      middlePart +=
        changed[changed.length - 2] + " and " + changed[changed.length - 1];
    }

    changedText = `We see that you have changed your dog’s ${middlePart}. You may need to update your calorie goal.`;
  }

  return (
    <Container>
      {verificationTokenMessageContextValue &&
        verificationTokenMessageContextValue.verificationTokenMessage && (
          <StyledBanner
            clearBanner={() =>
              verificationTokenMessageContextValue.setVerificationTokenMessage(
                ""
              )
            }
          >
            <StyledSmallText>
              {verificationTokenMessageContextValue.verificationTokenMessage}
            </StyledSmallText>
          </StyledBanner>
        )}
      {dogBannersContextValue &&
        dogBannersContextValue.dogBanners.map((dogBanner, index) => {
          return (
            <StyledBanner
              clearBanner={() => {
                const newDogBanners = dogBannersContextValue.dogBanners.filter(
                  (filterDogBanner) => filterDogBanner !== dogBanner
                );
                dogBannersContextValue.setDogBanners(newDogBanners);
              }}
              key={index}
            >
              <StyledSmallText> {dogBanner}</StyledSmallText>
            </StyledBanner>
          );
        })}
      {changedText && (
        <StyledBanner clearBanner={clearChanged}>
          <StyledSmallText>{changedText}</StyledSmallText>
        </StyledBanner>
      )}
      {nutrientReqsNameBannerPart !== null && (
        <StyledBanner
          clearBanner={() => {
            setNutrientReqsNameBannerPart(null);
          }}
        >
          <StyledSmallText>
            Changes made to dog means that the nutritional standard of &quot;
            {nutrientReqsNameBannerPart}&quot; will be used when formulating
            recipes.
          </StyledSmallText>
        </StyledBanner>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const StyledBanner = styled(Banner)`
  &:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing1};
  }
`;

const StyledSmallText = styled(SmallText)`
  color: ${(props) => props.theme.purple4};
`;

export default DogBannersArea;
