import React, { useContext } from "react";
import styled from "styled-components";
import OneChoiceModalOpenContext from "../../../../contexts/oneChoiceModalOpenContext/OneChoiceModalOpenContext";
import Button from "../../../shared/Button";
import Modal from "../../../shared/Modal";
import IntroductionText from "../../../shared/text/IntroductionText";

const OneChoiceModal = (): JSX.Element => {
  const oneChoiceModalOpenContextValue = useContext(OneChoiceModalOpenContext);

  return (
    <Modal
      title="You need to create a dog before you continue"
      open={oneChoiceModalOpenContextValue?.oneChoiceModalOpen || false}
      setOpen={oneChoiceModalOpenContextValue?.setOneChoiceModalOpen}
      closable={true}
    >
      <StyledIntroductionText>
        We know that you are curious about the rest of the app, but you need to
        create your first dog before you continue.
      </StyledIntroductionText>

      <PurpleButton
        eventHandler={() =>
          oneChoiceModalOpenContextValue?.setOneChoiceModalOpen(false)
        }
      >
        Stay
      </PurpleButton>
    </Modal>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};
  margin-top: ${(props) => props.theme.spacing2};
`;

const PurpleButton = styled(Button)`
  background-color: ${(props) => props.theme.purple2};
  color: ${(props) => props.theme.purple4};
  width: 100%;

  &:hover {
    background-color: ${(props) => props.theme.purple1};
  }
  &:focus {
    background-color: ${(props) => props.theme.purple1};
    outline-color: ${(props) => props.theme.purple1};
  }
`;

export default OneChoiceModal;
