import React, { useEffect, useRef, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { ReactComponent as CrossIcon } from "../../assets/icons/x.svg";
import FocusTrap from "focus-trap-react";
import Heading2 from "./text/headings/Heading2";

interface Props {
  open: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  children: React.ReactNode;
  closable: boolean;
  refComponent?: React.RefObject<HTMLDivElement>;
  wideMode?: boolean;
}

const Modal = ({
  open,
  setOpen,
  title,
  children,
  closable,
  refComponent,
  wideMode,
}: Props): JSX.Element | null => {
  const [topValue, setTopValue] = useState<null | number>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (open) {
      setTopValue(-window.scrollY);
    } else {
      if (topValue !== null) {
        const scrollTo = -topValue;
        setTopValue(null);
        // TODO: change to ref
        const html = document.querySelector("html");
        if (html) {
          html.style.scrollBehavior = "auto";
          window.scrollTo({
            left: 0,
            top: scrollTo,
          });
          html.style.scrollBehavior = "smooth";
        }
      }
    }
  }, [open]);

  useEffect(() => {
    if (open && contentRef.current) {
      contentRef.current.focus();
    }
  }, [open]);

  useEffect(() => {
    if (closable && setOpen) {
      const close = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          setOpen(false);
        }
      };
      window.addEventListener("keydown", close);
    }
    return () => {
      if (closable && setOpen) {
        window.removeEventListener("keydown", close);
      }
    };
  }, []);

  if (!open) {
    return null;
  }

  const closeModal = (e: React.MouseEvent) => {
    e.preventDefault();
    if (closable && setOpen) {
      setOpen(false);
    }
  };

  return (
    <>
      <Backdrop></Backdrop>
      <FocusTrap>
        <Container onClick={closeModal} ref={refComponent}>
          <Content
            onClick={(e) => {
              e.stopPropagation();
            }}
            aria-labelledby="modal-label"
            role="dialog"
            aria-modal="true"
            tabIndex={-1}
            ref={contentRef}
            wideMode={wideMode}
          >
            <TopPart wideMode={wideMode}>
              <StyledHeading2 asValue={"h1"} id="modal-label">
                {title}
              </StyledHeading2>
              {closable && (
                <StyledButton onClick={closeModal}>
                  <StyledCrossIcon aria-label="Close modal" />
                </StyledButton>
              )}
            </TopPart>
            {children}
          </Content>
        </Container>
      </FocusTrap>
      {topValue !== null && <BodyStyleGlobal topValue={topValue} />}
    </>
  );
};

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: ${(props) => props.theme.black4};
  opacity: 0.8;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  padding-top: ${(props) => props.theme.spacing1};
  padding-bottom: ${(props) => props.theme.spacing1};

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    padding-top: 0;
    padding-bottom: 0;
  }
  @media print {
    padding-top: 0;
    padding-bottom: 0;

    position: static;
    height: fit-content;
    overflow: visible;
  }
`;

const Content = styled.div<{ wideMode?: boolean }>`
  background-color: ${(props) => props.theme.white};
  border-radius: 2rem; // 32px
  max-width: ${(props) =>
    props.wideMode ? props.theme.mainWidth : props.theme.halfWidth};
  margin-left: ${(props) => props.theme.spacing1};
  margin-right: ${(props) => props.theme.spacing1};
  margin-top: auto;
  margin-bottom: auto;
  padding: ${(props) => props.theme.spacing3};
  height: fit-content;
  width: 100%;

  &:focus {
    outline: none;
  }
  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    padding: ${(props) => props.theme.spacing2};
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    box-sizing: border-box;
    min-height: 100%;
    border-radius: 0;
  }
  @media print {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    box-sizing: "border-box";
    min-height: 100%;
    border-radius: 0;

    position: static;
    overflow: visible;
  }
`;

const TopPart = styled.div<{ wideMode?: boolean }>`
  display: ${(props) => (props.wideMode ? "block" : "flex")};
  justify-content: ${(props) => !props.wideMode && "space-between"};
  max-width: ${(props) => props.wideMode && props.theme.halfWidth};
  margin-left: ${(props) => props.wideMode && "auto"};
  margin-right: ${(props) => props.wideMode && "auto"};
`;

const StyledCrossIcon = styled(CrossIcon)`
  width: 2rem; // 32px
  height: 2rem; // 32px
  color: ${(props) => props.theme.black4};
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  width: 2rem; // 32px
  height: 2rem; // 32px
  cursor: pointer;
  padding: 0;
  margin-left: ${(props) => props.theme.spacing1};

  &:focus {
    outline-width: 0.125rem; // 2px
    outline-style: solid;
    outline-offset: 0.125rem; // 2px
    outline-color: ${(props) => props.theme.black4};
  }

  @media print {
    visibility: hidden;
  }
`;

const StyledHeading2 = styled(Heading2)`
  color: ${(props) => props.theme.black4};
  word-break: break-word;
`;

const BodyStyleGlobal = createGlobalStyle<{ topValue: number }>`
  body {
    top: ${(props) => props.topValue + "px"};
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  
  @media print {
    body {
      position: static;
    }
  }
`;

export default Modal;
