import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import AuthContainer from "../../../shared/auth/AuthContainer";
import Input from "../../../shared/form/normal/Input";
import LabelForm from "../../../shared/form/LabelForm";
import SubmitButton from "../../../shared/form/normal/SubmitButton";
import Text from "../../../shared/text/Text";
import StyledLink from "../../../shared/StyledLink";
import useGetForgotPasswordTokenStatus from "../../../../hooks/api/useGetForgotPasswordTokenStatus";
import { passwordRegex } from "../../../../utils/regexPatterns";
import HideRevealPassword from "../../../shared/auth/HideRevealPassword";
import { passwordErrorMessage } from "../../../../utils/errorMessagesAuth";
import useChangePasswordToken from "../../../../hooks/api/useChangePasswordToken";

interface formValues {
  password: string;
}

interface Props {
  forgotPasswordTokenMessage: string | null;
  setForgotPasswordTokenMessage: React.Dispatch<
    React.SetStateAction<string | null>
  >;
}

const ChangePassword = ({
  forgotPasswordTokenMessage,
  setForgotPasswordTokenMessage,
}: Props): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);

  const { forgotPasswordToken } = useParams<{ forgotPasswordToken: string }>();

  const forgotPasswordTokenStatusQuery =
    useGetForgotPasswordTokenStatus(forgotPasswordToken);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<formValues>();
  const history = useHistory();

  const changePasswordMutation = useChangePasswordToken();

  useEffect(() => {
    if (forgotPasswordTokenStatusQuery.data?.status) {
      if (forgotPasswordTokenStatusQuery.data.status === "expired") {
        setForgotPasswordTokenMessage("Your password token has expired.");
      } else if (forgotPasswordTokenStatusQuery.data.status === "invalid") {
        setForgotPasswordTokenMessage("Your password token is invalid.");
      } else if (forgotPasswordTokenStatusQuery.data.status === "used") {
        setForgotPasswordTokenMessage(
          "Your password token has already been used."
        );
      }
    }
  }, [forgotPasswordTokenStatusQuery.data?.status]);

  const submitHandler = (data: formValues) => {
    changePasswordMutation.mutate({
      forgotPasswordToken,
      data: { password: data.password },
    });
  };

  if (forgotPasswordTokenMessage) {
    return <Redirect to="/forgot" />;
  }

  return (
    <AuthContainer title="Choose a new password">
      <form onSubmit={handleSubmit(submitHandler)} noValidate autoComplete="on">
        <PasswordLabel name="passwordLogin">Password</PasswordLabel>
        <ContainerPasswordInput>
          <StyledInput
            register={register("password", {
              required: true,
              pattern: passwordRegex,
            })}
            error={errors.password}
            errorMessage={passwordErrorMessage}
            type={showPassword ? "text" : "password"}
            placeholder="•••••"
            autoComplete="new-password"
          />
          <HideRevealPassword
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        </ContainerPasswordInput>
        <ButtonContainer>
          <StyledSubmitButton text="Change password" />
        </ButtonContainer>
      </form>
      <ButtonContainer>
        <StyledText>
          <StyledStyledLink
            asButton={true}
            clickHandler={() => history.push("/login")}
          >
            Back to log in
          </StyledStyledLink>
        </StyledText>
      </ButtonContainer>
    </AuthContainer>
  );
};

const PasswordLabel = styled(LabelForm)`
  color: ${(props) => props.theme.black4};
  margin-bottom: 0.25rem; // 4px
  margin-top: ${(props) => props.theme.spacing2};
`;

const StyledInput = styled(Input)`
  color: ${(props) => props.theme.green4};
  background-color: ${(props) => props.theme.green1};
  padding-right: 4rem; // 64px
  border-style: solid;
  border-width: 0.125rem; // 2px
  border-color: ${(props) => props.theme.green4};

  &:hover {
    background-color: ${(props) => props.theme.white};
  }
  &:focus {
    background-color: ${(props) => props.theme.white};
    outline: none;
  }
  &::placeholder {
    color: ${(props) => props.theme.green3};
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: ${(props) => props.theme.spacing2};
`;

const StyledSubmitButton = styled(SubmitButton)`
  background-color: ${(props) => props.theme.green3};
  color: ${(props) => props.theme.green1};

  &:hover {
    background-color: ${(props) => props.theme.green4};
  }
  &:focus {
    outline-color: ${(props) => props.theme.green4};
    background-color: ${(props) => props.theme.green4};
  }
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.black4};
`;

const StyledStyledLink = styled(StyledLink)`
  color: ${(props) => props.theme.black4};

  &:hover {
    color: ${(props) => props.theme.black3};
  }
  &:focus {
    outline-color: ${(props) => props.theme.black3};
    color: ${(props) => props.theme.black3};
  }
`;

const ContainerPasswordInput = styled.div`
  position: relative;
`;

export default ChangePassword;
