import React from "react";
import styled from "styled-components";
import Heading2 from "../../../shared/text/headings/Heading2";
import IntroductionText from "../../../shared/text/IntroductionText";

interface Props {
  imageRight: boolean;
  textText: string;
  headingText: string;
  imageUrl: string;
  imageAlt: string;
  className?: string;
}

const FiftyFifty = ({
  imageRight,
  textText,
  headingText,
  imageUrl,
  imageAlt,
  className,
}: Props): JSX.Element => {
  return (
    <StyledContainer className={className} imageRight={imageRight}>
      <TextContainer imageRight={imageRight}>
        <StyledHeading2>{headingText}</StyledHeading2>
        <StyledIntroductionText>{textText}</StyledIntroductionText>
      </TextContainer>
      <RightPane imageRight={imageRight}>
        <ImageContainer>
          <AbsolutContainer>
            <StyledImage src={imageUrl} alt={imageAlt} />
          </AbsolutContainer>
        </ImageContainer>
      </RightPane>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ imageRight: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: ${(props) => props.theme.mainWidth};
  flex-direction: ${(props) => (props.imageRight ? "row" : "row-reverse")};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    flex-direction: column-reverse;
  }
`;

const StyledHeading2 = styled(Heading2)`
  color: ${(props) => props.theme.black4};
`;

const StyledIntroductionText = styled(IntroductionText)`
  margin-top: ${(props) => props.theme.spacing2};
  color: ${(props) => props.theme.black4};
`;

const TextContainer = styled.div<{ imageRight: boolean }>`
  max-width: ${(props) => props.theme.halfWidth};
  padding-right: ${(props) => (props.imageRight ? props.theme.spacing4 : 0)};
  padding-left: ${(props) => (props.imageRight ? 0 : props.theme.spacing4)};
  box-sizing: border-box;
  flex-basis: 50%;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    background-color: ${(props) => props.theme.purple1};
    margin-right: ${(props) => props.imageRight && props.theme.halfSpacing1};
    margin-left: ${(props) => !props.imageRight && props.theme.halfSpacing1};
    padding-top: ${(props) => props.theme.spacing5};
    padding-bottom: ${(props) => props.theme.spacing3};
    padding-left: ${(props) => props.theme.spacing1};
    padding-right: ${(props) => props.theme.spacing1};
    border-radius: 2rem; // 32px
  }
`;

const RightPane = styled.div<{ imageRight: boolean }>`
  max-width: ${(props) => props.theme.halfWidth};
  max-height: ${(props) => props.theme.halfWidth};
  width: 100%;
  height: 100%;
  flex-basis: 50%;
  box-sizing: border-box;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: -3.5rem; // 56px
    padding-left: ${(props) => props.imageRight && "2.75rem;"}; // 44px
    padding-right: ${(props) => !props.imageRight && "2.75rem;"}; // 44px
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
`;

const AbsolutContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  border-style: solid;
  border-width: 0.25rem; // 4px
  border-radius: 2rem; // 32px
  border-color: ${(props) => props.theme.purple1};
  overflow: hidden;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default FiftyFifty;
