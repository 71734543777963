import React, { useState } from "react";
import ColoredTopPartContext from "./ColoredTopPartContext";

interface Props {
  children: React.ReactNode;
}

const ColoredTopPartContextProvider = ({ children }: Props): JSX.Element => {
  const [coloredTopPart, setColoredTopPart] = useState(false);

  return (
    <ColoredTopPartContext.Provider
      value={{ coloredTopPart, setColoredTopPart }}
    >
      {children}
    </ColoredTopPartContext.Provider>
  );
};

export default ColoredTopPartContextProvider;
