import React, { useState } from "react";
import styled from "styled-components";
import useGetAuthUser from "../../../../../hooks/api/useGetAuthUser";
import useGetDog from "../../../../../hooks/api/useGetDog";
import getCalculatedCalorieGoal from "../../../../../services/getCalculatedCalorieGoal";
import getNutrientReqs from "../../../../../services/getNutrientReqs";
import getNutrientStandardCategory from "../../../../../services/getNutrientStandardCategory";
import amountNutrientInMeal from "../../../../../services/nutrientReqs/amountNutrientInMeal";
import { NutrientIngredientType } from "../../../../../types/ingredientTypes";
import {
  NutrientStandardCategory,
  NutrientStandardType,
} from "../../../../../types/standardTypes";
import Heading2 from "../../../../shared/text/headings/Heading2";
import NutrientInfoModal from "./NutrientInfoModal";
import NutritionalTable from "./NutritionalTable";
import NutrientRowCalories from "./rows/NutrientRowCalories";
import NutrientRowRatio from "./rows/NutrientRowRatio";
import NutrientRowSingle from "./rows/NutrientRowSingle";
import NutrientRowSum from "./rows/NutrientRowSum";
import NutrientFindIngredientModal from "./NutrientFindIngredientModal";
import { RecipeFull } from "../../../../../types/recipeTypes";

interface Props {
  recipe: RecipeFull;
  printModeRecipe: boolean;
}

const NutrientsPart = ({
  recipe,
  printModeRecipe,
}: Props): JSX.Element | null => {
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [infoModalNutrient, setInfoModalNutrient] =
    useState<NutrientStandardType | null>(null);

  const [nutrientFindIngredientModalOpen, setNutrientFindIngredientModalOpen] =
    useState(false);
  const [nutrientFindIngredient, setNutrientFindIngredient] =
    useState<NutrientIngredientType | null>(null);

  const authUserQuery = useGetAuthUser();
  const getDogQuery = useGetDog(authUserQuery.data?.selectedDog, {
    enabled: !!authUserQuery.data?.selectedDog,
  });

  if (
    !getDogQuery.data ||
    !authUserQuery.data ||
    authUserQuery.data.kgOrLb === null
  ) {
    return null;
  }

  const dog = getDogQuery.data;

  const nutrientGuideline = dog.nutrientGuideline;
  const nutrientStandardCategory: NutrientStandardCategory =
    getNutrientStandardCategory(
      dog.birthDate,
      dog.breedSize,
      dog.pregnancyStatus === "gestation",
      dog.pregnancyStatus === "lactation"
    );

  const ingredients = recipe.ingredients;

  // Total amount of nutrients needed for requirements
  const proteinInMeal = amountNutrientInMeal("protein", ingredients);
  const linoleicAcidInMeal = amountNutrientInMeal("linoleicAcid", ingredients);
  const alphaLinolenicAcidInMeal = amountNutrientInMeal(
    "alphaLinoleicAcid",
    ingredients
  );
  const arachidonicAcidInMeal = amountNutrientInMeal(
    "arachidonicAcid",
    ingredients
  );
  const epaInMeal = amountNutrientInMeal("epa", ingredients);
  const dhaInMeal = amountNutrientInMeal("dha", ingredients);

  let calorieGoal: number | null = null;
  if (dog.calorieGoal === undefined) {
    calorieGoal = getCalculatedCalorieGoal(dog, authUserQuery.data.kgOrLb);
  } else {
    calorieGoal = dog.calorieGoal;
  }

  if (!calorieGoal) {
    return null;
  }

  const nutrientReqs = getNutrientReqs(
    nutrientGuideline,
    nutrientStandardCategory,
    proteinInMeal,
    calorieGoal,
    dog.weight,
    dog.expectedWeight,
    dog.breedSize,
    authUserQuery.data.kgOrLb,
    dog.birthDate,
    linoleicAcidInMeal,
    alphaLinolenicAcidInMeal,
    arachidonicAcidInMeal,
    epaInMeal,
    dhaInMeal,
    dog.activityLevel
  );
  if (nutrientReqs === null) {
    return null;
  }
  const [nutrientReqsMin, nutrientReqsMax] = nutrientReqs;

  return (
    <>
      <StyledHeading2>Nutrients (daily)</StyledHeading2>
      <StyledNutritionalTable
        title="Calorie goal"
        printModeRecipe={printModeRecipe}
      >
        <NutrientRowCalories
          ingredients={ingredients}
          calorieGoal={calorieGoal}
        />
      </StyledNutritionalTable>

      <StyledNutritionalTable
        title="Macronutrients"
        printModeRecipe={printModeRecipe}
      >
        <NutrientRowSingle
          nutrientStandard={"protein"}
          nutrientIngredient={"protein"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"fat"}
          nutrientIngredient={"fat"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"carbohydrate"}
          nutrientIngredient={"carbohydrate"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"water"}
          nutrientIngredient={"water"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"ash"}
          nutrientIngredient={"ash"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
      </StyledNutritionalTable>

      <StyledNutritionalTable
        title="Amino acids"
        printModeRecipe={printModeRecipe}
      >
        <NutrientRowSingle
          nutrientStandard={"tryptophan"}
          nutrientIngredient={"tryptophan"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"threonine"}
          nutrientIngredient={"threonine"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"isoleucine"}
          nutrientIngredient={"isoleucine"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"leucine"}
          nutrientIngredient={"leucine"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"lysine"}
          nutrientIngredient={"lysine"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"methionine"}
          nutrientIngredient={"methionine"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSum
          nutrientStandard={"methionineAndCystine"}
          nutrientIngredient1={"methionine"}
          nutrientIngredient2={"cystine"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
        />
        <NutrientRowSingle
          nutrientStandard={"phenylalanine"}
          nutrientIngredient={"phenylalanine"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSum
          nutrientStandard={"phenylalanineAndTyrosine"}
          nutrientIngredient1={"phenylalanine"}
          nutrientIngredient2={"tyrosine"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
        />
        <NutrientRowSingle
          nutrientStandard={"valine"}
          nutrientIngredient={"valine"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"arginine"}
          nutrientIngredient={"arginine"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"histidine"}
          nutrientIngredient={"histidine"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
      </StyledNutritionalTable>

      <StyledNutritionalTable
        title="Fatty acids"
        printModeRecipe={printModeRecipe}
      >
        <NutrientRowSingle
          nutrientStandard={"linoleicAcid"}
          nutrientIngredient={"linoleicAcid"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"alphaLinoleicAcid"}
          nutrientIngredient={"alphaLinoleicAcid"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"arachidonicAcid"}
          nutrientIngredient={"arachidonicAcid"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowRatio
          nutrientStandard={"linoleicToAlphaLinoleicAcids"}
          nutrientIngredient1={"linoleicAcid"}
          nutrientIngredient2={"alphaLinoleicAcid"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
        />
        <NutrientRowSum
          nutrientStandard={"epaAndDha"}
          nutrientIngredient1={"epa"}
          nutrientIngredient2={"dha"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
        />
      </StyledNutritionalTable>

      <StyledNutritionalTable
        title="Carbohydrates"
        printModeRecipe={printModeRecipe}
      >
        <NutrientRowSingle
          nutrientStandard={"fiber"}
          nutrientIngredient={"fiber"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
      </StyledNutritionalTable>

      <StyledNutritionalTable
        title="Minerals"
        printModeRecipe={printModeRecipe}
      >
        <NutrientRowSingle
          nutrientStandard={"calcium"}
          nutrientIngredient={"calcium"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"iron"}
          nutrientIngredient={"iron"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"magnesium"}
          nutrientIngredient={"magnesium"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"phosphorus"}
          nutrientIngredient={"phosphorus"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowRatio
          nutrientStandard={"calciumToPhosphorus"}
          nutrientIngredient1={"calcium"}
          nutrientIngredient2={"phosphorus"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
        />
        <NutrientRowSingle
          nutrientStandard={"potassium"}
          nutrientIngredient={"potassium"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"chloride"}
          nutrientIngredient={"chloride"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientInfoModal={() => {
            setInfoModalNutrient("chloride");
            setInfoModalOpen(true);
          }}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"sodium"}
          nutrientIngredient={"sodium"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"zinc"}
          nutrientIngredient={"zinc"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"copper"}
          nutrientIngredient={"copper"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowRatio
          nutrientStandard={"zincToCopper"}
          nutrientIngredient1={"zinc"}
          nutrientIngredient2={"copper"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
        />
        <NutrientRowSingle
          nutrientStandard={"manganese"}
          nutrientIngredient={"manganese"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"selenium"}
          nutrientIngredient={"selenium"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"iodine"}
          nutrientIngredient={"iodine"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
          openNutrientInfoModal={() => {
            setInfoModalNutrient("iodine");
            setInfoModalOpen(true);
          }}
        />
      </StyledNutritionalTable>

      <StyledNutritionalTable
        title="Vitamins"
        printModeRecipe={printModeRecipe}
      >
        <NutrientRowSingle
          nutrientStandard={"thiaminB1"}
          nutrientIngredient={"thiaminB1"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"riboflavinB2"}
          nutrientIngredient={"riboflavinB2"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"niacinB3"}
          nutrientIngredient={"niacinB3"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"pantothenicAcidB5"}
          nutrientIngredient={"pantothenicAcidB5"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"vitaminB6"}
          nutrientIngredient={"vitaminB6"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"folicAcidB9"}
          nutrientIngredient={"folicAcidB9"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"choline"}
          nutrientIngredient={"choline"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
          openNutrientInfoModal={() => {
            setInfoModalNutrient("choline");
            setInfoModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"vitaminB12"}
          nutrientIngredient={"vitaminB12"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"vitaminA"}
          nutrientIngredient={"vitaminA"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"vitaminE"}
          nutrientIngredient={"vitaminE"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"cholecalciferolD3"}
          nutrientIngredient={"cholecalciferolD3"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
        <NutrientRowSingle
          nutrientStandard={"phylloquinoneK"}
          nutrientIngredient={"phylloquinoneK"}
          ingredients={ingredients}
          nutrientReqsMin={nutrientReqsMin}
          nutrientReqsMax={nutrientReqsMax}
          openNutrientFindIngredientModal={(nutrient) => {
            setNutrientFindIngredient(nutrient);
            setNutrientFindIngredientModalOpen(true);
          }}
        />
      </StyledNutritionalTable>
      <NutrientInfoModal
        nutrient={infoModalNutrient}
        open={infoModalOpen}
        setOpen={setInfoModalOpen}
      />
      <NutrientFindIngredientModal
        nutrient={nutrientFindIngredient}
        open={nutrientFindIngredientModalOpen}
        setOpen={setNutrientFindIngredientModalOpen}
        recipe={recipe}
      />
    </>
  );
};

const StyledHeading2 = styled(Heading2)`
  margin-top: ${(props) => props.theme.spacing3};
  color: ${(props) => props.theme.black4};

  @media print {
    margin-top: ${(props) => props.theme.spacing2};
  }
`;

const StyledNutritionalTable = styled(NutritionalTable)<{
  printModeRecipe: boolean;
}>`
  margin-top: ${(props) => props.theme.spacing3};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-top: ${(props) => props.theme.spacing2};
  }
  @media print {
    margin-top: ${(props) => props.theme.spacing2};
  }

  &:last-child {
    @media print {
      padding-bottom: 330px;
      padding-bottom: ${(props) => props.printModeRecipe && 0};
    }
  }
`;

export default NutrientsPart;
