import React, { useContext } from "react";
import ReactSelect, { SingleValue, StylesConfig } from "react-select";
import styled from "styled-components";
import useChangeSelectedDog from "../../../hooks/api/useChangeSelectedDog";
import useGetAuthUser from "../../../hooks/api/useGetAuthUser";
import useGetDogs from "../../../hooks/api/useGetDogs";
import theme from "../../../style/theme";
import CustomSwitcherArrow from "./CustomSwitcherArrow";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import NavigationText from "../../shared/text/NavigationText";
import useLeavingDogLogic from "../../../hooks/useLeavingDogLogic";
import DogBannersContext from "../../../contexts/dogBannersContext/DogBannersContext";
import WrapLink from "../../shared/WrapLink";
import { useHistory } from "react-router-dom";
import useIsValidSubscription from "../../../hooks/useIsValidSubscription";

interface Props {
  mobileMenuOpen: boolean;
}

const DogSwitcher = ({ mobileMenuOpen }: Props): JSX.Element => {
  const authUserQuery = useGetAuthUser();

  const validSubscription = useIsValidSubscription(
    authUserQuery.data === undefined
      ? undefined
      : authUserQuery.data.stripeSubscription,
    authUserQuery.data === undefined
      ? undefined
      : authUserQuery.data.stripeSubscriptionTimestamp
  );

  const getDogsQuery = useGetDogs({
    enabled: !!authUserQuery.data && validSubscription,
  });

  const changeSelectedDogMutation = useChangeSelectedDog();

  const leavingDogLogic = useLeavingDogLogic();

  const dogBannersContextValue = useContext(DogBannersContext);

  const history = useHistory();

  const selectedDog =
    getDogsQuery.data &&
    authUserQuery.data &&
    getDogsQuery.data.find((dog) => authUserQuery.data.selectedDog === dog.id);

  const changeSelectedDog = (
    newSelectedDogOption: SingleValue<{ label: string; value: string }>
  ) => {
    const callback = () => {
      if (newSelectedDogOption) {
        changeSelectedDogMutation.mutate({
          selectedDog: newSelectedDogOption.value,
        });
      }

      if (
        dogBannersContextValue &&
        dogBannersContextValue.dogBanners.length > 0
      ) {
        dogBannersContextValue.setDogBanners([]);
      }

      if (window.location.pathname.match(/\/recipes\/./)) {
        history.push("/recipes");
      }
    };

    leavingDogLogic(callback, null);
  };

  const customStylesSelect: StylesConfig<
    { label: string; value: string },
    false
  > = {
    control: (_, state) => ({
      backgroundColor: state.isFocused ? theme.green2 : theme.white,
      borderTopLeftRadius: "1rem", // 16px
      borderTopRightRadius: "1rem", // 16px
      borderBottomLeftRadius: state.menuIsOpen ? 0 : "1rem", // 16px
      borderBottomRightRadius: state.menuIsOpen ? 0 : "1rem", // 16px
      display: "flex",
      alignItems: "center",
      fontSize: theme.navigationTextSize,
      fontFamily: "DM Sans Medium, sans-serif",
      fontWeight: 400,
      borderStyle: "solid",
      borderWidth: "0.125rem", // 2px
      borderColor: theme.green4,
      paddingTop: theme.spacing1,
      paddingBottom: theme.spacing1,
      paddingLeft: theme.spacing2,
      paddingRight: theme.spacing2,
      transition: state.menuIsOpen
        ? "none"
        : "background-color " + theme.transitionValue,

      outlineWidth: "0.125rem", // 2px
      outlineStyle: state.isFocused && !state.menuIsOpen ? "solid" : "none",
      outlineOffset: "0.125rem", // 2px
      outlineColor: theme.green4,

      "&:hover": {
        backgroundColor: theme.green2,
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: 0,
      color: theme.green4,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
      paddingRight: theme.halfSpacing1,
      textOverflow: "ellipsis",
      cursor: "default",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme.green2,
      margin: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: "1rem", // 16px
      borderBottomRightRadius: "1rem", // 16px
      paddingTop: "calc(" + theme.spacing1 + " - " + theme.halfSpacing1 + ")",
      paddingBottom:
        "calc(" + theme.spacing1 + " - " + theme.halfSpacing1 + ")",
      paddingLeft: "calc(" + theme.spacing2 + " - " + theme.halfSpacing1 + ")",
      paddingRight: "calc(" + theme.spacing2 + " - " + theme.halfSpacing1 + ")",
      borderStyle: "solid",
      borderWidth: "0.125rem", // 2px
      borderColor: theme.green4,
      borderTopStyle: "none",
      fontSize: theme.navigationTextSize,
      fontFamily: "DM Sans Medium, sans-serif",
      fontWeight: 400,
      color: theme.green4,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    option: (_, state) => ({
      margin: "0.5rem", // 8px
      cursor: "default",
      transition:
        "color " +
        theme.transitionValue +
        ", text-decoration-color " +
        theme.transitionValue,
      textDecoration: state.isSelected ? "underline" : "none",
      textDecorationColor: theme.green4,
      color: state.isFocused ? theme.green3 : theme.green4,
      outlineWidth: "0.125rem", // 2px
      outlineStyle: state.isFocused ? "solid" : "none",
      outlineOffset: "0.125rem", // 2px
      outlineColor: theme.green3,
      textOverflow: "ellipsis",
      overflow: "hidden",
      "&:hover": {
        color: theme.green3,
        borderStyle: "none",
        textDecorationColor: theme.green3,
      },
    }),
  };

  const options =
    getDogsQuery.data &&
    getDogsQuery.data
      .map((dog) => {
        return { label: dog.name, value: dog.id };
      })
      .sort((a, b) => {
        const aCompare = a.label.toLowerCase();
        const bCompare = b.label.toLowerCase();
        if (aCompare < bCompare) {
          return -1;
        }
        if (aCompare > bCompare) {
          return 1;
        }
        return 0;
      });

  return (
    <Container open={mobileMenuOpen}>
      {getDogsQuery.data && getDogsQuery.data.length > 0 ? (
        <ReactSelect
          options={options}
          value={
            selectedDog
              ? { label: selectedDog.name, value: selectedDog.id }
              : null
          }
          onChange={changeSelectedDog}
          isSearchable={false}
          styles={customStylesSelect}
          formatOptionLabel={(option, formatOptionLabelMeta) =>
            formatOptionLabelMeta.context == "value" ? (
              <SelectedOptionContainer>
                <DogLabel>Dog</DogLabel>
                <SelectedDogSpan>{option.label}</SelectedDogSpan>
              </SelectedOptionContainer>
            ) : (
              option.label
            )
          }
          components={{
            IndicatorsContainer: CustomSwitcherArrow,
          }}
          placeholder={"Creating new dog..."}
          // menuIsOpen={true}
        />
      ) : (
        <>
          {window.location.pathname === "/dogs" ? (
            <StyledNoDogContainer as={"div"}>
              <StyledPlusIcon />
              <NavigationText>Add new dog</NavigationText>
            </StyledNoDogContainer>
          ) : (
            <StyledNoDogContainer to={"/dogs"}>
              <StyledPlusIcon />
              <NavigationText>Add new dog</NavigationText>
            </StyledNoDogContainer>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div<{ open: boolean }>`
  margin-bottom: ${(props) => props.theme.spacing2};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    display: ${(props) => !props.open && "none"};
  }
`;

const SelectedOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DogLabel = styled.span`
  font-size: ${(props) => props.theme.smallTextSize};
  margin-bottom: 0.25rem; // 4px
`;

const StyledNoDogContainer = styled(WrapLink)`
  color: ${(props) => props.theme.black4};
  background-color: ${(props) => props.theme.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  padding-left: ${(props) => props.theme.spacing2};
  padding-right: ${(props) => props.theme.spacing2};
  height: 5.4375rem; // 87px
  border-radius: 1rem; // 16px
  border-style: solid;
  border-width: 0.125rem; // 2px
  border-color: ${(props) => props.theme.green4};
  transition: background-color ${(props) => props.theme.transitionValue};

  &:hover {
    background-color: ${(props) => props.theme.green2};
  }
  &:focus {
    outline-width: 0.125rem; // 2px
    outline-style: solid;
    outline-offset: 0.125rem; // 2px
    outline-color: ${(props) => props.theme.green4};
    background-color: ${(props) => props.theme.green2};
  }
`;

const StyledPlusIcon = styled(PlusIcon)`
  width: 1.5rem; // 24px
  height: 1.5rem; // 24px
  margin-right: ${(props) => props.theme.halfSpacing1};
  color: ${(props) => props.theme.black4};
`;

const SelectedDogSpan = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default DogSwitcher;
