import React, { useState } from "react";
import MinYearDatePickerContext from "./MinYearDatePickerContext";

interface Props {
  children: React.ReactNode;
}

const MinYearDatePickerContextProvider = ({ children }: Props): JSX.Element => {
  const [minYear, setMinYear] = useState<number | null>(null);

  return (
    <MinYearDatePickerContext.Provider value={{ minYear, setMinYear }}>
      {children}
    </MinYearDatePickerContext.Provider>
  );
};

export default MinYearDatePickerContextProvider;
