import React, { useEffect, useState } from "react";
import styled from "styled-components";
import theme from "../../../../style/theme";
import EditableCheckbox from "../../../shared/form/editable/EditableCheckbox";
import EditableDatePicker from "../../../shared/form/editable/EditableDatePicker";
import EditableSelect from "../../../shared/form/editable/EditableSelect";
import LabelForm from "../../../shared/form/LabelForm";
import StyledLink from "../../../shared/StyledLink";
import Heading2 from "../../../shared/text/headings/Heading2";
import IntroductionText from "../../../shared/text/IntroductionText";
import EditableText from "../../../shared/form/editable/EditableText";
import EditableNumber from "../../../shared/form/editable/EditableNumber";
import InfoModalButton from "../../../shared/InfoModalButton";
import DogInputInfoModal from "../../../shared/DogInputInfoModal";
import EditableMultiCheckbox from "../../../shared/form/editable/EditableMultiCheckbox";
import isBreedSize from "../../../../utils/typeGuards/isBreedSize";
import checkIfPuppy from "../../../../services/checkIfPuppy";
import getDateNumberOfWeeksBack from "../../../../utils/date/getDateNumberOfWeeksBack";
import CalorieGoalBottom from "./CalorieGoalBottom";
import TwoChoiceModal from "./TwoChoiceModal";
import OneChoiceModal from "./OneChoiceModal";
import ExtraFieldsModal from "./ExtraFieldsModal";
import DogBannersArea from "./DogBannersArea";
import useGetAuthUser from "../../../../hooks/api/useGetAuthUser";
import {
  DogValuesNoIdsOptional,
  DogValuesNoIdsType,
  nutrientGuideline,
} from "../../../../types/dogTypes";
import { infoOptions } from "../../../../types/frontendTypes";
import getCalculatedCalorieGoal from "../../../../services/getCalculatedCalorieGoal";
import getValuesLabelsBreedSize from "../../../../services/getValuesLabelsBreedSize";
import getNutrientReqsName from "../../../../services/getNutrientReqsName";
import getNutrientStandardCategory from "../../../../services/getNutrientStandardCategory";
import { NutrientStandardCategory } from "../../../../types/standardTypes";

interface Props {
  dog: DogValuesNoIdsOptional;
  saveValue: (
    value: string | number | Date | boolean | null | undefined,
    property: DogValuesNoIdsType
  ) => void;
  dogId?: string;
}

const DogPartial = ({ dog, saveValue, dogId }: Props): JSX.Element | null => {
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [infoToShow, setInfoToShow] = useState<infoOptions>("expectedWeight");
  const [changed, setChanged] = useState<string[]>([]);
  const [nutrientReqsName, setNutrientReqsName] = useState<string | null>(null);
  const [nutrientReqsNameBannerPart, setNutrientReqsNameBannerPart] = useState<
    string | null
  >(null);

  const authUserQuery = useGetAuthUser();

  useEffect(() => {
    setChanged([]);
  }, [dogId]);

  let isPuppy: boolean | null = null;
  if (dog.birthDate && dog.breedSize && isBreedSize(dog.breedSize)) {
    isPuppy = checkIfPuppy(dog.birthDate, dog.breedSize);
  }

  useEffect(() => {
    if (!isPuppy && dog.expectedWeight) {
      saveValue(undefined, "expectedWeight");
    }
  }, [isPuppy]);

  useEffect(() => {
    if (dog.pregnancyStatus !== "gestation" && dog.matingDate) {
      saveValue(undefined, "matingDate");
    }
  }, [dog.pregnancyStatus]);

  useEffect(() => {
    if (dog.pregnancyStatus !== "lactation") {
      if (dog.parturitionDate) {
        saveValue(undefined, "parturitionDate");
      }
      if (dog.numberOfPuppies) {
        saveValue(undefined, "numberOfPuppies");
      }
    }
  }, [dog.pregnancyStatus]);

  useEffect(() => {
    setNutrientReqsNameBannerPart(null);
  }, [dogId]);

  if (!authUserQuery.data || authUserQuery.data.kgOrLb === null) {
    return null;
  }

  const valuesLabelsBreedSize = getValuesLabelsBreedSize(
    authUserQuery.data.kgOrLb
  );

  const valuesLabelsNutrientGuideline = new Map<nutrientGuideline, string>();
  valuesLabelsNutrientGuideline.set("nrc", "NRC");
  valuesLabelsNutrientGuideline.set("fediaf", "FEDIAF");
  valuesLabelsNutrientGuideline.set("aafco", "AAFCO");

  const calculatedCalorieGoal = getCalculatedCalorieGoal(
    dog,
    authUserQuery.data.kgOrLb
  );

  const addToChanged = (property: string): void => {
    if (!authUserQuery.data || authUserQuery.data.selectedDog === null) {
      return;
    }
    if (!dog.calorieGoal) {
      return;
    }

    if (!changed.includes(property)) {
      setChanged([...changed, property]);
    }
  };

  // Banner informing about change in nutritional requirements name
  let nutrientReqsNameNew: string | null = null;
  if (
    dog.birthDate !== undefined &&
    dog.breedSize !== undefined &&
    dog.pregnancyStatus !== undefined &&
    dog.nutrientGuideline !== undefined
  ) {
    const nutrientStandardCategory: NutrientStandardCategory =
      getNutrientStandardCategory(
        dog.birthDate,
        dog.breedSize,
        dog.pregnancyStatus === "gestation",
        dog.pregnancyStatus === "lactation"
      );

    nutrientReqsNameNew = getNutrientReqsName(
      dog.nutrientGuideline,
      nutrientStandardCategory
    );
  }
  if (
    nutrientReqsNameNew !== null &&
    nutrientReqsNameNew !== nutrientReqsName
  ) {
    setNutrientReqsNameBannerPart(nutrientReqsNameNew);
    setNutrientReqsName(nutrientReqsNameNew);
  }

  return (
    <>
      <StyledIntroductionText>
        We need some information about your dog to help you balance your
        recipes. Do you want to add another dog? Go to{" "}
        <StyledStyledLink to="/preferences">preferences</StyledStyledLink>.
      </StyledIntroductionText>
      <DogBannersArea
        changed={changed}
        clearChanged={() => setChanged([])}
        nutrientReqsNameBannerPart={nutrientReqsNameBannerPart}
        setNutrientReqsNameBannerPart={setNutrientReqsNameBannerPart}
      />
      <StyledFieldset>
        <StyledHeading2 asValue="legend">
          General dog information
        </StyledHeading2>
        <StyledLabel name="name">Name</StyledLabel>
        <StyledEditableText
          serverValue={dog.name}
          id={"name"}
          placeholder={"Add your dog's name"}
          onSave={(name) => saveValue(name, "name")}
        />

        <StyledLabel name="birthDate">Birth date</StyledLabel>
        <StyledEditableDatePicker
          serverValue={dog.birthDate}
          id={"birthDate"}
          onSave={(date) => {
            addToChanged("birth date");
            const dateUTC = new Date(
              Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
            );

            saveValue(dateUTC, "birthDate");
          }}
        />

        <StyledLabel name="breedSize">Breed size</StyledLabel>
        <StyledEditableSelect
          placeholder="Choose your dog’s breed size"
          id="breedSize"
          serverValue={dog.breedSize}
          valuesLabels={valuesLabelsBreedSize}
          onSave={(breedSize) => {
            addToChanged("breed size");

            saveValue(breedSize, "breedSize");
          }}
        />

        <StyledLabel name="weight">Weight</StyledLabel>
        <StyledEditableNumber
          serverValue={dog.weight}
          id={"weight"}
          placeholder={"Add your dog's weight"}
          onSave={(weight) => {
            addToChanged("weight");

            saveValue(weight, "weight");
          }}
          unit={authUserQuery.data.kgOrLb}
          colorWeightUnit={theme.black4}
        />

        {isPuppy && (
          <>
            <StyledLabel name="expectedWeight">
              Expected weight{" "}
              <StyledInfoModalButton
                clickHandler={() => {
                  setInfoToShow("expectedWeight");
                  setInfoModalOpen(true);
                }}
                ariaLabelIcon={
                  "More information about the expected weight input"
                }
              />
            </StyledLabel>
            <StyledEditableNumber
              serverValue={dog.expectedWeight}
              id={"expectedWeight"}
              placeholder={"Specify your dog's expected weight"}
              onSave={(value) => {
                addToChanged("expected weight");

                saveValue(value, "expectedWeight");
              }}
              unit={authUserQuery.data.kgOrLb}
              colorWeightUnit={theme.black4}
            />
          </>
        )}

        <StyledLabel name="activityLevel">
          Activity level (number)
          <StyledInfoModalButton
            clickHandler={() => {
              setInfoToShow("activityLevel");
              setInfoModalOpen(true);
            }}
            ariaLabelIcon={"More information about the activity level input"}
          />
        </StyledLabel>
        <StyledEditableNumber
          serverValue={dog.activityLevel}
          id={"activityLevel"}
          placeholder={"Specify your dog's activity level"}
          onSave={(activityLevel) => {
            addToChanged("activity level");

            saveValue(activityLevel, "activityLevel");
          }}
          noDecimals={true}
        />

        <StyledEditableCheckbox
          id={"spayedOrNeutered"}
          label={"Spayed or neutered"}
          onSave={(spayedOrNeutered) => {
            addToChanged("neuter status");

            saveValue(spayedOrNeutered, "spayedOrNeutered");
          }}
          serverValue={dog.spayedOrNeutered}
          disabled={!!dog.pregnancyStatus}
        />

        <EditableMultiCheckbox
          id1="gestation"
          label1="Gestation"
          id2="lactation"
          label2="Lactation"
          onSave={(value1, value2) => {
            addToChanged("pregnancy status");

            if (value1) {
              saveValue("gestation", "pregnancyStatus");
            } else if (value2) {
              saveValue("lactation", "pregnancyStatus");
            } else {
              saveValue(null, "pregnancyStatus");
            }
          }}
          serverValue1={dog.pregnancyStatus === "gestation"}
          serverValue2={dog.pregnancyStatus === "lactation"}
          bigLabel={"Pregnancy status"}
          disabled={dog.spayedOrNeutered || false}
        />

        {dog.pregnancyStatus === "gestation" && (
          <>
            <StyledLabelMarginTop name="matingDate">
              Mating date
            </StyledLabelMarginTop>
            <EditableDatePicker
              serverValue={dog.matingDate}
              id={"matingDate"}
              onSave={(date) => {
                addToChanged("mating date");

                const dateUTC = new Date(
                  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
                );
                saveValue(dateUTC, "matingDate");
              }}
            />
          </>
        )}

        {dog.pregnancyStatus === "lactation" && (
          <>
            <StyledLabelMarginTop name="parturitionDate">
              Parturition date{" "}
              <StyledInfoModalButton
                clickHandler={() => {
                  setInfoToShow("parturitionDate");
                  setInfoModalOpen(true);
                }}
                ariaLabelIcon={
                  "More information about the activity level input"
                }
              />
            </StyledLabelMarginTop>
            <StyledEditableDatePicker
              serverValue={dog.parturitionDate}
              id={"parturitionDate"}
              onSave={(date) => {
                addToChanged("parturition date");

                const dateUTC = new Date(
                  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
                );

                saveValue(dateUTC, "parturitionDate");
              }}
              minDate={getDateNumberOfWeeksBack(4)}
            />

            <StyledLabel name="numberOfPuppies">Number of puppies</StyledLabel>
            <EditableNumber
              serverValue={dog.numberOfPuppies}
              id={"numberOfPuppies"}
              placeholder={"Specify the number of puppies"}
              onSave={(activityLevel) => {
                addToChanged("number of puppies");

                saveValue(activityLevel, "numberOfPuppies");
              }}
              noDecimals={true}
            />
          </>
        )}

        <StyledLabelMarginTop name="calorieGoal">
          Calorie goal{" "}
          <StyledInfoModalButton
            clickHandler={() => {
              setInfoToShow("calorieGoal");
              setInfoModalOpen(true);
            }}
            ariaLabelIcon={"More information about the calorie goal input"}
          />
        </StyledLabelMarginTop>
        <EditableNumber
          serverValue={dog.calorieGoal || calculatedCalorieGoal || undefined}
          id={"calorieGoal"}
          placeholder={"Specify your dog's calorie goal"}
          onSave={(value) => saveValue(value, "calorieGoal")}
          unit={"kcal"}
          noDecimals={true}
          colorWeightUnit={theme.black4}
        />
        <CalorieGoalBottom
          calculatedCalorieGoal={calculatedCalorieGoal}
          resetHandler={() => {
            setChanged([]);

            saveValue(undefined, "calorieGoal");
          }}
          customValue={dog.calorieGoal !== undefined}
        />
      </StyledFieldset>
      <StyledFieldset>
        <StyledHeading2 asValue="legend">Feeding standards</StyledHeading2>
        <StyledLabel name="nutrientGuideline">
          Nutrient guideline{" "}
          <StyledInfoModalButton
            clickHandler={() => {
              setInfoToShow("nutrientGuideline");
              setInfoModalOpen(true);
            }}
            ariaLabelIcon={
              "More information about the nutrient guideline input"
            }
          />
        </StyledLabel>
        <StyledEditableSelect
          placeholder="Choose your dog's nutrient guideline"
          id="nutrientGuideline"
          serverValue={dog.nutrientGuideline}
          valuesLabels={valuesLabelsNutrientGuideline}
          onSave={(nutrientGuideline) => {
            saveValue(nutrientGuideline, "nutrientGuideline");
          }}
        />
      </StyledFieldset>
      <DogInputInfoModal
        open={infoModalOpen}
        setOpen={setInfoModalOpen}
        toShow={infoToShow}
      />
      <TwoChoiceModal />
      <OneChoiceModal />
      <ExtraFieldsModal />
    </>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  margin-bottom: ${(props) => props.theme.spacing3};
  color: ${(props) => props.theme.black4};
`;

const StyledStyledLink = styled(StyledLink)`
  color: ${(props) => props.theme.black4};

  &:hover {
    color: ${(props) => props.theme.black3};
  }
  &:focus {
    outline-color: ${(props) => props.theme.black3};
    color: ${(props) => props.theme.black3};
  }
`;

const StyledFieldset = styled.fieldset`
  padding: 0;
  border: none;

  &:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing3};
  }
`;

const StyledHeading2 = styled(Heading2)`
  margin-bottom: ${(props) => props.theme.spacing2};
  color: ${(props) => props.theme.black4};
`;

const StyledLabel = styled(LabelForm)`
  color: ${(props) => props.theme.black4};
  margin-bottom: 0.25rem; // 4px
`;

const StyledLabelMarginTop = styled(StyledLabel)`
  margin-top: ${(props) => props.theme.spacing2};
`;

const StyledEditableText = styled(EditableText)`
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const StyledEditableNumber = styled(EditableNumber)`
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const StyledEditableDatePicker = styled(EditableDatePicker)`
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const StyledEditableSelect = styled(EditableSelect)`
  margin-bottom: ${(props) => props.theme.spacing2};
` as typeof EditableSelect;

const StyledInfoModalButton = styled(InfoModalButton)`
  color: ${(props) => props.theme.black4};

  &:hover {
    color: ${(props) => props.theme.black3};
  }
  &:focus {
    color: ${(props) => props.theme.black3};
    outline-color: ${(props) => props.theme.black3};
  }
`;

const StyledEditableCheckbox = styled(EditableCheckbox)`
  margin-bottom: ${(props) => props.theme.spacing2};
`;

export default DogPartial;
