import React from "react";
import styled from "styled-components";
import theme from "../../../../style/theme";
import StyledCheckbox from "../StyledCheckbox";

interface Props {
  label: string;
  value: boolean;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  multi: boolean;

  disabled?: boolean;
  className?: string;
}

const SimpleCheckbox = ({ setValue, ...rest }: Props): JSX.Element => {
  return (
    <StyledStyledCheckbox
      {...rest}
      changeHandler={(value) => setValue(value)}
      checkboxColor={theme.green4}
      color1={theme.green1}
      color2={theme.white}
      checkboxBorderColor={theme.green4}
    />
  );
};

const StyledStyledCheckbox = styled(StyledCheckbox)`
  &::before {
    transition: background-color ${(props) => props.theme.transitionValue},
      border-color ${(props) => props.theme.transitionValue};
  }
` as typeof StyledCheckbox;

export default SimpleCheckbox;
