import React, { useState } from "react";
import TwoChoiceModalOpenContext from "./TwoChoiceModalOpenContext";

interface Props {
  children: React.ReactNode;
}

const TwoChoiceModalOpenContextProvider = ({
  children,
}: Props): JSX.Element => {
  const [twoChoiceModalOpen, setTwoChoiceModalOpen] = useState(false);

  return (
    <TwoChoiceModalOpenContext.Provider
      value={{ twoChoiceModalOpen, setTwoChoiceModalOpen }}
    >
      {children}
    </TwoChoiceModalOpenContext.Provider>
  );
};

export default TwoChoiceModalOpenContextProvider;
