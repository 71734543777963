import React from "react";
import styled from "styled-components";
import heroImageSrc from "../../../../assets/images/hero-image.jpg";
import {
  clipPathHero,
  clipPathHeroMobile,
} from "../../../../constants/constants";
import Heading1 from "../../../shared/text/headings/Heading1";
import IntroductionText from "../../../shared/text/IntroductionText";

const HeroSection = (): JSX.Element => {
  return (
    <StyledContainer>
      <LeftPanel>
        <TextContainer>
          <StyledHeading1>
            Create nutritionally balanced dog meals without breaking a sweat
          </StyledHeading1>
          <StyledIntroductionText>
            It should be easy to feed your dog a science based diet. The
            Mealment application helps you create homemade meals and share your
            recipes with like-minded people.
          </StyledIntroductionText>
        </TextContainer>
      </LeftPanel>
      <RightPanel>
        <FilterImage></FilterImage>
        <HeroImage
          src={heroImageSrc}
          alt="A smiling girl sitting in the grass and holding her arms around two dogs"
        />
      </RightPanel>
    </StyledContainer>
  );
};

const StyledContainer = styled.section`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  min-height: 48rem; // 768px

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    flex-direction: column;
    height: auto;
  }
`;

const LeftPanel = styled.div`
  background-color: ${(props) => props.theme.green2};
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  justify-content: flex-end;
  box-sizing: border-box;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    justify-content: center;
  }
`;

const RightPanel = styled.div`
  background-color: ${(props) => props.theme.green2};
  width: 100%;
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    height: 35rem; // 560px
  }

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    height: 25rem; // 400px
  }
`;

const HeroImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(${clipPathHero.toString()});

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    clip-path: polygon(${clipPathHeroMobile.toString()});
  }
`;

const FilterImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  clip-path: polygon(${clipPathHero.toString()});
  background-color: ${(props) => props.theme.black4};
  opacity: 0.3;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    clip-path: polygon(${clipPathHeroMobile.toString()});
  }
`;

const TextContainer = styled.div`
  margin-right: ${(props) => props.theme.spacing4};
  margin-left: ${(props) => props.theme.spacing1};
  max-width: ${(props) => props.theme.widthSmallText};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-right: ${(props) => props.theme.spacing1};
    max-width: ${(props) => props.theme.halfWidth};
  }
`;

const StyledHeading1 = styled(Heading1)`
  margin-bottom: ${(props) => props.theme.spacing2};
  color: ${(props) => props.theme.green4};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    // 56px height of mobile header. 40px spacing3
    margin-top: ${40 + 56 + "px"};
  }
  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    font-size: ${(props) => props.theme.heading1SizeMobile};
  }
`;

const StyledIntroductionText = styled(IntroductionText)`
  margin-bottom: ${(props) => props.theme.spacing2};
  color: ${(props) => props.theme.green4};
`;

export default HeroSection;
