import React, { useEffect, useState } from "react";
import { Switch } from "react-router-dom";
import Page404 from "./components/screens/public/page404/Page404";
import Landing from "./components/screens/public/landing/Landing";
import Login from "./components/screens/public/login/Login";
import ErrorPage from "./components/screens/public/errorPage/ErrorPage";
import ProtectedRouteHandler from "./components/screens/protectedRouteHandler/ProtectedRouteHandler";
import UnprotectedRouteHandler from "./components/screens/UnprotectedRouteHandler";
import VerifyEmailSpecific from "./components/screens/public/verifyEmailSpecific/VerifyEmailSpecific";
import Recipes from "./components/screens/protected/recipes/Recipes";
import * as smoothscroll from "smoothscroll-polyfill";
import Pricing from "./components/screens/public/pricing/Pricing";
import PrivacyPolicy from "./components/screens/public/privacyPolicy/PrivacyPolicy";
import Signup from "./components/screens/public/signup/Signup";
import AuthEmailContextProvider from "./contexts/authEmailContext/AuthEmailContextProvider";
import ForgotPassword from "./components/screens/public/forgotPassword/ForgotPassword";
import Verify from "./components/screens/protected/verify/Verify";
import Ingredients from "./components/screens/protected/ingredients/Ingredients";
import ChangePassword from "./components/screens/public/changePassword/ChangePassword";
import AppContainer from "./components/shared/AppContainer";
import Preferences from "./components/screens/protected/preferences/Preferences";
import ColoredTopPartContextProvider from "./contexts/coloredTopPartContext/ColoredTopPartContextProvider";
import Dog from "./components/screens/protected/dogs/Dog";
import { QueryClientProvider, useQueryClient } from "react-query";
import Disclaimer from "./components/screens/public/disclaimer/Disclaimer";
import TermsOfUse from "./components/screens/public/termsOfUse/TermsOfUse";
import TwoChoiceModalOpenContextProvider from "./contexts/twoChoiceModalOpenContext/TwoChoiceModalOpenContextProvider";
import DogsModalCallbackContextProvider from "./contexts/dogsModalCallbackContext/DogsModalCallbackContextProvider";
import OneChoiceModalOpenContextProvider from "./contexts/oneChoiceModalOpenContext/TwoChoiceModalOpenContextProvider";
import ExtraFieldsModalOpenContextProvider from "./contexts/extraFieldsModalOpenContext/ExtraFieldsModalOpenContextProvider";
import DogBannersContextProvider from "./contexts/dogBannersContext/DogBannersContextProvider";
import useGetDogs from "./hooks/api/useGetDogs";
import useGetAuthUser from "./hooks/api/useGetAuthUser";
import useChangeSelectedDog from "./hooks/api/useChangeSelectedDog";
import useGetLoginStatus from "./hooks/api/useGetLoginStatus";
import LoginErrorMessageContextProvider from "./contexts/loginErrorMessageContext/loginErrorMessageContextProvider";
import VerificationTokenMessageContextProvider from "./contexts/verificationTokenMessageContext/VerificationTokenMessageContextProvider";
import theme from "./style/theme";
import RecipesName from "./components/screens/protected/recipesName/RecipesName";
import IngredientsName from "./components/screens/protected/ingredientsName/IngredientsName";
import RouteHandler from "./components/screens/RouteHandler";
import MyAccount from "./components/screens/protected/myAccount/MyAccount";
import AuthModalContextProvider from "./contexts/authModalContext/AuthModalContextProvider";
import useIsValidSubscription from "./hooks/useIsValidSubscription";
import { accountUrl } from "./constants/constants";
import CookiePolicy from "./components/screens/public/cookiePolicy/CookiePolicy";

smoothscroll.polyfill();

const App = (): JSX.Element => {
  const [forgotPasswordTokenMessage, setForgotPasswordTokenMessage] = useState<
    string | null
  >(null);

  const queryClient = useQueryClient();

  const loggedInStatusQuery = useGetLoginStatus();
  const authUserQuery = useGetAuthUser({
    enabled: !!loggedInStatusQuery?.data?.loggedIn,
  });

  const validSubscription = useIsValidSubscription(
    authUserQuery.data === undefined
      ? undefined
      : authUserQuery.data.stripeSubscription,
    authUserQuery.data === undefined
      ? undefined
      : authUserQuery.data.stripeSubscriptionTimestamp
  );

  const getDogsQuery = useGetDogs({
    enabled: !!authUserQuery.data?.selectedDog && validSubscription,
  });

  const changeSelectedDogMutation = useChangeSelectedDog();

  useEffect(() => {
    if (authUserQuery.data?.selectedDog === null) {
      const newSelected =
        getDogsQuery.data && getDogsQuery.data[0] && getDogsQuery.data[0].id;

      if (newSelected) {
        changeSelectedDogMutation.mutate({ selectedDog: newSelected });
      }
    }
  }, [getDogsQuery.isSuccess === true]);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <AuthEmailContextProvider>
          <ColoredTopPartContextProvider>
            <DogsModalCallbackContextProvider>
              <TwoChoiceModalOpenContextProvider>
                <OneChoiceModalOpenContextProvider>
                  <ExtraFieldsModalOpenContextProvider>
                    <DogBannersContextProvider>
                      <LoginErrorMessageContextProvider>
                        <VerificationTokenMessageContextProvider>
                          <AuthModalContextProvider>
                            <Switch>
                              <UnprotectedRouteHandler path="/" exact={true}>
                                <Landing />
                              </UnprotectedRouteHandler>

                              <UnprotectedRouteHandler
                                path="/login"
                                exact={true}
                              >
                                <Login />
                              </UnprotectedRouteHandler>
                              <UnprotectedRouteHandler
                                path="/signup"
                                exact={true}
                              >
                                <Signup />
                              </UnprotectedRouteHandler>
                              <UnprotectedRouteHandler
                                path="/forgot"
                                exact={true}
                              >
                                <ForgotPassword
                                  forgotPasswordTokenMessage={
                                    forgotPasswordTokenMessage
                                  }
                                  setForgotPasswordTokenMessage={
                                    setForgotPasswordTokenMessage
                                  }
                                />
                              </UnprotectedRouteHandler>

                              <UnprotectedRouteHandler
                                path="/pricing"
                                exact={true}
                              >
                                <Pricing />
                              </UnprotectedRouteHandler>
                              <UnprotectedRouteHandler
                                path="/privacy"
                                exact={true}
                              >
                                <PrivacyPolicy />
                              </UnprotectedRouteHandler>
                              <UnprotectedRouteHandler
                                path="/terms-of-use"
                                exact={true}
                              >
                                <TermsOfUse />
                              </UnprotectedRouteHandler>
                              <UnprotectedRouteHandler
                                path="/disclaimer"
                                exact={true}
                              >
                                <Disclaimer />
                              </UnprotectedRouteHandler>
                              <UnprotectedRouteHandler
                                path="/cookie"
                                exact={true}
                              >
                                <CookiePolicy />
                              </UnprotectedRouteHandler>

                              <RouteHandler path="/verify" exact={true}>
                                <Verify />
                              </RouteHandler>

                              <ProtectedRouteHandler path="/dogs" exact={true}>
                                <AppContainer
                                  title="Dog"
                                  selectedMenuItem="dogs"
                                  width={theme.halfWidth}
                                >
                                  <Dog />
                                </AppContainer>
                              </ProtectedRouteHandler>

                              <ProtectedRouteHandler
                                path="/recipes"
                                exact={true}
                              >
                                <AppContainer
                                  title="Recipes"
                                  selectedMenuItem="recipes"
                                  width={theme.mainWidth}
                                >
                                  <Recipes />
                                </AppContainer>
                              </ProtectedRouteHandler>
                              <ProtectedRouteHandler
                                path="/recipes/:recipeId"
                                exact={false}
                              >
                                <AppContainer
                                  selectedMenuItem="recipes"
                                  width={theme.mainWidth}
                                >
                                  <RecipesName />
                                </AppContainer>
                              </ProtectedRouteHandler>

                              <ProtectedRouteHandler
                                path="/ingredients"
                                exact={true}
                              >
                                <AppContainer
                                  title="Ingredients"
                                  selectedMenuItem="ingredients"
                                  width={theme.mainWidth}
                                >
                                  <Ingredients />
                                </AppContainer>
                              </ProtectedRouteHandler>
                              <ProtectedRouteHandler
                                path="/ingredients/:ingredientId"
                                exact={false}
                              >
                                <AppContainer
                                  selectedMenuItem="ingredients"
                                  width={theme.mainWidth}
                                >
                                  <IngredientsName />
                                </AppContainer>
                              </ProtectedRouteHandler>

                              <ProtectedRouteHandler
                                path="/preferences"
                                exact={true}
                              >
                                <AppContainer
                                  title="Preferences"
                                  selectedMenuItem="preferences"
                                  width={theme.halfWidth}
                                >
                                  <Preferences />
                                </AppContainer>
                              </ProtectedRouteHandler>
                              <ProtectedRouteHandler
                                path={accountUrl}
                                exact={true}
                              >
                                <AppContainer
                                  title="My account"
                                  selectedMenuItem="account"
                                  width={theme.halfWidth}
                                >
                                  <MyAccount />
                                </AppContainer>
                              </ProtectedRouteHandler>

                              <RouteHandler
                                path="/verify-email/:verifyToken"
                                exact={true}
                              >
                                <VerifyEmailSpecific />
                              </RouteHandler>
                              <RouteHandler
                                path="/change-password/:forgotPasswordToken"
                                exact={true}
                              >
                                <ChangePassword
                                  forgotPasswordTokenMessage={
                                    forgotPasswordTokenMessage
                                  }
                                  setForgotPasswordTokenMessage={
                                    setForgotPasswordTokenMessage
                                  }
                                />
                              </RouteHandler>
                              <RouteHandler path="/error" exact={true}>
                                <ErrorPage />
                              </RouteHandler>
                              <UnprotectedRouteHandler>
                                <Page404 />
                              </UnprotectedRouteHandler>
                            </Switch>
                          </AuthModalContextProvider>
                        </VerificationTokenMessageContextProvider>
                      </LoginErrorMessageContextProvider>
                    </DogBannersContextProvider>
                  </ExtraFieldsModalOpenContextProvider>
                </OneChoiceModalOpenContextProvider>
              </TwoChoiceModalOpenContextProvider>
            </DogsModalCallbackContextProvider>
          </ColoredTopPartContextProvider>
        </AuthEmailContextProvider>
      </div>
    </QueryClientProvider>
  );
};

export default App;
