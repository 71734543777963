import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { BACKEND_URL } from "../../constants/constants";

const useVerifyEmail = (): UseMutationResult<
  void,
  AxiosError<{ error: string }>,
  string
> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (verifyToken: string) => {
      await axios.patch(`${BACKEND_URL}/api/users/verified`, {
        verifyToken,
      });
    },
    {
      onError: (error: AxiosError<{ error: string }>) => {
        console.log(error.response?.data.error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["loggedInUser"], { exact: true });
      },
    }
  );
};

export default useVerifyEmail;
