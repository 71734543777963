import React from "react";
import styled from "styled-components";
import useDeleteRecipe from "../../../hooks/api/useDeleteRecipe";
import Button from "../../shared/Button";
import Modal from "../../shared/Modal";
import IntroductionText from "../../shared/text/IntroductionText";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dogId: string | null;
  recipeId: string | null;
}

const DeleteRecipeModal = ({
  open,
  setOpen,
  dogId,
  recipeId,
}: Props): JSX.Element => {
  const deleteRecipeMutation = useDeleteRecipe(dogId);

  const deleteRecipe = () => {
    if (recipeId === null) {
      return;
    }

    deleteRecipeMutation.mutate(recipeId);
  };

  return (
    <Modal
      title="Do you really want to delete?"
      open={open}
      setOpen={setOpen}
      closable={true}
    >
      <StyledIntroductionText>
        If you delete this recipe, it will be gone forever.
      </StyledIntroductionText>
      <ButtonContainer>
        <PurpleButton
          eventHandler={() => {
            deleteRecipe();
            setOpen(false);
          }}
        >
          Delete
        </PurpleButton>
        <WhiteButton
          eventHandler={() => {
            setOpen(false);
          }}
        >
          Keep
        </WhiteButton>
      </ButtonContainer>
    </Modal>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};
  margin-top: ${(props) => props.theme.spacing2};
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const PurpleButton = styled(Button)`
  background-color: ${(props) => props.theme.purple2};
  color: ${(props) => props.theme.purple4};
  width: 50%;
  margin-right: 0.25rem;

  &:hover {
    background-color: ${(props) => props.theme.purple1};
  }
  &:focus {
    background-color: ${(props) => props.theme.purple1};
    outline-color: ${(props) => props.theme.purple1};
  }
`;

const WhiteButton = styled(Button)`
  background-color: ${(props) => props.theme.white};
  color: ${(props) => props.theme.black4};
  width: 50%;

  &:focus {
    outline-color: ${(props) => props.theme.black4};
  }
`;

export default DeleteRecipeModal;
