import React, { useState } from "react";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";
import styled from "styled-components";
import theme from "../../../../style/theme";
import { allColors } from "../../../../types/frontendTypes";
import ErrorMessage from "../ErrorMessage";
import StyledCheckbox from "../StyledCheckbox";

interface Props {
  label: string;
  register: UseFormRegisterReturn;
  color1: allColors;
  color2: allColors;
  checkBoxColor: allColors;
  checkboxBorderColor: allColors;
  className?: string;
  error?: FieldError | undefined;
  errorMessage?: string;
}

const Checkbox = ({
  label,
  register,
  color1,
  color2,
  checkBoxColor,
  checkboxBorderColor,
  className,
  error,
  errorMessage,
}: Props): JSX.Element => {
  const [checked, setChecked] = useState(false);

  return (
    <div>
      <StyledStyledCheckbox
        label={label}
        value={checked}
        changeHandler={(value) => {
          setChecked(value);
        }}
        id={register.name}
        color1={color1}
        color2={color2}
        checkboxColor={checkBoxColor}
        checkboxBorderColor={error ? theme.red3 : checkboxBorderColor}
        register={register}
        className={className}
      />
      {errorMessage && error && (
        <ErrorMessage message={errorMessage} idForDescribed={register.name} />
      )}
    </div>
  );
};

const StyledStyledCheckbox = styled(StyledCheckbox)`
  &::before {
    transition: background-color ${(props) => props.theme.transitionValue};
  }
`;

export default Checkbox;
