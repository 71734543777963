import React from "react";
import styled from "styled-components";
import InnerHTML from "dangerously-set-html-content";

const CookiePolicy = (): JSX.Element => {
  return (
    <Container>
      <InnerHTML
        html={`<div class="section__content">  <div name="termly-embed" data-id="2ff405bd-1a75-4b03-8f4b-91b00643505b" data-type="iframe"></div>  <script src="https://app.termly.io/embed-policy.min.js"></script> </div>
        `}
      />
    </Container>
  );
};

const Container = styled.div`
  max-width: ${(props) => props.theme.halfWidth};
  margin-top: ${(props) => props.theme.spacing2};
  margin-left: auto;
  margin-right: auto;
`;

export default CookiePolicy;
