import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Checkbox from "../form/normal/Checkbox";
import Input from "../form/normal/Input";
import LabelForm from "../form/LabelForm";
import SubmitButton from "../form/normal/SubmitButton";
import Line from "../Line";
import Text from "../text/Text";
import StyledLink from "../StyledLink";
import theme from "../../../style/theme";
import useSendVerificationEmail from "../../../hooks/api/useSendVerificationEmail";
import { emailRegex, passwordRegex } from "../../../utils/regexPatterns";
import {
  emailErrorMessage,
  passwordErrorMessage,
} from "../../../utils/errorMessagesAuth";
import HideRevealPassword from "./HideRevealPassword";
import useSignup from "../../../hooks/api/useSignup";
import LoginErrorMessageContext from "../../../contexts/loginErrorMessageContext/LoginErrorMessageContext";

interface SignupValues {
  emailSignup: string;
  passwordSignup: string;
  privacyPolicySignup: boolean;
}

interface Props {
  loginHandler: (email: string) => void;
  verificationHandler: (data: { email: string; password: string }) => void;
  savedEmail: string;
  className?: string;
}

const SignupPartial = ({
  loginHandler,
  verificationHandler,
  savedEmail,
  className,
}: Props): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<SignupValues>({
    defaultValues: {
      emailSignup: savedEmail,
    },
  });

  const signupMutation = useSignup();
  const sendVerificationEmailMutation = useSendVerificationEmail();

  const loginErrorMessageContextValue = useContext(LoginErrorMessageContext);

  const emailForm = watch("emailSignup");

  const submitHandler = (data: SignupValues) => {
    signupMutation.mutate(
      { email: data.emailSignup, password: data.passwordSignup },
      {
        onSuccess: () => {
          sendVerificationEmailMutation.mutate(data.emailSignup, {
            onSuccess: () => {
              verificationHandler({
                email: data.emailSignup,
                password: data.passwordSignup,
              });
            },
          });
        },
        onError(error) {
          if (loginErrorMessageContextValue) {
            if (error.response?.data.error === "Email is already registered") {
              loginErrorMessageContextValue.setLoginErrorMessage(
                "This email is already taken. Please log in."
              );
              loginHandler(emailForm);
            }
          }
        },
      }
    );
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(submitHandler)}
        className={className}
        noValidate
        autoComplete="on"
      >
        <EmailLabel name="emailSignup">Email</EmailLabel>
        <StyledInput
          register={register("emailSignup", {
            required: true,
            pattern: emailRegex,
          })}
          error={errors.emailSignup}
          errorMessage={emailErrorMessage}
          type={"email"}
          placeholder="name@youremail.com"
          autoComplete="email"
        />
        <PasswordLabel name="passwordSignup">Password</PasswordLabel>
        <ContainerPasswordInput>
          <PasswordInput
            register={register("passwordSignup", {
              required: true,
              pattern: passwordRegex,
            })}
            error={errors.passwordSignup}
            errorMessage={passwordErrorMessage}
            type={showPassword ? "text" : "password"}
            placeholder="•••••"
            autoComplete="new-password"
          />
          <HideRevealPassword
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        </ContainerPasswordInput>
        <PrivacyFieldset>
          <StyledTextMarginBottom asValue="legend">
            Read our{" "}
            <StyledStyledLink href="/privacy">Privacy Policy</StyledStyledLink>,{" "}
            <StyledStyledLink href="/terms-of-use">
              Terms of use
            </StyledStyledLink>
            {" and "}
            <StyledStyledLink href="/disclaimer">Disclaimer</StyledStyledLink>.
          </StyledTextMarginBottom>

          <StyledCheckbox
            label="I accept the Privacy Policy, Terms of use and Disclaimer."
            register={register("privacyPolicySignup", { required: true })}
            error={errors.privacyPolicySignup}
            errorMessage="You need to accept the privacy policy."
            color1={theme.green1}
            color2={theme.white}
            checkBoxColor={theme.green4}
            checkboxBorderColor={theme.green4}
          />
        </PrivacyFieldset>
        <ButtonContainer>
          <StyledSubmitButton text="Sign up" />
        </ButtonContainer>
      </form>
      <Line />
      <SwitchLinks>
        <StyledText>
          Already have an account?{" "}
          <StyledStyledLink
            asButton={true}
            clickHandler={() => loginHandler(emailForm)}
          >
            Log in
          </StyledStyledLink>
          .
        </StyledText>
      </SwitchLinks>
    </>
  );
};

const StyledSubmitButton = styled(SubmitButton)`
  background-color: ${(props) => props.theme.green3};
  color: ${(props) => props.theme.green1};

  &:hover {
    background-color: ${(props) => props.theme.green4};
  }
  &:focus {
    outline-color: ${(props) => props.theme.green4};
    background-color: ${(props) => props.theme.green4};
  }
`;

const EmailLabel = styled(LabelForm)`
  color: ${(props) => props.theme.black4};
  margin-bottom: 0.25rem; // 4px
`;

const PasswordLabel = styled(EmailLabel)`
  margin-top: ${(props) => props.theme.spacing2};
`;

const StyledInput = styled(Input)`
  color: ${(props) => props.theme.green4};
  background-color: ${(props) => props.theme.green1};

  border-style: solid;
  border-width: 0.125rem; // 2px
  border-color: ${(props) => props.theme.green4};

  outline: none;

  &:hover {
    background-color: ${(props) => props.theme.white};
  }
  &:focus {
    background-color: ${(props) => props.theme.white};
    outline: none;
  }
  &::placeholder {
    color: ${(props) => props.theme.green3};
  }
`;

const PasswordInput = styled(StyledInput)`
  padding-right: 4rem; // 64px
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: ${(props) => props.theme.spacing2};
  margin-bottom: ${(props) => props.theme.spacing3};
`;

const SwitchLinks = styled.div`
  margin-top: ${(props) => props.theme.spacing3};
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.black4};
`;

const StyledTextMarginBottom = styled(StyledText)`
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const StyledStyledLink = styled(StyledLink)`
  color: ${(props) => props.theme.black4};

  &:hover {
    color: ${(props) => props.theme.black3};
  }
  &:focus {
    outline-color: ${(props) => props.theme.black3};
    color: ${(props) => props.theme.black3};
  }
`;

const PrivacyFieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin-top: ${(props) => props.theme.spacing2};
`;

const StyledCheckbox = styled(Checkbox)`
  color: ${(props) => props.theme.black4};
`;

const ContainerPasswordInput = styled.div`
  position: relative;
`;

export default SignupPartial;
