import React from "react";
import styled from "styled-components";
import Button from "../../../shared/Button";
import Modal from "../../../shared/Modal";
import IntroductionText from "../../../shared/text/IntroductionText";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShareModal = ({ open, setOpen }: Props): JSX.Element => {
  return (
    <Modal title="Share recipe" open={open} setOpen={setOpen} closable={true}>
      <StyledIntroductionText>
        We will be launching the feature of recipe sharing shortly. This feature
        will allow you to share your recipes with others with a simple link.
      </StyledIntroductionText>

      <PurpleButton
        eventHandler={() => {
          setOpen(false);
        }}
      >
        Close
      </PurpleButton>
    </Modal>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};
  margin-top: ${(props) => props.theme.spacing2};
`;

const PurpleButton = styled(Button)`
  background-color: ${(props) => props.theme.purple2};
  color: ${(props) => props.theme.purple4};
  width: 100%;

  &:hover {
    background-color: ${(props) => props.theme.purple1};
  }
  &:focus {
    background-color: ${(props) => props.theme.purple1};
    outline-color: ${(props) => props.theme.purple1};
  }
`;

export default ShareModal;
