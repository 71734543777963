import React, { useEffect, useState } from "react";
import useCreateDog from "../../../../hooks/api/useCreateDog";
import {
  DogValuesNoIdsOptional,
  DogValuesNoIdsType,
} from "../../../../types/dogTypes";
import DogPartial from "./DogPartial";

const NewDog = (): JSX.Element => {
  const [newDog, setNewDog] = useState<DogValuesNoIdsOptional>({
    spayedOrNeutered: false,
    pregnancyStatus: null,
  });

  const createDogMutation = useCreateDog();

  useEffect(() => {
    if (
      newDog.name &&
      newDog.birthDate &&
      newDog.breedSize &&
      newDog.weight &&
      newDog.activityLevel &&
      newDog.spayedOrNeutered !== undefined &&
      newDog.pregnancyStatus !== undefined &&
      newDog.nutrientGuideline
    ) {
      const mutationDog = {
        name: newDog.name,
        birthDate: newDog.birthDate,
        breedSize: newDog.breedSize,
        weight: newDog.weight,
        expectedWeight: newDog.expectedWeight,
        activityLevel: newDog.activityLevel,
        spayedOrNeutered: newDog.spayedOrNeutered,
        pregnancyStatus: newDog.pregnancyStatus,
        matingDate: newDog.matingDate,
        parturitionDate: newDog.parturitionDate,
        numberOfPuppies: newDog.numberOfPuppies,
        calorieGoal: newDog.calorieGoal,
        nutrientGuideline: newDog.nutrientGuideline,
      };
      createDogMutation.mutate(mutationDog);
    }
  }, [newDog]);

  const saveValue = (
    value: string | number | Date | boolean | null | undefined,
    property: DogValuesNoIdsType
  ) => {
    setNewDog((prevDog) => {
      const updatedDog = { ...prevDog, [property]: value };

      return updatedDog;
    });
  };

  return <DogPartial dog={newDog} saveValue={saveValue} />;
};

export default NewDog;
