const isValidUrl = (string: string): boolean => {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};

export default isValidUrl;
