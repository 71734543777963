import React, { useRef, useState } from "react";
import styled from "styled-components";
import ButtonText from "../../../../../shared/text/ButtonText";
import Text from "../../../../../shared/text/Text";
import WrapLink from "../../../../../shared/WrapLink";
import { ReactComponent as PlusIcon } from "../../../../../../assets/icons/plus.svg";
import { ReactComponent as MinusIcon } from "../../../../../../assets/icons/minus.svg";
import theme from "../../../../../../style/theme";
import { useMediaQuery } from "react-responsive";
import TextBold from "../../../../../shared/text/TextBold";
import {
  NutrientIngredientType,
  TableRowValueNestedTable,
} from "../../../../../../types/ingredientTypes";
import {
  NutrientsStandard,
  NutrientStandardType,
} from "../../../../../../types/standardTypes";
import NUTRIENT_STANDARD_NAMES from "../../../../../../constants/ingredients/nutrientStandardNames";
import roundToDecimal from "../../../../../../utils/roundToDecimal";
import NestedTable from "../NestedTable";
import InfoModalButton from "../../../../../shared/InfoModalButton";

interface Props {
  nutrientStandard: NutrientStandardType;
  nutrientIngredient?: NutrientIngredientType;
  nutrientReqsMin: NutrientsStandard;
  nutrientReqsMax: NutrientsStandard;
  totalInMeal: number | null;
  unitShort?: string;
  nestedTableRows?: TableRowValueNestedTable[];
  openNutrientInfoModal?: () => void;
  openNutrientFindIngredientModal?: (nutrient: NutrientIngredientType) => void;
}

const NutrientRow = ({
  nutrientStandard,
  nutrientIngredient,
  nutrientReqsMin,
  nutrientReqsMax,
  totalInMeal,
  unitShort,
  nestedTableRows,
  openNutrientInfoModal,
  openNutrientFindIngredientModal,
}: Props): JSX.Element => {
  const [accordionOut, setAccordionOut] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: theme.breakpoint2 });

  const refIngredientsCellTable = useRef<HTMLDivElement | null>(null);

  const nameNutrientStandard = NUTRIENT_STANDARD_NAMES[nutrientStandard];

  // Requirement text
  let requirementsString = "";
  const min = nutrientReqsMin[nutrientStandard];
  const max = nutrientReqsMax[nutrientStandard];
  if (min === null && max === null) {
    requirementsString = "n/a";
  } else if (max === null && min !== null) {
    requirementsString = `> ${roundToDecimal(min, 2)}`;
  } else if (min === null && max !== null) {
    requirementsString = `< ${roundToDecimal(max, 2)}`;
  } else if (min !== null && max !== null) {
    requirementsString = `${roundToDecimal(min, 2)} - ${roundToDecimal(
      max,
      2
    )}`;
  }

  // Fills requirement
  let fillsRequirement = false;
  if (totalInMeal === null) {
    fillsRequirement = false;
  } else if (min === null && max === null) {
    fillsRequirement = true;
  } else if (max === null && min !== null) {
    fillsRequirement = totalInMeal > min;
  } else if (min === null && max !== null) {
    fillsRequirement = totalInMeal < max;
  } else if (min !== null && max !== null) {
    fillsRequirement = totalInMeal > min && totalInMeal < max;
  }

  const noRequirement = min === null && max === null;

  const ingredientsCellVar = (
    <IngredientsCell
      accordionOut={accordionOut}
      maxHeightOpen={refIngredientsCellTable.current?.scrollHeight || 0}
    >
      {nestedTableRows && unitShort && (
        <NestedTable
          nestedTableRows={nestedTableRows}
          refIngredientsCellTable={refIngredientsCellTable}
          unitShort={unitShort}
          nutrient={nutrientIngredient}
          openNutrientFindIngredientModal={openNutrientFindIngredientModal}
        />
      )}
    </IngredientsCell>
  );

  const bottomCellVar = (
    <BottomCell>
      {nestedTableRows && (
        <ClickWrapper
          asButton={true}
          clickHandler={() => setAccordionOut(!accordionOut)}
          aria-expanded={accordionOut}
          aria-controls={"more-info-content"}
        >
          <StyledIcon as={accordionOut ? MinusIcon : undefined} />
          <StyledButtonText>{accordionOut ? "Less" : "More"}</StyledButtonText>
        </ClickWrapper>
      )}
    </BottomCell>
  );

  return (
    <Container
      role="row"
      accordionOut={accordionOut}
      fillsRequirement={fillsRequirement}
    >
      <NameCell role="rowheader">
        <HiddenSpan aria-hidden={isMobile ? "false" : "true"}>
          Nutrient
        </HiddenSpan>
        <NutrientSpan
          as={isMobile ? TextBold : undefined}
          asValue={"span"}
          marginRight={openNutrientInfoModal === undefined}
        >
          {unitShort
            ? `${nameNutrientStandard} (${unitShort})`
            : nameNutrientStandard}
        </NutrientSpan>
        {openNutrientInfoModal !== undefined && (
          <StyledInfoModalButton
            clickHandler={() => {
              openNutrientInfoModal();
            }}
            ariaLabelIcon={"More information about the nutrient"}
          />
        )}
      </NameCell>
      <DataCell>
        <HiddenSpan aria-hidden={isMobile ? "false" : "true"}>
          Result
        </HiddenSpan>
        <ResultText
          fillsRequirement={fillsRequirement}
          asValue={"span"}
          noRequirement={noRequirement}
        >
          {totalInMeal === null ? "undefined" : roundToDecimal(totalInMeal, 2)}
        </ResultText>
      </DataCell>
      <DataCell>
        <HiddenSpan aria-hidden={isMobile ? "false" : "true"}>
          Requirement
        </HiddenSpan>
        <StyledText>{requirementsString}</StyledText>
      </DataCell>

      {isMobile ? (
        <>
          {ingredientsCellVar}
          {bottomCellVar}
        </>
      ) : (
        <>
          {bottomCellVar}
          {ingredientsCellVar}
        </>
      )}
    </Container>
  );
};

const Container = styled.tr<{
  fillsRequirement: boolean;
  accordionOut: boolean;
}>`
  border-bottom: 0.125rem solid ${(props) => props.theme.black1}; //2px
  background-color: ${(props) => !props.fillsRequirement && props.theme.red1};
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    display: block;
    margin-left: -${(props) => props.theme.spacing1};
    padding-left: ${(props) => props.theme.spacing1};

    margin-right: -${(props) => props.theme.spacing1};
    padding-right: ${(props) => props.theme.spacing1};
  }
  @media print {
    display: flex;
    margin-left: 0;
    padding-left: 0;

    margin-right: 0;
    padding-right: 0;
    page-break-inside: avoid;
  }
`;

const NameCell = styled.th`
  padding: 0;
  text-align: left;
  padding-top: ${(props) => props.theme.spacing1};
  padding-bottom: ${(props) => props.theme.spacing1};
  flex: 1;
  display: flex;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    justify-content: flex-end;
    padding-bottom: 0;
    padding-right: 0;
  }
  @media print {
    justify-content: flex-start;
    padding-bottom: ${(props) => props.theme.spacing1};
  }
`;

const DataCell = styled.td`
  padding: 0;
  text-align: left;
  padding-top: ${(props) => props.theme.spacing1};
  padding-bottom: ${(props) => props.theme.spacing1};
  flex: 1;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.25rem; // 4px
    padding-bottom: 0;
    padding-right: 0;
  }
  @media print {
    display: table-cell;
    justify-content: flex-start;
    align-items: stretch;
    padding-top: ${(props) => props.theme.spacing1};
    padding-bottom: ${(props) => props.theme.spacing1};
  }
`;

const BottomCell = styled.td`
  padding: 0;
  padding-top: ${(props) => props.theme.spacing1};
  padding-bottom: ${(props) => props.theme.spacing1};
  flex: 1;

  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    max-width: 110px;
  }
  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    display: flex;
    justify-content: center;
    padding-top: ${(props) => props.theme.halfSpacing1};
    max-width: none;
  }
  @media print {
    display: none;
  }
`;

const IngredientsCell = styled.td<{
  maxHeightOpen: number;
  accordionOut: boolean;
}>`
  padding: 0;
  flex: 1 1 100%;
  display: block;
  padding-top: ${(props) => props.accordionOut && props.theme.halfSpacing1};
  padding-bottom: ${(props) => props.accordionOut && props.theme.spacing2};
  padding-left: ${(props) => props.theme.spacing5};
  padding-right: ${(props) => props.theme.spacing5};

  overflow-y: hidden;
  overflow-x: visible;
  transition: max-height ${(props) => props.theme.transitionValue},
    padding ${(props) => props.theme.transitionValue};
  max-height: ${(props) =>
    props.accordionOut ? `${props.maxHeightOpen}px` : "0px"};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    padding-top: ${(props) => props.accordionOut && props.theme.spacing1};
    padding-bottom: ${(props) =>
      props.accordionOut && props.theme.halfSpacing1};
    padding-left: ${(props) => props.theme.spacing3};
    padding-right: ${(props) => props.theme.spacing3};
    margin-left: -${(props) => props.theme.spacing1};
    margin-right: -${(props) => props.theme.spacing1};
  }
  @media print {
    display: none;
  }
`;

const HiddenSpan = styled(Text)`
  color: ${(props) => props.theme.black4};
  display: none;
  padding-right: ${(props) => props.theme.spacing1};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    display: block;
    margin-right: auto;
  }
  @media print {
    display: none;
  }
`;

const ResultText = styled(Text)<{
  fillsRequirement: boolean;
  noRequirement: boolean;
}>`
  padding-top: ${(props) => props.theme.halfSpacing1};
  padding-bottom: ${(props) => props.theme.halfSpacing1};
  width: 7.5rem; // 120px
  background-color: ${(props) =>
    props.fillsRequirement ? props.theme.green3 : props.theme.red3};
  background-color: ${(props) => props.noRequirement && "transparent"};
  color: ${(props) =>
    props.fillsRequirement ? props.theme.green1 : props.theme.red1};
  color: ${(props) => props.noRequirement && props.theme.black4};
  border-radius: 0.5rem; // 8px
  display: block;
  text-align: center;
  font-size: ${(props) => props.theme.navigationTextSize};
  margin-right: ${(props) => props.theme.spacing1};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-right: 0;
  }
  @media print {
    margin-right: ${(props) => props.theme.spacing1};
  }
`;

const ClickWrapper = styled(WrapLink)`
  text-decoration: none;
  display: flex;
  align-items: center;

  &:focus {
    outline-color: ${(props) => props.theme.black4};
  }
`;

const StyledButtonText = styled(ButtonText)`
  color: ${(props) => props.theme.black4};
  text-align: left;
  font-size: ${(props) => props.theme.navigationTextSize};
`;

const StyledIcon = styled(PlusIcon)`
  width: 1.5rem; // 24px
  height: 1.5rem; // 24px
  color: ${(props) => props.theme.black4};
  margin-right: ${(props) => props.theme.spacing1};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-right: ${(props) => props.theme.halfSpacing1};
  }
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.black4};
  font-size: ${(props) => props.theme.navigationTextSize};
  margin-right: ${(props) => props.theme.spacing1};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-right: 0;
  }
  @media print {
    margin-right: ${(props) => props.theme.spacing1};
  }
`;

const NutrientSpan = styled(Text)<{ marginRight: boolean }>`
  color: ${(props) => props.theme.black4};
  word-break: break-word;
  font-size: ${(props) => props.theme.navigationTextSize};
  margin-right: ${(props) => props.marginRight && props.theme.spacing1};
  margin-left: ${(props) => props.theme.spacing1};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    text-align: right;
    margin-right: 0;
  }
  @media print {
    text-align: left;
    margin-right: ${(props) => props.theme.spacing1};
  }
`;

const StyledInfoModalButton = styled(InfoModalButton)`
  color: ${(props) => props.theme.black4};
  margin-right: ${(props) => props.theme.spacing1};

  &:hover {
    color: ${(props) => props.theme.black3};
  }
  &:focus {
    color: ${(props) => props.theme.black3};
    outline-color: ${(props) => props.theme.black3};
  }
  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-right: 0;
  }
`;

export default NutrientRow;
