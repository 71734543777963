import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useEditableLogic from "../../../../hooks/useEditableLogic";
import InputButtons from "./InputButtons";

interface Props {
  id: string;
  placeholder: string;
  serverValue: string | undefined;
  onSave: (value: string) => void;
  allowEmpty: boolean;
  disableEnter: boolean;
  className?: string;
}

const EditableTextArea = ({
  id,
  placeholder,
  serverValue,
  onSave,
  allowEmpty,
  disableEnter,
  className,
}: Props): JSX.Element => {
  const [textareaValue, setTextareaValue] = useState<string | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const { editing, setEditing, saveEdit, cancelEdit, handleKeys } =
    useEditableLogic<string>(
      serverValue,
      textareaValue,
      setTextareaValue,
      onSave,
      () => {
        textareaRef.current?.focus();
      },
      (a, b) => a === b,
      (value: string) => value === "",
      allowEmpty
    );

  // https://stackoverflow.com/questions/17772260/textarea-auto-height
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "5px";
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + 5 + "px";
    }
  }, [textareaRef.current, editing]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.target.style.height = "5px";
    e.target.style.height = e.target.scrollHeight + 5 + "px";
  };

  useEffect(() => {
    if (!editing) {
      textareaRef.current?.blur();
    }
  }, [editing]);

  return (
    <Container>
      <StyledTextarea
        className={className}
        value={textareaValue || ""}
        editing={editing}
        hasValue={!!serverValue}
        id={id}
        placeholder={placeholder}
        onClick={() => setEditing(true)}
        onBlur={saveEdit}
        ref={textareaRef}
        onChange={(e) => setTextareaValue(e.target.value)}
        onFocus={() => setEditing(true)}
        onKeyDown={(e) => {
          {
            if (disableEnter && e.key === "Enter") {
              return;
            }

            handleKeys(e);
          }
        }}
        onInput={handleInputChange}
        onLoad={handleInputChange}
      />
      <InputButtonsContainer editing={editing}>
        <StyledInputButtons
          cancelEdit={cancelEdit}
          editing={editing}
          saveEdit={saveEdit}
          setEditing={setEditing}
          notAbsolute={true}
        />
      </InputButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const StyledTextarea = styled.textarea<{ editing: boolean; hasValue: boolean }>`
  display: block;
  width: 100%;
  padding: ${(props) => (props.editing ? "1rem 2rem" : "0rem")}; // 16px 32px
  box-sizing: border-box;
  border-width: ${(props) => props.editing && "0.125rem"}; // 2px
  border-radius: ${(props) => props.editing && "1.75rem"}; // 28px
  border-style: ${(props) => (props.editing ? "solid" : "none")};
  border-color: ${(props) => props.editing && props.theme.black4};
  font-size: ${(props) => props.theme.buttonTextSize};
  outline: none;
  padding-right: ${(props) => !props.editing && "4.9375rem"}; // 79px
  padding-bottom: ${(props) => props.editing && "4.3125rem"}; // 69px
  color: ${(props) => props.theme.black4};

  resize: none;

  &::placeholder {
    color: ${(props) => props.theme.black3};
  }
  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.buttonTextSizeMobile};
  }
  /* @media print {
    padding: 0;
    height: auto !important;
    overflow: visible !important;
  } */
`;

const InputButtonsContainer = styled.div<{ editing: boolean }>`
  position: absolute;
  top: ${(props) => !props.editing && "0"};
  right: ${(props) => (!props.editing ? "0" : "1.375rem")}; // 22px
  bottom: ${(props) => (!props.editing ? "0" : "1.125rem")}; // 18px
  display: flex;
  align-items: center;
  pointer-events: none;

  @media print {
    display: none;
  }
`;

const StyledInputButtons = styled(InputButtons)`
  pointer-events: all;
`;

export default EditableTextArea;
