import axios, { AxiosError } from "axios";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import { User } from "../../types/userTypes";

const useGetAuthUser = (
  options?: UseQueryOptions<User, AxiosError>
): UseQueryResult<User, AxiosError> => {
  return useQuery<User, AxiosError>(
    ["loggedInUser"],
    async () => {
      const responseCurrentUser = await axios.get<User>(
        `${BACKEND_URL}/api/users`
      );

      if (responseCurrentUser.data.stripeSubscriptionTimestamp !== null) {
        responseCurrentUser.data.stripeSubscriptionTimestamp = new Date(
          responseCurrentUser.data.stripeSubscriptionTimestamp
        );
      }

      return responseCurrentUser.data;
    },
    {
      ...options,
    }
  );
};

export default useGetAuthUser;
