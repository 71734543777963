import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LoginPartial from "../../shared/auth/LoginPartial";
import Modal from "../../shared/Modal";
import ForgotPasswordPartial from "../../shared/auth/ForgotPasswordPartial";
import SignupPartial from "../../shared/auth/SignupPartial";
import ForgotPasswordAnswerPartial from "../../shared/auth/ForgotPasswordAnswerPartial";
import { authViews } from "../../../types/frontendTypes";
import useLogin from "../../../hooks/api/useLogin";
import useGetLoginStatus from "../../../hooks/api/useGetLoginStatus";
import useGetAuthUser from "../../../hooks/api/useGetAuthUser";
import { useHistory } from "react-router-dom";

interface Props {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showing: authViews;
  setShowing: React.Dispatch<React.SetStateAction<authViews>>;
}

const AuthModal = ({
  modalOpen,
  setModalOpen,
  showing,
  setShowing,
}: Props): JSX.Element => {
  const loginMutation = useLogin();

  const [savedEmail, setSavedEmail] = useState("");

  useEffect(() => {
    if (!modalOpen) {
      setSavedEmail("");
    }
  }, [modalOpen]);

  const history = useHistory();

  const loggedInStatusQuery = useGetLoginStatus({
    enabled: loginMutation.isSuccess,
  });

  useGetAuthUser({
    enabled: loginMutation.isSuccess && !!loggedInStatusQuery?.data?.loggedIn,
    onSuccess: () => {
      if (loginMutation.isSuccess && loggedInStatusQuery.isSuccess) {
        history.push("/verify");
      }
    },
  });

  let content: JSX.Element | null = null;
  let title = "";
  if (showing === "login") {
    title = "Log in";
    content = (
      <LoginPartial
        forgetHandler={(email: string) => {
          setSavedEmail(email);
          setShowing("forgotPassword");
        }}
        signupHandler={(email: string) => {
          setSavedEmail(email);
          setShowing("signup");
        }}
        savedEmail={savedEmail}
        redirectAfterLogin={true}
      />
    );
  } else if (showing === "signup") {
    title = "Sign up";
    content = (
      <SignupPartial
        loginHandler={(email: string) => {
          setSavedEmail(email);
          setShowing("login");
        }}
        verificationHandler={(data: { email: string; password: string }) => {
          setSavedEmail(data.email);
          loginMutation.mutate(data);
        }}
        savedEmail={savedEmail}
      />
    );
  } else if (showing === "forgotPassword") {
    title = "Reset password";
    content = (
      <ForgotPasswordPartial
        backToLogin={(email: string) => {
          setSavedEmail(email);
          setShowing("login");
        }}
        forgotPasswordAnswerHandler={(email: string) => {
          setSavedEmail(email);
          setShowing("forgotPasswordAnswer");
        }}
        savedEmail={savedEmail}
      />
    );
  } else if (showing === "forgotPasswordAnswer") {
    title = "Reset password";
    content = (
      <ForgotPasswordAnswerPartial
        backToLogin={() => {
          setShowing("login");
        }}
        savedEmail={savedEmail}
      />
    );
  }

  return (
    <>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        title={title}
        closable={true}
      >
        <MarginContainer>{content}</MarginContainer>
      </Modal>
    </>
  );
};

const MarginContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing2};
`;

export default AuthModal;
