import adjustNutrientReqActivityLevel from "../../../services/nutrientReqs/adjustNutrientReqActivityLevel";
import adjustNutrientReqCalories from "../../../services/nutrientReqs/adjustNutrientReqCalories";
import { NutrientsStandard } from "../../../types/standardTypes";

const NUTRIENT_REQ_AAFCO_ADULT_MIN: NutrientsStandard = {
  // Macronutrients
  protein: 45.0,
  fat: 13.8,
  carbohydrate: null,
  water: null,
  ash: null,

  // Amino acids
  tryptophan: 0.4,
  threonine: 1.2,
  isoleucine: 0.95,
  leucine: 1.7,
  lysine: 1.58,
  methionine: 0.83,
  methionineAndCystine: 1.63,
  phenylalanine: 1.13,
  phenylalanineAndTyrosine: 1.85,
  valine: 1.23,
  arginine: 1.28,
  histidine: 0.48,

  // Fatty acids
  linoleicAcid: 2.8,
  alphaLinoleicAcid: null,
  arachidonicAcid: null,
  linoleicToAlphaLinoleicAcids: 2.6,
  epaAndDha: null,

  // Carbohydrates
  fiber: null,

  // Minerals
  calcium: 1250,
  iron: 10,
  magnesium: 150,
  phosphorus: 1000,
  calciumToPhosphorus: 1,
  potassium: 1500,
  chloride: 300,
  sodium: 200,
  zinc: 20,
  copper: 1.83,
  zincToCopper: 9,
  manganese: 1.25,
  selenium: 80,
  iodine: 250,

  // Vitamins
  thiaminB1: 0.56,
  riboflavinB2: 1.3,
  niacinB3: 3.4,
  pantothenicAcidB5: 3.0,
  vitaminB6: 0.38,
  folicAcidB9: 54,
  choline: 340,
  vitaminB12: 7,
  vitaminA: 375,
  vitaminE: 8.375,
  cholecalciferolD3: 3.125,
  phylloquinoneK: null,
};

const NUTRIENT_REQ_AAFCO_ADULT_MAX: NutrientsStandard = {
  // Macronutrients
  protein: null,
  fat: null,
  carbohydrate: null,
  water: null,
  ash: null,

  // Amino acids
  tryptophan: null,
  threonine: null,
  isoleucine: null,
  leucine: null,
  lysine: null,
  methionine: null,
  methionineAndCystine: null,
  phenylalanine: null,
  phenylalanineAndTyrosine: null,
  valine: null,
  arginine: null,
  histidine: null,

  // Fatty acids
  linoleicAcid: null,
  alphaLinoleicAcid: null,
  arachidonicAcid: null,
  linoleicToAlphaLinoleicAcids: 26,
  epaAndDha: null,

  // Carbohydrates
  fiber: null,

  // Minerals
  calcium: 6250,
  iron: null,
  magnesium: null,
  phosphorus: 4000,
  calciumToPhosphorus: 2,
  potassium: null,
  chloride: null,
  sodium: null,
  zinc: null,
  copper: null,
  zincToCopper: null,
  manganese: null,
  selenium: 500,
  iodine: 2750,

  // Vitamins
  thiaminB1: null,
  riboflavinB2: null,
  niacinB3: null,
  pantothenicAcidB5: null,
  vitaminB6: null,
  folicAcidB9: null,
  choline: null,
  vitaminB12: null,
  vitaminA: 18750,
  vitaminE: null,
  cholecalciferolD3: 18.75,
  phylloquinoneK: null,
};

export const getNutrientReqAafcoAdultMin = (
  calorieRequriement: number,
  activityLevel: number
): NutrientsStandard => {
  const activityLevelRatio = activityLevel < 132 ? 132 / activityLevel : 1;

  const specificReq = adjustNutrientReqActivityLevel(
    NUTRIENT_REQ_AAFCO_ADULT_MIN,
    activityLevelRatio
  );

  const adjustedNutrients = adjustNutrientReqCalories(
    specificReq,
    calorieRequriement
  );
  return adjustedNutrients;
};

export const getNutrientReqAafcoAdultMax = (
  calorieRequriement: number,
  activityLevel: number
): NutrientsStandard => {
  const activityLevelRatio = activityLevel < 132 ? 132 / activityLevel : 1;

  const specificReq = adjustNutrientReqActivityLevel(
    NUTRIENT_REQ_AAFCO_ADULT_MAX,
    activityLevelRatio
  );

  const adjustedNutrients = adjustNutrientReqCalories(
    specificReq,
    calorieRequriement
  );
  return adjustedNutrients;
};
