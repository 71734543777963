import React from "react";
import styled from "styled-components";
import useCreateStripePortal from "../../../hooks/api/useCreateStripePortal";
import Button from "../../shared/Button";
import Line from "../../shared/Line";
import Modal from "../../shared/Modal";
import StyledLink from "../../shared/StyledLink";
import IntroductionText from "../../shared/text/IntroductionText";
import Text from "../../shared/text/Text";

interface Props {
  open: boolean;
}

const FailedPaymentModal = ({ open }: Props): JSX.Element | null => {
  const createStripePortalMutation = useCreateStripePortal();

  return (
    <Modal open={open} title="Update payment info" closable={false}>
      <StyledIntroductionText>
        There seems to be a problem with your payment information. Please update
        your payment information to continue using the app.
      </StyledIntroductionText>
      <PurpleButton
        eventHandler={() => {
          createStripePortalMutation.mutate();
        }}
      >
        Go to customer portal
      </PurpleButton>
      <StyledLine />
      <StyledText>
        <StyledStyledLink to="/account">Manage your account</StyledStyledLink>
      </StyledText>
    </Modal>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};
  margin-top: ${(props) => props.theme.spacing2};
`;

const PurpleButton = styled(Button)`
  background-color: ${(props) => props.theme.purple2};
  color: ${(props) => props.theme.purple4};
  width: 100%;

  &:hover {
    background-color: ${(props) => props.theme.purple1};
  }
  &:focus {
    background-color: ${(props) => props.theme.purple1};
    outline-color: ${(props) => props.theme.purple1};
  }
`;

const StyledLine = styled(Line)`
  margin-top: ${(props) => props.theme.spacing2};
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.black4};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledStyledLink = styled(StyledLink)`
  color: ${(props) => props.theme.black4};

  &:hover {
    color: ${(props) => props.theme.black3};
  }
  &:focus {
    outline-color: ${(props) => props.theme.black3};
    color: ${(props) => props.theme.black3};
  }
`;

export default FailedPaymentModal;
