import React, { useState } from "react";
import ExtraFieldsModalOpenContext from "./ExtraFieldsModalOpenContext";

interface Props {
  children: React.ReactNode;
}

const ExtraFieldsModalOpenContextProvider = ({
  children,
}: Props): JSX.Element => {
  const [extraFieldsModalOpen, setExtraFieldsModalOpen] = useState(false);

  return (
    <ExtraFieldsModalOpenContext.Provider
      value={{ extraFieldsModalOpen, setExtraFieldsModalOpen }}
    >
      {children}
    </ExtraFieldsModalOpenContext.Provider>
  );
};

export default ExtraFieldsModalOpenContextProvider;
