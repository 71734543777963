const getTextWidth = (text: string, font: string): number | null => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (context) {
    context.font = font;
    return context.measureText(text).width;
  }
  return null;
};

export default getTextWidth;
