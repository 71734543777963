import { kgOrLb } from "../../types/userTypes";

const isWeightUnit = (
  // eslint-disable-next-line
  param: any
): param is kgOrLb => {
  return ["kg", "lb"].includes(param);
};

export default isWeightUnit;
