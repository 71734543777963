import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import useSendForgotPassword from "../../../hooks/api/useSendForgotPassword";
import Input from "../form/normal/Input";
import LabelForm from "../form/LabelForm";
import SubmitButton from "../form/normal/SubmitButton";
import Text from "../text/Text";
import StyledLink from "../StyledLink";
import { emailRegex } from "../../../utils/regexPatterns";
import { emailErrorMessage } from "../../../utils/errorMessagesAuth";

interface ForgotValues {
  emailForgot: string;
}

interface Props {
  backToLogin: (email: string) => void;
  forgotPasswordAnswerHandler: (email: string) => void;
  savedEmail: string;
  className?: string;
}

const ForgotPasswordPartial = ({
  backToLogin,
  forgotPasswordAnswerHandler,
  savedEmail,
  className,
}: Props): JSX.Element => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<ForgotValues>({
    defaultValues: {
      emailForgot: savedEmail,
    },
  });

  const sendForgotPasswordMutation = useSendForgotPassword();

  const emailForm = watch("emailForgot");

  const submitHandler = (data: ForgotValues) => {
    sendForgotPasswordMutation.mutate(data.emailForgot);
    forgotPasswordAnswerHandler(emailForm);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(submitHandler)}
        className={className}
        noValidate
        autoComplete="on"
      >
        <EmailLabel name="emailForgot">Email</EmailLabel>
        <StyledInput
          register={register("emailForgot", {
            required: true,
            pattern: emailRegex,
          })}
          error={errors.emailForgot}
          errorMessage={emailErrorMessage}
          type={"email"}
          placeholder="name@youremail.com"
          autoComplete="email"
        />
        <ButtonContainer>
          <StyledSubmitButton text="Reset password" />
        </ButtonContainer>
      </form>
      <ButtonContainer>
        <StyledText>
          <StyledStyledLink
            asButton={true}
            clickHandler={() => backToLogin(emailForm)}
          >
            Cancel
          </StyledStyledLink>
        </StyledText>
      </ButtonContainer>
    </>
  );
};

const StyledSubmitButton = styled(SubmitButton)`
  background-color: ${(props) => props.theme.green3};
  color: ${(props) => props.theme.green1};

  &:hover {
    background-color: ${(props) => props.theme.green4};
  }
  &:focus {
    outline-color: ${(props) => props.theme.green4};
    background-color: ${(props) => props.theme.green4};
  }
`;

const EmailLabel = styled(LabelForm)`
  color: ${(props) => props.theme.black4};
  margin-bottom: 0.25rem; // 4px
`;

const StyledInput = styled(Input)`
  color: ${(props) => props.theme.green4};
  background-color: ${(props) => props.theme.green1};

  border-style: solid;
  border-width: 0.125rem; // 2px
  border-color: ${(props) => props.theme.green4};

  &:hover {
    background-color: ${(props) => props.theme.white};
  }
  &:focus {
    background-color: ${(props) => props.theme.white};
    outline: none;
  }
  &::placeholder {
    color: ${(props) => props.theme.green3};
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: ${(props) => props.theme.spacing2};
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.black4};
`;

const StyledStyledLink = styled(StyledLink)`
  color: ${(props) => props.theme.black4};

  &:hover {
    color: ${(props) => props.theme.black3};
  }
  &:focus {
    outline-color: ${(props) => props.theme.black3};
    color: ${(props) => props.theme.black3};
  }
`;

export default ForgotPasswordPartial;
