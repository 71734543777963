import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { BACKEND_URL } from "../../constants/constants";

const useDeleteDog = (): UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (dogId: string) => {
      if (dogId) {
        await axios.delete(`${BACKEND_URL}/api/dogs/${dogId}`);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dogs"], {
          exact: true,
        });
        queryClient.invalidateQueries(["recipes"], {
          exact: true,
        });
      },
    }
  );
};

export default useDeleteDog;
