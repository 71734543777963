import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import { IngredientMetaNutrientsNoIdNoOwnerId } from "../../types/ingredientTypes";

interface returnValue {
  id: string;
  name: string;
}

const useCreateIngredient = (): UseMutationResult<
  returnValue,
  AxiosError,
  IngredientMetaNutrientsNoIdNoOwnerId
> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const response = await axios.post<returnValue>(
        `${BACKEND_URL}/api/ingredients`,
        data
      );

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["ingredients", "search"], {
          exact: true,
        });
        queryClient.invalidateQueries(["ingredients", "custom"], {
          exact: true,
        });
      },
    }
  );
};

export default useCreateIngredient;
