import { kgOrLb } from "../types/userTypes";

const getWeigthInKg = (weight: number, weightSetting: kgOrLb): number => {
  let weightKgs: number;
  if (weightSetting === "lb") {
    weightKgs = weight * 0.453592;
  } else {
    weightKgs = weight;
  }

  return weightKgs;
};

export default getWeigthInKg;
