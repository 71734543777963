import React, { useState } from "react";
import { authViews } from "../../types/frontendTypes";
import AuthModalContext from "./AuthModalContext";

interface Props {
  children: React.ReactNode;
}

const AuthModalContextProvider = ({ children }: Props): JSX.Element => {
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [authModalShowing, setAuthModalShowing] = useState<authViews>("login");

  return (
    <AuthModalContext.Provider
      value={{
        authModalOpen,
        setAuthModalOpen,
        authModalShowing,
        setAuthModalShowing,
      }}
    >
      {children}
    </AuthModalContext.Provider>
  );
};

export default AuthModalContextProvider;
