import axios, { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import { forgotPasswordTokenStatus } from "../../types/frontendTypes";

interface data {
  status: forgotPasswordTokenStatus;
}

const useGetForgotPasswordTokenStatus = (
  forgotPasswordToken: string
): UseQueryResult<data, AxiosError> => {
  return useQuery<data, AxiosError>(["forgotPasswordTokenStatus"], async () => {
    const responseCurrentUser = await axios.get<data>(
      `${BACKEND_URL}/api/auth/password-token/${forgotPasswordToken}`
    );

    return responseCurrentUser.data;
  });
};

export default useGetForgotPasswordTokenStatus;
