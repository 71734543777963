import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { allColors } from "../../../../types/frontendTypes";
import FormLabel from "../../text/FormLabel";
import FormText from "../../text/FormText";

interface Props<T> {
  valueOption1: T;
  labelOption1: string;
  valueOption2: T;
  labelOption2: string;
  value: T;
  changeHandler: (value: T) => void;
  id: string;
  color1: allColors;
  color2: allColors;
  title: string;
  colorTitle: allColors;
  className?: string;
}

const SimpleSwitcher = <T extends string>({
  valueOption1,
  labelOption1,
  valueOption2,
  labelOption2,
  value,
  changeHandler,
  id,
  color1,
  color2,
  title,
  colorTitle,
  className,
}: Props<T>): JSX.Element => {
  const label1Ref = useRef<null | HTMLLabelElement>(null);
  const label2Ref = useRef<null | HTMLLabelElement>(null);
  const switcherRef = useRef<null | HTMLDivElement>(null);

  const [label1Offset, setLabel1Offset] = useState(0);
  const [label2Offset, setLabel2Offset] = useState(0);

  useEffect(() => {
    if (label1Ref.current) {
      setLabel1Offset(label1Ref.current?.offsetWidth || 0);
    }
  }, [label1Ref.current]);

  useEffect(() => {
    if (label2Ref.current) {
      setLabel2Offset(label2Ref.current?.offsetWidth);
    }
  }, [label2Ref.current]);

  return (
    <Container className={className}>
      {title && colorTitle && (
        <StyledFormLabel color={colorTitle}>{title}</StyledFormLabel>
      )}
      <Switcher
        backgroundColor={color2}
        outlineColor={color1}
        ref={switcherRef}
      >
        <StyledInput
          type="radio"
          id={valueOption1}
          name={id}
          value={valueOption1}
          checked={value === valueOption1}
          onChange={(): void => changeHandler(valueOption1)}
        />
        <StyledLabel
          asValue={"label"}
          forValue={valueOption1}
          checked={value === valueOption1}
          color1={color1}
          color2={color2}
          refValue={label1Ref}
        >
          {labelOption1}
        </StyledLabel>
        <StyledInput
          type="radio"
          id={valueOption2}
          name={id}
          value={valueOption2}
          checked={value === valueOption2}
          onChange={(): void => changeHandler(valueOption2)}
        />
        <StyledLabel
          asValue={"label"}
          forValue={valueOption2}
          checked={value === valueOption2}
          color1={color1}
          color2={color2}
          refValue={label2Ref}
        >
          {labelOption2}
        </StyledLabel>
        <CheckedBlock
          leftChecked={value === valueOption1}
          backgroundColor={color1}
          width={value === valueOption1 ? label1Offset : label2Offset}
          containerWidth={switcherRef.current?.offsetWidth || 0}
        />
      </Switcher>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledInput = styled.input`
  appearance: none;
  width: 0;
`;

const Switcher = styled.div<{
  backgroundColor: allColors;
  outlineColor: allColors;
}>`
  background-color: ${(props) => props.backgroundColor};
  position: relative;
  border-radius: 0.5rem; // 8px
  padding-top: ${(props) => props.theme.halfSpacing1};
  padding-bottom: ${(props) => props.theme.halfSpacing1};
  overflow: hidden;

  &:focus-within {
    outline-width: 0.125rem; // 2px
    outline-style: solid;
    outline-offset: 0.125rem; // 2px
    outline-color: ${(props) => props.outlineColor};
  }
`;

const StyledFormLabel = styled(FormLabel)<{ color: allColors }>`
  color: ${(props) => props.color};

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    margin-bottom: 0.25rem; // 4px
  }
`;

const StyledLabel = styled(FormText)<{
  checked: boolean;
  color1: allColors;
  color2: allColors;
}>`
  color: ${(props) => (props.checked ? props.color2 : props.color1)};
  position: relative;
  z-index: 2;
  padding-left: ${(props) => props.theme.spacing1};
  padding-right: ${(props) => props.theme.spacing1};
  padding-top: ${(props) => props.theme.halfSpacing1};
  padding-bottom: ${(props) => props.theme.halfSpacing1};
  border-radius: 0.5rem; // 8px
  transition: color ${(props) => props.theme.transitionValue};
`;

const CheckedBlock = styled.span<{
  leftChecked: boolean;
  backgroundColor: allColors;
  width: number;
  containerWidth: number;
}>`
  position: absolute;
  top: 0.125rem; // 2px
  bottom: 0.125rem; // 2px
  left: ${(props) =>
    props.leftChecked
      ? "0.125rem"
      : props.containerWidth - props.width - 1 + "px"}; // 2px
  right: ${(props) =>
    !props.leftChecked
      ? "0.125rem"
      : props.containerWidth - props.width - 1 + "px"}; // 2px
  /* width: ${(props) => props.width}px; */
  background-color: ${(props) => props.backgroundColor};
  z-index: 1;
  border-radius: 0.5rem; // 8px
  transition: left ${(props) => props.theme.transitionValue},
    right ${(props) => props.theme.transitionValue};
`;

export default SimpleSwitcher;
