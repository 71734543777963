import axios, { AxiosError } from "axios";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { BACKEND_URL } from "../../constants/constants";

interface Data {
  loggedIn: boolean;
}

const useGetLoginStatus = (
  options?: UseQueryOptions<Data, AxiosError<any>>
): UseQueryResult<Data, AxiosError> => {
  return useQuery<Data, AxiosError>(
    ["loginCheck"],
    async () => {
      const responseLoggedIn = await axios.get<Data>(
        `${BACKEND_URL}/api/auth/logged-in`
      );

      return responseLoggedIn.data;
    },
    options
  );
};

export default useGetLoginStatus;
