import React, { useState } from "react";
import DogsModalCallbackContext from "./DogsModalCallbackContext";

interface Props {
  children: React.ReactNode;
}

const DogsModalCallbackContextProvider = ({ children }: Props): JSX.Element => {
  const placeHolderFunc = () => {
    return;
  };
  const [dogsModalCallback, setDogsModalCallback] = useState<() => void>(
    () => placeHolderFunc
  );

  return (
    <DogsModalCallbackContext.Provider
      value={{ dogsModalCallback, setDogsModalCallback }}
    >
      {children}
    </DogsModalCallbackContext.Provider>
  );
};

export default DogsModalCallbackContextProvider;
