import React, { useEffect } from "react";
import { useContext } from "react";
import { Redirect } from "react-router-dom";
import DogBannersContext from "../../contexts/dogBannersContext/DogBannersContext";
import useGetAuthUser from "../../hooks/api/useGetAuthUser";
import useGetDog from "../../hooks/api/useGetDog";
import calculateCaloriesNeeded from "../../services/calculateCaloriesNeeded";

const RecipeIngredientLogic = (): JSX.Element | null => {
  const dogBannersContextValue = useContext(DogBannersContext);

  const authUserQuery = useGetAuthUser();
  const getDogQuery = useGetDog(authUserQuery.data?.selectedDog, {
    enabled: !!authUserQuery.data?.selectedDog,
  });

  useEffect(() => {
    // If the user has no dog
    if (authUserQuery.data?.selectedDog === null) {
      dogBannersContextValue &&
        dogBannersContextValue.setDogBanners([
          "You need to create a dog before you can add a recipe or ingredient.",
        ]);
      return;
    }

    // If base dog already created but extra fields not filled out and leaving for rec/ingr
    if (authUserQuery.data && authUserQuery.data.kgOrLb && getDogQuery.data) {
      const caloriesNeeded = calculateCaloriesNeeded(
        getDogQuery.data?.birthDate,
        getDogQuery.data?.breedSize,
        authUserQuery.data?.kgOrLb,
        getDogQuery.data?.weight,
        getDogQuery.data?.expectedWeight,
        getDogQuery.data?.activityLevel,
        getDogQuery.data?.spayedOrNeutered,
        getDogQuery.data?.pregnancyStatus === "gestation",
        getDogQuery.data?.pregnancyStatus === "lactation",
        getDogQuery.data?.matingDate,
        getDogQuery.data?.parturitionDate,
        getDogQuery.data?.numberOfPuppies
      );

      if (caloriesNeeded === null) {
        dogBannersContextValue &&
          dogBannersContextValue.setDogBanners([
            "You need to fill out all fields before you can add a recipe or ingredient.",
          ]);
        return;
      }
    }
  }, [authUserQuery.data?.selectedDog, getDogQuery.data]);

  if (dogBannersContextValue && dogBannersContextValue.dogBanners.length > 0) {
    return <Redirect to="/dogs" />;
  }
  return null;
};

export default RecipeIngredientLogic;
