import React, { useState } from "react";
import LoginErrorMessageContext from "./LoginErrorMessageContext";

interface Props {
  children: React.ReactNode;
}

const LoginErrorMessageContextProvider = ({ children }: Props): JSX.Element => {
  const [loginErrorMessage, setLoginErrorMessage] = useState("");

  return (
    <LoginErrorMessageContext.Provider
      value={{ loginErrorMessage, setLoginErrorMessage }}
    >
      {children}
    </LoginErrorMessageContext.Provider>
  );
};

export default LoginErrorMessageContextProvider;
