import React from "react";
import styled from "styled-components";

interface Props {
  name: string;
  children: React.ReactNode;
  className?: string;
}

const LabelForm = ({ name, children, className }: Props): JSX.Element => {
  return (
    <StyledLabel htmlFor={name} className={className}>
      {children}
    </StyledLabel>
  );
};

const StyledLabel = styled.label`
  display: block;
  font-size: ${(props) => props.theme.buttonTextSize};
  font-family: "DM Sans Medium", sans-serif;
  font-weight: 400;
  line-height: ${(props) => props.theme.textLineHeight};

  display: flex;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.buttonTextSizeMobile};
  }
`;

export default LabelForm;
