import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import { DogValuesNoIdsType } from "../../types/dogTypes";
import kebabize from "../../utils/kebabize";

interface Input {
  id: string;
  value: string | number | Date | boolean | null | undefined;
  property: DogValuesNoIdsType;
}

const useChangeDogProperty = (): UseMutationResult<void, AxiosError, Input> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const dataSend = { value: data.value };
      await axios.patch(
        `${BACKEND_URL}/api/dogs/${data.id}/${kebabize(data.property)}`,
        dataSend
      );
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(["dog"]);
        queryClient.invalidateQueries(["dogs"]);
      },
      // onMutate: async (data) => {
      //   // Optimistically update dogs query
      //   await queryClient.cancelQueries(["dogs"]);
      //   const oldDogs: DogValues[] | undefined = queryClient.getQueryData([
      //     "dogs",
      //   ]);
      //   if (oldDogs) {
      //     const changingDog = oldDogs.find((dog) => dog.id === data.id);
      //     if (changingDog) {
      //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //       // @ts-ignore
      //       changingDog[data.property] = data.value;
      //       const newDogs = oldDogs.filter((dog) => dog.id !== data.id);
      //       newDogs.push(changingDog);
      //       queryClient.setQueryData(["dogs"], newDogs);
      //     }
      //   }

      //   // Optimistically update dog-id query
      //   await queryClient.cancelQueries(["dogs", data.id]);
      //   const oldDog: DogValues | undefined = queryClient.getQueryData([
      //     "dogs",
      //     data.id,
      //   ]);
      //   if (oldDog) {
      //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //     // @ts-ignore
      //     oldDog[data.property] = data.value;
      //     queryClient.setQueryData(["dogs", data.id], oldDog);
      //   }
      // },
    }
  );
};

export default useChangeDogProperty;
