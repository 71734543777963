import {
  generateClipPath,
  generateClipPathMobile,
} from "../services/generateClipPaths";
import { environmentType } from "../types/otherTypes";

export const ENVIRONMENT: environmentType =
  (process.env.NODE_ENV as environmentType) || "development";

export const BACKEND_URL =
  ENVIRONMENT === "production"
    ? "https://server.mealment.com"
    : "http://localhost:3333";

export const FRONTEND_URL =
  ENVIRONMENT === "production"
    ? "https://mealment.com"
    : "http://localhost:3000";

export const FRONTEND_URL_WWW_PROD = "https://www.mealment.com";

export const AUTH_COOKIE_NAME = "connect.sid";

export const clipPathHero = generateClipPath();

export const clipPathHeroMobile = generateClipPathMobile();

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const POSITIVE_VERIFICATION_MESSAGE =
  "Sweet! Your account is now verified. Go ahead and set up your dog profile.";

export const ALREADY_USED_VERIFICATION_MESSAGE =
  "Your verification token has already been used.";

export const priceIdBasicMonthly =
  ENVIRONMENT === "production"
    ? "price_1MbRnVLXFxJIpkPL2mSudCOU"
    : "price_1MYwuBLXFxJIpkPL767yYYns";
export const priceIdBasicYearly =
  ENVIRONMENT === "production"
    ? "price_1MbRnVLXFxJIpkPLjfTfgEbA"
    : "price_1MYwvDLXFxJIpkPLRcTPjttU";
export const priceIdPlusMonthly =
  ENVIRONMENT === "production"
    ? "price_1MbRnsLXFxJIpkPL2BGj7nNU"
    : "price_1MYwwQLXFxJIpkPLWKyA9Uwp";
export const priceIdPlusYearly =
  ENVIRONMENT === "production"
    ? "price_1MbRnsLXFxJIpkPLmOYasfss"
    : "price_1MYwwpLXFxJIpkPLsVcBNGmT";
export const priceIdProMonthly =
  ENVIRONMENT === "production"
    ? "price_1MbRoALXFxJIpkPL40gu6XlI"
    : "price_1MYwxlLXFxJIpkPLUO0ZfE3O";
export const priceIdProYearly =
  ENVIRONMENT === "production"
    ? "price_1MbRoALXFxJIpkPLPBt6ifuI"
    : "price_1MYwy7LXFxJIpkPLdeU22s9B";

export const priceBasicMonthly = 12;
export const priceBasicYearly = 10;
export const pricePlusMonthly = 18;
export const pricePlusYearly = 15;
export const priceProMonthly = 29;
export const priceProYearly = 26;

export const maxNumberOfDogsBasic = 1;
export const maxNumberOfDogsPlus = 3;
export const maxNumberOfDogsPro = 6;

export const maxNumberOfRecipesBasic = 5;
export const maxNumberOfRecipesPlus = 20;
export const maxNumberOfRecipesPro = 50;

export const accountUrl = "/account";
