import React, { useState } from "react";
import VerificationTokenMessageContext from "./VerificationTokenMessageContext";

interface Props {
  children: React.ReactNode;
}

const VerificationTokenMessageContextProvider = ({
  children,
}: Props): JSX.Element => {
  const [verificationTokenMessage, setVerificationTokenMessage] = useState("");

  return (
    <VerificationTokenMessageContext.Provider
      value={{ verificationTokenMessage, setVerificationTokenMessage }}
    >
      {children}
    </VerificationTokenMessageContext.Provider>
  );
};

export default VerificationTokenMessageContextProvider;
