import React, { useEffect } from "react";
import styled from "styled-components";
import EditableTextArea from "../../../shared/form/editable/EditableTextArea";
import urlSlug from "url-slug";
import { useHistory } from "react-router-dom";
import { IngredientFull } from "../../../../types/ingredientTypes";
import useChangeIngredientProperty from "../../../../hooks/api/useChangeIngredientProperty";

interface Props {
  ingredient: IngredientFull;
}

const TextPartIngredient = ({ ingredient }: Props): JSX.Element => {
  const changeIngredientPropertyMutation = useChangeIngredientProperty(
    ingredient.id
  );

  const history = useHistory();

  useEffect(() => {
    history.replace(
      `/ingredients/${ingredient.id}/${urlSlug(ingredient.name)}`
    );
  }, [ingredient.name]);

  const saveName = (name: string) => {
    changeIngredientPropertyMutation.mutate({
      value: name,
      property: "name",
    });
  };

  return (
    <>
      <Container>
        <label htmlFor="name" hidden>
          Ingredient name
        </label>
        <Heading
          id={"name"}
          placeholder={"Add your ingredient's name"}
          serverValue={ingredient.name}
          onSave={(name) => saveName(name)}
          allowEmpty={false}
          disableEnter={false}
        />
      </Container>
    </>
  );
};

const Container = styled.div`
  max-width: ${(props) => props.theme.halfWidth};
`;

const Heading = styled(EditableTextArea)`
  font-family: "Nunito Bold", sans-serif;
  line-height: ${(props) => props.theme.headingLineHeight};
  font-weight: 400;
  font-size: ${(props) => props.theme.heading1Size};

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.heading1SizeMobile};
  }
`;

export default TextPartIngredient;
