const theme = {
  // Colors
  black1: "#E8E8E8",
  black2: "#ADADAD",
  black3: "#6C6C6C",
  black4: "#191919",

  green1: "#E6F7EE",
  green2: "#AEE6C7",
  green3: "#4C6A5A",
  green4: "#25322A",

  purple1: "#E5E4F8",
  purple2: "#ABA8E8",
  purple3: "#59578C",
  purple4: "#342C48",

  white: "#FFFFFF",

  red1: "#FFDEDE",
  red2: "#FFA7A7",
  red3: "#9F4444",
  red4: "#FFA7A7",

  // Spacing
  mainWidth: "80rem", // 1280px
  halfWidth: "40rem", // 640px
  widthSmallText: "36.25rem", // 580px

  halfSpacing1: "0.5rem", // 8px
  spacing1: "1rem", // 16px
  spacing2: "1.5rem", // 24px
  spacing3: "2.5rem", // 40px
  spacing4: "3.75rem", // 60px
  spacing5: "5rem", // 80px
  spacing6: "7.5rem", // 120px

  // Font
  heading1Size: "4rem", // 64px
  heading2Size: "3rem", // 48px
  heading3Size: "2.125rem", // 34px
  heading4Size: "1.5rem", // 24px
  heading5Size: "20rem", // 20px

  heading1SizeMobile: "2.875rem", // 46px
  heading2SizeMobile: "2.375rem", // 38px
  heading3SizeMobile: "1.875rem", // 30px
  heading4SizeMobile: "1.375rem", // 22px
  heading5SizeMobile: "1.125rem", // 18px

  labelTextSize: "0.875rem", // 14px
  buttonTextSize: "1.25rem", // 20px
  introductionTextSize: "1.625rem", // 26px
  navigationTextSize: "1.125rem", // 18px
  smallTextSize: "1rem", // 16px

  buttonTextSizeMobile: "1.125rem", // 18px
  introductionTextSizeMobile: "1.375rem", // 22px
  navigationTextSizeMobile: "1rem", // 16px

  // Other
  componentLineHeight: 1.2,
  headingLineHeight: 1.2,
  textLineHeight: 1.5,

  transitionValue: "0.3s ease-out",
  transitionValueHeader: "0.45s cubic-bezier(0.77, 0, 0.175, 1)",

  // Responsiveness
  breakpoint1: "37.5rem", // 600px
  breakpoint2: "61.875rem", // "990px"
  breakpoint3: "80rem", // "1280px"
} as const;

export default Object.freeze(theme);
