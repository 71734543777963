import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import useDeleteAccount from "../../../../hooks/api/useDeleteAccount";
import { passwordErrorMessage } from "../../../../utils/errorMessagesAuth";
import { passwordRegex } from "../../../../utils/regexPatterns";
import HideRevealPassword from "../../../shared/auth/HideRevealPassword";
import Button from "../../../shared/Button";
import ErrorMessage from "../../../shared/form/ErrorMessage";
import LabelForm from "../../../shared/form/LabelForm";
import Input from "../../../shared/form/normal/Input";
import SubmitButton from "../../../shared/form/normal/SubmitButton";
import Modal from "../../../shared/Modal";
import IntroductionText from "../../../shared/text/IntroductionText";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface formValues {
  password: string;
}

const DeleteAccountModal = ({ open, setOpen }: Props): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);

  const deleteAccountMutation = useDeleteAccount();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<formValues>();

  useEffect(() => {
    if (!open) {
      setIncorrectPassword(false);
      setShowPassword(false);
      reset();
    }
  }, [open]);

  const submitHandler = (data: formValues) => {
    setIncorrectPassword(false);

    deleteAccountMutation.mutate(data, {
      onError: (error) => {
        if (error.response?.data.error === "Incorrect password") {
          setIncorrectPassword(true);
        }
      },
    });
  };

  return (
    <Modal
      title="Do you really want to delete your account?"
      open={open}
      setOpen={setOpen}
      closable={true}
    >
      <StyledIntroductionText>
        All your created data will be deleted. Are you sure that you want to
        delete your account?
      </StyledIntroductionText>

      {incorrectPassword && (
        <StyledErrorMessage message={"Incorrect password"} />
      )}

      <form onSubmit={handleSubmit(submitHandler)} noValidate autoComplete="on">
        <StyledLabel name="password">Password</StyledLabel>
        <ContainerPasswordInput>
          <StyledInput
            register={register("password", {
              required: true,
              pattern: passwordRegex,
            })}
            error={errors.password}
            errorMessage={passwordErrorMessage}
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
          />
          <HideRevealPassword
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        </ContainerPasswordInput>

        <ButtonContainer>
          <StyledSubmitButton text="Delete account" />
          <WhiteButton
            eventHandler={() => {
              setOpen(false);
            }}
          >
            Cancel
          </WhiteButton>
        </ButtonContainer>
      </form>
    </Modal>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};
  margin-top: ${(props) => props.theme.spacing2};
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const StyledLabel = styled(LabelForm)`
  color: ${(props) => props.theme.black4};
  margin-bottom: 0.25rem; // 4px
`;

const ContainerPasswordInput = styled.div`
  position: relative;
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const StyledInput = styled(Input)`
  color: ${(props) => props.theme.black4};
  border-style: solid;
  border-width: 0.125rem; // 2px
  border-color: ${(props) => props.theme.black4};

  outline: none;

  padding-right: 4rem; // 64px

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${(props) => props.theme.black3};
  }
`;

const StyledSubmitButton = styled(SubmitButton)`
  background-color: ${(props) => props.theme.purple2};
  color: ${(props) => props.theme.purple4};
  width: 50%;
  margin-right: 0.25rem;
  white-space: normal;

  &:hover {
    background-color: ${(props) => props.theme.purple1};
  }
  &:focus {
    outline-color: ${(props) => props.theme.purple1};
    background-color: ${(props) => props.theme.purple1};
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 0;
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const WhiteButton = styled(Button)`
  background-color: ${(props) => props.theme.white};
  color: ${(props) => props.theme.black4};
  width: 50%;

  &:focus {
    outline-color: ${(props) => props.theme.black4};
  }
`;

export default DeleteAccountModal;
