import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import { DogValuesNoIds } from "../../types/dogTypes";

const useCreateDog = (): UseMutationResult<
  void,
  AxiosError,
  DogValuesNoIds
> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      await axios.post<void>(`${BACKEND_URL}/api/dogs`, data);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["dogs"], { exact: true });
        queryClient.invalidateQueries(["loggedInUser"], { exact: true });
      },
    }
  );
};

export default useCreateDog;
