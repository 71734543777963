import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  eventHandler?: (e: React.MouseEvent<HTMLElement>) => void;
  asA?: boolean;
  href?: string;
  asLink?: boolean;
  to?: string;
  className?: string;
}

const Button = ({
  children,
  eventHandler,
  asA,
  href,
  asLink,
  to,
  className,
}: Props): JSX.Element => {
  if (asA) {
    return (
      <StyledButton as={"a"} className={className} href={href} target="_blank">
        {children}
      </StyledButton>
    );
  }
  if (asLink && to) {
    return (
      <StyledButton as={Link} className={className} to={to}>
        {children}
      </StyledButton>
    );
  }
  return (
    <StyledButton onClick={eventHandler} className={className}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  cursor: pointer;
  padding: ${(props) => props.theme.spacing1} 2rem;
  border-radius: 100rem;
  border: none;
  font-size: ${(props) => props.theme.buttonTextSize};
  line-height: ${(props) => props.theme.componentLineHeight};
  font-family: "DM Sans Bold", sans-serif;
  font-weight: 400;
  transition: color ${(props) => props.theme.transitionValue},
    background-color ${(props) => props.theme.transitionValue};
  text-decoration: none;

  &:focus {
    outline-width: 0.125rem; // 2px
    outline-style: solid;
    outline-offset: 0.125rem; // 2px
  }
  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.buttonTextSizeMobile};
  }
`;

export default Button;
