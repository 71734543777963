import React from "react";
import NUTRIENT_INGREDIENT_UNITS_SHORT from "../../../../../../constants/ingredients/nutrientIngredientUnitsShort";
import calculateNutrientAmountWeight from "../../../../../../services/calculateNutrientAmountWeight";
import {
  DefaultWeightUnits,
  IngredientDetailed,
  NutrientIngredientType,
  TableRowValueNestedTable,
} from "../../../../../../types/ingredientTypes";
import {
  NutrientsStandard,
  NutrientStandardType,
} from "../../../../../../types/standardTypes";
import NutrientRow from "./NutrientRow";

interface Props {
  nutrientStandard: NutrientStandardType;
  nutrientIngredient: NutrientIngredientType;
  ingredients: IngredientDetailed[];
  nutrientReqsMin: NutrientsStandard;
  nutrientReqsMax: NutrientsStandard;
  openNutrientFindIngredientModal: (nutrient: NutrientIngredientType) => void;
  openNutrientInfoModal?: () => void;
}

const NutrientRowSingle = ({
  nutrientStandard,
  nutrientIngredient,
  ingredients,
  nutrientReqsMin,
  nutrientReqsMax,
  openNutrientFindIngredientModal,
  openNutrientInfoModal,
}: Props): JSX.Element => {
  const unitShort = NUTRIENT_INGREDIENT_UNITS_SHORT[nutrientIngredient];

  // Nested table rows
  const removedHiddenIngredients = ingredients.filter((ingredient) => {
    return ingredient.show;
  });

  let nestedTableRows: TableRowValueNestedTable[] =
    removedHiddenIngredients.map((ingredient) => {
      const value = ingredient[nutrientIngredient];
      let adjustedValue: number | null = null;
      if (value === null) {
        adjustedValue = null;
      } else if (ingredient.defaultUnit !== "gram") {
        adjustedValue = value * ingredient.amount;
      } else {
        adjustedValue = calculateNutrientAmountWeight(
          ingredient.unit as DefaultWeightUnits,
          ingredient.amount,
          value
        );
      }
      return {
        name: ingredient.name,
        value: adjustedValue,
      };
    });
  nestedTableRows = nestedTableRows.sort((a, b) => {
    const aValue = a.value === null ? -1 : a.value;
    const bValue = b.value === null ? -1 : b.value;

    return aValue > bValue ? -1 : bValue > aValue ? 1 : 0;
  });

  // Total in meal
  const nutrientValues = nestedTableRows.map((row) => {
    if (row.value === null) {
      return 0;
    } else {
      return row.value;
    }
  });
  const totalInMeal = nutrientValues.reduce(
    (total, number) => total + number,
    0
  );

  return (
    <NutrientRow
      nutrientStandard={nutrientStandard}
      nutrientIngredient={nutrientIngredient}
      nutrientReqsMin={nutrientReqsMin}
      nutrientReqsMax={nutrientReqsMax}
      totalInMeal={totalInMeal}
      unitShort={unitShort}
      nestedTableRows={nestedTableRows}
      openNutrientInfoModal={openNutrientInfoModal}
      openNutrientFindIngredientModal={openNutrientFindIngredientModal}
    />
  );
};

export default NutrientRowSingle;
