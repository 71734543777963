import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  className?: string;
  asValue?: "li" | "legend" | "th" | "span" | "td";
  role?: string;
  refValue?: React.MutableRefObject<HTMLTableCellElement | null>;
}

const Text = ({
  children,
  className,
  asValue,
  role,
  refValue,
}: Props): JSX.Element => {
  return (
    <StyledText className={className} as={asValue} role={role} ref={refValue}>
      {children}
    </StyledText>
  );
};

const StyledText = styled.p`
  font-size: ${(props) => props.theme.buttonTextSize};
  line-height: ${(props) => props.theme.textLineHeight};
  font-family: "DM Sans Regular", sans-serif;
  font-weight: 400;

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.buttonTextSizeMobile};
  }
`;

export default Text;
