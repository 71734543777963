import React from "react";
import styled from "styled-components";
import FiftyFifty from "./FiftyFifty";
import dogFilledInUrl from "../../../../assets/images/mealment_dog.png";
import recipeBuilderUrl from "../../../../assets/images/mealment_recipe.png";
import ingredientUrl from "../../../../assets/images/mealment_ingredient.png";

const ScreenshotSection = (): JSX.Element => {
  return (
    <StyledContainer>
      <MarginFiftyFifty
        textText="On the profile page, you will fill in information about your dog. The data will then automatically transfer to the recipe builder that will use this information to give you the correct output/result. On the profile page, you can also choose what standard you prefer. Of course, the web application supports neutered, pregnant and lactating dogs. You can also add multiple dogs and easily switch between them."
        headingText="Creating dog profiles"
        imageRight={true}
        imageUrl={dogFilledInUrl}
        imageAlt="A screenshot of the application's dog profile page"
      />
      <MarginFiftyFifty
        textText="In the recipe list, you can see your saved recipes. Either create a new or select an existing one to create a recipe. In the recipe builder, you can add your ingredients. The web application then calculates how well the recipe fulfils the necessary calories and nutrients for your dog and will show it to you with a user-friendly interface."
        headingText="Building recipes"
        imageRight={false}
        imageUrl={recipeBuilderUrl}
        imageAlt="A screenshot of the application's recipe builder page"
      />
      <MarginFiftyFifty
        textText="You can enhance the selection of ingredients in the application by adding new ones with their respective unit and nutrient values. Once created, these ingredients will be available for use in future recipes. This offers you the flexibility to personalize your dog's diet according to their specific needs and preferences, ensuring optimal health and wellness."
        headingText="Creating ingredients"
        imageRight={true}
        imageUrl={ingredientUrl}
        imageAlt="A screenshot of the application's ingredient builder page"
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.section`
  margin-top: ${(props) => props.theme.spacing6};
  margin-left: ${(props) =>
    "calc(" + props.theme.spacing1 + " + env(safe-area-inset-left))"};
  margin-right: ${(props) =>
    "calc(" + props.theme.spacing1 + " + env(safe-area-inset-right))"};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-top: ${(props) => props.theme.spacing5};
  }
`;

const MarginFiftyFifty = styled(FiftyFifty)`
  margin-bottom: ${(props) => props.theme.spacing4};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: ${(props) => props.theme.spacing3};
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export default ScreenshotSection;
