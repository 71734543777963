import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as LeftArrowSvg } from "../../../../assets/icons/arrow-left.svg";
import { ReactComponent as RightArrowSvg } from "../../../../assets/icons/arrow-right.svg";

interface Props {
  // [x: string]: any;
  onClick: any;
  right: boolean;
  refCard: React.MutableRefObject<HTMLDivElement | null>;
}

const CustomArrowButton = ({
  onClick,
  right,
  refCard,
}: Props): JSX.Element | null => {
  const [distance, setDistance] = useState<number | null>(null);
  const [visible, setVisible] = useState(false);

  const refButton = useRef<null | HTMLButtonElement>(null);

  useEffect(() => {
    if (
      refButton &&
      refButton.current &&
      refButton.current.parentElement &&
      refCard.current
    ) {
      const clientWidth = document.body.clientWidth;
      // Responsiveness
      const widthWithScroll = window.innerWidth;
      const scrollWidth = widthWithScroll - clientWidth;
      if (clientWidth + scrollWidth < 991) {
        const sizeContainer = refCard.current.offsetWidth;
        const distanceToSet = -(clientWidth / 2 - sizeContainer / 2 - 32);
        setDistance(distanceToSet);
      } else {
        const elementRight =
          refButton.current.parentElement.getBoundingClientRect().right;
        const minMargin = -104;
        const maxMargin = 16;

        let distanceToSet;

        const spaceRight = clientWidth - elementRight;

        distanceToSet = 16 - spaceRight;

        distanceToSet = Math.max(minMargin, distanceToSet);
        distanceToSet = Math.min(maxMargin, distanceToSet);
        setDistance(distanceToSet);
      }

      if (!visible) {
        setVisible(true);
      }
    }
  }, [document.body.clientWidth]);

  return (
    <StyledButton
      onClick={() => onClick()}
      right={right}
      ref={refButton}
      distance={distance}
      visible={visible}
    >
      {right ? (
        <StyledRightArrowSvg aria-label="Next features" />
      ) : (
        <StyledLeftArrowSvg aria-label="Previous features" />
      )}
    </StyledButton>
  );
};

const StyledButton = styled.button.attrs(
  (props: { distance: number | null; right: boolean }) => ({
    style: {
      marginRight: props.right && props.distance + "px",
      marginLeft: !props.right && props.distance + "px",
    },
  })
)`
  position: absolute;
  border-radius: 100%;
  z-index: 1;
  border: 0;
  width: 3.75rem; // 60px
  height: 3.75rem; // 60px
  background-color: ${(props) => props.theme.green3};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: background-color ${(props) => props.theme.transitionValue};

  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  right: ${(props) => props.right && 0};
  left: ${(props) => !props.right && 0};

  &:hover {
    background-color: ${(props) => props.theme.green4};
  }
  &:focus {
    outline-width: 0.125rem; // 2px
    outline-style: solid;
    outline-offset: 0.125rem; // 2px
    outline-color: ${(props) => props.theme.green4};
    background-color: ${(props) => props.theme.green4};
  }
`;

const StyledRightArrowSvg = styled(RightArrowSvg)`
  color: ${(props) => props.theme.green1};
  width: 1.5rem; // 24px
  height: 1.5rem; // 24px
`;

const StyledLeftArrowSvg = styled(LeftArrowSvg)`
  color: ${(props) => props.theme.green1};
  width: 1.5rem; // 24px
  height: 1.5rem; // 24px
`;

export default CustomArrowButton;
