import { useEffect, useState } from "react";

interface Output {
  editing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  saveEdit: () => void;
  cancelEdit: () => void;
  handleKeys: (e: React.KeyboardEvent) => void;
}

const useEditableLogic = <T>(
  serverValue: T | undefined,
  elementValue: T | null,
  setInputValue: React.Dispatch<React.SetStateAction<T | null>>,
  onSave: (value: T) => void,
  focusElement: () => void,
  checkifEqual: (a: T, b: T) => boolean,
  checkIfEmpty: (value: T) => boolean,
  allowEmpty?: boolean
): Output => {
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setInputValue(serverValue === undefined ? null : serverValue);
  }, [serverValue]);

  useEffect(() => {
    if (editing) {
      focusElement();
    }
  }, [editing]);

  const handleKeys = (e: React.KeyboardEvent) => {
    if (!editing) return;
    if (e.key === "Escape") {
      e.preventDefault();
      cancelEdit();
    } else if (e.key === "Enter") {
      e.preventDefault();
      saveEdit();
    }
  };

  const saveEdit = () => {
    setEditing(false);

    if (elementValue === null) {
      setInputValue(serverValue || null);
      return;
    }

    // Handle empty values if allowed
    if (allowEmpty && checkIfEmpty(elementValue)) {
      onSave(elementValue);
      return;
    } else if (checkIfEmpty(elementValue)) {
      setInputValue(serverValue || null);
      return;
    }

    if (serverValue && checkifEqual(elementValue, serverValue)) {
      return;
    }
    onSave(elementValue);
  };

  const cancelEdit = () => {
    setInputValue(serverValue || null);
    setEditing(false);
  };

  return { editing, setEditing, saveEdit, cancelEdit, handleKeys };
};

export default useEditableLogic;
