import React from "react";
import { Route } from "react-router-dom";

interface Props {
  children: React.ReactNode;
  path: string;
  exact?: boolean;
}

const RouteHandler = ({ children, path, exact }: Props): JSX.Element | null => {
  return (
    <Route path={path} exact={exact ? exact : false}>
      {children}
    </Route>
  );
};

export default RouteHandler;
