import React from "react";

const ErrorPage = (): JSX.Element => {
  return (
    <div>
      <h1>Something went wrong!!</h1>
    </div>
  );
};

export default ErrorPage;
