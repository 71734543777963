import React from "react";
import styled from "styled-components";
import Heading4 from "../../../../shared/text/headings/Heading4";
import Text from "../../../../shared/text/Text";
import Button from "../../../../shared/Button";
import { NutrientIngredientType } from "../../../../../types/ingredientTypes";
import NUTRIENT_INGREDIENT_NAMES from "../../../../../constants/ingredients/nutrientIngredientNames";

interface Props {
  nutrient: NutrientIngredientType;
  openNutrientFindIngredientModal: (nutrient: NutrientIngredientType) => void;
}

const NeedMoreBadge = ({
  nutrient,
  openNutrientFindIngredientModal,
}: Props): JSX.Element => {
  const nameNutrientStandard = NUTRIENT_INGREDIENT_NAMES[nutrient];

  return (
    <Container>
      <StyledHeading4>Need more of this nutrient?</StyledHeading4>
      <Preamble>
        We can help you find ingredients with a high amount of{" "}
        {nameNutrientStandard.toLowerCase()}.
      </Preamble>
      <StyledButton
        eventHandler={() => openNutrientFindIngredientModal(nutrient)}
      >
        Find nutrients
      </StyledButton>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 2rem; // 32px
  background-color: ${(props) => props.theme.green1};
  max-width: ${(props) => props.theme.widthSmallText};
  padding: ${(props) => props.theme.spacing3};
  margin-top: ${(props) => props.theme.spacing2};
`;

const StyledHeading4 = styled(Heading4)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing1};
`;

const Preamble = styled(Text)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing1};
  max-width: 37.5rem; // 600px
`;

const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.green3};
  color: ${(props) => props.theme.green1};

  &:hover {
    background-color: ${(props) => props.theme.green4};
  }
  &:focus {
    background-color: ${(props) => props.theme.green4};
    outline-color: ${(props) => props.theme.green4};
  }
`;

export default NeedMoreBadge;
