import adjustNutrientReqCalories from "../../../services/nutrientReqs/adjustNutrientReqCalories";
import { NutrientsStandard } from "../../../types/standardTypes";
import { NUTRIENT_REQ_FEDIAF_EARLY_GROWTH_MIN } from "./nutrientReqFediafEarlyGrowth";

const NUTRIENT_REQ_FEDIAF_REPRODUCTION_MIN: NutrientsStandard = {
  ...NUTRIENT_REQ_FEDIAF_EARLY_GROWTH_MIN,
};

const NUTRIENT_REQ_FEDIAF_REPRODUCTION_MAX: NutrientsStandard = {
  // Macronutrients
  protein: null,
  fat: null,
  carbohydrate: null,
  water: null,
  ash: null,

  // Amino acids
  tryptophan: null,
  threonine: null,
  isoleucine: null,
  leucine: null,
  lysine: null,
  methionine: null,
  methionineAndCystine: null,
  phenylalanine: null,
  phenylalanineAndTyrosine: null,
  valine: null,
  arginine: null,
  histidine: null,

  // Fatty acids
  linoleicAcid: null,
  alphaLinoleicAcid: null,
  arachidonicAcid: null,
  linoleicToAlphaLinoleicAcids: 16,
  epaAndDha: null,

  // Carbohydrates
  fiber: null,

  // Minerals
  calcium: null,
  iron: null,
  magnesium: null,
  phosphorus: null,
  calciumToPhosphorus: 1.6,
  potassium: null,
  chloride: null,
  sodium: null,
  zinc: null,
  copper: null,
  zincToCopper: null,
  manganese: null,
  selenium: null,
  iodine: null,

  // Vitamins
  thiaminB1: null,
  riboflavinB2: null,
  niacinB3: null,
  pantothenicAcidB5: null,
  vitaminB6: null,
  folicAcidB9: null,
  choline: null,
  vitaminB12: null,
  vitaminA: 30000,
  vitaminE: null,
  cholecalciferolD3: 20,
  phylloquinoneK: null,
};

export const getNutrientReqFediafReproductionMin = (
  calorieRequriement: number,
  protein: number
): NutrientsStandard => {
  let specificReq = NUTRIENT_REQ_FEDIAF_REPRODUCTION_MIN;
  const proteinDiff = protein - 200;
  if (proteinDiff > 0) {
    specificReq = {
      ...specificReq,
      arginine: (specificReq.arginine as number) + proteinDiff * 0.01,
    };
  }

  const adjustedNutrients = adjustNutrientReqCalories(
    specificReq,
    calorieRequriement
  );
  return adjustedNutrients;
};

export const getNutrientReqFediafReproductionMax = (
  calorieRequriement: number
): NutrientsStandard => {
  const adjustedNutrients = adjustNutrientReqCalories(
    NUTRIENT_REQ_FEDIAF_REPRODUCTION_MAX,
    calorieRequriement
  );
  return adjustedNutrients;
};
