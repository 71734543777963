import React from "react";
import styled from "styled-components";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import WrapLink from "./WrapLink";

interface Props {
  children: React.ReactNode;
  clearBanner: () => void;
  className?: string;
}

const Banner = ({ children, clearBanner, className }: Props): JSX.Element => {
  return (
    <Container className={className}>
      {children}
      <StyledWrapLink asButton={true} clickHandler={clearBanner}>
        <StyledXIcon />
      </StyledWrapLink>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.purple1};
  padding-top: ${(props) => props.theme.halfSpacing1};
  padding-bottom: ${(props) => props.theme.halfSpacing1};
  padding-left: ${(props) => props.theme.spacing1};
  padding-right: ${(props) => props.theme.spacing1};
  border-radius: 0.5rem; // 8px
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledXIcon = styled(XIcon)`
  width: ${(props) => props.theme.spacing1};
  height: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.purple4};
`;

const StyledWrapLink = styled(WrapLink)`
  margin-left: ${(props) => props.theme.spacing1};
  display: flex;
  align-items: center;
`;

export default Banner;
