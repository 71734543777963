import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  ALREADY_USED_VERIFICATION_MESSAGE,
  POSITIVE_VERIFICATION_MESSAGE,
} from "../../../../constants/constants";
import AuthEmailContext from "../../../../contexts/authEmailContext/AuthEmailContext";
import VerificationTokenMessageContext from "../../../../contexts/verificationTokenMessageContext/VerificationTokenMessageContext";
import AuthContainer from "../../../shared/auth/AuthContainer";
import LoginPartial from "../../../shared/auth/LoginPartial";
import Banner from "../../../shared/Banner";
import ErrorMessage from "../../../shared/form/ErrorMessage";
import SmallText from "../../../shared/text/SmallText";

const Login = (): JSX.Element => {
  const history = useHistory();
  const authEmailContextValue = useContext(AuthEmailContext);
  const verificationTokenMessageContextValue = useContext(
    VerificationTokenMessageContext
  );

  useEffect(() => {
    return () => {
      if (verificationTokenMessageContextValue?.verificationTokenMessage) {
        verificationTokenMessageContextValue.setVerificationTokenMessage("");
      }
    };
  }, []);

  return (
    <AuthContainer title="Log in">
      {verificationTokenMessageContextValue?.verificationTokenMessage &&
        (verificationTokenMessageContextValue.verificationTokenMessage ===
          POSITIVE_VERIFICATION_MESSAGE ||
        verificationTokenMessageContextValue.verificationTokenMessage ===
          ALREADY_USED_VERIFICATION_MESSAGE ? (
          <StyledBanner
            clearBanner={() =>
              verificationTokenMessageContextValue.setVerificationTokenMessage(
                ""
              )
            }
          >
            <StyledSmallText>
              {verificationTokenMessageContextValue.verificationTokenMessage}
            </StyledSmallText>
          </StyledBanner>
        ) : (
          <StyledErrorMessage
            message={
              verificationTokenMessageContextValue.verificationTokenMessage
            }
          />
        ))}
      <LoginPartial
        forgetHandler={(email: string) => {
          authEmailContextValue?.setAuthEmail(email);
          history.push("/forgot");
        }}
        signupHandler={(email: string) => {
          authEmailContextValue?.setAuthEmail(email);
          history.push("/signup");
        }}
        savedEmail={authEmailContextValue?.authEmail || ""}
        redirectAfterLogin={false}
      />
    </AuthContainer>
  );
};

const StyledSmallText = styled(SmallText)`
  color: ${(props) => props.theme.purple4};
`;

const StyledBanner = styled(Banner)`
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 0;
  margin-bottom: ${(props) => props.theme.spacing2};
`;

export default Login;
