import React from "react";
import styled from "styled-components";
import AuthLogic from "../AuthLogic";
import Heading2 from "../text/headings/Heading2";

interface Props {
  title: string;
  children: React.ReactNode;
}

const AuthContainer = ({ title, children }: Props): JSX.Element => {
  return (
    <>
      <AuthLogic />
      <Container>
        <Content>
          <StyledHeading2 asValue={"h2"}>{title}</StyledHeading2>
          {children}
        </Content>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  max-width: 45rem; // 720px
  width: 100%;
  margin-top: ${(props) => props.theme.spacing6};
  margin-left: ${(props) => props.theme.spacing1};
  margin-right: ${(props) => props.theme.spacing1};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-top: ${(props) => props.theme.spacing5};
  }
`;

const StyledHeading2 = styled(Heading2)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};
`;

export default AuthContainer;
