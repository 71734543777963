import React, { useEffect, useState } from "react";
import styled from "styled-components";
import calculateCaloriesNeeded from "../../../../services/calculateCaloriesNeeded";
import checkIfPuppy from "../../../../services/checkIfPuppy";
import getValuesLabelsBreedSize from "../../../../services/getValuesLabelsBreedSize";
import theme from "../../../../style/theme";
import { breedSizeType } from "../../../../types/dogTypes";
import { infoOptions } from "../../../../types/frontendTypes";
import { kgOrLb } from "../../../../types/userTypes";
import calculateWeeksSinceDate from "../../../../utils/date/calculateWeeksSinceDate";
import getDateNumberOfWeeksBack from "../../../../utils/date/getDateNumberOfWeeksBack";
import isBreedSize from "../../../../utils/typeGuards/isBreedSize";
import isWeightUnit from "../../../../utils/typeGuards/isWeightUnit";
import DogInputInfoModal from "../../../shared/DogInputInfoModal";
import InfoModalButton from "../../../shared/InfoModalButton";
import LabelForm from "../../../shared/form/LabelForm";
import SimpleCheckbox from "../../../shared/form/simple/SimpleCheckbox";
import SimpleDatePicker from "../../../shared/form/simple/simpleDatePicker/SimpleDatePicker";
import SimpleInputNumber from "../../../shared/form/simple/SimpleInputNumber";
import SimpleSelect from "../../../shared/form/simple/SimpleSelect";
import SimpleSwitcher from "../../../shared/form/simple/SimpleSwitcher";
import SimpleWeightInput from "../../../shared/form/simple/SimpleWeightInput";
import FormLabel from "../../../shared/text/FormLabel";
import Heading2 from "../../../shared/text/headings/Heading2";
import Heading3 from "../../../shared/text/headings/Heading3";
import IntroductionText from "../../../shared/text/IntroductionText";

const CalorieCalculatorLanding = (): JSX.Element => {
  const [weightUnit, setWeightUnit] = useState<kgOrLb>("kg");
  const [birthDate, setBirthDate] = useState<Date | null>(null);
  const [breedSize, setBreedSize] = useState<breedSizeType | "">("");
  const [weight, setWeight] = useState<number | null>(null);
  const [activityLevel, setActivityLevel] = useState<number | null>(null);
  const [spayedOrNeutered, setSpayedOrNeutered] = useState(false);
  const [gestation, setGestation] = useState(false);
  const [lactation, setLactation] = useState(false);

  const [expectedWeight, setExpectedWeight] = useState<number | null>(null);
  const [matingDate, setMatingDate] = useState<Date | null>(null);
  const [parturitionDate, setParturitionDate] = useState<Date | null>(null);
  const [numberOfPuppies, setNumberOfPuppies] = useState<number | null>(null);

  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [infoToShow, setInfoToShow] = useState<infoOptions>("expectedWeight");

  let caloriesNeeded: number | null = null;
  if (
    birthDate &&
    weight &&
    activityLevel &&
    breedSize &&
    isBreedSize(breedSize) &&
    isWeightUnit(weightUnit)
  ) {
    caloriesNeeded = calculateCaloriesNeeded(
      birthDate,
      breedSize,
      weightUnit,
      weight,
      expectedWeight,
      activityLevel,
      spayedOrNeutered,
      gestation,
      lactation,
      matingDate,
      parturitionDate,
      numberOfPuppies
    );
  }

  let isPuppy: boolean | null = null;
  if (birthDate && breedSize && isBreedSize(breedSize)) {
    isPuppy = checkIfPuppy(birthDate, breedSize);
  }

  let ageWeeks: number | null = null;
  if (birthDate) {
    ageWeeks = calculateWeeksSinceDate(birthDate);
  }

  useEffect(() => {
    if (!isPuppy) {
      setExpectedWeight(null);
    }
  }, [isPuppy]);

  useEffect(() => {
    if (!gestation) {
      setMatingDate(null);
    }
  }, [gestation]);

  useEffect(() => {
    if (!lactation) {
      setParturitionDate(null);
      setNumberOfPuppies(null);
    }
  }, [lactation]);

  const valuesLabelsBreedSize = getValuesLabelsBreedSize(weightUnit);

  return (
    <>
      <StyledContainer id="calorie-calculator">
        <InnerContainer>
          <TextContainer>
            <StyledHeading2>Just a tiny part</StyledHeading2>
            <StyledIntroductionText>
              Use this calculator to understand your dog&apos;s caloric needs.
              It closely adheres to the calculations published in the book
              Nutrient Requirements of Dogs and Cats by the National Research
              Council. Always monitor and listen to your dog; they are all
              unique individuals with unique caloric needs.
            </StyledIntroductionText>
          </TextContainer>
          <Calculator>
            <WeightUnitSwitcher
              changeHandler={(value: kgOrLb) => {
                setWeightUnit(value);
              }}
              value={weightUnit}
              valueOption1={"kg"}
              valueOption2={"lb"}
              title="Kilograms or pounds"
              id="kgsOrLbs"
              labelOption1="Kilograms"
              labelOption2="Pounds"
              color1={theme.green3}
              color2={theme.green1}
              colorTitle={theme.green4}
            />
            <StyledLabel name="birthDate">Birth date</StyledLabel>
            <StyledSimpleInputNoMargin
              value={birthDate}
              setValue={setBirthDate}
              id={"birthDate"}
            />

            <StyledLabelMarginTop name="breedSize">
              Breed size
            </StyledLabelMarginTop>
            <StyledSimpleSelect
              id="breedSize"
              changeValue={(value: string) => {
                if (isBreedSize(value)) {
                  setBreedSize(value);
                }
              }}
              valuesLabels={valuesLabelsBreedSize}
              value={breedSize}
              placeholder={"Choose your dog’s breed size"}
              placeholderColor={theme.green3}
            />

            <StyledLabel name="weight">Weight</StyledLabel>
            <StyledSimpleInput
              as={SimpleWeightInput}
              id="weight"
              setValue={setWeight}
              unit={isWeightUnit(weightUnit) ? weightUnit : "kg"}
              value={weight}
              colorWeightUnit={theme.green4}
              placeholder={"Specify your dog’s weight"}
            />
            {isPuppy && (
              <>
                <StyledLabel name="expectedWeight">
                  Expected weight{" "}
                  <StyledInfoModalButton
                    clickHandler={() => {
                      setInfoToShow("expectedWeight");
                      setInfoModalOpen(true);
                    }}
                    ariaLabelIcon={"More information about the weight input"}
                  />
                </StyledLabel>
                <StyledSimpleInput
                  as={SimpleWeightInput}
                  id="expectedWeight"
                  setValue={setExpectedWeight}
                  unit={isWeightUnit(weightUnit) ? weightUnit : "kg"}
                  value={expectedWeight}
                  colorWeightUnit={theme.green4}
                  placeholder={"Specify your dog’s expected weight"}
                />
              </>
            )}
            <StyledLabel name="activityLevel">
              Activity level
              <StyledInfoModalButton
                clickHandler={() => {
                  setInfoToShow("activityLevel");
                  setInfoModalOpen(true);
                }}
                ariaLabelIcon={
                  "More information about the activity level input"
                }
              />
            </StyledLabel>
            <StyledSimpleInput
              as={SimpleInputNumber}
              id="activityLevel"
              setValue={setActivityLevel}
              value={activityLevel}
              placeholder={"Specify your dog’s activity level"}
              noDecimals={true}
            />

            <StyledSimpleCheckbox
              label={"Spayed or neutered"}
              id={"spayedOrNeutered"}
              setValue={setSpayedOrNeutered}
              value={spayedOrNeutered}
              multi={false}
              disabled={gestation || lactation}
            />

            <StyledFormLabel asValue={"legend"} isDisabled={spayedOrNeutered}>
              Pregnancy status
            </StyledFormLabel>
            <GestationCheckBox
              label={"Gestation"}
              id={"gestation"}
              setValue={setGestation}
              value={gestation}
              multi={true}
              disabled={spayedOrNeutered || lactation}
            />
            <LactationCheckBox
              label={"Lactation"}
              id={"lactation"}
              setValue={setLactation}
              value={lactation}
              multi={true}
              disabled={spayedOrNeutered || gestation}
            />

            {gestation && (
              <>
                <StyledLabel name="matingDate">Mating date</StyledLabel>
                <StyledSimpleInput
                  value={matingDate}
                  setValue={setMatingDate}
                  id={"matingDate"}
                />
              </>
            )}

            {lactation && (
              <>
                <StyledLabel name="parturitionDate">
                  Parturition date{" "}
                  <StyledInfoModalButton
                    clickHandler={() => {
                      setInfoToShow("parturitionDate");
                      setInfoModalOpen(true);
                    }}
                    ariaLabelIcon={
                      "More information about the activity level input"
                    }
                  />
                </StyledLabel>
                <StyledSimpleInput
                  value={parturitionDate}
                  setValue={setParturitionDate}
                  id={"parturitionDate"}
                  minDate={getDateNumberOfWeeksBack(4)}
                />
                <StyledLabel name="numberOfPuppies">
                  Number of puppies
                </StyledLabel>
                <StyledSimpleInput
                  as={SimpleInputNumber}
                  id="numberOfPuppies"
                  setValue={setNumberOfPuppies}
                  value={numberOfPuppies}
                  placeholder={"Specify the number of puppies"}
                  noDecimals={true}
                />
              </>
            )}

            <Results>
              <StyledHeading3>Daily calorie goal</StyledHeading3>
              <ResultsText aria-live="polite">
                {ageWeeks && ageWeeks < 4
                  ? "The puppy should be nursing"
                  : Math.round(caloriesNeeded || 0) + " kcal"}
              </ResultsText>
            </Results>
          </Calculator>
        </InnerContainer>
      </StyledContainer>
      <DogInputInfoModal
        open={infoModalOpen}
        setOpen={setInfoModalOpen}
        toShow={infoToShow}
      />
    </>
  );
};

const StyledContainer = styled.section`
  padding-left: ${(props) =>
    "calc(" + props.theme.spacing1 + " + env(safe-area-inset-left))"};
  padding-right: ${(props) =>
    "calc(" + props.theme.spacing1 + " + env(safe-area-inset-right))"};
`;

const InnerContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing6};
  margin-left: auto;
  margin-right: auto;
  background-color: ${(props) => props.theme.green2};
  max-width: ${(props) => props.theme.mainWidth};
  border-radius: 2rem; // 32px
  padding-top: ${(props) => props.theme.spacing5};
  padding-bottom: ${(props) => props.theme.spacing5};
  padding-left: ${(props) => props.theme.spacing1};
  padding-right: ${(props) => props.theme.spacing1};
  box-sizing: border-box;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-top: ${(props) => props.theme.spacing5};
    padding-top: ${(props) => props.theme.spacing4};
    padding-bottom: ${(props) => props.theme.spacing4};
  }
`;

const TextContainer = styled.div`
  max-width: ${(props) => props.theme.halfWidth};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${(props) => props.theme.spacing4};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: ${(props) => props.theme.spacing3};
  }
`;

const StyledHeading2 = styled(Heading2)`
  color: ${(props) => props.theme.green4};
  text-align: center;
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.green4};
  text-align: center;
`;

const Calculator = styled.div`
  max-width: ${(props) => props.theme.halfWidth};
  margin-left: auto;
  margin-right: auto;
`;

// https://github.com/styled-components/styled-components/issues/1803#issuecomment-857092410
const WeightUnitSwitcher = styled(SimpleSwitcher)`
  margin-bottom: ${(props) => props.theme.spacing2};
` as typeof SimpleSwitcher;

const StyledLabel = styled(LabelForm)`
  color: ${(props) => props.theme.green4};
  margin-bottom: 0.25rem; // 4px
`;

const StyledLabelMarginTop = styled(StyledLabel)`
  margin-top: ${(props) => props.theme.spacing2};
`;

const StyledSimpleInput = styled(SimpleDatePicker)`
  border-color: ${(props) => props.theme.green4};
  background-color: ${(props) => props.theme.green1};
  color: ${(props) => props.theme.green4};
  margin-bottom: ${(props) => props.theme.spacing2};

  &::placeholder {
    color: ${(props) => props.theme.green3};
  }
  &:focus {
    background-color: ${(props) => props.theme.white};
  }
  &:hover {
    background-color: ${(props) => props.theme.white};
  }
`;

const StyledSimpleSelect = styled(SimpleSelect)`
  border-color: ${(props) => props.theme.green4};
  background-color: ${(props) => props.theme.green1};
  color: ${(props) => props.theme.green4};
  margin-bottom: ${(props) => props.theme.spacing2};

  &::placeholder {
    color: ${(props) => props.theme.green3};
  }
  &:focus {
    background-color: ${(props) => props.theme.white};
  }
  &:hover {
    background-color: ${(props) => props.theme.white};
  }
` as typeof SimpleSelect;

const StyledSimpleInputNoMargin = styled(StyledSimpleInput)`
  margin-bottom: 0;
`;

const StyledInfoModalButton = styled(InfoModalButton)`
  color: ${(props) => props.theme.green4};

  &:hover {
    color: ${(props) => props.theme.green3};
  }
  &:focus {
    color: ${(props) => props.theme.green3};
    outline-color: ${(props) => props.theme.green3};
  }
`;

const StyledSimpleCheckbox = styled(SimpleCheckbox)`
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const GestationCheckBox = styled(SimpleCheckbox)`
  margin-bottom: ${(props) => props.theme.spacing1};
`;

const LactationCheckBox = styled(SimpleCheckbox)`
  margin-bottom: ${(props) => props.theme.spacing3};
`;

const StyledFormLabel = styled(FormLabel)<{ isDisabled?: boolean }>`
  margin-bottom: 0.25rem; // 4px
  color: ${(props) =>
    props.isDisabled ? props.theme.black3 : props.theme.green4};
  transition: color ${(props) => props.theme.transitionValue};
`;

const Results = styled.div`
  background-color: ${(props) => props.theme.green1};
  border-radius: 2rem; // 32px
  text-align: center;
  padding: ${(props) => props.theme.spacing2 + " " + props.theme.spacing1};
`;

const StyledHeading3 = styled(Heading3)`
  margin-bottom: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.green4};
`;

const ResultsText = styled(IntroductionText)`
  color: ${(props) => props.theme.green4};
`;

export default CalorieCalculatorLanding;
