import React, { useContext } from "react";
import styled from "styled-components";
import { priceIdBasicMonthly } from "../../../constants/constants";
import AuthModalContext from "../../../contexts/authModalContext/AuthModalContext";
import useCreateStripeCheckout from "../../../hooks/api/useCreateStripeCheckout";
import useCreateStripePortal from "../../../hooks/api/useCreateStripePortal";
import useGetAuthUser from "../../../hooks/api/useGetAuthUser";
import useGetLoginStatus from "../../../hooks/api/useGetLoginStatus";
import Line from "../../shared/Line";
import Modal from "../../shared/Modal";
import PricePlans from "../../shared/PricePlans";
import StyledLink from "../../shared/StyledLink";
import IntroductionText from "../../shared/text/IntroductionText";
import Text from "../../shared/text/Text";

interface Props {
  open: boolean;
}

const PricingModal = ({ open }: Props): JSX.Element | null => {
  const loggedInStatusQuery = useGetLoginStatus();
  const authUserQuery = useGetAuthUser({
    enabled: !!loggedInStatusQuery?.data?.loggedIn,
  });
  const createStripeCheckoutMutation = useCreateStripeCheckout();
  const createStripePortalMutation = useCreateStripePortal();

  let showTryBasic = true;
  if (
    authUserQuery.data !== undefined &&
    authUserQuery.data.stripeSubscriptionTimestamp !== null
  ) {
    showTryBasic = false;
  }

  const authModalContext = useContext(AuthModalContext);

  if (!authModalContext) {
    return null;
  }

  const handleClick = (priceId: string) => {
    if (authUserQuery.data === undefined) {
      authModalContext.setAuthModalShowing("signup");
      authModalContext.setAuthModalOpen(true);
      return;
    }

    if (authUserQuery.data.stripeSubscription !== null) {
      createStripePortalMutation.mutate();
      return;
    }

    createStripeCheckoutMutation.mutate({ priceId });
  };

  return (
    <Modal open={open} title="Choose a plan" closable={false} wideMode={true}>
      <StyledIntroductionText>
        Choose one of our plans depending on how many recipes you want to create
        and how many dogs you have.{" "}
        {showTryBasic && (
          <>
            You can{" "}
            <StyledStyledLink
              asButton={true}
              clickHandler={() => handleClick(priceIdBasicMonthly)}
            >
              try it for free
            </StyledStyledLink>{" "}
            for 14 days. No credit card required.
          </>
        )}
      </StyledIntroductionText>
      <PricePlans neverSubscribed={showTryBasic} handleClick={handleClick} />
      <StyledLine />
      <StyledText>
        <StyledStyledLink to="/account">Manage your account</StyledStyledLink>
      </StyledText>
    </Modal>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  margin-top: ${(props) => props.theme.spacing2};
  margin-bottom: ${(props) => props.theme.spacing3};
  color: ${(props) => props.theme.black4};
  max-width: ${(props) => props.theme.halfWidth};
  margin-left: auto;
  margin-right: auto;
`;

const StyledStyledLink = styled(StyledLink)`
  color: ${(props) => props.theme.black4};

  &:hover {
    color: ${(props) => props.theme.black3};
  }
  &:focus {
    outline-color: ${(props) => props.theme.black3};
    color: ${(props) => props.theme.black3};
  }
`;

const StyledLine = styled(Line)`
  margin-top: ${(props) => props.theme.spacing3};
  margin-bottom: ${(props) => props.theme.spacing3};
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.black4};

  &:last-child {
    margin-bottom: 0;
  }
`;

export default PricingModal;
