import axios, { AxiosError } from "axios";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import { IngredientFull } from "../../types/ingredientTypes";

const useGetCustomIngredient = (
  id: string,
  options?: UseQueryOptions<any, AxiosError<any>>
): UseQueryResult<IngredientFull, AxiosError> => {
  return useQuery<IngredientFull, AxiosError>(
    ["ingredients", id],
    async () => {
      const response = await axios.get<IngredientFull>(
        `${BACKEND_URL}/api/ingredients/${id}`
      );
      return response.data;
    },
    options
  );
};

export default useGetCustomIngredient;
