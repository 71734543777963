import React from "react";
import styled from "styled-components";
import InnerHTML from "dangerously-set-html-content";

const Disclaimer = (): JSX.Element => {
  return (
    <Container id="testing">
      <InnerHTML
        html={`<div class="section__content">  <div name="termly-embed" data-id="650db46d-135d-405f-a648-a785c736929b" data-type="iframe"></div>  <script src="https://app.termly.io/embed-policy.min.js"></script> </div>`}
      />
    </Container>
  );
};

const Container = styled.div`
  max-width: ${(props) => props.theme.halfWidth};
  margin-top: ${(props) => props.theme.spacing2};
  margin-left: auto;
  margin-right: auto;
`;

export default Disclaimer;
