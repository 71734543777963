import axios, { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import { IngredientSearch } from "../../types/ingredientTypes";

const useGetIngredientsSearch = (): UseQueryResult<
  IngredientSearch[],
  AxiosError
> => {
  return useQuery<IngredientSearch[], AxiosError>(
    ["ingredients", "search"],
    async () => {
      const responseIngredientsSearch = await axios.get<IngredientSearch[]>(
        `${BACKEND_URL}/api/ingredients/search`
      );

      return responseIngredientsSearch.data;
    }
  );
};

export default useGetIngredientsSearch;
