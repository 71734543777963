import { createGlobalStyle } from "styled-components";
import { GlobalStyleType } from "../types/frontendTypes";

const GlobalStyle: any = createGlobalStyle<{
  theme: GlobalStyleType;
}>`
* {
    margin: 0;
    line-height: ${(props) => props.theme.textLineHeight};
}

html {
  scroll-behavior: smooth;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

@media print {
  /* https://www.w3schools.com/howto/howto_css_hide_scrollbars.asp */
  * {
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}


`;

export default GlobalStyle;
