import React from "react";
import styled from "styled-components";
import range from "../../../../utils/date/Range";
import LabelForm from "../LabelForm";
import SimpleSelect from "../simple/SimpleSelect";

interface Props {
  minYearProp?: number | null;
  date: Date;
  changeYear(year: number): void;
}

const CustomDateHeaderYearSelect = ({
  minYearProp,
  date,
  changeYear,
}: Props): JSX.Element => {
  const maxYear = new Date().getFullYear();
  const minYear = minYearProp ? minYearProp : maxYear - 30;
  const yearsRange = range(minYear, maxYear).reverse();
  const valuesLabelsYear = new Map<string, string>();
  for (const year of yearsRange)
    valuesLabelsYear.set(year.toString(), year.toString());

  return (
    <>
      <StyledLabel name="year">Year</StyledLabel>
      <StyledSimpleSelect
        id="year"
        changeValue={(value: string) => changeYear(parseInt(value))}
        valuesLabels={valuesLabelsYear}
        value={date.getFullYear() + ""}
      />
    </>
  );
};

const StyledLabel = styled(LabelForm)`
  color: ${(props) => props.theme.black4};
  margin-bottom: 0.25rem; // 4px
`;

const StyledSimpleSelect = styled(SimpleSelect)`
  border-color: ${(props) => props.theme.black4};
  color: ${(props) => props.theme.black4};
  background-color: ${(props) => props.theme.white};

  &:focus {
    outline-width: 0.125rem; // 2px
    outline-style: solid;
    outline-offset: 0.125rem; // 2px
    outline-color: ${(props) => props.theme.black4};
  }
`;

export default CustomDateHeaderYearSelect;
