import {
  getNutrientReqAafcoAdultMax,
  getNutrientReqAafcoAdultMin,
} from "../constants/ingredients/aafco/nutrientReqAafcoAdult";
import {
  getNutrientReqAafcoGrowthAndReproductionMax,
  getNutrientReqAafcoGrowthAndReproductionMin,
} from "../constants/ingredients/aafco/nutrientReqAafcoGrowthAndReproduction";
import {
  getNutrientReqFediafAdultMax,
  getNutrientReqFediafAdultMin,
} from "../constants/ingredients/fediaf/nutrientReqFediafAdult";
import {
  getNutrientReqFediafEarlyGrowthMax,
  getNutrientReqFediafEarlyGrowthMin,
} from "../constants/ingredients/fediaf/nutrientReqFediafEarlyGrowth";
import {
  getNutrientReqFediafLateGrowthMax,
  getNutrientReqFediafLateGrowthMin,
} from "../constants/ingredients/fediaf/nutrientReqFediafLateGrowth";
import {
  getNutrientReqFediafReproductionMax,
  getNutrientReqFediafReproductionMin,
} from "../constants/ingredients/fediaf/nutrientReqFediafReproduction";
import {
  getNutrientReqNrcAdultMax,
  getNutrientReqNrcAdultMin,
} from "../constants/ingredients/nrc/nutrientReqNrcAdult";
import {
  getNutrientReqNrcPuppy414Max,
  getNutrientReqNrcPuppy414Min,
} from "../constants/ingredients/nrc/nutrientReqNrcPuppy414";
import {
  getNutrientReqNrcPuppyAfter14Max,
  getNutrientReqNrcPuppyAfter14Min,
} from "../constants/ingredients/nrc/nutrientReqNrcPuppyAfter14";
import {
  getNutrientReqNrcReproductionMax,
  getNutrientReqNrcReproductionMin,
} from "../constants/ingredients/nrc/nutrientReqNrcReproduction";
import { breedSizeType, nutrientGuideline } from "../types/dogTypes";
import {
  NutrientsStandard,
  NutrientStandardCategory,
} from "../types/standardTypes";
import { kgOrLb } from "../types/userTypes";
import adjustVitaminEReq from "./nutrientReqs/adjustVitaminEReq";

const getNutrientReqs = (
  nutrientGuideline: nutrientGuideline,
  nutrientStandardCategory: NutrientStandardCategory,
  protein: number,
  calorieRequriement: number,
  weight: number,
  expectedWeight: number | undefined,
  breedSize: breedSizeType,
  weightSetting: kgOrLb,
  birthDate: Date,
  linoleicAcid: number,
  alphaLinoleicAcid: number,
  arachidonicAcid: number,
  epa: number,
  dha: number,
  activityLevel: number
): [NutrientsStandard, NutrientsStandard] | null => {
  let toReturn: [NutrientsStandard, NutrientsStandard];
  switch (nutrientGuideline) {
    case "nrc":
      switch (nutrientStandardCategory) {
        case "adult":
          toReturn = [
            getNutrientReqNrcAdultMin(
              protein,
              calorieRequriement,
              activityLevel
            ),
            getNutrientReqNrcAdultMax(calorieRequriement, activityLevel),
          ];
          break;
        case "puppy414":
          if (expectedWeight === undefined) {
            return null;
          }
          toReturn = [
            getNutrientReqNrcPuppy414Min(
              protein,
              calorieRequriement,
              weight,
              expectedWeight
            ),
            getNutrientReqNrcPuppy414Max(calorieRequriement),
          ];
          break;
        case "puppyAfter14":
          toReturn = [
            getNutrientReqNrcPuppyAfter14Min(protein, calorieRequriement),
            getNutrientReqNrcPuppyAfter14Max(calorieRequriement),
          ];
          break;
        case "reproduction":
          toReturn = [
            getNutrientReqNrcReproductionMin(protein, calorieRequriement),
            getNutrientReqNrcReproductionMax(calorieRequriement),
          ];
          break;
      }
      break;
    case "fediaf":
      switch (nutrientStandardCategory) {
        case "adult":
          toReturn = [
            getNutrientReqFediafAdultMin(
              protein,
              calorieRequriement,
              activityLevel
            ),
            getNutrientReqFediafAdultMax(calorieRequriement, activityLevel),
          ];
          break;
        case "puppy414":
          toReturn = [
            getNutrientReqFediafEarlyGrowthMin(calorieRequriement, protein),
            getNutrientReqFediafEarlyGrowthMax(calorieRequriement),
          ];
          break;
        case "puppyAfter14":
          if (expectedWeight === undefined) {
            return null;
          }
          toReturn = [
            getNutrientReqFediafLateGrowthMin(
              calorieRequriement,
              expectedWeight,
              weightSetting,
              birthDate,
              protein
            ),
            getNutrientReqFediafLateGrowthMax(
              calorieRequriement,
              expectedWeight,
              weightSetting,
              birthDate
            ),
          ];
          break;
        case "reproduction":
          toReturn = [
            getNutrientReqFediafReproductionMin(protein, calorieRequriement),
            getNutrientReqFediafReproductionMax(calorieRequriement),
          ];
          break;
      }
      break;
    case "aafco":
      switch (nutrientStandardCategory) {
        case "adult":
          toReturn = [
            getNutrientReqAafcoAdultMin(calorieRequriement, activityLevel),
            getNutrientReqAafcoAdultMax(calorieRequriement, activityLevel),
          ];
          break;
        case "puppy414":
          toReturn = [
            getNutrientReqAafcoGrowthAndReproductionMin(calorieRequriement),
            getNutrientReqAafcoGrowthAndReproductionMax(
              calorieRequriement,
              true,
              breedSize
            ),
          ];
          break;
        case "puppyAfter14":
          toReturn = [
            getNutrientReqAafcoGrowthAndReproductionMin(calorieRequriement),
            getNutrientReqAafcoGrowthAndReproductionMax(
              calorieRequriement,
              true,
              breedSize
            ),
          ];
          break;
        case "reproduction":
          toReturn = [
            getNutrientReqAafcoGrowthAndReproductionMin(calorieRequriement),
            getNutrientReqAafcoGrowthAndReproductionMax(
              calorieRequriement,
              false,
              breedSize
            ),
          ];
          break;
      }
      break;
  }

  const adjustedNutrients: [NutrientsStandard, NutrientsStandard] = [
    adjustVitaminEReq(
      toReturn[0],
      linoleicAcid,
      alphaLinoleicAcid,
      arachidonicAcid,
      epa,
      dha
    ),
    toReturn[1],
  ];

  return adjustedNutrients;
};

export default getNutrientReqs;

// Vitamin IU conversions
// Vitamin A: 1 IU = 0.3 mcg
// Vitamin D: 1 IU = 0.025 mcg
// Vitamin E: 1 IU = 0.67 mg

// {
//   // Macronutrients
//   protein: 3333,
//   fat: 3333,
//   carbohydrate: 3333,
//   water: 3333,
//   ash: 3333,

//   // Amino acids
//   tryptophan: 3333,
//   threonine: 3333,
//   isoleucine: 3333,
//   leucine: 3333,
//   lysine: 3333,
//   methionine: 3333,
//   methionineAndCystine: 3333,
//   phenylalanine: 3333,
//   phenylalanineAndTyrosine: 3333,
//   valine: 3333,
//   arginine: 3333,
//   histidine: 3333,

//   // Fatty acids
//   linoleicAcid: 3333,
//   alphaLinoleicAcid: 3333,
//   arachidonicAcid: 3333,
//   linoleicToAlphaLinoleicAcids: 3333,
//   epaAndDha: 3333,

//   // Carbohydrates
//   fiber: 3333,

//   // Minerals
//   calcium: 3333,
//   iron: 3333,
//   magnesium: 3333,
//   phosphorus: 3333,
//   calciumToPhosphorus: 3333,
//   potassium: 3333,
//   chloride: 3333,
//   sodium: 3333,
//   zinc: 3333,
//   copper: 3333,
//   zincToCopper: 3333,
//   manganese: 3333,
//   selenium: 3333,
//   iodine: 3333,

//   // Vitamins
//   thiaminB1: 3333,
//   riboflavinB2: 3333,
//   niacinB3: 3333,
//   pantothenicAcidB5: 3333,
//   vitaminB6: 3333,
//   folicAcidB9: 3333,
//   choline: 3333,
//   vitaminB12: 3333,
//   vitaminA: 3333,
//   vitaminE: 3333,
//   cholecalciferolD3: 3333,
//   phylloquinoneK: 3333,
// };
