import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult } from "react-query";
import { BACKEND_URL } from "../../constants/constants";

const useCreateStripePortal = (): UseMutationResult<void, AxiosError, void> => {
  return useMutation(async () => {
    const response = await axios.post<{ url: string }>(
      `${BACKEND_URL}/api/billing/portal`
    );

    window.location.href = response.data.url;
  });
};

export default useCreateStripePortal;
