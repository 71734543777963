import { nutrientGuideline } from "../types/dogTypes";
import { NutrientStandardCategory } from "../types/standardTypes";

const getNutrientReqsName = (
  nutrientGuideline: nutrientGuideline,
  nutrientStandardCategory: NutrientStandardCategory
): string => {
  switch (nutrientGuideline) {
    case "nrc":
      switch (nutrientStandardCategory) {
        case "adult":
          return "NRC Adult";
          break;
        case "puppy414":
          return "NRC Growing puppy 4-14 weeks";
          break;
        case "puppyAfter14":
          return "NRC Growing puppy after 14 weeks";
          break;
        case "reproduction":
          return "NRC Late gestation and peak lactation";
          break;
      }
      break;
    case "fediaf":
      switch (nutrientStandardCategory) {
        case "adult":
          return "FEDIAF Adult";
          break;
        case "puppy414":
          return "FEDIAF Early growth (<14 weeks) and reproduction";
          break;
        case "puppyAfter14":
          return "FEDIAF Late growth (>14 weeks)";
          break;
        case "reproduction":
          return "FEDIAF Early growth (<14 weeks) and reproduction";
          break;
      }
      break;
    case "aafco":
      switch (nutrientStandardCategory) {
        case "adult":
          return "AAFCO Adult maintenance";
          break;
        case "puppy414":
          return "AAFCO Growth and reproduction";
          break;
        case "puppyAfter14":
          return "AAFCO Growth and reproduction";
          break;
        case "reproduction":
          return "AAFCO Growth and reproduction";
          break;
      }
      break;
  }
};

export default getNutrientReqsName;
