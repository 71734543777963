import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult } from "react-query";
import { BACKEND_URL } from "../../constants/constants";

const useSendForgotPassword = (): UseMutationResult<
  void,
  AxiosError,
  string
> => {
  return useMutation(async (email) => {
    await axios.get(`${BACKEND_URL}/api/auth/password/${email}`);
  });
};

export default useSendForgotPassword;
