import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  asValue?: "legend" | "span" | "label";
  className?: string;
}

const FormLabel = ({ children, asValue, className }: Props): JSX.Element => {
  return (
    <StyledText className={className} as={asValue}>
      {children}
    </StyledText>
  );
};

const StyledText = styled.p`
  font-size: ${(props) => props.theme.buttonTextSize};
  line-height: ${(props) => props.theme.textLineHeight};
  font-family: "DM Sans Medium", sans-serif;
  font-weight: 400;

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.buttonTextSizeMobile};
  }
`;

export default FormLabel;
