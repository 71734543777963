import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import PlusButton from "../../../shared/PlusButton";
import RecipeIngredientLogic from "../../../shared/RecipeIngredientLogic";
import IntroductionText from "../../../shared/text/IntroductionText";
import urlSlug from "url-slug";
import useCreateIngredient from "../../../../hooks/api/useCreateIngredient";
import getEmptyIngredient from "../../../../services/getEmptyIngredient";
import useGetCustomIngredients from "../../../../hooks/api/useGetCustomIngredients";
import WrapLink from "../../../shared/WrapLink";
import Heading4 from "../../../shared/text/headings/Heading4";
import ButtonText from "../../../shared/text/ButtonText";
import { ReactComponent as RightArrowSvg } from "../../../../assets/icons/arrow-right.svg";
import useGetAuthUser from "../../../../hooks/api/useGetAuthUser";
import getDefaultUnitShort from "../../../../services/getDefaultUnitShort";

const Ingredients = (): JSX.Element | null => {
  const getCustomIngredientsQuery = useGetCustomIngredients();
  const createIngredientMutation = useCreateIngredient();
  const authUserQuery = useGetAuthUser();

  const history = useHistory();

  if (!getCustomIngredientsQuery.data) {
    return null;
  }

  const createNewIngredient = (): void => {
    createIngredientMutation.mutate(getEmptyIngredient("Untitled ingredient"), {
      onSuccess: (data) => {
        if (data) {
          history.push(`/ingredients/${data.id}/${urlSlug(data.name)}`);
        }
      },
    });
  };

  return (
    <>
      <RecipeIngredientLogic />
      <IntroButtonContainer>
        <StyledIntroductionText>
          This is a list of your saved ingredients. You can create new
          ingredients by adding a new one.
        </StyledIntroductionText>
        <StyledPlusButton
          clickHandler={createNewIngredient}
          text={"Add new ingredient"}
        />
      </IntroButtonContainer>
      <IngredientList>
        {getCustomIngredientsQuery.data
          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
          .map((ingredient) => (
            <IngredientContainer key={ingredient.id}>
              <IngredientLink
                to={`/ingredients/${ingredient.id}/${urlSlug(ingredient.name)}`}
              >
                <TextContainer>
                  <StyledHeading4 asValue={"h2"}>
                    {ingredient.name},{" "}
                    {getDefaultUnitShort(
                      ingredient,
                      authUserQuery.data !== undefined
                        ? authUserQuery.data.kgOrLb
                        : null
                    )}
                  </StyledHeading4>
                </TextContainer>
                <ArrowPart>
                  <StyledButtonText>Show ingredient</StyledButtonText>
                  <StyledRightArrowSvg />
                </ArrowPart>
              </IngredientLink>
            </IngredientContainer>
          ))}
      </IngredientList>
    </>
  );
};

const IntroButtonContainer = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing3};
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: ${(props) => props.theme.spacing2};
  }
`;

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.black4};
  max-width: ${(props) => props.theme.halfWidth};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: ${(props) => props.theme.spacing2};
  }
`;

const StyledPlusButton = styled(PlusButton)`
  margin-left: ${(props) => props.theme.spacing3};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-left: 0;
  }
`;

const IngredientList = styled.ul`
  list-style: none;
  padding: 0;
`;

const IngredientContainer = styled.li`
  background-color: ${(props) => props.theme.purple1};
  border-radius: 2rem; // 32px
  &:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing2};
  }

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    &:not(:last-child) {
      margin-bottom: ${(props) => props.theme.spacing1};
    }
  }
`;

const IngredientLink = styled(WrapLink)`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing2} ${(props) => props.theme.spacing3};
  text-decoration: none;
  border-radius: 2rem; // 32px

  &:focus {
    outline-color: ${(props) => props.theme.purple1};
  }
  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    flex-direction: column;
    padding-top: ${(props) => props.theme.spacing2};
    padding-bottom: ${(props) => props.theme.spacing2};
    padding-left: ${(props) => props.theme.spacing2};
    padding-right: ${(props) => props.theme.spacing2};
  }
`;

const TextContainer = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: ${(props) => props.theme.spacing1};
  }
`;

const StyledHeading4 = styled(Heading4)`
  color: ${(props) => props.theme.purple4};
  word-break: break-word;
`;

const ArrowPart = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.theme.spacing2};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    align-self: flex-end;
  }
`;

const StyledButtonText = styled(ButtonText)`
  color: ${(props) => props.theme.purple4};
  white-space: nowrap;
`;

const StyledRightArrowSvg = styled(RightArrowSvg)`
  color: ${(props) => props.theme.purple4};
  width: 1.5rem; // 24px
  height: 1.5rem; // 24px
  margin-left: ${(props) => props.theme.spacing1};
  transition: margin-left ${(props) => props.theme.transitionValue},
    margin-right ${(props) => props.theme.transitionValue};

  ${IngredientLink}:hover & {
    margin-left: 2rem; // 32px
    margin-right: -${(props) => props.theme.spacing1};
  }
`;

export default Ingredients;
