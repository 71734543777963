import axios, { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import { IngredientMetaValues } from "../../types/ingredientTypes";

const useGetCustomIngredients = (): UseQueryResult<
  IngredientMetaValues[],
  AxiosError
> => {
  return useQuery<IngredientMetaValues[], AxiosError>(
    ["ingredients", "custom"],
    async () => {
      const responseIngredients = await axios.get<IngredientMetaValues[]>(
        `${BACKEND_URL}/api/ingredients`
      );

      return responseIngredients.data;
    }
  );
};

export default useGetCustomIngredients;
