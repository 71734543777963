import React from "react";
import styled from "styled-components";
import Text from "./Text";

interface Props {
  children: React.ReactNode;
  className?: string;
  asValue?: "th" | "span";
  role?: string;
}

const TextBold = ({
  children,
  className,
  asValue,
  role,
}: Props): JSX.Element => {
  return (
    <StyledText className={className} asValue={asValue} role={role}>
      {children}
    </StyledText>
  );
};

const StyledText = styled(Text)`
  font-family: "DM Sans Bold", sans-serif;
  font-weight: 400;
`;

export default TextBold;
