import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "./style/theme";
import GlobalStyle from "./style/GlobalStyle";
import { QueryClient, QueryClientProvider } from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./components/screens/public/errorPage/ErrorPage";
import axios from "axios";
import "./assets/fonts/fonts.css";
import { ReactQueryDevtools } from "react-query/devtools";
import ScrollToTop from "./components/shared/ScrollToTop";
import ReactGA from "react-ga4";
import { ENVIRONMENT } from "./constants/constants";

const queryClient = new QueryClient();
axios.defaults.withCredentials = true;

if (ENVIRONMENT === "production") {
  ReactGA.initialize("G-6HCHVCVZ65");
}

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  // <React.StrictMode>
  <ErrorBoundary FallbackComponent={ErrorPage}>
    <BrowserRouter>
      <ScrollToTop />
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <App />
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  </ErrorBoundary>
  // </React.StrictMode>,
);
