import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { BACKEND_URL } from "../../constants/constants";

interface Input {
  password: string;
}

const useDeleteAccount = (): UseMutationResult<void, AxiosError, Input> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      await axios.delete(`${BACKEND_URL}/api/users`, {
        data: data,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["loginCheck"], { exact: true });
        queryClient.setQueryData(["loggedInUser"], undefined);
      },
    }
  );
};

export default useDeleteAccount;
