import React from "react";
import styled from "styled-components";
import { ReactComponent as ChevronLeftIcon } from "../../../../assets/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "../../../../assets/icons/chevron-right.svg";
import { MONTHS } from "../../../../constants/constants";
import MinYearDatePickerContext from "../../../../contexts/minYearDatePickerContext/MinYearDatePickerContext";
import StyledLink from "../../StyledLink";
import FormLabel from "../../text/FormLabel";
import CustomDateHeaderYearSelect from "./CustomDateHeaderYearSelect";

interface Props {
  date: Date;
  changeYear(year: number): void;
  changeMonth(month: number): void;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}

const CustomDatePickerHeader = ({
  date,
  changeYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: Props): JSX.Element => {
  return (
    <MinYearDatePickerContext.Consumer>
      {(minYearDatePickerContextValue) => (
        <Container>
          <TopPart>
            {!prevMonthButtonDisabled ? (
              <IconButton asButton={true} clickHandler={decreaseMonth}>
                <StyledChevronIcon />
              </IconButton>
            ) : (
              <ChevronIconPlaceholder />
            )}
            <CurrentDate asValue={"span"}>
              {MONTHS[date.getMonth()] + " " + date.getFullYear()}
            </CurrentDate>
            {!nextMonthButtonDisabled ? (
              <IconButton asButton={true} clickHandler={increaseMonth}>
                <StyledChevronIcon as={ChevronRightIcon} />
              </IconButton>
            ) : (
              <ChevronIconPlaceholder />
            )}
          </TopPart>
          <CustomDateHeaderYearSelect
            date={date}
            minYearProp={minYearDatePickerContextValue?.minYear}
            changeYear={changeYear}
          />
        </Container>
      )}
    </MinYearDatePickerContext.Consumer>
  );
};

const Container = styled.div`
  margin-bottom: ${(props) => props.theme.spacing3};
`;

const TopPart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing1};
`;

const IconButton = styled(StyledLink)`
  background-color: ${(props) => props.theme.green3};
  width: ${(props) => props.theme.spacing3};
  height: ${(props) => props.theme.spacing3};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.green4};
  }
  &:focus {
    background-color: ${(props) => props.theme.green4};
    outline-color: ${(props) => props.theme.green4};
  }
`;

const StyledChevronIcon = styled(ChevronLeftIcon)`
  height: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.green1};
`;

const ChevronIconPlaceholder = styled.div`
  width: ${(props) => props.theme.spacing3};
  height: ${(props) => props.theme.spacing3};
`;

const CurrentDate = styled(FormLabel)`
  color: ${(props) => props.theme.black4};
`;

export default CustomDatePickerHeader;
