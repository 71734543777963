import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  eventHandler: (e: React.MouseEvent<HTMLElement>) => void;
  mouseDownHandler?: (e: React.MouseEvent<HTMLElement>) => void;
  refValue?: React.MutableRefObject<HTMLButtonElement | null>;
  className?: string;
}

const TagButton = ({
  children,
  eventHandler,
  mouseDownHandler,
  refValue,
  className,
}: Props): JSX.Element => {
  return (
    <StyledButton
      onClick={eventHandler}
      className={className}
      onMouseDown={mouseDownHandler}
      tabIndex={-1}
      ref={refValue}
    >
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  cursor: pointer;
  padding: ${(props) => props.theme.halfSpacing1 + " " + props.theme.spacing1};
  border-radius: 1.375rem; // 22px
  border: none;
  font-size: ${(props) => props.theme.labelTextSize};
  line-height: ${(props) => props.theme.textLineHeight};
  font-family: "DM Sans Bold", sans-serif;
  font-weight: 400;

  &:focus {
    outline-width: 0.125rem; // 2px
    outline-style: solid;
    outline-offset: 0.125rem; // 2px
  }
`;

export default TagButton;
