import React, { useContext, useEffect } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled, { createGlobalStyle } from "styled-components";
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/calendar.svg";
import MinYearDatePickerContext from "../../../../contexts/minYearDatePickerContext/MinYearDatePickerContext";
import { allColors, themeType } from "../../../../types/frontendTypes";
import CustomDatePickerDay from "./CustomDatePickerDay";
import CustomDatePickerHeader from "./CustomDatePickerHeader";

interface Props {
  value: Date | null;
  setValue: React.Dispatch<React.SetStateAction<Date | null>>;
  id: string;
  iconColor: allColors;
  minDate?: Date;
  className?: string;
  refValue?: React.MutableRefObject<DatePicker<
    string,
    boolean | undefined
  > | null>;
  showIcon?: boolean;
  optionalArgs?: Partial<ReactDatePickerProps>;
}

const CustomizedDatePickerMain = ({
  value,
  setValue,
  id,
  iconColor,
  minDate,
  className,
  refValue,
  showIcon,
  optionalArgs,
}: Props): JSX.Element => {
  const minYearDatePickerContextValue = useContext(MinYearDatePickerContext);
  const minYear = minDate?.getFullYear();

  useEffect(() => {
    if (minYear) {
      minYearDatePickerContextValue?.setMinYear(minYear);
    }
  }, [minYear]);

  return (
    <>
      <Container>
        <StyledDatePicker
          className={className}
          selected={value}
          onChange={(date: Date) => {
            setValue(date);
          }}
          placeholderText={"YYYY-MM-DD"}
          id={id}
          dateFormat={"yyyy-MM-dd"}
          maxDate={new Date()}
          minDate={minDate}
          calendarStartDay={1}
          renderCustomHeader={CustomDatePickerHeader}
          calendarClassName={"container-custom"}
          popperClassName={"popper-custom"}
          renderDayContents={CustomDatePickerDay}
          dayClassName={() => "day-custom"}
          popperPlacement={"bottom"}
          popperModifiers={[{ name: "offset", options: { offset: [0, 24] } }]}
          ref={refValue}
          wrapperClassName="react-datepicker-wrapper-custom"
          {...optionalArgs}
        />
        <StyledCalendarIcon
          color={iconColor}
          $showIcon={showIcon}
          role="img"
          aria-label="Calendar icon"
        />
      </Container>
      <BodyStyleGlobal />
    </>
  );
};

const Container = styled.div`
  position: relative;
`;

const StyledDatePicker = styled(DatePicker)`
  display: block;
  width: 100%;
  padding: 1rem 2rem; // 16px 32px
  padding-right: 4rem; // 64px
  box-sizing: border-box;
  border-width: 0.125rem; // 2px
  border-radius: 100rem;
  border-style: solid;
  font-size: ${(props) => props.theme.buttonTextSize};
  font-family: "DM Sans Regular", sans-serif;
  font-weight: 400;
  line-height: ${(props) => props.theme.componentLineHeight};
  outline: none;
  text-overflow: ellipsis;
  transition: background-color ${(props) => props.theme.transitionValue};

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.buttonTextSizeMobile};
  }
`;

const StyledCalendarIcon = styled(CalendarIcon)<{
  color: allColors;
  $showIcon?: boolean;
}>`
  display: ${(props) =>
    props.$showIcon !== undefined && !props.$showIcon && "none"};
  position: absolute;
  top: 1.125rem; // 18px
  right: ${(props) => (props.$showIcon ? "10.75rem" : "2rem")}; // 32px
  width: ${(props) => props.theme.spacing2};
  height: ${(props) => props.theme.spacing2};
  color: ${(props) => props.color};
  pointer-events: none;
`;

const BodyStyleGlobal = createGlobalStyle<{ theme: themeType }>`
  .popper-custom{
    z-index: 3;
    padding: 0 !important;
  }
  .container-custom{
    border: none;
    border-radius: 0;
    padding: ${(props) => props.theme.spacing2};
    border-radius: 2rem; // 32px
    border-style: solid;
    border-width: 0.125rem; // 2px
    border-color: ${(props) => props.theme.black4};
  }
  .day-custom{
    background-color: ${(props) => props.theme.green1};
    border-radius: 0.5rem; // 8px
    width: 2rem; // 32px
    height: 2rem; // 32px
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    &:not(:last-child){
      margin-right: 0.5rem; // 8px
    }
    &:hover{
      background-color: ${(props) => props.theme.green1};
    }
    &:focus-visible {
      outline-width: 0.125rem; // 2px
      outline-style: solid;
      outline-offset: 0.125rem; // 2px
      outline-color: ${(props) => props.theme.green3};
    }
  }

  .react-datepicker__day--disabled{
    span{
      color: ${(props) => props.theme.black2};
    }
  }
  .react-datepicker__day--selected{
    background-color: ${(props) => props.theme.green3};

    span{
      color: ${(props) => props.theme.green1};
    }
  }
  .react-datepicker__triangle{
    display: none;
  }
  .react-datepicker__header{
    border-radius: 0 !important;
    padding: 0;
    background-color: transparent;
    border: none
  }
  
  .react-datepicker__week{
    &:first-child {
      .react-datepicker__day--020,
      .react-datepicker__day--021,
      .react-datepicker__day--022,
      .react-datepicker__day--023,
      .react-datepicker__day--024,
      .react-datepicker__day--025,
      .react-datepicker__day--026,
      .react-datepicker__day--027,
      .react-datepicker__day--028,
      .react-datepicker__day--029,
      .react-datepicker__day--030,
      .react-datepicker__day--031 {
        visibility: hidden;
      }
    }
    &:last-child {
      .react-datepicker__day--001,
      .react-datepicker__day--002,
      .react-datepicker__day--003,
      .react-datepicker__day--004,
      .react-datepicker__day--005,
      .react-datepicker__day--006,
      .react-datepicker__day--007 {
        visibility: hidden;
      }
    }
    :not(:last-child){
      margin-bottom: 0.5rem; // 8px
    }
  }
  .react-datepicker__day-names{
    margin-bottom: ${(props) => props.theme.halfSpacing1};
    display: flex;
    width: 100%;
    justify-content: space-between;

    .react-datepicker__day-name{
      font-size: ${(props) => props.theme.smallTextSize};
      line-height: ${(props) => props.theme.textLineHeight};
      font-family: "DM Sans Regular", sans-serif;
      font-weight: 400;
      width: auto;
      margin: 0;
      flex: 1;
    }
  }
  .react-datepicker__month{
    margin: 0;
  }
  .react-datepicker-wrapper-custom{
    width: 100%;
  }
`;

export default CustomizedDatePickerMain;
