import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../../assets/images/mealment-logotype.svg";
import StyledLink from "../../shared/StyledLink";
import Heading4 from "../../shared/text/headings/Heading4";
import WrapLink from "../../shared/WrapLink";
import { ReactComponent as FacebookIcon } from "../../../assets/icons/facebook.svg";
import Text from "../../shared/text/Text";

interface Props {
  className?: string;
}

const Footer = ({ className }: Props): JSX.Element => {
  return (
    <StyledContainer>
      <Content className={className}>
        <LinkContainer>
          <LinkContainerColumn>
            <StyledHeading4 asValue="h1">Contact</StyledHeading4>
            <StyledUl>
              <StyledLi>
                <Text>
                  <StyledStyledLink href="mailto:hello@mealment.com">
                    hello@mealment.com
                  </StyledStyledLink>
                </Text>
              </StyledLi>
            </StyledUl>
          </LinkContainerColumn>
          <LinkContainerColumn>
            <StyledHeading4 asValue="h1">About Mealment</StyledHeading4>
            <StyledUl>
              <StyledLi>
                <Text>
                  <StyledStyledLink to="/privacy">
                    Privacy policy
                  </StyledStyledLink>
                </Text>
              </StyledLi>
              <StyledLi>
                <Text>
                  <StyledStyledLink to="/disclaimer">
                    Disclaimer
                  </StyledStyledLink>
                </Text>
              </StyledLi>
              <StyledLi>
                <Text>
                  <StyledStyledLink to="/terms-of-use">
                    Terms of use
                  </StyledStyledLink>
                </Text>
              </StyledLi>
              <StyledLi>
                <Text>
                  <StyledStyledLink to="/cookie">
                    Cookie policy
                  </StyledStyledLink>
                </Text>
              </StyledLi>
              <StyledLi>
                <Text>
                  <StyledStyledLink
                    as={"a"}
                    href="#"
                    onClick={() => {
                      // Disable errors when termly is implemented
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      window.displayPreferenceModal();
                      return false;
                    }}
                    id="termly-consent-preferences"
                  >
                    Cookie preferences
                  </StyledStyledLink>
                </Text>
              </StyledLi>
            </StyledUl>
          </LinkContainerColumn>
          <LinkContainerColumn>
            <StyledHeading4 asValue="h1">Social</StyledHeading4>
            <StyledUl>
              <StyledLi>
                <SocialContainer href="https://www.facebook.com/Mealmentapp">
                  <IconContainer>
                    <StyledFacebookIcon />
                  </IconContainer>
                  <SocialText>Facebook</SocialText>
                </SocialContainer>
              </StyledLi>
            </StyledUl>
          </LinkContainerColumn>
        </LinkContainer>
        <StyledWrapLink
          to="/"
          clickHandler={() => {
            if (window.location.pathname === "/") {
              window.scrollTo(0, 0);
            }
          }}
        >
          <StyledLogo aria-label="Mealment logotype with a link to the start page" />
        </StyledWrapLink>
      </Content>
    </StyledContainer>
  );
};

const StyledContainer = styled.footer`
  padding-top: ${(props) => props.theme.spacing6};
  padding-bottom: ${(props) => props.theme.spacing6};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    padding-top: ${(props) => props.theme.spacing5};
    padding-bottom: ${(props) => props.theme.spacing5};
  }
  @media print {
    display: none;
  }
`;

const Content = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: ${(props) =>
    "calc(" + props.theme.spacing1 + " + env(safe-area-inset-left))"};
  padding-right: ${(props) =>
    "calc(" + props.theme.spacing1 + " + env(safe-area-inset-right))"};
  max-width: ${(props) => props.theme.mainWidth};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.spacing5};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: ${(props) => props.theme.spacing4};
  }
  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    flex-direction: column;
    width: fit-content;
  }
`;

const LinkContainerColumn = styled.div`
  width: 100%;

  &:not(:last-child) {
    margin-right: ${(props) => props.theme.spacing2};
  }

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: ${(props) => props.theme.spacing3};
    }
  }
`;

const StyledHeading4 = styled(Heading4)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing1};
`;

const StyledUl = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const StyledLi = styled.li`
  &:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing1};
  }
`;

const SocialContainer = styled(WrapLink)`
  margin-right: ${(props) => props.theme.spacing2};
  display: flex;
  align-items: center;
  text-decoration: none;
  width: fit-content;

  &:focus {
    outline-color: ${(props) => props.theme.black4};
  }
`;

const IconContainer = styled.div`
  background-color: ${(props) => props.theme.purple1};
  width: ${(props) => props.theme.spacing3};
  height: ${(props) => props.theme.spacing3};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => props.theme.spacing1};
  transition: background-color ${(props) => props.theme.transitionValue};

  ${SocialContainer}:hover & {
    background-color: ${(props) => props.theme.purple2};
  }
`;

const SocialText = styled(Text)`
  color: ${(props) => props.theme.black4};
`;

const StyledFacebookIcon = styled(FacebookIcon)`
  width: ${(props) => props.theme.spacing1};
  height: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.black4};
`;

const StyledLogo = styled(Logo)`
  height: 4.6875rem; // 75px
  fill: ${(props) => props.theme.black4};

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    height: 3.125rem; // 50px
  }
`;

const StyledStyledLink = styled(StyledLink)`
  color: ${(props) => props.theme.black4};
  font-family: "DM Sans Regular", sans-serif;
  font-weight: 400;

  &:hover {
    color: ${(props) => props.theme.black3};
  }
  &:focus {
    outline-color: ${(props) => props.theme.black3};
    color: ${(props) => props.theme.black3};
  }
`;

const StyledWrapLink = styled(WrapLink)`
  &:focus {
    outline-color: ${(props) => props.theme.black4};
  }
`;

export default Footer;
