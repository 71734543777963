import React from "react";
import styled from "styled-components";
import {
  NutrientIngredientType,
  TableRowValueNestedTable,
} from "../../../../../types/ingredientTypes";
import roundToDecimal from "../../../../../utils/roundToDecimal";
import Text from "../../../../shared/text/Text";
import TextBold from "../../../../shared/text/TextBold";
import NeedMoreBadge from "./NeedMoreBadge";

interface Props {
  nestedTableRows: TableRowValueNestedTable[];
  refIngredientsCellTable: React.MutableRefObject<HTMLDivElement | null>;
  unitShort: string;
  nutrient?: NutrientIngredientType;
  openNutrientFindIngredientModal?: (nutrient: NutrientIngredientType) => void;
}

const NestedTable = ({
  nestedTableRows,
  refIngredientsCellTable,
  unitShort,
  nutrient,
  openNutrientFindIngredientModal,
}: Props): JSX.Element => {
  return (
    <Container ref={refIngredientsCellTable}>
      <table role="grid">
        <thead role="presentation">
          <tr role="row">
            <TableHeadMarginRight asValue="th" role="columnheader">
              Ingredient
            </TableHeadMarginRight>
            <TableHead asValue="th" role="columnheader">
              Amount
            </TableHead>
          </tr>
        </thead>
        <tbody role="presentation">
          {nestedTableRows.map((row, index) => (
            <tr role="row" key={index}>
              <Name
                asValue="th"
                role="rowheader"
                grey={row.value === null || row.value === 0}
              >
                {row.name}
              </Name>
              <Cell asValue="td" grey={row.value === null || row.value === 0}>
                {row.value === null
                  ? "-"
                  : `${roundToDecimal(row.value, 2)} ${unitShort}`}
              </Cell>
            </tr>
          ))}
        </tbody>
      </table>
      {openNutrientFindIngredientModal !== undefined &&
        nutrient !== undefined && (
          <NeedMoreBadge
            nutrient={nutrient}
            openNutrientFindIngredientModal={openNutrientFindIngredientModal}
          />
        )}
    </Container>
  );
};

const Container = styled.div`
  max-width: ${(props) => props.theme.widthSmallText};
`;

const TableHead = styled(TextBold)`
  text-align: left;
  color: ${(props) => props.theme.black4};
  padding: 0;
  font-size: ${(props) => props.theme.navigationTextSize};
`;

const TableHeadMarginRight = styled(TableHead)`
  padding-right: ${(props) => props.theme.spacing1};
`;

const Cell = styled(Text)<{ grey: boolean }>`
  color: ${(props) => (props.grey ? props.theme.black3 : props.theme.black4)};
  padding: 0;
  text-align: left;
  padding-top: ${(props) => props.theme.spacing1};
  font-size: ${(props) => props.theme.navigationTextSize};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    padding-top: ${(props) => props.theme.halfSpacing1};
  }
`;

const Name = styled(Cell)`
  padding-right: ${(props) => props.theme.spacing1};
  word-break: break-word;
`;

export default NestedTable;
