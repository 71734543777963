import { IngredientDetailed } from "../types/ingredientTypes";
import capitalizeFirstLetter from "../utils/capitalizeFirstLetter";
import lowercaseFirstLetter from "../utils/lowercaseFirstLetter";

const generateRecipeDesc = (ingredients: IngredientDetailed[]): string => {
  if (ingredients.length === 0) {
    return "";
  }

  if (ingredients.length > 5) {
    ingredients = ingredients.slice(0, 5);
  }

  ingredients.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

  let changedText = capitalizeFirstLetter(ingredients[0].name);
  if (ingredients.length === 1) {
    return changedText;
  }

  if (ingredients.length === 2) {
    changedText += " and " + lowercaseFirstLetter(ingredients[1].name);
    return changedText;
  }

  if (ingredients.length === 3) {
    changedText += ", " + lowercaseFirstLetter(ingredients[1].name);
    changedText += " and " + lowercaseFirstLetter(ingredients[2].name);
    return changedText;
  }

  changedText += ", ";

  for (let i = 1; i < ingredients.length - 2; i++) {
    changedText += lowercaseFirstLetter(ingredients[i].name) + ", ";
  }
  changedText +=
    lowercaseFirstLetter(ingredients[ingredients.length - 2].name) +
    " and " +
    lowercaseFirstLetter(ingredients[ingredients.length - 1].name);

  return changedText;
};

export default generateRecipeDesc;
