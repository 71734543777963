import { DogValuesNoIdsOptional } from "../types/dogTypes";
import { kgOrLb } from "../types/userTypes";
import calculateCaloriesNeeded from "./calculateCaloriesNeeded";

const getCalculatedCalorieGoal = (
  dog: DogValuesNoIdsOptional,
  kgOrLb: kgOrLb | null
): number | null => {
  let calculatedCalorieGoal: number | null = null;

  if (
    dog.birthDate &&
    dog.breedSize &&
    dog.weight &&
    dog.activityLevel &&
    dog.spayedOrNeutered !== undefined
  ) {
    calculatedCalorieGoal = calculateCaloriesNeeded(
      dog.birthDate,
      dog.breedSize,
      kgOrLb || "kg",
      dog.weight,
      dog.expectedWeight,
      dog.activityLevel,
      dog.spayedOrNeutered,
      dog.pregnancyStatus === "gestation",
      dog.pregnancyStatus === "lactation",
      dog.matingDate,
      dog.parturitionDate,
      dog.numberOfPuppies
    );
  }

  return calculatedCalorieGoal;
};

export default getCalculatedCalorieGoal;
