import React from "react";
import { ReactComponent as DogIcon } from "../../../assets/icons/dog.svg";
import { ReactComponent as BookmarkIcon } from "../../../assets/icons/bookmark.svg";
import { ReactComponent as KeyIcon } from "../../../assets/icons/key.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import styled from "styled-components";
import WrapLink from "../../shared/WrapLink";
import { selectedAppMenuItem } from "../../../types/frontendTypes";
import NavigationText from "../../shared/text/NavigationText";

interface Props {
  selectedMenuItem: selectedAppMenuItem;
  mobileMenuOpen: boolean;
  setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppMenuItems = ({
  selectedMenuItem,
  mobileMenuOpen,
  setMobileMenuOpen,
}: Props): JSX.Element => {
  const closeMobileMenu = () => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  };

  return (
    <StyledNav open={mobileMenuOpen}>
      <StyledUl>
        <StyledLi>
          <MenuItem to="/dogs" clickHandler={closeMobileMenu}>
            <StyledNavIcon selected={selectedMenuItem === "dogs"} />
            <NavigationText>Dog</NavigationText>
          </MenuItem>
        </StyledLi>
        <StyledLi>
          <MenuItem to="/recipes" clickHandler={closeMobileMenu}>
            <StyledNavIcon
              as={BookmarkIcon}
              selected={selectedMenuItem === "recipes"}
            />
            <NavigationText>Recipes</NavigationText>
          </MenuItem>
        </StyledLi>
        <StyledLi>
          <MenuItem to="/ingredients" clickHandler={closeMobileMenu}>
            <StyledNavIcon
              as={KeyIcon}
              selected={selectedMenuItem === "ingredients"}
            />
            <NavigationText>Ingredients</NavigationText>
          </MenuItem>
        </StyledLi>
        <StyledLi>
          <MenuItem to="/preferences" clickHandler={closeMobileMenu}>
            <StyledPreferencesIcon
              as={SettingsIcon}
              selected={selectedMenuItem === "preferences"}
            />
            <NavigationText>Preferences</NavigationText>
          </MenuItem>
        </StyledLi>
      </StyledUl>
      <MenuItem to="/account" clickHandler={closeMobileMenu}>
        <StyledNavIcon
          as={UserIcon}
          selected={selectedMenuItem === "account"}
        />
        <NavigationText>My account</NavigationText>
      </MenuItem>
    </StyledNav>
  );
};

const StyledUl = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  align-items: stretch;
`;

const StyledLi = styled.li`
  margin-bottom: ${(props) => props.theme.spacing2};

  &:last-child {
    margin-bottom: ${(props) => props.theme.spacing3};
  }
`;

const MenuItem = styled(WrapLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.green4};
  transition: color ${(props) => props.theme.transitionValue};

  &:hover {
    color: ${(props) => props.theme.green3};
  }
  &:focus {
    outline-color: ${(props) => props.theme.green3};
    color: ${(props) => props.theme.green3};
  }
`;

const StyledNavIcon = styled(DogIcon)<{ selected: boolean }>`
  width: ${(props) => props.theme.spacing2};
  height: ${(props) => props.theme.spacing2};
  margin-right: ${(props) => props.theme.spacing1};
  fill: ${(props) => (props.selected ? props.theme.green4 : "transparent")};
  transition: fill ${(props) => props.theme.transitionValue};

  ${MenuItem}:hover & {
    fill: ${(props) => props.theme.green3};
  }
  ${MenuItem}:focus & {
    fill: ${(props) => props.theme.green3};
  }
`;

const StyledPreferencesIcon = styled(StyledNavIcon)`
  circle {
    fill: ${(props) => props.theme.green1};
  }
`;

const StyledNav = styled.nav<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    display: ${(props) => !props.open && "none"};
    justify-content: initial;
  }
`;

export default AppMenuItems;
