import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import theme from "../../style/theme";
import LeftMenu from "../layout/appHeader/LeftMenu";
import Footer from "../layout/footer/Footer";
import Heading1 from "./text/headings/Heading1";

type widths = typeof theme.mainWidth | typeof theme.halfWidth;

interface Props {
  children: React.ReactNode;
  selectedMenuItem:
    | "dogs"
    | "recipes"
    | "ingredients"
    | "preferences"
    | "account";
  width: widths;
  title?: string;
}

const AppContainer = ({
  children,
  title,
  selectedMenuItem,
  width,
}: Props): JSX.Element => {
  const [scrollTo, setScrollTo] = useState(0);

  const contentRef = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();

  useEffect(() => {
    setScrollTo(0);

    if (contentRef.current) {
      contentRef.current.scrollTo({ top: 0 });
    }
  }, [pathname]);

  return (
    <Container>
      <LeftMenu
        selectedMenuItem={selectedMenuItem}
        scrollTo={scrollTo}
        setScrollTo={setScrollTo}
      />
      <RightColumn>
        <BigArea ref={contentRef}>
          <Content maxWidth={width}>
            {title && <StyledHeading>{title}</StyledHeading>}
            {children}
          </Content>
          <StyledFooter />
        </BigArea>
      </RightColumn>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  max-height: 100vh;
  display: flex;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    flex-direction: column;
  }
`;

const RightColumn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    height: initial;
  }
`;

const BigArea = styled.div`
  overflow: auto;
  padding-top: ${(props) => props.theme.spacing3};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    padding-top: 6rem; // 96px = 24px + 72px
  }
  @media print {
    padding-top: 0;
  }
`;

const Content = styled.main<{ maxWidth: widths }>`
  padding-right: ${(props) => props.theme.spacing3};
  padding-left: ${(props) => props.theme.spacing3};
  max-width: ${(props) => props.maxWidth};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    padding-right: ${(props) => props.theme.spacing1};
    padding-left: ${(props) => props.theme.spacing1};
  }
`;

const StyledHeading = styled(Heading1)`
  margin-bottom: ${(props) => props.theme.spacing2};
  color: ${(props) => props.theme.black4};
  word-break: break-word;
`;

const StyledFooter = styled(Footer)`
  padding-right: ${(props) => props.theme.spacing3};
  padding-left: ${(props) => props.theme.spacing3};
  margin-left: initial;
  margin-right: initial;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    padding-right: ${(props) => props.theme.spacing1};
    padding-left: ${(props) => props.theme.spacing1};
  }
`;

export default AppContainer;
