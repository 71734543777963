import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { BACKEND_URL } from "../../constants/constants";

interface Input {
  ingredientId: string;
  amount: number;
  unit: string;
  show: boolean;
}

const useAddRecipeIngredient = (
  dogId: string | undefined | null,
  recipeId: string
): UseMutationResult<void, AxiosError, Input> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      await axios.post(
        `${BACKEND_URL}/api/recipes/${recipeId}/ingredients/${data.ingredientId}`,
        data
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["recipes", "dogs", dogId], {
          exact: true,
        });
        queryClient.invalidateQueries(["recipes", recipeId], {
          exact: true,
        });
        queryClient.invalidateQueries(["recipes"], {
          exact: true,
        });
      },
    }
  );
};

export default useAddRecipeIngredient;
