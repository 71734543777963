import React from "react";
import styled from "styled-components";
import SmallText from "../../../shared/text/SmallText";
import SmallTextBold from "../../../shared/text/SmallTextBold";
import WrapLink from "../../../shared/WrapLink";
import { ReactComponent as rotateCCW } from "../../../../assets/icons/rotate-ccw.svg";

interface Props {
  calculatedCalorieGoal: number | null;
  resetHandler: () => void;
  customValue: boolean;
}

const CalorieGoalBottom = ({
  calculatedCalorieGoal,
  resetHandler,
  customValue,
}: Props): JSX.Element | null => {
  if (calculatedCalorieGoal === null) {
    return null;
  }

  return (
    <Container>
      <Calculated customValue={customValue}>
        Calculated calorie goal:{" "}
        {calculatedCalorieGoal === null ? 0 : calculatedCalorieGoal} kcal
      </Calculated>
      {customValue && (
        <ResetWrapLink asButton={true} clickHandler={resetHandler}>
          <StyledIcon />
          <Reset>Reset to calculated</Reset>
        </ResetWrapLink>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: ${(props) => props.theme.halfSpacing1};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Calculated = styled(SmallText)<{ customValue: boolean }>`
  color: ${(props) => props.theme.black4};

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    margin-bottom: ${(props) => props.customValue && props.theme.halfSpacing1};
  }
`;

const ResetWrapLink = styled(WrapLink)`
  text-decoration: none;
  display: flex;
  align-items: center;

  &:focus {
    outline-color: ${(props) => props.theme.black4};
  }
`;

const Reset = styled(SmallTextBold)`
  color: ${(props) => props.theme.black4};
`;

const StyledIcon = styled(rotateCCW)`
  width: ${(props) => props.theme.spacing1};
  height: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.black4};
  margin-right: ${(props) => props.theme.halfSpacing1};
`;

export default CalorieGoalBottom;
