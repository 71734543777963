import React from "react";
import theme from "../../../../../style/theme";
import CustomizedDatePicker from "../../styledDatePicker/CustomizedDatePicker";

interface Props {
  value: Date | null;
  setValue: React.Dispatch<React.SetStateAction<Date | null>>;
  id: string;
  minDate?: Date;
  className?: string;
}

const SimpleDatePicker = (props: Props): JSX.Element => {
  return <CustomizedDatePicker {...props} iconColor={theme.green4} />;
};

export default SimpleDatePicker;
