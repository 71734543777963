import React from "react";
import styled from "styled-components";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-icon.svg";
import ButtonText from "./text/ButtonText";

interface Props {
  clickHandler: () => void;
  ariaLabelIcon: string;
  text?: string;
  tabIndex?: number;
  className?: string;
}

const InfoModalButton = ({
  clickHandler,
  ariaLabelIcon,
  text,
  tabIndex,
  className,
}: Props): JSX.Element => {
  return (
    <StyledButton
      onClick={clickHandler}
      className={className}
      tabIndex={tabIndex}
    >
      <StyledInfoIcon
        aria-label={ariaLabelIcon}
        $hasText={text !== undefined}
      />
      {text !== undefined && <StyledButtonText>{text}</StyledButtonText>}
    </StyledButton>
  );
};

const StyledInfoIcon = styled(InfoIcon)<{ $hasText: boolean }>`
  width: 1.5rem; // 24px
  height: 1.5rem; // 24px

  margin-right: ${(props) => props.$hasText && props.theme.halfSpacing1};
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  /* width: 1.5rem; // 24px
  height: 1.5rem; // 24px */
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin-left: ${(props) => props.theme.halfSpacing1};
  transition: color ${(props) => props.theme.transitionValue};

  &:focus {
    outline-width: 0.125rem; // 2px
    outline-style: solid;
    outline-offset: 0.125rem; // 2px
  }
`;

const StyledButtonText = styled(ButtonText)`
  white-space: nowrap;
  color: inherit;
`;

export default InfoModalButton;
