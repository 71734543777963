import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { IngredientDetailed } from "../../../../types/ingredientTypes";
import roundToDecimal from "../../../../utils/roundToDecimal";
import Button from "../../../shared/Button";
import LabelForm from "../../../shared/form/LabelForm";
import SimpleInputNumber from "../../../shared/form/simple/SimpleInputNumber";
import Modal from "../../../shared/Modal";
import IntroductionText from "../../../shared/text/IntroductionText";
import Text from "../../../shared/text/Text";
import TextBold from "../../../shared/text/TextBold";
import WrapLink from "../../../shared/WrapLink";
import ButtonText from "../../../shared/text/ButtonText";
import { ReactComponent as PrinterIcon } from "../../../../assets/icons/printer.svg";
import { useReactToPrint } from "react-to-print";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ingredients: IngredientDetailed[];
  recipeName: string;
}

const ScaleModal = ({
  open,
  setOpen,
  ingredients,
  recipeName,
}: Props): JSX.Element => {
  const [numberOfDays, setNumberOfDays] = useState<null | number>(null);
  const [printMode, setPrintMode] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setPrintMode(false),
    documentTitle: recipeName,
  });

  useEffect(() => {
    if (printMode) {
      handlePrint();
    }
  }, [printMode]);

  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!open) {
      setNumberOfDays(null);
    }
  }, [open]);

  const handlePrintShortcut = (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "p") {
      if (open) {
        event.preventDefault();
        setPrintMode(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handlePrintShortcut);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("keydown", handlePrintShortcut);
    };
  }, [open, handlePrintShortcut]);

  return (
    <Modal
      title={printMode ? recipeName : "Scale recipe"}
      open={open}
      setOpen={setOpen}
      closable={true}
      refComponent={componentRef}
    >
      <StyledIntroductionText>
        Are you planning your grocery shopping? Adjust the recipe according to
        the desired number of days to get the right amount of each ingredient.
      </StyledIntroductionText>

      <PinkBar>
        <ButtonContainer
          asButton={true}
          clickHandler={() => setPrintMode(true)}
        >
          <ButtonIcon />
          <StyledButtonText>Print scaled recipe</StyledButtonText>
        </ButtonContainer>
      </PinkBar>

      <StyledLabel name="numberOfDays">Number of days</StyledLabel>
      <StyledSimpleInput
        id="numberOfDays"
        setValue={setNumberOfDays}
        value={numberOfDays}
      />

      <StyledTable role="grid">
        <thead role="presentation">
          <HeadRow role="row">
            <TableHead role="columnheader">
              <StyledTextBold>Name</StyledTextBold>
            </TableHead>
            <TableHead role="columnheader">
              <StyledTextBold>Amount</StyledTextBold>
            </TableHead>
            <TableHead role="columnheader">
              <StyledTextBoldNoMargin>Unit</StyledTextBoldNoMargin>
            </TableHead>
          </HeadRow>
        </thead>
        <tbody role="presentation">
          {ingredients
            .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
            .map((ingredient) => (
              <tr key={ingredient.id} role="row">
                <DataCell>
                  <IngredientName>{ingredient.name}</IngredientName>
                </DataCell>
                <DataCell>
                  <StyledText>
                    {numberOfDays !== null
                      ? roundToDecimal(numberOfDays * ingredient.amount, 2)
                      : roundToDecimal(ingredient.amount, 2)}
                  </StyledText>
                </DataCell>
                <DataCell>
                  <StyledTextNoMargin>{ingredient.unit}</StyledTextNoMargin>
                </DataCell>
              </tr>
            ))}
        </tbody>
      </StyledTable>

      <PurpleButton
        eventHandler={() => {
          setOpen(false);
        }}
      >
        Close
      </PurpleButton>
    </Modal>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.black4};
  margin-top: ${(props) => props.theme.spacing2};

  @media print {
    display: none;
  }
`;

const StyledLabel = styled(LabelForm)`
  margin-top: ${(props) => props.theme.spacing2};
  color: ${(props) => props.theme.black4};
  margin-bottom: 0.25rem; // 4px
`;

const PurpleButton = styled(Button)`
  background-color: ${(props) => props.theme.purple2};
  color: ${(props) => props.theme.purple4};
  width: 100%;

  &:hover {
    background-color: ${(props) => props.theme.purple1};
  }
  &:focus {
    background-color: ${(props) => props.theme.purple1};
    outline-color: ${(props) => props.theme.purple1};
  }
  @media print {
    display: none;
  }
`;

const StyledSimpleInput = styled(SimpleInputNumber)`
  border-color: ${(props) => props.theme.black4};
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const HeadRow = styled.tr`
  border-bottom: 0.125rem solid ${(props) => props.theme.black1};
`;

const TableHead = styled.th`
  text-align: left;
  padding: 0;
  padding-bottom: ${(props) => props.theme.spacing1};
`;

const StyledTextBold = styled(TextBold)`
  color: ${(props) => props.theme.black4};
  margin-right: ${(props) => props.theme.spacing1};
`;

const StyledTextBoldNoMargin = styled(StyledTextBold)`
  margin-right: 0;
`;

const DataCell = styled.th`
  padding: 0;
  text-align: left;
  padding-top: ${(props) => props.theme.spacing1};
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.black4};
  margin-right: ${(props) => props.theme.spacing1};
`;

const StyledTextNoMargin = styled(StyledText)`
  margin-right: 0;
`;

const IngredientName = styled(StyledText)`
  word-break: break-word;
`;

const PinkBar = styled.div`
  background-color: ${(props) => props.theme.purple1};
  border-radius: 2rem; // 32px
  padding: ${(props) => props.theme.spacing2};
  margin-top: ${(props) => props.theme.spacing2};
  display: flex;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    flex-direction: column;
    align-items: center;
  }
  @media print {
    display: none;
  }
`;

const ButtonContainer = styled(WrapLink)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.purple4};
  text-decoration: none;
  margin-right: ${(props) => props.theme.spacing3};

  &:last-child {
    margin-right: 0;
  }
  &:focus {
    outline-color: ${(props) => props.theme.purple4};
  }
  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-right: 0;

    &:not(:last-child) {
      margin-bottom: ${(props) => props.theme.spacing2};
    }
  }
`;

const ButtonIcon = styled(PrinterIcon)`
  width: ${(props) => props.theme.spacing2};
  min-width: ${(props) => props.theme.spacing2};
  height: ${(props) => props.theme.spacing2};
  min-height: ${(props) => props.theme.spacing2};
  margin-right: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.purple4};
`;

const StyledButtonText = styled(ButtonText)`
  word-break: break-word;
`;

export default ScaleModal;
