import React, { useRef } from "react";
import styled from "styled-components";
import TagButton from "../../TagButton";

interface Props {
  editing: boolean;
  cancelEdit: () => void;
  saveEdit: () => void;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  notAbsolute?: boolean;
  top?: number;
  className?: string;
}

const InputButtons = ({
  editing,
  cancelEdit,
  saveEdit,
  setEditing,
  notAbsolute,
  top,
  className,
}: Props): JSX.Element => {
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <>
      <CancelButtonInput
        eventHandler={() => {
          cancelEdit();
        }}
        className={className}
        notAbsolute={notAbsolute}
        show={editing}
        top={top}
        mouseDownHandler={(e) => e.preventDefault()}
        refValue={cancelButtonRef}
      >
        Cancel
      </CancelButtonInput>
      <ButtonStyle1
        eventHandler={saveEdit}
        className={className}
        notAbsolute={notAbsolute}
        show={editing}
        top={top}
      >
        Save
      </ButtonStyle1>

      <ButtonStyle1
        eventHandler={() => setEditing(true)}
        className={className}
        notAbsolute={notAbsolute}
        show={!editing}
        top={top}
      >
        Edit
      </ButtonStyle1>
    </>
  );
};

const StyledTagButton = styled(TagButton)<{
  show: boolean;
  notAbsolute?: boolean;
  top?: number;
}>`
  outline: none;
  position: ${(props) => !props.notAbsolute && "absolute"};
  top: ${(props) =>
    props.top === undefined ? "0.75rem" : `${props.top}px`}; // 12px
  z-index: 1;

  display: ${(props) => !props.show && "none"};

  @media print {
    display: none;
  }
`;

const ButtonStyle1 = styled(StyledTagButton)`
  background-color: ${(props) => props.theme.green3};
  color: ${(props) => props.theme.green1};

  right: 0;
  margin-right: 0.75rem; // 12px
`;

const CancelButtonInput = styled(StyledTagButton)<{ notAbsolute?: boolean }>`
  background-color: ${(props) => props.theme.green1};
  color: ${(props) => props.theme.green4};

  right: ${(props) => (props.notAbsolute ? 0 : "5.25rem")}; // 84px
  margin-right: ${(props) => props.notAbsolute && "0.5rem"}; // 8px
`;

export default InputButtons;
