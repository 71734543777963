import React from "react";
import styled from "styled-components";
import InnerHTML from "dangerously-set-html-content";

const TermsOfUse = (): JSX.Element => {
  return (
    <Container>
      <InnerHTML
        html={`<div class="section__content">  <div name="termly-embed" data-id="1f8e36e1-7a74-4e2c-a4e0-f31a07b2de32" data-type="iframe"></div>  <script src="https://app.termly.io/embed-policy.min.js"></script> </div>`}
      />
    </Container>
  );
};

const Container = styled.div`
  max-width: ${(props) => props.theme.halfWidth};
  margin-top: ${(props) => props.theme.spacing2};
  margin-left: auto;
  margin-right: auto;
`;

export default TermsOfUse;
