import adjustNutrientReqActivityLevel from "../../../services/nutrientReqs/adjustNutrientReqActivityLevel";
import adjustNutrientReqCalories from "../../../services/nutrientReqs/adjustNutrientReqCalories";
import { NutrientsStandard } from "../../../types/standardTypes";

const NUTRIENT_REQ_NRC_ADULT_MIN: NutrientsStandard = {
  // Macronutrients
  protein: 25,
  fat: 13.8,
  carbohydrate: null,
  water: null,
  ash: null,

  // Amino acids
  tryptophan: 0.35,
  threonine: 1.08,
  isoleucine: 0.95,
  leucine: 1.7,
  lysine: 0.88,
  methionine: 0.83,
  methionineAndCystine: 1.63,
  phenylalanine: 1.13,
  phenylalanineAndTyrosine: 1.85,
  valine: 1.23,
  arginine: 0.88,
  histidine: 0.48,

  // Fatty acids
  linoleicAcid: 2.8,
  alphaLinoleicAcid: 0.11,
  arachidonicAcid: null,
  linoleicToAlphaLinoleicAcids: 2.6,
  epaAndDha: 0.11,

  // Carbohydrates
  fiber: null,

  // Minerals
  calcium: 1000,
  iron: 7.5,
  magnesium: 150,
  phosphorus: 750,
  calciumToPhosphorus: 1.0,
  potassium: 1000,
  chloride: 300,
  sodium: 200,
  zinc: 15,
  copper: 1.5,
  zincToCopper: 9,
  manganese: 1.2,
  selenium: 87.5,
  iodine: 220,

  // Vitamins
  thiaminB1: 0.56,
  riboflavinB2: 1.3,
  niacinB3: 4.25,
  pantothenicAcidB5: 3.75,
  vitaminB6: 0.375,
  folicAcidB9: 67.5,
  choline: 425,
  vitaminB12: 8.75,
  vitaminA: 379,
  vitaminE: 7.5,
  cholecalciferolD3: 3.4,
  phylloquinoneK: 410,
};

const NUTRIENT_REQ_NRC_ADULT_MAX: NutrientsStandard = {
  // Macronutrients
  protein: null,
  fat: 82.5,
  carbohydrate: null,
  water: null,
  ash: null,

  // Amino acids
  tryptophan: null,
  threonine: null,
  isoleucine: null,
  leucine: null,
  lysine: null,
  methionine: null,
  methionineAndCystine: null,
  phenylalanine: null,
  phenylalanineAndTyrosine: null,
  valine: null,
  arginine: null,
  histidine: null,

  // Fatty acids
  linoleicAcid: 16.3,
  alphaLinoleicAcid: null,
  arachidonicAcid: null,
  linoleicToAlphaLinoleicAcids: 26,
  epaAndDha: 2.8,

  // Carbohydrates
  fiber: null,

  // Minerals
  calcium: null,
  iron: null,
  magnesium: null,
  phosphorus: null,
  calciumToPhosphorus: 2,
  potassium: null,
  chloride: null,
  sodium: null,
  zinc: null,
  copper: null,
  zincToCopper: null,
  manganese: null,
  selenium: null,
  iodine: null,

  // Vitamins
  thiaminB1: null,
  riboflavinB2: null,
  niacinB3: null,
  pantothenicAcidB5: null,
  vitaminB6: null,
  folicAcidB9: null,
  choline: null,
  vitaminB12: null,
  vitaminA: 16000,
  vitaminE: null,
  cholecalciferolD3: 20,
  phylloquinoneK: null,
};

export const getNutrientReqNrcAdultMin = (
  protein: number,
  calorieRequriement: number,
  activityLevel: number
): NutrientsStandard => {
  const activityLevelRatio = activityLevel < 130 ? 130 / activityLevel : 1;

  let specificReq = adjustNutrientReqActivityLevel(
    NUTRIENT_REQ_NRC_ADULT_MIN,
    activityLevelRatio
  );

  const proteinDiff = protein - 100;
  if (proteinDiff > 0) {
    specificReq = {
      ...specificReq,
      arginine: (specificReq.arginine as number) + proteinDiff * 0.01,
    };
  }

  const adjustedNutrients = adjustNutrientReqCalories(
    specificReq,
    calorieRequriement
  );

  return adjustedNutrients;
};

export const getNutrientReqNrcAdultMax = (
  calorieRequriement: number,
  activityLevel: number
): NutrientsStandard => {
  const activityLevelRatio = activityLevel < 130 ? 130 / activityLevel : 1;

  const specificReq = adjustNutrientReqActivityLevel(
    NUTRIENT_REQ_NRC_ADULT_MAX,
    activityLevelRatio
  );

  const adjustedNutrients = adjustNutrientReqCalories(
    specificReq,
    calorieRequriement
  );
  return adjustedNutrients;
};
