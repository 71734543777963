import React from "react";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "../../../assets/icons/eye-off.svg";
import styled from "styled-components";
import WrapLink from "../WrapLink";

interface Props {
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

const HideRevealPassword = ({
  showPassword,
  setShowPassword,
}: Props): JSX.Element => {
  return (
    <StyledWrapLink
      asButton={true}
      clickHandler={() => setShowPassword((prevState) => !prevState)}
      asTypeButton={true}
    >
      {showPassword ? (
        <StyledIcon role="img" aria-label="Hide password" />
      ) : (
        <StyledIcon as={EyeIcon} role="img" aria-label="Show password" />
      )}
    </StyledWrapLink>
  );
};

const StyledIcon = styled(EyeOffIcon)`
  color: ${(props) => props.theme.green4};
  width: 1.5rem; // 24px
  height: 1.5rem; // 24px
`;

const StyledWrapLink = styled(WrapLink)`
  width: 1.5rem; // 24px
  height: 1.5rem; // 24px
  position: absolute;
  top: 1.3125rem; // 21px
  right: 2rem; // 32px

  &:focus {
    outline-color: ${(props) => props.theme.green4};
  }
`;

export default HideRevealPassword;
