import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useChangeIngredientProperty from "../../../../hooks/api/useChangeIngredientProperty";
import useGetCustomIngredients from "../../../../hooks/api/useGetCustomIngredients";
import {
  ChangeableIngredientMetaValuesType,
  IngredientFull,
} from "../../../../types/ingredientTypes";
import EditableSelect from "../../../shared/form/editable/EditableSelect";
import EditableText from "../../../shared/form/editable/EditableText";
import LabelForm from "../../../shared/form/LabelForm";
import Heading2 from "../../../shared/text/headings/Heading2";

interface Props {
  ingredient: IngredientFull;
}

const GeneralInfoPart = ({ ingredient }: Props): JSX.Element | null => {
  const [showOtherUnitInput, setShowOtherUnitInput] = useState(false);
  const [freshOtherShow, setFreshOtherShow] = useState(true);

  useEffect(() => {
    if (showOtherUnitInput) {
      setFreshOtherShow(true);
    }
  }, [showOtherUnitInput]);

  const changeIngredientPropertyMutation = useChangeIngredientProperty(
    ingredient.id
  );

  const getCustomIngredientsQuery = useGetCustomIngredients();

  if (!getCustomIngredientsQuery.data) {
    return null;
  }

  const valuesLabelsDefaultUnits = new Map<string, string>();

  // Standard units
  valuesLabelsDefaultUnits.set("gram", "Weight");
  valuesLabelsDefaultUnits.set("Capsule", "Capsule");
  valuesLabelsDefaultUnits.set("Tablet", "Tablet");
  valuesLabelsDefaultUnits.set("Softgel", "Softgel");
  valuesLabelsDefaultUnits.set("Tablespoon", "Tablespoon");
  valuesLabelsDefaultUnits.set("Teaspoon", "Teaspoon");
  valuesLabelsDefaultUnits.set("Scoop", "Scoop");
  valuesLabelsDefaultUnits.set("Drop", "Drop");

  getCustomIngredientsQuery.data.forEach((ingredient) => {
    if (!valuesLabelsDefaultUnits.has(ingredient.defaultUnit)) {
      valuesLabelsDefaultUnits.set(
        ingredient.defaultUnit,
        ingredient.defaultUnit
      );
    }
  });
  valuesLabelsDefaultUnits.set("other", "Create new unit");

  const defaultUnitsAndShort = new Map<string, string | null>();
  defaultUnitsAndShort.set("Tablespoon", "tbsp");
  defaultUnitsAndShort.set("Teaspoon", "tsp");
  getCustomIngredientsQuery.data.forEach((ingredient) => {
    if (!defaultUnitsAndShort.has(ingredient.defaultUnit)) {
      defaultUnitsAndShort.set(
        ingredient.defaultUnit,
        ingredient.defaultUnitShort
      );
    }
  });

  const saveValue = (
    value: string | null,
    property: ChangeableIngredientMetaValuesType,
    callback?: () => void
  ) => {
    changeIngredientPropertyMutation.mutate(
      {
        value: value,
        property: property,
      },
      {
        onSuccess: callback,
      }
    );
  };

  return (
    <>
      <StyledHeading2>General information</StyledHeading2>
      <StyledLabel name="source">Source</StyledLabel>
      <EditableText
        serverValue={ingredient.source === null ? undefined : ingredient.source}
        id={"source"}
        placeholder={"Specify a source link"}
        onSave={(source) => {
          if (source === "") {
            saveValue(null, "source");
          } else {
            saveValue(source, "source");
          }
        }}
        allowEmpty={true}
      />

      <StyledLabel name="unit">Unit</StyledLabel>
      <EditableSelect
        placeholder="Choose a unit"
        id="unit"
        serverValue={showOtherUnitInput ? "other" : ingredient.defaultUnit}
        valuesLabels={valuesLabelsDefaultUnits}
        onSave={(defaultUnit) => {
          if (defaultUnit === "other") {
            setShowOtherUnitInput(true);
            return;
          } else {
            if (showOtherUnitInput) {
              setShowOtherUnitInput(false);
            }
            saveValue(defaultUnit, "defaultUnit");
            const unitShort = defaultUnitsAndShort.get(defaultUnit);

            saveValue(
              unitShort === undefined ? null : unitShort,
              "defaultUnitShort"
            );
          }
        }}
      />

      {showOtherUnitInput && (
        <>
          <StyledLabel name="newUnitName">New unit name</StyledLabel>
          <EditableText
            id="newUnitName"
            placeholder="Specify a new unit name"
            onSave={(newUnitName) => {
              if (freshOtherShow) {
                saveValue(newUnitName, "defaultUnit", () => {
                  saveValue(null, "defaultUnitShort", () => {
                    setFreshOtherShow(false);
                  });
                });
              } else {
                saveValue(newUnitName, "defaultUnit");
              }
            }}
            serverValue={freshOtherShow ? undefined : ingredient.defaultUnit}
          />

          {!freshOtherShow && (
            <>
              <StyledLabel name="newUnitShort">
                New unit abbreviation (optional)
              </StyledLabel>
              <EditableText
                id="newUnitShort"
                placeholder="Specify a new unit short"
                onSave={(newUnitShort) => {
                  if (newUnitShort === "") {
                    saveValue(null, "defaultUnitShort");
                  } else {
                    saveValue(newUnitShort, "defaultUnitShort");
                  }
                }}
                serverValue={
                  freshOtherShow || ingredient.defaultUnitShort === null
                    ? undefined
                    : ingredient.defaultUnitShort
                }
                allowEmpty={true}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

const StyledHeading2 = styled(Heading2)`
  margin-top: ${(props) => props.theme.spacing3};
  color: ${(props) => props.theme.black4};
`;

const StyledLabel = styled(LabelForm)`
  color: ${(props) => props.theme.black4};
  margin-top: ${(props) => props.theme.spacing2};
  margin-bottom: 0.25rem; // 4px

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-top: ${(props) => props.theme.spacing1};
  }
`;

export default GeneralInfoPart;
