import React from "react";
import styled from "styled-components";
import HeadingBase from "./HeadingBase";

interface Props {
  children: React.ReactNode;
  asValue?: "h1" | "h2" | "h3" | "h4" | "h5" | "legend";
  className?: string;
  tabIndex?: number;
  refValue?: React.MutableRefObject<HTMLHeadingElement | null> | null;
  id?: string;
}

const Heading2 = ({
  children,
  asValue,
  className,
  tabIndex,
  refValue,
  id,
}: Props): JSX.Element => {
  return (
    <StyledHeading2
      asValue={asValue || "h2"}
      className={className}
      tabIndex={tabIndex}
      refValue={refValue}
      id={id}
    >
      {children}
    </StyledHeading2>
  );
};

const StyledHeading2 = styled(HeadingBase)`
  font-size: ${(props) => props.theme.heading2Size};

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.heading2SizeMobile};
  }
`;

export default Heading2;
