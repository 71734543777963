import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import AuthEmailContext from "../../../../contexts/authEmailContext/AuthEmailContext";
import useLogin from "../../../../hooks/api/useLogin";
import AuthContainer from "../../../shared/auth/AuthContainer";
import SignupPartial from "../../../shared/auth/SignupPartial";

const Signup = (): JSX.Element => {
  const history = useHistory();
  const authEmailContextValue = useContext(AuthEmailContext);
  const loginMutation = useLogin();

  return (
    <AuthContainer title="Sign up">
      <SignupPartial
        loginHandler={(email: string) => {
          authEmailContextValue?.setAuthEmail(email);
          history.push("/login");
        }}
        verificationHandler={(data: { email: string; password: string }) => {
          loginMutation.mutate(data);
        }}
        savedEmail={authEmailContextValue?.authEmail || ""}
      />
    </AuthContainer>
  );
};

export default Signup;
