import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import { NutrientIngredientType } from "../../types/ingredientTypes";

interface Input {
  amount: number | null;
  nutrient: NutrientIngredientType;
}

const useChangeIngredientNutrient = (
  ingredientId: string
): UseMutationResult<void, AxiosError, Input> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const dataSend = { amount: data.amount, nutrient: data.nutrient };
      await axios.patch(
        `${BACKEND_URL}/api/ingredients/${ingredientId}/nutrient`,
        dataSend
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["ingredients", ingredientId], {
          exact: true,
        });
        queryClient.invalidateQueries(["recipes"]);
      },
    }
  );
};

export default useChangeIngredientNutrient;
