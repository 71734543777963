import useGetDateNowWrapper from "./useGetDateNowWrapper";

const useIsValidSubscription = (
  subscription: string | null | undefined,
  subscriptionTimestamp: Date | null | undefined
): boolean => {
  const dateNow = useGetDateNowWrapper();

  return (
    subscription !== null &&
    subscription !== undefined &&
    subscriptionTimestamp !== null &&
    subscriptionTimestamp !== undefined &&
    subscriptionTimestamp > dateNow
  );
};

export default useIsValidSubscription;
