import React from "react";
import styled from "styled-components";

interface Props {
  className?: string;
  text: string;
}

const SubmitButton = ({ className, text }: Props): JSX.Element => {
  return <StyledButton type="submit" className={className} value={text} />;
};

const StyledButton = styled.input`
  cursor: pointer;
  padding: ${(props) => props.theme.spacing1} 2rem;
  border-radius: 100rem;
  border: none;
  font-size: ${(props) => props.theme.buttonTextSize};
  line-height: ${(props) => props.theme.componentLineHeight};
  font-family: "DM Sans Bold", sans-serif;
  font-weight: 400;
  transition: background-color ${(props) => props.theme.transitionValue};
  appearance: none;

  &:focus {
    outline-width: 0.125rem; // 2px
    outline-style: solid;
    outline-offset: 0.125rem; // 2px
  }
  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.buttonTextSizeMobile};
  }
`;

export default SubmitButton;
