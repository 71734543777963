import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import { kgOrLb } from "../../types/userTypes";

const useChangeKgOrLb = (): UseMutationResult<
  void,
  AxiosError,
  { kgOrLb: kgOrLb }
> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      await axios.patch(`${BACKEND_URL}/api/users/kg-or-lb`, data);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(["loggedInUser"], { exact: true });
      },
      onMutate: async () => {
        // await queryClient.cancelQueries("loggedInUser");
        // const oldUser: User | undefined =
        //   queryClient.getQueryData("loggedInUser");
        // if (oldUser) {
        //   queryClient.setQueryData<User | undefined>("loggedInUser", {
        //     ...oldUser,
        //     kgOrLb: data.kgOrLb,
        //   });
        // }
      },
    }
  );
};

export default useChangeKgOrLb;
