import React from "react";
import calculateNutrientAmountWeight from "../../../../../../services/calculateNutrientAmountWeight";
import {
  DefaultWeightUnits,
  IngredientDetailed,
  NutrientIngredientType,
} from "../../../../../../types/ingredientTypes";
import {
  NutrientsStandard,
  NutrientStandardType,
} from "../../../../../../types/standardTypes";
import NutrientRow from "./NutrientRow";

interface Props {
  nutrientStandard: NutrientStandardType;
  nutrientIngredient1: NutrientIngredientType;
  nutrientIngredient2: NutrientIngredientType;
  ingredients: IngredientDetailed[];
  nutrientReqsMin: NutrientsStandard;
  nutrientReqsMax: NutrientsStandard;
}

const NutrientRowRatio = ({
  nutrientStandard,
  nutrientIngredient1,
  nutrientIngredient2,
  ingredients,
  nutrientReqsMin,
  nutrientReqsMax,
}: Props): JSX.Element => {
  // Total in meal
  const removedHiddenIngredients = ingredients.filter((ingredient) => {
    return ingredient.show;
  });

  const valuesIngredient1: number[] = removedHiddenIngredients.map(
    (ingredient) => {
      const value = ingredient[nutrientIngredient1];
      let adjustedValue = 0;

      if (value === null) {
        adjustedValue = 0;
      } else if (ingredient.defaultUnit !== "gram") {
        adjustedValue = value * ingredient.amount;
      } else {
        adjustedValue = calculateNutrientAmountWeight(
          ingredient.unit as DefaultWeightUnits,
          ingredient.amount,
          value
        );
      }

      return adjustedValue;
    }
  );

  const valuesIngredient2: number[] = removedHiddenIngredients.map(
    (ingredient) => {
      const value = ingredient[nutrientIngredient2];
      let adjustedValue = 0;

      if (value === null) {
        adjustedValue = 0;
      } else if (ingredient.defaultUnit !== "gram") {
        adjustedValue = value * ingredient.amount;
      } else {
        adjustedValue = calculateNutrientAmountWeight(
          ingredient.unit as DefaultWeightUnits,
          ingredient.amount,
          value
        );
      }

      return adjustedValue;
    }
  );

  const totalInMealIngredient1 = valuesIngredient1.reduce(
    (total, number) => total + number,
    0
  );
  const totalInMealIngredient2 = valuesIngredient2.reduce(
    (total, number) => total + number,
    0
  );

  let totalInMeal = 0;
  if (totalInMealIngredient2 !== 0) {
    totalInMeal = totalInMealIngredient1 / totalInMealIngredient2;
  }

  return (
    <NutrientRow
      nutrientStandard={nutrientStandard}
      nutrientReqsMin={nutrientReqsMin}
      nutrientReqsMax={nutrientReqsMax}
      totalInMeal={totalInMealIngredient2 === 0 ? null : totalInMeal}
    />
  );
};

export default NutrientRowRatio;
