import axios, { AxiosError } from "axios";

import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import { UserLoginSignup } from "../../types/userTypes";

const useLogin = (): UseMutationResult<void, AxiosError, UserLoginSignup> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body) => {
      const responseLogin = await axios.post<void>(
        `${BACKEND_URL}/api/auth/login`,
        body
      );

      return responseLogin.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["loginCheck"], { exact: true });
        queryClient.invalidateQueries(["loggedInUser"], { exact: true });
      },
    }
  );
};

export default useLogin;
