import { NutrientsStandard } from "../../types/standardTypes";

const adjustVitaminEReq = (
  nutrientsReq: NutrientsStandard,
  linoleicAcid: number,
  alphaLinoleicAcid: number,
  arachidonicAcid: number,
  epa: number,
  dha: number
): NutrientsStandard => {
  if (nutrientsReq.vitaminE === null) {
    return nutrientsReq;
  }

  const newVitaminELevel =
    linoleicAcid * 0.6 +
    alphaLinoleicAcid * 0.9 +
    arachidonicAcid * 1.2 +
    epa * 1.5 +
    dha * 1.8 +
    nutrientsReq.vitaminE;

  const adjustedNutrients: NutrientsStandard = {
    ...nutrientsReq,
    vitaminE: newVitaminELevel,
  };

  return adjustedNutrients;
};

export default adjustVitaminEReq;
