import React from "react";
import useChangeDogProperty from "../../../../hooks/api/useChangeDogProperty";
import useGetAuthUser from "../../../../hooks/api/useGetAuthUser";
import useGetDog from "../../../../hooks/api/useGetDog";
import { DogValuesNoIdsType } from "../../../../types/dogTypes";
import DogPartial from "./DogPartial";

const ExistingDog = (): JSX.Element | null => {
  const authUserQuery = useGetAuthUser();
  const getDogQuery = useGetDog(authUserQuery.data?.selectedDog, {
    enabled: !!authUserQuery.data?.selectedDog,
  });

  const changeDogPropertyMutation = useChangeDogProperty();

  const saveValue = (
    value: string | number | Date | boolean | null | undefined,
    property: DogValuesNoIdsType
  ) => {
    if (getDogQuery.data) {
      changeDogPropertyMutation.mutate({
        id: getDogQuery.data.id,
        value,
        property,
      });
    }
  };

  if (!getDogQuery.data) {
    return null;
  }

  const formattedDog = {
    ...getDogQuery.data,
    id: undefined,
    ownerId: undefined,
  };
  delete formattedDog.id;
  delete formattedDog.ownerId;

  return (
    <DogPartial
      dog={formattedDog}
      saveValue={saveValue}
      dogId={getDogQuery.data.id}
    />
  );
};

export default ExistingDog;
