import adjustNutrientReqCalories from "../../../services/nutrientReqs/adjustNutrientReqCalories";
import { NutrientsStandard } from "../../../types/standardTypes";

export const NUTRIENT_REQ_NRC_PUPPY_4_14_MIN: NutrientsStandard = {
  // Macronutrients
  protein: 56.3,
  fat: 21.3,
  carbohydrate: null,
  water: null,
  ash: null,

  // Amino acids
  tryptophan: 0.58,
  threonine: 2.03,
  isoleucine: 1.63,
  leucine: 3.22,
  lysine: 2.2,
  methionine: 0.88,
  methionineAndCystine: 1.75,
  phenylalanine: 1.63,
  phenylalanineAndTyrosine: 3.25,
  valine: 1.7,
  arginine: 1.98,
  histidine: 0.98,

  // Fatty acids
  linoleicAcid: 3.3,
  alphaLinoleicAcid: 0.2,
  arachidonicAcid: 0.08,
  linoleicToAlphaLinoleicAcids: 2.6,
  epaAndDha: 0.13,

  // Carbohydrates
  fiber: null,

  // Minerals
  calcium: 3000,
  iron: 22,
  magnesium: 100,
  phosphorus: 2500,
  calciumToPhosphorus: 1.2,
  potassium: 1100,
  chloride: 720,
  sodium: 550,
  zinc: 25,
  copper: 2.7,
  zincToCopper: 8.0,
  manganese: 1.4,
  selenium: 87.5,
  iodine: 220,

  // Vitamins
  thiaminB1: 0.34,
  riboflavinB2: 1.32,
  niacinB3: 4.25,
  pantothenicAcidB5: 3.75,
  vitaminB6: 0.375,
  folicAcidB9: 68,
  choline: 425,
  vitaminB12: 8.75,
  vitaminA: 379,
  vitaminE: 7.5,
  cholecalciferolD3: 3.4,
  phylloquinoneK: 410,
};

export const NUTRIENT_REQ_NRC_PUPPY_4_14_MAX: NutrientsStandard = {
  // Macronutrients
  protein: null,
  fat: 82.5,
  carbohydrate: null,
  water: null,
  ash: null,

  // Amino acids
  tryptophan: null,
  threonine: null,
  isoleucine: null,
  leucine: null,
  lysine: 5,
  methionine: null,
  methionineAndCystine: null,
  phenylalanine: null,
  phenylalanineAndTyrosine: null,
  valine: null,
  arginine: null,
  histidine: null,

  // Fatty acids
  linoleicAcid: 16.3,
  alphaLinoleicAcid: null,
  arachidonicAcid: null,
  linoleicToAlphaLinoleicAcids: 16,
  epaAndDha: 2.8,

  // Carbohydrates
  fiber: null,

  // Minerals
  calcium: 4500,
  iron: null,
  magnesium: null,
  phosphorus: null,
  calciumToPhosphorus: 2.0,
  potassium: null,
  chloride: null,
  sodium: null,
  zinc: null,
  copper: null,
  zincToCopper: null,
  manganese: null,
  selenium: null,
  iodine: null,

  // Vitamins
  thiaminB1: null,
  riboflavinB2: null,
  niacinB3: null,
  pantothenicAcidB5: null,
  vitaminB6: null,
  folicAcidB9: null,
  choline: null,
  vitaminB12: null,
  vitaminA: 3750,
  vitaminE: null,
  cholecalciferolD3: 20,
  phylloquinoneK: null,
};

export const getNutrientReqNrcPuppy414Min = (
  protein: number,
  calorieRequriement: number,
  weight: number,
  expectedWeight: number
): NutrientsStandard => {
  let specificReq = NUTRIENT_REQ_NRC_PUPPY_4_14_MIN;
  const proteinDiff = protein - 225;
  if (proteinDiff > 0) {
    specificReq = {
      ...specificReq,
      arginine: (specificReq.arginine as number) + proteinDiff * 0.01,
    };
  }

  const adjustedNutrients = adjustNutrientReqCalories(
    specificReq,
    calorieRequriement
  );

  // Adjusting calcium
  if (expectedWeight > 25) {
    const minValueCalcium = 0.54 * weight;

    adjustedNutrients.calcium = Math.max(
      minValueCalcium,
      adjustedNutrients.calcium === null ? 0 : adjustedNutrients.calcium
    );
  }
  return adjustedNutrients;
};

export const getNutrientReqNrcPuppy414Max = (
  calorieRequriement: number
): NutrientsStandard => {
  const adjustedNutrients = adjustNutrientReqCalories(
    NUTRIENT_REQ_NRC_PUPPY_4_14_MAX,
    calorieRequriement
  );
  return adjustedNutrients;
};
