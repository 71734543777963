import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import useLeavingDogLogic from "../../hooks/useLeavingDogLogic";

interface Props {
  children: React.ReactNode;
  href?: string;
  className?: string;
  asButton?: boolean;
  clickHandler?: () => void;
  to?: string;
  asButtonAsA?: boolean;
}

const StyledLink = ({
  href,
  children,
  className,
  asButton,
  clickHandler,
  to,
  asButtonAsA,
}: Props): JSX.Element => {
  const leavingDogLogic = useLeavingDogLogic();
  const history = useHistory();

  if (asButton) {
    if (!asButtonAsA) {
      return (
        <StyledButton
          as={"button"}
          className={className}
          onClick={clickHandler}
        >
          {children}
        </StyledButton>
      );
    } else {
      return (
        <LinkStyled
          role="button"
          onClick={clickHandler}
          className={className}
          tabIndex={0}
        >
          {children}
        </LinkStyled>
      );
    }
  } else {
    if (href) {
      return (
        <LinkStyled
          href={href}
          target="_blank"
          className={className}
          onClick={clickHandler}
        >
          {children}
        </LinkStyled>
      );
    } else {
      return (
        <StyledInternalLink
          className={className}
          onClick={(e) => {
            e.preventDefault();

            const callback = () => {
              history.push(to ? to : "/");
              clickHandler && clickHandler();
            };

            leavingDogLogic(callback, to ? to : "/");
          }}
          to={to ? to : "/"}
        >
          {children}
        </StyledInternalLink>
      );
    }
  }
};

const LinkStyled = styled.a`
  line-height: ${(props) => props.theme.textLineHeight};
  font-family: "DM Sans Medium", sans-serif;
  font-weight: 400;
  transition: color ${(props) => props.theme.transitionValue};
  text-decoration: underline;
  cursor: pointer;

  &:focus {
    outline-width: 0.125rem; // 2px
    outline-style: solid;
    outline-offset: 0.125rem; // 2px
  }
`;

const StyledInternalLink = styled(Link)`
  line-height: ${(props) => props.theme.textLineHeight};
  font-family: "DM Sans Medium", sans-serif;
  font-weight: 400;
  transition: color ${(props) => props.theme.transitionValue};
  &:focus {
    outline-width: 0.125rem; // 2px
    outline-style: solid;
    outline-offset: 0.125rem; // 2px
  }
`;

const StyledButton = styled(LinkStyled)`
  background-color: transparent;
  border: none;
  font-size: inherit;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
`;

export default StyledLink;
