import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import useLogin from "../../../hooks/api/useLogin";
import SmallText from "../text/SmallText";
import Input from "../form/normal/Input";
import LabelForm from "../form/LabelForm";
import SubmitButton from "../form/normal/SubmitButton";
import Line from "../Line";
import Text from "../text/Text";
import StyledLink from "../StyledLink";
import {
  emailErrorMessage,
  passwordErrorMessage,
} from "../../../utils/errorMessagesAuth";
import ErrorMessage from "../form/ErrorMessage";
import HideRevealPassword from "./HideRevealPassword";
import LoginErrorMessageContext from "../../../contexts/loginErrorMessageContext/LoginErrorMessageContext";
import useGetLoginStatus from "../../../hooks/api/useGetLoginStatus";
import useGetAuthUser from "../../../hooks/api/useGetAuthUser";
import { useHistory } from "react-router-dom";
import { emailRegex, passwordRegex } from "../../../utils/regexPatterns";

interface LoginValues {
  emailLogin: string;
  passwordLogin: string;
}

interface Props {
  forgetHandler: (email: string) => void;
  signupHandler: (email: string) => void;
  savedEmail: string;
  redirectAfterLogin: boolean;
  className?: string;
}

const LoginPartial = ({
  forgetHandler,
  signupHandler,
  savedEmail,
  redirectAfterLogin,
  className,
}: Props): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<LoginValues>({
    defaultValues: {
      emailLogin: savedEmail,
    },
  });

  const loginMutation = useLogin();

  const loginErrorMessageContextValue = useContext(LoginErrorMessageContext);

  const history = useHistory();

  useEffect(() => {
    return () => {
      if (loginErrorMessageContextValue) {
        loginErrorMessageContextValue.setLoginErrorMessage("");
      }
    };
  }, []);

  const loggedInStatusQuery = useGetLoginStatus({
    enabled: loginMutation.isSuccess && redirectAfterLogin,
  });

  useGetAuthUser({
    enabled:
      loginMutation.isSuccess &&
      !!loggedInStatusQuery?.data?.loggedIn &&
      redirectAfterLogin,
    onSuccess: (data) => {
      if (
        loginMutation.isSuccess &&
        loggedInStatusQuery.isSuccess &&
        redirectAfterLogin
      ) {
        if (data.verified) {
          history.push("/dogs");
        } else if (data.verified === false) {
          history.push("/verify");
        }
      }
    },
  });

  const submitHandler = (data: LoginValues) => {
    loginMutation.mutate(
      {
        email: data.emailLogin,
        password: data.passwordLogin,
      },
      {
        onError: (error) => {
          if (loginErrorMessageContextValue) {
            loginErrorMessageContextValue.setLoginErrorMessage("");
            if (error.response?.data.error === "Incorrect combination") {
              loginErrorMessageContextValue.setLoginErrorMessage(
                "That email and password combination is incorrect."
              );
            }
          }
        },
      }
    );
  };

  const emailForm = watch("emailLogin");

  return (
    <>
      {loginErrorMessageContextValue?.loginErrorMessage && (
        <StyledErrorMessage
          message={loginErrorMessageContextValue.loginErrorMessage}
        />
      )}
      <form
        onSubmit={handleSubmit(submitHandler)}
        className={className}
        noValidate
        autoComplete="on"
      >
        <EmailLabel name="emailLogin">Email</EmailLabel>
        <StyledInput
          register={register("emailLogin", {
            required: true,
            pattern: emailRegex,
          })}
          error={errors.emailLogin}
          errorMessage={emailErrorMessage}
          type={"email"}
          placeholder="name@youremail.com"
          autoComplete="email"
        />

        <PasswordLabel name="passwordLogin">Password</PasswordLabel>
        <ContainerPasswordInput>
          <PasswordInput
            register={register("passwordLogin", {
              required: true,
              pattern: passwordRegex,
            })}
            error={errors.passwordLogin}
            errorMessage={passwordErrorMessage}
            type={showPassword ? "text" : "password"}
            placeholder="•••••"
            autoComplete="current-password"
          />
          <HideRevealPassword
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        </ContainerPasswordInput>
        <ButtonContainer>
          <StyledSubmitButton text="Log in" />
        </ButtonContainer>
      </form>
      <Line />
      <SwitchLinks>
        <StyledText>
          <StyledStyledLink
            asButton={true}
            clickHandler={() => forgetHandler(emailForm)}
          >
            Forgot your password?
          </StyledStyledLink>
        </StyledText>
        <StyledText>
          No account yet?{" "}
          <StyledStyledLink
            asButton={true}
            asButtonAsA={true}
            clickHandler={() => signupHandler(emailForm)}
          >
            Sign up for a new account
          </StyledStyledLink>
          .
        </StyledText>
      </SwitchLinks>
      <Line />
      <TermsLinks>
        <StyledSmallText>
          Read our{" "}
          <StyledStyledLink href="/privacy">Privacy Policy</StyledStyledLink>,{" "}
          <StyledStyledLink href="/terms-of-use">Terms of use</StyledStyledLink>
          {" and "}
          <StyledStyledLink href="/disclaimer">Disclaimer</StyledStyledLink>.
        </StyledSmallText>
      </TermsLinks>
    </>
  );
};

const StyledSubmitButton = styled(SubmitButton)`
  background-color: ${(props) => props.theme.green3};
  color: ${(props) => props.theme.green1};

  &:hover {
    background-color: ${(props) => props.theme.green4};
  }
  &:focus {
    outline-color: ${(props) => props.theme.green4};
    background-color: ${(props) => props.theme.green4};
  }
`;

const EmailLabel = styled(LabelForm)`
  color: ${(props) => props.theme.black4};
  margin-bottom: 0.25rem; // 4px
`;

const PasswordLabel = styled(EmailLabel)`
  margin-top: ${(props) => props.theme.spacing2};
`;

const StyledInput = styled(Input)`
  color: ${(props) => props.theme.green4};
  background-color: ${(props) => props.theme.green1};

  border-style: solid;
  border-width: 0.125rem; // 2px
  border-color: ${(props) => props.theme.green4};

  &:hover {
    background-color: ${(props) => props.theme.white};
  }
  &:focus {
    background-color: ${(props) => props.theme.white};
    outline: none;
  }
  &::placeholder {
    color: ${(props) => props.theme.green3};
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: ${(props) => props.theme.spacing2};
  margin-bottom: ${(props) => props.theme.spacing3};
`;

const SwitchLinks = styled.div`
  margin-top: ${(props) => props.theme.spacing3};
  margin-bottom: ${(props) => props.theme.spacing3};
`;

const TermsLinks = styled.div`
  margin-top: ${(props) => props.theme.spacing3};
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledSmallText = styled(SmallText)`
  color: ${(props) => props.theme.black4};
`;

const StyledStyledLink = styled(StyledLink)`
  color: ${(props) => props.theme.black4};

  &:hover {
    color: ${(props) => props.theme.black3};
  }
  &:focus {
    outline-color: ${(props) => props.theme.black3};
    color: ${(props) => props.theme.black3};
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 0;
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const ContainerPasswordInput = styled.div`
  position: relative;
`;

const PasswordInput = styled(StyledInput)`
  padding-right: 4rem; // 64px
`;

export default LoginPartial;
