import React from "react";
import styled from "styled-components";
import { allColors } from "../../../../types/frontendTypes";
import { kgOrLb } from "../../../../types/userTypes";
import UnitLabel from "../UnitLabel";
import SimpleInputNumber from "./SimpleInputNumber";

interface Props {
  value: number | null;
  setValue: React.Dispatch<React.SetStateAction<number | null>>;
  id: string;
  unit: kgOrLb;
  colorWeightUnit: allColors;
  placeholder: string;
  className?: string;
}

const SimpleWeightInput = ({
  value,
  setValue,
  id,
  unit,
  colorWeightUnit,
  placeholder,
  className,
}: Props): JSX.Element => {
  return (
    <Container>
      <SimpleInputNumber
        className={className}
        id={id}
        value={value}
        setValue={setValue}
        placeholder={placeholder}
      />
      <UnitLabel unit={unit} value={value} color={colorWeightUnit} />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

export default SimpleWeightInput;
