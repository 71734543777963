import React, { useState } from "react";
import OneChoiceModalOpenContext from "./OneChoiceModalOpenContext";

interface Props {
  children: React.ReactNode;
}

const OneChoiceModalOpenContextProvider = ({
  children,
}: Props): JSX.Element => {
  const [oneChoiceModalOpen, setOneChoiceModalOpen] = useState(false);

  return (
    <OneChoiceModalOpenContext.Provider
      value={{ oneChoiceModalOpen, setOneChoiceModalOpen }}
    >
      {children}
    </OneChoiceModalOpenContext.Provider>
  );
};

export default OneChoiceModalOpenContextProvider;
