import adjustNutrientReqCalories from "../../../services/nutrientReqs/adjustNutrientReqCalories";
import { NutrientsStandard } from "../../../types/standardTypes";

const NUTRIENT_REQ_NRC_REPRODUCTION_MIN: NutrientsStandard = {
  // Macronutrients
  protein: 50,
  fat: 21.3,
  carbohydrate: null,
  water: null,
  ash: null,

  // Amino acids
  tryptophan: 0.3,
  threonine: 2.6,
  isoleucine: 1.78,
  leucine: 5.0,
  lysine: 2.25,
  methionine: 0.78,
  methionineAndCystine: 1.55,
  phenylalanine: 2.08,
  phenylalanineAndTyrosine: 3.08,
  valine: 3.25,
  arginine: 2.5,
  histidine: 1.1,

  // Fatty acids
  linoleicAcid: 3.3,
  alphaLinoleicAcid: 0.2,
  arachidonicAcid: null,
  linoleicToAlphaLinoleicAcids: 2.6,
  epaAndDha: 0.13,

  // Carbohydrates
  fiber: null,

  // Minerals
  calcium: 1900,
  iron: 17,
  magnesium: 150,
  phosphorus: 1200,
  calciumToPhosphorus: 1.2,
  potassium: 900,
  chloride: 750,
  sodium: 500,
  zinc: 24,
  copper: 3.1,
  zincToCopper: 8.0,
  manganese: 1.8,
  selenium: 87.5,
  iodine: 220,

  // Vitamins
  thiaminB1: 0.56,
  riboflavinB2: 1.3,
  niacinB3: 4.25,
  pantothenicAcidB5: 3.75,
  vitaminB6: 0.375,
  folicAcidB9: 67.5,
  choline: 425,
  vitaminB12: 8.75,
  vitaminA: 379,
  vitaminE: 7.5,
  cholecalciferolD3: 3.4,
  phylloquinoneK: 410,
};

const NUTRIENT_REQ_NRC_REPRODUCTION_MAX: NutrientsStandard = {
  // Macronutrients
  protein: null,
  fat: 82.5,
  carbohydrate: null,
  water: null,
  ash: null,

  // Amino acids
  tryptophan: null,
  threonine: null,
  isoleucine: null,
  leucine: null,
  lysine: null,
  methionine: null,
  methionineAndCystine: null,
  phenylalanine: null,
  phenylalanineAndTyrosine: null,
  valine: null,
  arginine: null,
  histidine: null,

  // Fatty acids
  linoleicAcid: 16.3,
  alphaLinoleicAcid: null,
  arachidonicAcid: null,
  linoleicToAlphaLinoleicAcids: 16,
  epaAndDha: 2.8,

  // Carbohydrates
  fiber: null,

  // Minerals
  calcium: null,
  iron: null,
  magnesium: null,
  phosphorus: null,
  calciumToPhosphorus: 2.0,
  potassium: null,
  chloride: null,
  sodium: null,
  zinc: null,
  copper: null,
  zincToCopper: null,
  manganese: null,
  selenium: null,
  iodine: null,

  // Vitamins
  thiaminB1: null,
  riboflavinB2: null,
  niacinB3: null,
  pantothenicAcidB5: null,
  vitaminB6: null,
  folicAcidB9: null,
  choline: null,
  vitaminB12: null,
  vitaminA: 3750,
  vitaminE: null,
  cholecalciferolD3: 20,
  phylloquinoneK: null,
};

export const getNutrientReqNrcReproductionMin = (
  protein: number,
  calorieRequriement: number
): NutrientsStandard => {
  let specificReq = NUTRIENT_REQ_NRC_REPRODUCTION_MIN;
  const proteinDiff = protein - 200;
  if (proteinDiff > 0) {
    specificReq = {
      ...specificReq,
      arginine: (specificReq.arginine as number) + proteinDiff * 0.01,
    };
  }

  const adjustedNutrients = adjustNutrientReqCalories(
    specificReq,
    calorieRequriement
  );
  return adjustedNutrients;
};

export const getNutrientReqNrcReproductionMax = (
  calorieRequriement: number
): NutrientsStandard => {
  const adjustedNutrients = adjustNutrientReqCalories(
    NUTRIENT_REQ_NRC_REPRODUCTION_MAX,
    calorieRequriement
  );
  return adjustedNutrients;
};
