import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useEditableLogic from "../../../../hooks/useEditableLogic";
import InputButtons from "./InputButtons";

interface Props {
  id: string;
  placeholder: string;
  serverValue: string | undefined;
  onSave: (value: string) => void;
  allowEmpty?: boolean;
  className?: string;
}

const EditableText = ({
  id,
  placeholder,
  serverValue,
  onSave,
  allowEmpty,
  className,
}: Props): JSX.Element => {
  const [inputValue, setInputValue] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { editing, setEditing, saveEdit, cancelEdit, handleKeys } =
    useEditableLogic<string>(
      serverValue,
      inputValue,
      setInputValue,
      onSave,
      () => {
        inputRef.current?.focus();
      },
      (a, b) => a === b,
      (value) => value === "",
      allowEmpty
    );

  useEffect(() => {
    if (!editing) {
      inputRef.current?.blur();
    }
  }, [editing]);

  return (
    <Container>
      <StyledInput
        className={className}
        type={"text"}
        value={inputValue || ""}
        editing={editing}
        hasValue={!!serverValue}
        id={id}
        placeholder={placeholder}
        onClick={() => setEditing(true)}
        onBlur={saveEdit}
        ref={inputRef}
        onChange={(e) => setInputValue(e.target.value)}
        onFocus={() => setEditing(true)}
        onKeyDown={handleKeys}
      />
      {!(!serverValue && !editing) && (
        <InputButtons
          cancelEdit={cancelEdit}
          editing={editing}
          saveEdit={saveEdit}
          setEditing={setEditing}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const StyledInput = styled.input<{ editing: boolean; hasValue: boolean }>`
  display: block;
  width: 100%;
  padding: 1rem 2rem; // 13px 32px
  box-sizing: border-box;
  border-width: 0.125rem; // 2px
  border-radius: 100rem;
  border-style: solid;
  border-color: ${(props) =>
    props.editing || !props.hasValue ? props.theme.black4 : props.theme.black1};
  font-size: ${(props) => props.theme.buttonTextSize};
  font-family: "DM Sans Regular", sans-serif;
  font-weight: 400;
  outline: none;
  text-overflow: ellipsis;
  padding-right: ${(props) =>
    props.editing ? "10.75rem" : "4.9375rem"}; // 172px 79px
  color: ${(props) => props.theme.black4};
  line-height: ${(props) => props.theme.componentLineHeight};
  // https://stackoverflow.com/questions/9333044/how-to-hide-blinking-cursor-in-input-text
  /* caret-color: ${(props) => (props.editing ? "auto" : "transparent")}; */

  &::placeholder {
    color: ${(props) => props.theme.black3};
  }
  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.buttonTextSizeMobile};
  }
`;

export default EditableText;
