import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import TopMenu from "./TopMenu";
import { ReactComponent as Logo } from "../../../assets/images/mealment-logotype.svg";
import WrapLink from "../../shared/WrapLink";
import AuthModal from "./AuthModal";
import AuthModalContext from "../../../contexts/authModalContext/AuthModalContext";

const Header = (): JSX.Element | null => {
  const lastScrollTop = useRef(0);
  const [headerVisible, setHeaderVisible] = useState(true);
  const [backgroundVisible, setBackgroundVisible] = useState(false);

  const authModalContext = useContext(AuthModalContext);

  const stuckLimit = 60;

  useEffect(() => {
    const handleScroll = () => {
      const scrollingUp = window.pageYOffset < lastScrollTop.current;
      const withinStuckLimit = window.pageYOffset <= stuckLimit;

      setHeaderVisible(scrollingUp || withinStuckLimit);
      lastScrollTop.current = window.pageYOffset;
      if (scrollingUp) {
        setBackgroundVisible(!withinStuckLimit);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (!authModalContext) {
    return null;
  }

  return (
    <>
      <StyledHeader
        visible={headerVisible}
        backgroundVisible={backgroundVisible}
      >
        <Content>
          <StyledWrapLink
            to="/"
            clickHandler={() => {
              if (window.location.pathname === "/") {
                window.scrollTo(0, 0);
              }
            }}
          >
            <StyledLogo aria-label="Mealment logotype with a link to the start page" />
          </StyledWrapLink>
          <TopMenu
            headerBackgroundVisible={backgroundVisible}
            setLoginModalOpen={() => {
              authModalContext.setAuthModalOpen(true);
              authModalContext.setAuthModalShowing("login");
            }}
            setSignupModalOpen={() => {
              authModalContext.setAuthModalOpen(true);
              authModalContext.setAuthModalShowing("signup");
            }}
          />
        </Content>
      </StyledHeader>
      <AuthModal
        modalOpen={authModalContext.authModalOpen}
        setModalOpen={authModalContext.setAuthModalOpen}
        showing={authModalContext.authModalShowing}
        setShowing={authModalContext.setAuthModalShowing}
      />
    </>
  );
};

const StyledHeader = styled.header<{
  visible: boolean;
  backgroundVisible: boolean;
}>`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  padding-top: ${(props) => props.theme.spacing1};
  padding-bottom: ${(props) => props.theme.spacing1};
  padding-left: ${(props) =>
    "calc(" + props.theme.spacing1 + " + env(safe-area-inset-left))"};
  padding-right: ${(props) =>
    "calc(" + props.theme.spacing1 + " + env(safe-area-inset-right))"};
  box-sizing: border-box;
  background-color: ${(props) =>
    props.backgroundVisible && "hsla(0, 0%, 100%, 0.8)"};
  backdrop-filter: ${(props) =>
    props.backgroundVisible && "blur(" + props.theme.spacing1 + ")"};
  transform: ${(props) => (props.visible ? "none" : "translateY(-100%)")};
  transition: transform ${(props) => props.theme.transitionValueHeader},
    background-color 0.25s ease-out,
    -webkit-transform ${(props) => props.theme.transitionValueHeader};
`;

const Content = styled.div`
  max-width: ${(props) => props.theme.mainWidth};
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLogo = styled(Logo)`
  height: 2.5rem; // 40px
  fill: ${(props) => props.theme.green4};
  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    height: 1.5rem; // 24px
  }
`;

const StyledWrapLink = styled(WrapLink)`
  &:focus {
    outline-color: ${(props) => props.theme.green4};
  }
`;

export default Header;
