import React from "react";
import styled from "styled-components";
import useGetDogs from "../../../hooks/api/useGetDogs";
import useGetRecipes from "../../../hooks/api/useGetRecipes";
import getAllowedNumberOfDogs from "../../../services/getAllowedNumberOfDogs";
import getAllowedNumberOfRecipes from "../../../services/getAllowedNumberOfRecipes";
import useIsValidSubscription from "../../../hooks/useIsValidSubscription";
import { User } from "../../../types/userTypes";
import DeleteDogTable from "../../shared/DeleteDogTable";
import Modal from "../../shared/Modal";
import StyledLink from "../../shared/StyledLink";
import IntroductionText from "../../shared/text/IntroductionText";
import DeleteRecipeTable from "./DeleteRecipeTable";

interface Props {
  user: User | undefined;
}

const ExceededResourceModal = ({ user }: Props): JSX.Element | null => {
  const validSubscription = useIsValidSubscription(
    user === undefined ? undefined : user.stripeSubscription,
    user === undefined ? undefined : user.stripeSubscriptionTimestamp
  );

  const getDogsQuery = useGetDogs({
    enabled: !!user && validSubscription,
  });

  const getRecipesQuery = useGetRecipes({
    enabled: !!user && validSubscription,
  });

  if (
    !user ||
    user.stripeSubscription === null ||
    getDogsQuery.data === undefined ||
    getRecipesQuery.data === undefined
  ) {
    return null;
  }

  const allowedNumberOfDogs = getAllowedNumberOfDogs(user.stripeSubscription);
  const TooManyDogsOpen = getDogsQuery.data.length > allowedNumberOfDogs;

  const allowedNumberOfRecipes = getAllowedNumberOfRecipes(
    user.stripeSubscription
  );
  const TooManyRecipesOpen =
    getRecipesQuery.data.length > allowedNumberOfRecipes && !TooManyDogsOpen;

  return (
    <>
      <Modal
        open={TooManyDogsOpen}
        title="Exceeded dog limit on current plan"
        closable={false}
      >
        <StyledIntroductionText>
          You have exceeded the maximum number of dogs allowed on your current
          plan. To continue having all your dogs, you need to remove some of the
          dogs or{" "}
          <StyledStyledLink to="/pricing">upgrade to a plan</StyledStyledLink>{" "}
          with a higher limit.
        </StyledIntroductionText>
        <DeleteDogTable />
      </Modal>

      <Modal
        open={TooManyRecipesOpen}
        title="Exceeded recipe limit on current plan"
        closable={false}
      >
        <StyledIntroductionText>
          You have exceeded the maximum number of recipes allowed on your
          current plan. To continue having all your recipes, you need to remove
          some of the recipes or{" "}
          <StyledStyledLink to="/pricing">upgrade to a plan</StyledStyledLink>{" "}
          with a higher limit.
        </StyledIntroductionText>
        <DeleteRecipeTable />
      </Modal>
    </>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};
  margin-top: ${(props) => props.theme.spacing2};
`;

const StyledStyledLink = styled(StyledLink)`
  color: ${(props) => props.theme.black4};

  &:hover {
    color: ${(props) => props.theme.black3};
  }
  &:focus {
    outline-color: ${(props) => props.theme.black3};
    color: ${(props) => props.theme.black3};
  }
`;

export default ExceededResourceModal;
