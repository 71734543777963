import React from "react";
import styled from "styled-components";
import { infoOptions } from "../../types/frontendTypes";
import Modal from "./Modal";
import IntroductionText from "./text/IntroductionText";
import Text from "./text/Text";

interface Props {
  toShow: infoOptions;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DogInputInfoModal = ({ toShow, open, setOpen }: Props): JSX.Element => {
  let title = "";
  switch (toShow) {
    case "expectedWeight":
      title = "Expected Weight";
      break;
    case "activityLevel":
      title = "Activity Level";
      break;
    case "calorieGoal":
      title = "Calorie Goal";
      break;
    case "nutrientGuideline":
      title = "Nutrient Guideline";
      break;
    case "parturitionDate":
      title = "Parturition Date";
      break;
  }

  return (
    <Modal open={open} setOpen={setOpen} title={title} closable={true}>
      {toShow === "expectedWeight" && (
        <>
          <StyledIntroductionText>
            The expected weight of the puppy when it is fully grown.
          </StyledIntroductionText>
          <StyledText>
            Information about different breeds&apos; weights at full growth is
            readily available online. For mixed-breed dogs, it can be a bit more
            complicated. If in doubt, discuss the matter with your veterinary.
          </StyledText>
        </>
      )}

      {toShow === "activityLevel" && (
        <>
          <StyledIntroductionText>
            The activity level affects the caloric needs of the dog at all
            different life stages and is determined by a multitude of factors.
          </StyledIntroductionText>
          <StyledTextMargin>
            Generally, the larger, younger and more active the dog, the higher
            the activity level. Giant breeds such as Great Danes have a
            considerably higher activity level.
          </StyledTextMargin>
          <StyledText>
            Use the following examples as a starting point for determining your
            adult dog&apos;s activity level:
          </StyledText>
          <StyledUl>
            <StyledText asValue="li">
              85: Pet dogs who are obese or geriatric.
            </StyledText>
            <StyledText asValue="li">
              95: Pet dogs who are kept in a domestic environment with little
              stimulus and opportunity to exercise.
            </StyledText>
            <StyledText asValue="li">
              105: Older dogs living in a kennel environment or older active pet
              dogs.
            </StyledText>
            <StyledText asValue="li">
              110: Somewhat active medium-sized pet dog.
            </StyledText>
            <StyledText asValue="li">
              120: Active medium-sized pet dog.
            </StyledText>
            <StyledText asValue="li">
              130: Dogs living in a kennel environment or very active pet dogs.
            </StyledText>
            <StyledText asValue="li">
              140: Young dogs living in a kennel environment or very active
              young pet dogs.
            </StyledText>
            <StyledText asValue="li">
              &gt;140: Working dogs, dogs engaging in sports or certain giant
              breeds such as Great Danes.
            </StyledText>
          </StyledUl>
          <StyledText>
            Use the following examples as a starting point for determining your
            puppy&apos;s activity level:
          </StyledText>
          <ul>
            <StyledText asValue="li">
              110: Pet puppies with little stimulus and opportunity to exercise.
            </StyledText>
            <StyledText asValue="li">130: Active puppies.</StyledText>
            <StyledText asValue="li">
              &gt;130: Very active puppies or certain giant breeds such as Great
              Danes.
            </StyledText>
          </ul>
        </>
      )}

      {toShow === "calorieGoal" && (
        <>
          <StyledIntroductionText>
            Let the Mealment app calculate your dog&apos;s calorie goal or set
            it yourself.
          </StyledIntroductionText>
          <StyledText>
            The calculation adheres to the formulas published in the book
            Nutrient Requirements of Dogs and Cats by the National Research
            Council. However, always monitor and listen to your dog; they are
            all unique individuals with unique caloric needs. You can set the
            calorie goal yourself if you have a good idea of your dog&apos;s
            caloric needs.
          </StyledText>
        </>
      )}

      {toShow === "nutrientGuideline" && (
        <>
          <StyledIntroductionText>
            The nutrient guidelines dictate the minimum (and sometimes maximum)
            amount of nutrients your dog needs to stay healthy.
          </StyledIntroductionText>
          <StyledTextMargin>
            The National Research Council (NRC) has published nutrient
            guidelines for dogs in the book Nutrient Requirements of Dogs and
            Cats. The latest version was published in 2006. it congregates the,
            at the time latest scientific knowledge about the nutrient
            requirements of dogs in a single source. The book was produced using
            both public and private funding.
          </StyledTextMargin>
          <StyledTextMargin>
            The Fédération européenne de l&apos;industrie des aliments pour
            animaux familiers (FEDIAF) publishes nutrient guidelines yearly.
            FEDIAF represents the European pet food industry.
          </StyledTextMargin>
          <StyledText>
            The Association of American Feed Control Officials (AAFCO) publishes
            nutrient guidelines yearly. The guidelines are adjusted to
            commercial diets but not necessarily to home-prepared diets. The
            AAFCO is comprised of state and federal representatives.
          </StyledText>
        </>
      )}

      {toShow === "parturitionDate" && (
        <>
          <StyledIntroductionText>
            The parturition date is the date of birth of the puppies.
          </StyledIntroductionText>
          <StyledText>
            Calculating the increased caloric need of a lactating bitch is only
            possible during the first four weeks. After that, the milk yield
            varies too much from dog to dog. Some dogs will continue requiring
            significant extra calories after these four weeks.
          </StyledText>
        </>
      )}
    </Modal>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  margin-top: ${(props) => props.theme.spacing2};
  margin-bottom: ${(props) => props.theme.spacing2};
  color: ${(props) => props.theme.black4};
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.black4};
`;

const StyledTextMargin = styled(StyledText)`
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const StyledUl = styled.ul`
  margin-bottom: ${(props) => props.theme.spacing2};
`;

export default DogInputInfoModal;
