import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import AuthEmailContext from "../../../../contexts/authEmailContext/AuthEmailContext";
import AuthContainer from "../../../shared/auth/AuthContainer";
import ForgotPasswordAnswerPartial from "../../../shared/auth/ForgotPasswordAnswerPartial";
import ForgotPasswordPartial from "../../../shared/auth/ForgotPasswordPartial";
import ErrorMessage from "../../../shared/form/ErrorMessage";

interface Props {
  forgotPasswordTokenMessage: string | null;
  setForgotPasswordTokenMessage: React.Dispatch<
    React.SetStateAction<string | null>
  >;
}

const ForgotPassword = ({
  forgotPasswordTokenMessage,
  setForgotPasswordTokenMessage,
}: Props): JSX.Element => {
  const history = useHistory();
  const authEmailContextValue = useContext(AuthEmailContext);
  const [front, setFront] = useState(true);

  useEffect(() => {
    return () => {
      if (forgotPasswordTokenMessage) {
        setForgotPasswordTokenMessage(null);
      }
    };
  }, []);

  return (
    <AuthContainer title="Reset password">
      {front ? (
        <>
          {forgotPasswordTokenMessage && (
            <StyledErrorMessage message={forgotPasswordTokenMessage} />
          )}
          <ForgotPasswordPartial
            backToLogin={(email: string) => {
              authEmailContextValue?.setAuthEmail(email);
              history.push("/login");
            }}
            forgotPasswordAnswerHandler={(email: string) => {
              authEmailContextValue?.setAuthEmail(email);
              setFront(false);
            }}
            savedEmail={authEmailContextValue?.authEmail || ""}
          />
        </>
      ) : (
        <ForgotPasswordAnswerPartial
          backToLogin={() => {
            history.push("/login");
          }}
          savedEmail={authEmailContextValue?.authEmail || ""}
        />
      )}
    </AuthContainer>
  );
};

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 0;
  margin-bottom: ${(props) => props.theme.spacing2};
`;

export default ForgotPassword;
