import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const NavigationText = ({ children, className }: Props): JSX.Element => {
  return <StyledText className={className}>{children}</StyledText>;
};

const StyledText = styled.span`
  font-size: ${(props) => props.theme.navigationTextSize};
  font-family: "DM Sans Medium", sans-serif;
  font-weight: 400;
`;

export default NavigationText;
