import React from "react";
import NUTRIENT_INGREDIENT_UNITS_SHORT from "../../../../../../constants/ingredients/nutrientIngredientUnitsShort";
import calculateNutrientAmountWeight from "../../../../../../services/calculateNutrientAmountWeight";
import {
  DefaultWeightUnits,
  IngredientDetailed,
  NutrientIngredientType,
} from "../../../../../../types/ingredientTypes";
import {
  NutrientsStandard,
  NutrientStandardType,
} from "../../../../../../types/standardTypes";
import NutrientRow from "./NutrientRow";

interface Props {
  nutrientStandard: NutrientStandardType;
  nutrientIngredient1: NutrientIngredientType;
  nutrientIngredient2: NutrientIngredientType;
  ingredients: IngredientDetailed[];
  nutrientReqsMin: NutrientsStandard;
  nutrientReqsMax: NutrientsStandard;
}

const NutrientRowSum = ({
  nutrientStandard,
  nutrientIngredient1,
  nutrientIngredient2,
  ingredients,
  nutrientReqsMin,
  nutrientReqsMax,
}: Props): JSX.Element => {
  const unitShort = NUTRIENT_INGREDIENT_UNITS_SHORT[nutrientIngredient1];

  // Total in meal
  const removedHiddenIngredients = ingredients.filter((ingredient) => {
    return ingredient.show;
  });

  const values: number[] = removedHiddenIngredients.map((ingredient) => {
    const value1 = ingredient[nutrientIngredient1];
    const value2 = ingredient[nutrientIngredient2];
    let adjustedValue1 = 0;
    let adjustedValue2 = 0;

    if (value1 === null) {
      adjustedValue1 = 0;
    } else if (ingredient.defaultUnit !== "gram") {
      adjustedValue1 = value1 * ingredient.amount;
    } else {
      adjustedValue1 = calculateNutrientAmountWeight(
        ingredient.unit as DefaultWeightUnits,
        ingredient.amount,
        value1
      );
    }

    if (value2 === null) {
      adjustedValue2 = 0;
    } else if (ingredient.defaultUnit !== "gram") {
      adjustedValue2 = value2 * ingredient.amount;
    } else {
      adjustedValue2 = calculateNutrientAmountWeight(
        ingredient.unit as DefaultWeightUnits,
        ingredient.amount,
        value2
      );
    }

    return adjustedValue1 + adjustedValue2;
  });

  const totalInMeal = values.reduce((total, number) => total + number, 0);

  return (
    <NutrientRow
      nutrientStandard={nutrientStandard}
      nutrientReqsMin={nutrientReqsMin}
      nutrientReqsMax={nutrientReqsMax}
      totalInMeal={totalInMeal}
      unitShort={unitShort}
    />
  );
};

export default NutrientRowSum;
