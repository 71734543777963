import {
  DefaultWeightUnits,
  IngredientDetailed,
  NutrientIngredientType,
} from "../../types/ingredientTypes";
import calculateNutrientAmountWeight from "../calculateNutrientAmountWeight";

const amountNutrientInMeal = (
  nutrient: NutrientIngredientType,
  ingredients: IngredientDetailed[]
): number => {
  const removedHiddenIngredients = ingredients.filter((ingredient) => {
    return ingredient.show;
  });

  const values: number[] = removedHiddenIngredients.map((ingredient) => {
    const value = ingredient[nutrient];
    let adjustedValue = 0;

    if (value === null) {
      adjustedValue = 0;
    } else if (ingredient.defaultUnit !== "gram") {
      adjustedValue = value * ingredient.amount;
    } else {
      adjustedValue = calculateNutrientAmountWeight(
        ingredient.unit as DefaultWeightUnits,
        ingredient.amount,
        value
      );
    }

    return adjustedValue;
  });

  const nutrientInMeal = values.reduce((total, number) => total + number, 0);

  return nutrientInMeal;
};

export default amountNutrientInMeal;
