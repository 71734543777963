import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import VerificationTokenMessageContext from "../../../../contexts/verificationTokenMessageContext/VerificationTokenMessageContext";
import useGetAuthUser from "../../../../hooks/api/useGetAuthUser";
import useGetLoginStatus from "../../../../hooks/api/useGetLoginStatus";
import useLogout from "../../../../hooks/api/useLogout";
import AuthContainer from "../../../shared/auth/AuthContainer";
import VerifyPartial from "../../../shared/auth/VerifyPartial";
import ErrorMessage from "../../../shared/form/ErrorMessage";

const Verify = (): JSX.Element => {
  const loggedInStatusQuery = useGetLoginStatus();
  const authUserQuery = useGetAuthUser({
    enabled: loggedInStatusQuery?.data?.loggedIn,
  });
  const logoutMutation = useLogout();

  const verificationTokenMessageContextValue = useContext(
    VerificationTokenMessageContext
  );

  useEffect(() => {
    return () => {
      if (verificationTokenMessageContextValue?.verificationTokenMessage) {
        verificationTokenMessageContextValue.setVerificationTokenMessage("");
      }
    };
  }, []);

  if (loggedInStatusQuery.data && !loggedInStatusQuery.data.loggedIn) {
    return <Redirect to="/signup" />;
  }

  return (
    <AuthContainer title="Verify your email">
      {verificationTokenMessageContextValue?.verificationTokenMessage && (
        <StyledErrorMessage
          message={
            verificationTokenMessageContextValue.verificationTokenMessage
          }
        />
      )}
      <VerifyPartial
        signupHandler={() => {
          logoutMutation.mutate();
        }}
        savedEmail={authUserQuery.data?.email || ""}
      />
    </AuthContainer>
  );
};

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 0;
  margin-bottom: ${(props) => props.theme.spacing2};
`;

export default Verify;
