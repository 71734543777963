import { breedSizeType } from "../types/dogTypes";
import calculateWeeksSinceDate from "../utils/date/calculateWeeksSinceDate";

const checkIfPuppy = (birthDate: Date, breedSize: breedSizeType): boolean => {
  const ageWeeks = calculateWeeksSinceDate(birthDate);
  let isPuppy = false;
  switch (breedSize) {
    case "toy":
      if (ageWeeks < 35) {
        isPuppy = true;
      }
      break;
    case "small":
      if (ageWeeks < 52) {
        isPuppy = true;
      }
      break;
    case "medium":
      if (ageWeeks < 61) {
        isPuppy = true;
      }
      break;
    case "large":
      if (ageWeeks < 78) {
        isPuppy = true;
      }
      break;
    case "giant":
      if (ageWeeks < 104) {
        isPuppy = true;
      }
      break;
  }
  return isPuppy;
};

export default checkIfPuppy;
