import React from "react";
import styled from "styled-components";
import Heading3 from "../../../shared/text/headings/Heading3";
import Text from "../../../shared/text/Text";

const UspSection = (): JSX.Element => {
  return (
    <StyledContainer>
      <PinkContainer>
        <UspContainer>
          <StyledHeading3>Easy to use</StyledHeading3>
          <StyledText>
            We promise high usability and sleek design throughout our products.
            There is no need for lengthy instructions to get comfortable with
            the application. After signing up, you are ready to start creating
            recipes right away.
          </StyledText>
        </UspContainer>
        <UspContainer>
          <StyledHeading3>Transparent pricing</StyledHeading3>
          <StyledText>
            There is no upfront cost to start using Mealment. We offer a monthly
            subscription that you can cancel whenever you want. Of course, you
            get to try the application before committing.
          </StyledText>
        </UspContainer>
        <UspContainer>
          <StyledHeading3>Accessible from anywhere</StyledHeading3>
          <StyledText>
            The only thing we require from you is an Internet connection. We
            solve the rest. Mobile, desktop, Mac, Windows, Chrome, Firefox,
            Safari or maybe Microsoft Edge? No problem.
          </StyledText>
        </UspContainer>
      </PinkContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.section`
  margin-top: ${(props) => props.theme.spacing6};
  margin-left: ${(props) =>
    "calc(" + props.theme.spacing1 + " + env(safe-area-inset-left))"};
  margin-right: ${(props) =>
    "calc(" + props.theme.spacing1 + " + env(safe-area-inset-right))"};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-top: ${(props) => props.theme.spacing5};
  }
`;

const PinkContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props) => props.theme.mainWidth};
  box-sizing: border-box;
  background-color: ${(props) => props.theme.purple1};
  padding: ${(props) => props.theme.spacing3};
  border-radius: 2rem; // 32px
  display: flex;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    flex-direction: column;
  }
  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    padding-left: ${(props) => props.theme.spacing1};
    padding-right: ${(props) => props.theme.spacing1};
  }
`;

const UspContainer = styled.div`
  text-align: center;
  margin-right: ${(props) => props.theme.spacing3};

  &:last-child {
    margin-right: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-right: 0;
    margin-bottom: ${(props) => props.theme.spacing3};
  }
`;

const StyledHeading3 = styled(Heading3)`
  margin-bottom: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.purple4};
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.purple4};
`;

export default UspSection;
