import { IngredientMetaValues } from "../types/ingredientTypes";
import { kgOrLb } from "../types/userTypes";

const getDefaultUnitShort = (
  ingredient: IngredientMetaValues,
  kgOrLb: kgOrLb | null
): string => {
  let returnValue: string;
  if (ingredient.defaultUnit === "gram") {
    if (kgOrLb === "lb") {
      returnValue = "oz";
    } else {
      returnValue = "g";
    }
  } else if (ingredient.defaultUnitShort !== null) {
    returnValue = ingredient.defaultUnitShort;
  } else {
    returnValue = ingredient.defaultUnit;
  }

  return returnValue;
};

export default getDefaultUnitShort;
