import axios, { AxiosError } from "axios";
import { useContext } from "react";
import { useMutation, UseMutationResult } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import DogBannersContext from "../../contexts/dogBannersContext/DogBannersContext";
import useLogin from "./useLogin";

interface Input {
  forgotPasswordToken: string;
  data: { password: string };
}

interface Output {
  email: string;
  password: string;
}

const useChangePasswordToken = (): UseMutationResult<
  Output,
  AxiosError,
  Input
> => {
  const loginMutation = useLogin();

  const dogBannersContextValue = useContext(DogBannersContext);

  return useMutation(
    async (input) => {
      const response = await axios.patch<{ email: string }>(
        `${BACKEND_URL}/api/users/password/${input.forgotPasswordToken}`,
        input.data
      );
      return { email: response.data.email, password: input.data.password };
    },
    {
      onSuccess: (data) => {
        loginMutation.mutate(
          {
            email: data.email,
            password: data.password,
          },
          {
            onSuccess: () => {
              dogBannersContextValue &&
                dogBannersContextValue.setDogBanners([
                  "Your password was succesfully changed. Let's continue creating more awesome stuff for your dog.",
                ]);
            },
          }
        );
      },
    }
  );
};

export default useChangePasswordToken;
