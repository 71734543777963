import React from "react";
import styled from "styled-components";
import { IngredientDetailed } from "../../../../types/ingredientTypes";
import isValidUrl from "../../../../utils/isValidUrl";
import Modal from "../../../shared/Modal";
import StyledLink from "../../../shared/StyledLink";
import IntroductionText from "../../../shared/text/IntroductionText";
import Text from "../../../shared/text/Text";

interface Props {
  ingredient: IngredientDetailed | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const IngredientInfoModal = ({
  ingredient,
  open,
  setOpen,
}: Props): JSX.Element | null => {
  if (ingredient === null) {
    return null;
  }

  const title = ingredient.name;
  const category = ingredient.category;

  return (
    <Modal open={open} setOpen={setOpen} title={title} closable={true}>
      {category === "usda" && (
        <>
          <StyledIntroductionText>
            Nutritional data for this ingredient comes from The United States
            Department of Agriculture (USDA). See the link to the source below.
          </StyledIntroductionText>
          <ul>
            <li>
              <StyledText>
                <StyledStyledLink href={ingredient.source as string}>
                  USDA
                </StyledStyledLink>
              </StyledText>
            </li>
          </ul>
        </>
      )}

      {category === "rmb" && (
        <>
          <StyledIntroductionTextMargin>
            Nutritional data about this ingredient comes from The United States
            Department of Agriculture (USDA) in conjunction with data from books
            by Monica Segal.
          </StyledIntroductionTextMargin>
          <StyledTextMargin>
            This ingredient&apos;s nutritional data is derived from a
            combination of laboratory analysis and estimates from similar
            boneless ingredients. While we strive to provide accurate
            information, there may be some variance in the data. Please see the
            link to our sources for further information.
          </StyledTextMargin>
          <ul>
            <li>
              <StyledText>
                <StyledStyledLink href={ingredient.source as string}>
                  USDA
                </StyledStyledLink>
              </StyledText>
            </li>
            <li>
              <StyledText>
                <StyledStyledLink
                  href={
                    "https://www.monicasegal.com/K9Kitchen_The_Truth_Behind_The-Hype.html"
                  }
                >
                  K9 Kitchen by Monica Segal
                </StyledStyledLink>
              </StyledText>
            </li>
            <li>
              <StyledText>
                <StyledStyledLink
                  href={"https://www.monicasegal.com/Optimal_Nutrition.html"}
                >
                  Optimal Nutrition by Monica Segal
                </StyledStyledLink>
              </StyledText>
            </li>
          </ul>
        </>
      )}

      {category === "custom" && (
        <>
          <StyledIntroductionText>
            This ingredient is custom created. Mealment can, therefore, not
            guarantee the correctness of the nutritional data. Make sure to
            double-check the values.{" "}
            {ingredient.source !== null && "See the link to the source below."}
          </StyledIntroductionText>
          {ingredient.source !== null && (
            <ul>
              <li>
                <StyledText>
                  {isValidUrl(ingredient.source) && (
                    <StyledStyledLink href={ingredient.source}>
                      {ingredient.source}
                    </StyledStyledLink>
                  )}
                  {!isValidUrl(ingredient.source) && ingredient.source}
                </StyledText>
              </li>
            </ul>
          )}
        </>
      )}

      {category === "supplement" && (
        <>
          <StyledIntroductionText>
            Nutrional data comes from the link below.
          </StyledIntroductionText>

          <ul>
            <li>
              <StyledText>
                <StyledStyledLink href={ingredient.source as string}>
                  {ingredient.source as string}
                </StyledStyledLink>
              </StyledText>
            </li>
          </ul>
        </>
      )}
    </Modal>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  margin-top: ${(props) => props.theme.spacing2};
  margin-bottom: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.black4};
`;

const StyledIntroductionTextMargin = styled(StyledIntroductionText)`
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.black4};
`;

const StyledTextMargin = styled(StyledText)`
  margin-bottom: ${(props) => props.theme.spacing1};
`;

const StyledStyledLink = styled(StyledLink)`
  color: ${(props) => props.theme.black4};
  word-break: break-word;

  &:hover {
    color: ${(props) => props.theme.black3};
  }
  &:focus {
    outline-color: ${(props) => props.theme.black3};
    color: ${(props) => props.theme.black3};
  }
`;

export default IngredientInfoModal;
