import React, { useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check-box.svg";
import { allColors } from "../../../types/frontendTypes";

interface Props {
  label: string;
  value: boolean;
  changeHandler: (value: boolean) => void;
  id: string;

  color1: allColors;
  color2: allColors;
  checkboxColor: allColors;
  checkboxBorderColor: allColors;

  partialFocusHandler?: () => void;
  clickHandler?: () => void;
  keyDownHandler?: (e: React.KeyboardEvent) => void;
  readOnly?: boolean;
  refValue?: React.MutableRefObject<HTMLInputElement | null>;
  disabled?: boolean;
  className?: string;

  register?: UseFormRegisterReturn;
}

const StyledCheckbox = ({
  label,
  value,
  changeHandler,
  id,

  color1,
  color2,
  checkboxColor,
  checkboxBorderColor,

  partialFocusHandler,
  clickHandler,
  keyDownHandler,
  readOnly,
  refValue,
  disabled,
  className,

  register,
}: Props): JSX.Element => {
  const [focus, setFocus] = useState(false);

  return (
    <div>
      <InnerContainer>
        <StyledInput
          type="checkbox"
          id={id}
          onChange={(e) => {
            changeHandler(e.target.checked);
            if (register) {
              register.onChange(e);
            }
          }}
          onFocus={() => {
            setFocus(true);
            partialFocusHandler?.();
          }}
          onBlur={(e) => {
            setFocus(false);
            if (register) {
              register.onBlur(e);
            }
          }}
          disabled={disabled}
          onClick={clickHandler}
          onKeyDown={keyDownHandler}
          readOnly={readOnly}
          ref={refValue || register?.ref}
          name={register?.name}
        />
        <StyledLabel
          htmlFor={id}
          focus={focus}
          color1={color1}
          color2={color2}
          checkboxColor={checkboxColor}
          checkboxBorderColor={checkboxBorderColor}
          className={className}
          disabled={disabled || false}
        >
          {label}
          <StyledCheckIcon
            checked={value}
            color={checkboxColor}
            role="img"
            aria-hidden="true"
          />
        </StyledLabel>
      </InnerContainer>
    </div>
  );
};

const StyledInput = styled.input`
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: auto;
  left: -10000px;
`;

const StyledLabel = styled.label<{
  focus: boolean;
  color1: allColors;
  color2: allColors;
  checkboxColor: allColors;
  checkboxBorderColor: allColors;
  disabled: boolean;
}>`
  font-size: ${(props) => props.theme.buttonTextSize};
  font-family: "DM Sans Regular", sans-serif;

  line-height: ${(props) => props.theme.textLineHeight};
  font-weight: 400;
  color: ${(props) =>
    props.disabled ? props.theme.black3 : props.checkboxColor};
  width: 100%;
  display: inline-block;
  position: relative;
  padding-left: 3rem;
  transition: color ${(props) => props.theme.transitionValue};
  word-break: break-word;

  &::before {
    box-sizing: border-box;
    content: "";
    background-color: ${(props) =>
      props.disabled ? props.theme.white : props.color1};
    border-radius: 0.5rem; // 8px
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${(props) =>
      props.focus && !props.disabled && props.color2};

    border-width: 0.125rem; // 2px
    border-radius: 0.5rem; // 8px
    border-style: solid;
    border-color: ${(props) =>
      props.disabled ? props.theme.black1 : props.checkboxBorderColor};
  }
  &:hover::before {
    background-color: ${(props) => !props.disabled && props.color2};
  }

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.buttonTextSizeMobile};
  }
`;

const InnerContainer = styled.div`
  display: flex;
`;

const StyledCheckIcon = styled(CheckIcon)<{
  checked: boolean;
  color: allColors;
}>`
  stroke: ${(props) => props.color};
  display: ${(props) => (props.checked ? "block" : "none")};
  width: ${(props) => props.checked && "2rem"};
  height: ${(props) => props.checked && "2rem"};
  position: ${(props) => props.checked && "absolute"};
  top: ${(props) => props.checked && 0};
  left: ${(props) => props.checked && 0};
`;

export default StyledCheckbox;
