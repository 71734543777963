import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { BACKEND_URL } from "../../constants/constants";

const useDeleteRecipe = (
  dogId: string | undefined | null
): UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (recipeId: string) => {
      if (dogId) {
        await axios.delete(`${BACKEND_URL}/api/recipes/${recipeId}`);
      }
    },
    {
      onSuccess: (_data, recipeId) => {
        queryClient.invalidateQueries(["recipes", "dogs", dogId], {
          exact: true,
        });
        queryClient.setQueryData(["recipes", recipeId], undefined);
        queryClient.invalidateQueries(["recipes"], {
          exact: true,
        });
      },
    }
  );
};

export default useDeleteRecipe;
