import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import { UserLoginSignup } from "../../types/userTypes";

const useSignup = (): UseMutationResult<void, AxiosError, UserLoginSignup> => {
  return useMutation(async (body) => {
    await axios.post<void>(`${BACKEND_URL}/api/users`, body);
  });
};

export default useSignup;
