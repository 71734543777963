import React, { useState } from "react";
import styled from "styled-components";
import ButtonText from "../../../shared/text/ButtonText";
import WrapLink from "../../../shared/WrapLink";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash-2.svg";
import { IngredientFull } from "../../../../types/ingredientTypes";
import DeleteModalIngredient from "./DeleteModalIngredient";

interface Props {
  ingredient: IngredientFull;
}

const ActionBarPartIngredient = ({ ingredient }: Props): JSX.Element => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <>
      <DeleteModalIngredient
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        ingredientId={ingredient.id}
      />
      <Container>
        <ButtonContainer
          asButton={true}
          clickHandler={() => setDeleteModalOpen(true)}
        >
          <ButtonIcon />
          <StyledButtonText>Delete ingredient</StyledButtonText>
        </ButtonContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.purple1};
  border-radius: 2rem; // 32px
  padding: ${(props) => props.theme.spacing2};
  margin-top: ${(props) => props.theme.spacing3};
  display: flex;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    flex-direction: column;
    align-items: center;
  }
`;

const ButtonContainer = styled(WrapLink)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.purple4};
  text-decoration: none;
  margin-right: ${(props) => props.theme.spacing3};

  &:last-child {
    margin-right: 0;
  }
  &:focus {
    outline-color: ${(props) => props.theme.purple4};
  }
  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-right: 0;

    &:not(:last-child) {
      margin-bottom: ${(props) => props.theme.spacing2};
    }
  }
`;

const ButtonIcon = styled(TrashIcon)`
  width: ${(props) => props.theme.spacing2};
  min-width: ${(props) => props.theme.spacing2};
  height: ${(props) => props.theme.spacing2};
  min-height: ${(props) => props.theme.spacing2};
  margin-right: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.purple4};
`;

const StyledButtonText = styled(ButtonText)`
  word-break: break-word;
`;

export default ActionBarPartIngredient;
