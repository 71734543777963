const NUTRIENT_INGREDIENT_UNITS_SHORT = {
  // Calories
  calories: "kcal",

  // Macronutrients
  protein: "g",
  fat: "g",
  carbohydrate: "g",
  water: "g",
  ash: "g",

  // Amino acids
  tryptophan: "g",
  threonine: "g",
  isoleucine: "g",
  leucine: "g",
  lysine: "g",
  methionine: "g",
  cystine: "g",
  phenylalanine: "g",
  tyrosine: "g",
  valine: "g",
  arginine: "g",
  histidine: "g",

  // Fatty acids
  linoleicAcid: "g",
  alphaLinoleicAcid: "g",
  arachidonicAcid: "g",
  epa: "g",
  dha: "g",

  // Carbohydrates
  fiber: "g",

  // Minerals
  calcium: "mg",
  iron: "mg",
  magnesium: "mg",
  phosphorus: "mg",
  potassium: "mg",
  chloride: "mg",
  sodium: "mg",
  zinc: "mg",
  copper: "mg",
  manganese: "mg",
  selenium: "μg",
  iodine: "μg",

  // Vitamins
  thiaminB1: "mg",
  riboflavinB2: "mg",
  niacinB3: "mg",
  pantothenicAcidB5: "mg",
  vitaminB6: "mg",
  folicAcidB9: "μg",
  choline: "mg",
  vitaminB12: "μg",
  vitaminA: "μg",
  vitaminE: "mg",
  cholecalciferolD3: "μg",
  phylloquinoneK: "μg",
};

export default NUTRIENT_INGREDIENT_UNITS_SHORT;
