import {
  maxNumberOfDogsPro,
  maxNumberOfDogsPlus,
  maxNumberOfDogsBasic,
} from "../constants/constants";
import { subscriptionType } from "../types/userTypes";

const getAllowedNumberOfDogs = (subscription: subscriptionType): number => {
  switch (subscription) {
    case "basic":
      return maxNumberOfDogsBasic;
      break;
    case "plus":
      return maxNumberOfDogsPlus;
      break;
    case "pro":
      return maxNumberOfDogsPro;
      break;
  }
};

export default getAllowedNumberOfDogs;
