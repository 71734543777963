import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { BACKEND_URL } from "../../constants/constants";

const useChangeSelectedDog = (): UseMutationResult<
  void,
  AxiosError,
  { selectedDog: string | null }
> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      await axios.patch(`${BACKEND_URL}/api/users/selected-dog`, data);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(["loggedInUser"], { exact: true });
      },
    }
  );
};

export default useChangeSelectedDog;
