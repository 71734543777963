import React from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import useGetAuthUser from "../../../hooks/api/useGetAuthUser";
import useGetLoginStatus from "../../../hooks/api/useGetLoginStatus";
import useSendVerificationEmail from "../../../hooks/api/useSendVerificationEmail";
import Text from "../text/Text";
import StyledLink from "../StyledLink";

interface Props {
  signupHandler: () => void;
  savedEmail: string;
  className?: string;
}

const VerifyPartial = ({
  signupHandler,
  savedEmail,
  className,
}: Props): JSX.Element => {
  const sendVerificationEmailMutation = useSendVerificationEmail();
  const loggedInStatusQuery = useGetLoginStatus();
  const authUserQuery = useGetAuthUser({
    enabled: loggedInStatusQuery?.data?.loggedIn,
  });

  if (authUserQuery.data && authUserQuery.data.verified) {
    return <Redirect to="/dogs" />;
  }

  return (
    <div className={className}>
      <StyledText>
        To use Mealment, you need to validate your email. We sent an
        verification link to <b>{savedEmail}</b>.
      </StyledText>
      <StyledText>
        Didn&apos;t received any email in your inbox or spam folder?{" "}
        <StyledStyledLink
          asButton={true}
          asButtonAsA={true}
          clickHandler={() => {
            sendVerificationEmailMutation.mutate(savedEmail);
          }}
        >
          Resend the email
        </StyledStyledLink>
        .
      </StyledText>
      <StyledText>
        Entered the wrong email?{" "}
        <StyledStyledLink
          asButton={true}
          asButtonAsA={true}
          clickHandler={signupHandler}
        >
          Sign up for a new account
        </StyledStyledLink>{" "}
        with a different email.
      </StyledText>
    </div>
  );
};

const StyledText = styled(Text)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing1};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledStyledLink = styled(StyledLink)`
  color: ${(props) => props.theme.black4};

  &:hover {
    color: ${(props) => props.theme.black3};
  }
  &:focus {
    outline-color: ${(props) => props.theme.black3};
    color: ${(props) => props.theme.black3};
  }
`;

export default VerifyPartial;
