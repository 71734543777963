import React from "react";
import styled from "styled-components";
import HeadingBase from "./HeadingBase";

interface Props {
  children: React.ReactNode;
  asValue?: "h1" | "h2" | "h3" | "h4" | "h5";
  className?: string;
}

const Heading3 = ({ children, asValue, className }: Props): JSX.Element => {
  return (
    <StyledHeading3 asValue={asValue || "h3"} className={className}>
      {children}
    </StyledHeading3>
  );
};

const StyledHeading3 = styled(HeadingBase)`
  font-size: ${(props) => props.theme.heading3Size};

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.heading3SizeMobile};
  }
`;

export default Heading3;
