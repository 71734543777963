import React, { useContext, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import {
  ALREADY_USED_VERIFICATION_MESSAGE,
  POSITIVE_VERIFICATION_MESSAGE,
} from "../../../../constants/constants";
import VerificationTokenMessageContext from "../../../../contexts/verificationTokenMessageContext/VerificationTokenMessageContext";
import useGetAuthUser from "../../../../hooks/api/useGetAuthUser";
import useGetLoginStatus from "../../../../hooks/api/useGetLoginStatus";
import useVerifyEmail from "../../../../hooks/api/useVerifyEmail";

const VerifyEmailSpecific = (): JSX.Element => {
  const [redirectTo, setRedirectTo] = useState<string | null>(null);

  interface PathVars {
    verifyToken: string;
  }
  const { verifyToken } = useParams<PathVars>();

  const verificationTokenMessageContextValue = useContext(
    VerificationTokenMessageContext
  );

  const mutationVerifyEmail = useVerifyEmail();

  const loggedInStatusQuery = useGetLoginStatus({
    onSuccess: (dataLoggedIn) => {
      mutationVerifyEmail.mutate(verifyToken, {
        onError: (error) => {
          if (error?.response?.data.error === "Expired") {
            verificationTokenMessageContextValue &&
              verificationTokenMessageContextValue.setVerificationTokenMessage(
                "Your verification token has expired."
              );
          } else if (error?.response?.data.error === "Invalid") {
            verificationTokenMessageContextValue &&
              verificationTokenMessageContextValue.setVerificationTokenMessage(
                "Your verification token is invalid."
              );
          } else if (error?.response?.data.error === "Used") {
            verificationTokenMessageContextValue &&
              verificationTokenMessageContextValue.setVerificationTokenMessage(
                ALREADY_USED_VERIFICATION_MESSAGE
              );
            if (dataLoggedIn.loggedIn) {
              setRedirectTo("/dogs");
            } else {
              setRedirectTo("/login");
            }
          }

          if (dataLoggedIn.loggedIn) {
            setRedirectTo("/verify");
          } else {
            setRedirectTo("/login");
          }
        },
        onSuccess: () => {
          verificationTokenMessageContextValue &&
            verificationTokenMessageContextValue.setVerificationTokenMessage(
              POSITIVE_VERIFICATION_MESSAGE
            );

          if (!dataLoggedIn.loggedIn) {
            setRedirectTo("/login");
          }
        },
      });
    },
  });

  useGetAuthUser({
    enabled:
      !!loggedInStatusQuery.data?.loggedIn && mutationVerifyEmail.isSuccess,
    onSuccess: () => {
      if (loggedInStatusQuery.data?.loggedIn && mutationVerifyEmail.isSuccess) {
        setRedirectTo("/dogs");
      }
    },
  });

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return <div>Loading</div>;
};

export default VerifyEmailSpecific;
