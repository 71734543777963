import React from "react";
import styled from "styled-components";
import Button from "../../../shared/Button";
import Modal from "../../../shared/Modal";
import IntroductionText from "../../../shared/text/IntroductionText";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MaxRecipesModal = ({ open, setOpen }: Props): JSX.Element => {
  return (
    <Modal
      title="Reached recipe limit on current plan"
      open={open}
      setOpen={setOpen}
      closable={true}
    >
      <StyledIntroductionText>
        Reached maximum number of recipes allowed on current plan. To add more
        recipes, upgrade to a plan with a higher limit.
      </StyledIntroductionText>

      <ButtonContainer>
        <StyledButton asLink={true} to="/pricing">
          Go to pricing page
        </StyledButton>
      </ButtonContainer>
    </Modal>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};
  margin-top: ${(props) => props.theme.spacing2};
`;

const ButtonContainer = styled.div``;

const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.purple2};
  color: ${(props) => props.theme.purple4};
  display: block;
  text-align: center;

  &:hover {
    background-color: ${(props) => props.theme.purple1};
  }
  &:focus {
    outline-color: ${(props) => props.theme.purple1};
    background-color: ${(props) => props.theme.purple1};
  }
`;

export default MaxRecipesModal;
