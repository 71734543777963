import React, { useState } from "react";
import styled from "styled-components";
import useCreateStripePortal from "../../../../hooks/api/useCreateStripePortal";
import useGetAuthUser from "../../../../hooks/api/useGetAuthUser";
import useLogout from "../../../../hooks/api/useLogout";
import Button from "../../../shared/Button";
import LabelForm from "../../../shared/form/LabelForm";
import NoInteractionInputText from "../../../shared/form/noInteraction/NoInteractionInputText";
import Heading2 from "../../../shared/text/headings/Heading2";
import IntroductionText from "../../../shared/text/IntroductionText";
import ChangePasswordModal from "./ChangePasswordModal";
import DeleteAccountModal from "./DeleteAccountModal";

const MyAccount = (): JSX.Element | null => {
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);

  const authUserQuery = useGetAuthUser();
  const logoutMutation = useLogout();
  const createStripePortalMutation = useCreateStripePortal();

  if (authUserQuery.data === undefined) {
    return null;
  }

  return (
    <>
      <ChangePasswordModal
        open={changePasswordModalOpen}
        setOpen={setChangePasswordModalOpen}
      />
      <DeleteAccountModal
        open={deleteAccountModalOpen}
        setOpen={setDeleteAccountModalOpen}
      />

      <StyledIntroductionText>
        View and manage your account details.
      </StyledIntroductionText>

      <StyledHeading2>Email</StyledHeading2>
      <StyledLabel name="email">Email</StyledLabel>
      <StyledNoInteractionInputText
        id="email"
        value={authUserQuery.data.email}
      />

      <StyledHeading2>Password</StyledHeading2>
      <PurpleButton eventHandler={() => setChangePasswordModalOpen(true)}>
        Change password
      </PurpleButton>

      <StyledHeading2>Subscription</StyledHeading2>
      <PurpleButton
        eventHandler={() => {
          createStripePortalMutation.mutate();
        }}
      >
        Manage subscription
      </PurpleButton>

      <StyledHeading2>Account</StyledHeading2>
      <SignOutButton eventHandler={() => logoutMutation.mutate()}>
        Sign out
      </SignOutButton>
      <PurpleButton eventHandler={() => setDeleteAccountModalOpen(true)}>
        Delete account
      </PurpleButton>
    </>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.black4};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: ${(props) => props.theme.spacing2};
  }
`;

const StyledHeading2 = styled(Heading2)`
  margin-bottom: ${(props) => props.theme.spacing2};
  margin-top: ${(props) => props.theme.spacing3};
  color: ${(props) => props.theme.black4};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: ${(props) => props.theme.spacing1};
  }
`;

const StyledLabel = styled(LabelForm)`
  color: ${(props) => props.theme.black4};
  margin-bottom: 0.25rem; // 4px
`;

const StyledNoInteractionInputText = styled(NoInteractionInputText)`
  border-color: ${(props) => props.theme.black4};
  color: ${(props) => props.theme.black4};
`;

const PurpleButton = styled(Button)`
  background-color: ${(props) => props.theme.purple2};
  color: ${(props) => props.theme.purple4};

  &:hover {
    background-color: ${(props) => props.theme.purple1};
  }
  &:focus {
    background-color: ${(props) => props.theme.purple1};
    outline-color: ${(props) => props.theme.purple1};
  }
`;

const SignOutButton = styled(PurpleButton)`
  margin-bottom: ${(props) => props.theme.spacing2};
  display: block;
`;

export default MyAccount;
