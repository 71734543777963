import React from "react";
import { Redirect } from "react-router-dom";
import useGetAuthUser from "../../hooks/api/useGetAuthUser";
import useGetLoginStatus from "../../hooks/api/useGetLoginStatus";

const AuthLogic = (): JSX.Element | null => {
  const loggedInStatusQuery = useGetLoginStatus();
  const authUserQuery = useGetAuthUser({
    enabled: !!loggedInStatusQuery?.data?.loggedIn,
  });

  if (
    loggedInStatusQuery.data?.loggedIn &&
    authUserQuery.data &&
    !authUserQuery.data.verified &&
    window.location.pathname !== "/verify"
  ) {
    return <Redirect to="/verify" />;
  } else if (
    loggedInStatusQuery.data?.loggedIn &&
    authUserQuery.data &&
    authUserQuery.data.verified
  ) {
    return <Redirect to="/dogs" />;
  }

  return null;
};

export default AuthLogic;
