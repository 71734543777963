import React, { useRef, useState } from "react";
import styled from "styled-components";
import InputButtons from "./InputButtons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useEditableLogic from "../../../../hooks/useEditableLogic";
import CustomizedDatePicker from "../styledDatePicker/CustomizedDatePicker";
import theme from "../../../../style/theme";

interface Props {
  id: string;
  serverValue: Date | undefined;
  onSave: (value: Date) => void;
  minDate?: Date;
  className?: string;
}

const EditableDatePicker = ({
  id,
  serverValue,
  onSave,
  minDate,
  className,
}: Props): JSX.Element => {
  const [dateValue, setDateValue] = useState<Date | null>(null);

  const datePickerRef = useRef<DatePicker<string, boolean | undefined> | null>(
    null
  );

  const { editing, setEditing, saveEdit, cancelEdit, handleKeys } =
    useEditableLogic<Date>(
      serverValue,
      dateValue,
      setDateValue,
      onSave,
      () => {
        datePickerRef.current?.setFocus();
      },
      (a, b) => a.getTime() === b.getTime(),
      (value) => value === null
    );

  return (
    <Container>
      <StyledDatePicker
        editing={editing}
        hasValue={!!serverValue}
        value={dateValue}
        setValue={setDateValue}
        id={id}
        minDate={minDate}
        className={className}
        refValue={datePickerRef}
        showIcon={editing}
        optionalArgs={{
          onInputClick: () => setEditing(true),
          onCalendarClose: () => {
            saveEdit();
          },
          onKeyDown: handleKeys,
          onFocus: () => setEditing(true),
        }}
        iconColor={theme.black4}
        noEditButton={!serverValue && !editing}
      />
      {!(!serverValue && !editing) && (
        <InputButtons
          cancelEdit={cancelEdit}
          editing={editing}
          saveEdit={saveEdit}
          setEditing={setEditing}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const StyledDatePicker = styled(CustomizedDatePicker)<{
  editing: boolean;
  hasValue: boolean;
  noEditButton: boolean;
}>`
  border-color: ${(props) =>
    props.editing || !props.hasValue ? props.theme.black4 : props.theme.black1};
  padding-right: ${(props) =>
    props.editing ? "12.75rem" : "4.9375rem"}; // 204px 79px
  color: ${(props) => props.theme.black4};
  padding-right: ${(props) => props.noEditButton && "2rem"}; // 32px
`;

export default EditableDatePicker;
