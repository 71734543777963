import adjustNutrientReqCalories from "../../../services/nutrientReqs/adjustNutrientReqCalories";
import { NutrientsStandard } from "../../../types/standardTypes";

export const NUTRIENT_REQ_FEDIAF_EARLY_GROWTH_MIN: NutrientsStandard = {
  // Macronutrients
  protein: 62.5,
  fat: 21.25,
  carbohydrate: null,
  water: null,
  ash: null,

  // Amino acids
  tryptophan: 0.58,
  threonine: 2.03,
  isoleucine: 1.63,
  leucine: 3.23,
  lysine: 2.2,
  methionine: 0.88,
  methionineAndCystine: 1.75,
  phenylalanine: 1.63,
  phenylalanineAndTyrosine: 3.25,
  valine: 1.7,
  arginine: 2.04,
  histidine: 0.98,

  // Fatty acids
  linoleicAcid: 3.25,
  alphaLinoleicAcid: 0.2,
  arachidonicAcid: 0.075,
  linoleicToAlphaLinoleicAcids: 2.6,
  epaAndDha: 0.13,

  // Carbohydrates
  fiber: null,

  // Minerals
  calcium: 2500,
  iron: 22.0,
  magnesium: 100,
  phosphorus: 2250,
  calciumToPhosphorus: 1,
  potassium: 1100,
  chloride: 830,
  sodium: 550,
  zinc: 25.0,
  copper: 2.75,
  zincToCopper: 8.0,
  manganese: 1.4,
  selenium: 100.0,
  iodine: 380,

  // Vitamins
  thiaminB1: 0.45,
  riboflavinB2: 1.05,
  niacinB3: 3.4,
  pantothenicAcidB5: 3.0,
  vitaminB6: 0.3,
  folicAcidB9: 54.0,
  choline: 425.0,
  vitaminB12: 7.0,
  vitaminA: 375,
  vitaminE: 8.375,
  cholecalciferolD3: 3.45,
  phylloquinoneK: null,
};

const NUTRIENT_REQ_FEDIAF_EARLY_GROWTH_MAX: NutrientsStandard = {
  // Macronutrients
  protein: null,
  fat: null,
  carbohydrate: null,
  water: null,
  ash: null,

  // Amino acids
  tryptophan: null,
  threonine: null,
  isoleucine: null,
  leucine: null,
  lysine: 7.0,
  methionine: null,
  methionineAndCystine: null,
  phenylalanine: null,
  phenylalanineAndTyrosine: null,
  valine: null,
  arginine: null,
  histidine: null,

  // Fatty acids
  linoleicAcid: 16.25,
  alphaLinoleicAcid: null,
  arachidonicAcid: null,
  linoleicToAlphaLinoleicAcids: 16,
  epaAndDha: null,

  // Carbohydrates
  fiber: null,

  // Minerals
  calcium: 4000,
  iron: null,
  magnesium: null,
  phosphorus: null,
  calciumToPhosphorus: 1.6,
  potassium: null,
  chloride: null,
  sodium: null,
  zinc: null,
  copper: null,
  zincToCopper: null,
  manganese: null,
  selenium: null,
  iodine: null,

  // Vitamins
  thiaminB1: null,
  riboflavinB2: null,
  niacinB3: null,
  pantothenicAcidB5: null,
  vitaminB6: null,
  folicAcidB9: null,
  choline: null,
  vitaminB12: null,
  vitaminA: 30000,
  vitaminE: null,
  cholecalciferolD3: 20,
  phylloquinoneK: null,
};

export const getNutrientReqFediafEarlyGrowthMin = (
  calorieRequriement: number,
  protein: number
): NutrientsStandard => {
  let specificReq = NUTRIENT_REQ_FEDIAF_EARLY_GROWTH_MIN;
  const proteinDiff = protein - 225;
  if (proteinDiff > 0) {
    specificReq = {
      ...specificReq,
      arginine: (specificReq.arginine as number) + proteinDiff * 0.01,
    };
  }

  const adjustedNutrients = adjustNutrientReqCalories(
    specificReq,
    calorieRequriement
  );
  return adjustedNutrients;
};

export const getNutrientReqFediafEarlyGrowthMax = (
  calorieRequriement: number
): NutrientsStandard => {
  const adjustedNutrients = adjustNutrientReqCalories(
    NUTRIENT_REQ_FEDIAF_EARLY_GROWTH_MAX,
    calorieRequriement
  );
  return adjustedNutrients;
};
