import { breedSizeType } from "../types/dogTypes";
import { NutrientStandardCategory } from "../types/standardTypes";
import calculateWeeksSinceDate from "../utils/date/calculateWeeksSinceDate";
import checkIfPuppy from "./checkIfPuppy";

const getNutrientStandardCategory = (
  birthDate: Date,
  breedSize: breedSizeType,
  gestation: boolean,
  lactation: boolean
): NutrientStandardCategory => {
  // Handle gestation case
  if (gestation) {
    return "reproduction";
  }

  // Handle lactation case
  if (lactation) {
    return "reproduction";
  }

  // Handle puppy case
  const isPuppy = checkIfPuppy(birthDate, breedSize);
  if (isPuppy) {
    const ageWeeks = calculateWeeksSinceDate(birthDate);

    if (ageWeeks < 14) {
      return "puppy414";
    } else {
      return "puppyAfter14";
    }
  }

  return "adult";
};

export default getNutrientStandardCategory;
