import axios, { AxiosError } from "axios";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import { DogValues } from "../../types/dogTypes";

const useGetDog = (
  id: string | undefined | null,
  options?: UseQueryOptions<any, AxiosError<any>>
): UseQueryResult<DogValues, AxiosError> => {
  return useQuery<DogValues, AxiosError>(
    ["dogs", id],
    async () => {
      const responseDog = await axios.get<DogValues>(
        `${BACKEND_URL}/api/dogs/${id}`
      );
      responseDog.data.birthDate = new Date(responseDog.data.birthDate);
      if (responseDog.data.matingDate) {
        responseDog.data.matingDate = new Date(responseDog.data.matingDate);
      }
      if (responseDog.data.parturitionDate) {
        responseDog.data.parturitionDate = new Date(
          responseDog.data.parturitionDate
        );
      }
      return responseDog.data;
    },
    options
  );
};

export default useGetDog;
