import React from "react";
import styled from "styled-components";
import ButtonText from "./text/ButtonText";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";

interface Props {
  clickHandler: () => void;
  text: string;
  className?: string;
}

const PlusButton = ({ clickHandler, text, className }: Props): JSX.Element => {
  return (
    <StyledButton
      asValue={"button"}
      className={className}
      clickHandler={clickHandler}
    >
      <StyledPlusIcon />
      {text}
    </StyledButton>
  );
};

const StyledButton = styled(ButtonText)`
  background-color: ${(props) => props.theme.purple1};
  color: ${(props) => props.theme.purple4};
  border: none;
  padding: ${(props) => props.theme.spacing1} 2rem; // 32px
  border-radius: 1rem; // 16px
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  transition: background-color ${(props) => props.theme.transitionValue};

  &:hover {
    background-color: ${(props) => props.theme.purple2};
  }
  &:focus {
    background-color: ${(props) => props.theme.purple2};
    outline-width: 0.125rem; // 2px
    outline-style: solid;
    outline-offset: 0.125rem; // 2px
    outline-color: ${(props) => props.theme.purple2};
  }
  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    padding-top: ${(props) => props.theme.spacing1};
    padding-bottom: ${(props) => props.theme.spacing1};
    padding-left: ${(props) => props.theme.spacing2};
    padding-right: ${(props) => props.theme.spacing2};
    white-space: initial;
  }
`;

const StyledPlusIcon = styled(PlusIcon)`
  width: 1.5rem; // 24px
  height: 1.5rem; // 24px
  margin-right: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.purple1};
  border-radius: 100%;
  background-color: ${(props) => props.theme.purple3};
  padding: ${(props) => props.theme.spacing1};
  transition: color ${(props) => props.theme.transitionValue};

  ${StyledButton}:hover & {
    color: ${(props) => props.theme.purple2};
  }
  ${StyledButton}:focus & {
    color: ${(props) => props.theme.purple2};
  }
`;

export default PlusButton;
