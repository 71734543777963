import React from "react";
import styled from "styled-components";
import { IngredientSearch } from "../../../../types/ingredientTypes";
import ButtonText from "../../../shared/text/ButtonText";
import Text from "../../../shared/text/Text";
import WrapLink from "../../../shared/WrapLink";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import { ReactComponent as MinusIcon } from "../../../../assets/icons/minus.svg";
import Tag from "../../../shared/Tag";
import useRemoveRecipeIngredient from "../../../../hooks/api/useRemoveRecipeIngredient";
import useAddRecipeIngredient from "../../../../hooks/api/useAddRecipeIngredient";
import useGetAuthUser from "../../../../hooks/api/useGetAuthUser";

interface Props {
  ingredient: IngredientSearch;
  alreadyAdded: boolean;
  dogId: string;
  recipeId: string;
}

const SearchResultEntry = ({
  ingredient,
  alreadyAdded,
  dogId,
  recipeId,
}: Props): JSX.Element => {
  const authUserQuery = useGetAuthUser();

  const removeRecipeIngredientMutation = useRemoveRecipeIngredient(
    dogId,
    recipeId
  );
  const addRecipeIngredientMutation = useAddRecipeIngredient(dogId, recipeId);

  const customIngredient = ingredient.ownerId !== null;

  const handleClick = () => {
    if (alreadyAdded) {
      removeRecipeIngredientMutation.mutate({ ingredientId: ingredient.id });
    } else {
      if (!authUserQuery.data) {
        return;
      }

      let unitToUse: string | null = null;
      if (ingredient.defaultUnit === "gram") {
        unitToUse = authUserQuery.data.kgOrLb === "kg" ? "gram" : "ounce";
      } else {
        unitToUse = ingredient.defaultUnit;
      }

      addRecipeIngredientMutation.mutate({
        ingredientId: ingredient.id,
        amount: 0,
        show: true,
        unit: unitToUse,
      });
    }
  };

  return (
    <li>
      <StyledWrapLink asButton={true} clickHandler={handleClick}>
        <TextPart>
          <StyledText customIngredient={customIngredient}>
            {ingredient.name}
          </StyledText>
          {customIngredient && <CurrentTag>Custom</CurrentTag>}
        </TextPart>
        <ButtonPart>
          <StyledIcon as={alreadyAdded ? MinusIcon : PlusIcon} />
          <StyledButtonText>{alreadyAdded ? "Remove" : "Add"}</StyledButtonText>
        </ButtonPart>
      </StyledWrapLink>
    </li>
  );
};

const StyledWrapLink = styled(WrapLink)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  padding-top: ${(props) => props.theme.spacing2};
  padding-bottom: ${(props) => props.theme.spacing2};
  border-bottom: 0.125rem solid ${(props) => props.theme.black1}; // 2px
  outline-color: ${(props) => props.theme.black4};
`;

const TextPart = styled.div`
  display: flex;
  margin-right: ${({ theme }) => theme.spacing2};
  align-items: center;
`;

const StyledText = styled(Text)<{ customIngredient: boolean }>`
  color: ${(props) => props.theme.black4};
  margin-right: ${(props) => props.customIngredient && props.theme.spacing1};
  text-align: left;
  word-break: break-word;
`;

const CurrentTag = styled(Tag)`
  background-color: ${(props) => props.theme.purple1};
  color: ${(props) => props.theme.purple4};
`;

const ButtonPart = styled.div`
  display: flex;
`;

const StyledIcon = styled(PlusIcon)`
  width: 1.5rem; // 24px
  height: 1.5rem; // 24px
  min-width: 1.5rem; // 24px
  min-height: 1.5rem; // 24px
  margin-right: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.black4};
`;

const StyledButtonText = styled(ButtonText)`
  color: ${(props) => props.theme.black4};
  white-space: nowrap;
`;

export default SearchResultEntry;
