import { ENVIRONMENT } from "../constants/constants";
import useGetDateNow from "./api/useGetDateNow";

const useGetDateNowWrapper = (): Date => {
  const getDateNowQuery = useGetDateNow();

  if (ENVIRONMENT === "production") {
    return new Date();
  }

  if (getDateNowQuery.data === undefined) {
    return new Date();
  }

  return getDateNowQuery.data.date;
};

export default useGetDateNowWrapper;
