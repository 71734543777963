import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import useLeavingDogLogic from "../../hooks/useLeavingDogLogic";

interface Props {
  children: React.ReactNode;
  href?: string;
  className?: string;
  asButton?: boolean;
  clickHandler?: () => void;
  to?: string;
  targetBlank?: boolean;
  asTypeButton?: boolean;
  tabIndex?: number;
}

const WrapLink = ({
  href,
  children,
  className,
  asButton,
  clickHandler,
  to,
  targetBlank,
  asTypeButton,
  tabIndex,
}: Props): JSX.Element => {
  const leavingDogLogic = useLeavingDogLogic();
  const history = useHistory();

  return (
    <>
      {asButton ? (
        <StyledButton
          as={"button"}
          className={className}
          onClick={clickHandler}
          type={asTypeButton ? "button" : undefined}
          tabIndex={tabIndex}
        >
          {children}
        </StyledButton>
      ) : href ? (
        <StyledLink
          onClick={clickHandler}
          href={href}
          target="_blank"
          className={className}
          tabIndex={tabIndex}
        >
          {children}
        </StyledLink>
      ) : (
        <StyledInternalLink
          to={to ? to : "/"}
          target={targetBlank ? "_blank" : "_self"}
          className={className}
          onClick={(e) => {
            e.preventDefault();

            const callback = () => {
              history.push(to ? to : "/");
              clickHandler && clickHandler();
            };

            leavingDogLogic(callback, to ? to : "/");
          }}
          tabIndex={tabIndex}
        >
          {children}
        </StyledInternalLink>
      )}
    </>
  );
};

const StyledLink = styled.a`
  &:focus {
    outline-width: 0.125rem; // 2px
    outline-style: solid;
    outline-offset: 0.125rem; // 2px
  }
`;

const StyledInternalLink = styled(Link)`
  &:focus {
    outline-width: 0.125rem; // 2px
    outline-style: solid;
    outline-offset: 0.125rem; // 2px
  }
`;

const StyledButton = styled(StyledLink)`
  background-color: transparent;
  border: none;
  font-size: inherit;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  transition: background-color ${(props) => props.theme.transitionValue};
`;

export default WrapLink;
