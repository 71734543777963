import React, { useEffect } from "react";
import styled from "styled-components";
import useChangeRecipeProperty from "../../../../hooks/api/useChangeRecipeProperty";
import { RecipeFull, RecipeNoIdType } from "../../../../types/recipeTypes";
import EditableTextArea from "../../../shared/form/editable/EditableTextArea";
import urlSlug from "url-slug";
import { useHistory } from "react-router-dom";
import Heading1 from "../../../shared/text/headings/Heading1";
import IntroductionText from "../../../shared/text/IntroductionText";

interface Props {
  recipe: RecipeFull;
}

const TextPart = ({ recipe }: Props): JSX.Element => {
  const changeRecipePropertyMutation = useChangeRecipeProperty(
    recipe.dogId,
    recipe.id
  );

  const history = useHistory();

  useEffect(() => {
    history.replace(`/recipes/${recipe.id}/${urlSlug(recipe.name)}`);
  }, [recipe.name]);

  const saveValue = (value: string | undefined, property: RecipeNoIdType) => {
    changeRecipePropertyMutation.mutate({
      value,
      property,
    });
  };

  return (
    <>
      <Container>
        <label htmlFor="name" hidden>
          Recipe name
        </label>
        <Heading
          id={"name"}
          placeholder={"Add your recipe's name"}
          serverValue={recipe.name}
          onSave={(name) => saveValue(name, "name")}
          allowEmpty={false}
          disableEnter={false}
        />
        <StyledHeading1>{recipe.name}</StyledHeading1>
        <label htmlFor="description" hidden>
          Recipe description
        </label>
        <DescriptionContainer>
          <Description
            id={"description"}
            placeholder={"Add a recipe description (optional)"}
            serverValue={recipe.description}
            onSave={(description: string) => {
              if (description === "") {
                saveValue(undefined, "description");
              } else {
                saveValue(description, "description");
              }
            }}
            allowEmpty={true}
            disableEnter={true}
          />
          <StyledIntroductionText>{recipe.description}</StyledIntroductionText>
        </DescriptionContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  max-width: ${(props) => props.theme.halfWidth};
`;

const Heading = styled(EditableTextArea)`
  font-family: "Nunito Bold", sans-serif;
  line-height: ${(props) => props.theme.headingLineHeight};
  font-weight: 400;
  font-size: ${(props) => props.theme.heading1Size};

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.heading1SizeMobile};
  }
  @media print {
    display: none;
  }
`;

const StyledHeading1 = styled(Heading1)`
  color: ${(props) => props.theme.black4};
  display: none;

  @media print {
    display: block;
  }
`;

const DescriptionContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing2};
`;

const Description = styled(EditableTextArea)`
  font-size: ${(props) => props.theme.introductionTextSize};
  line-height: ${(props) => props.theme.textLineHeight};
  font-family: "DM Sans Medium", sans-serif;
  font-weight: 400;

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.introductionTextSizeMobile};
  }
  @media print {
    display: none;
  }
`;

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.black4};
  display: none;
  white-space: pre-wrap;

  @media print {
    display: block;
  }
`;

export default TextPart;
