import React, { useEffect, useState } from "react";
import Modal from "../../../../shared/Modal";
import IntroductionText from "../../../../shared/text/IntroductionText";
import styled from "styled-components";
import LabelForm from "../../../../shared/form/LabelForm";
import SimpleInputText from "../../../../shared/form/simple/SimpleInputText";
import Fuse from "fuse.js";
import {
  IngredientSearchWithNutrient,
  NutrientIngredientType,
} from "../../../../../types/ingredientTypes";
import useGetIngredientsSearchWithNutrient from "../../../../../hooks/api/useGetIngredientsSearchWithNutrient";
import NUTRIENT_INGREDIENT_NAMES from "../../../../../constants/ingredients/nutrientIngredientNames";
import Button from "../../../../shared/Button";
import TextBold from "../../../../shared/text/TextBold";
import Text from "../../../../shared/text/Text";
import NUTRIENT_INGREDIENT_UNITS_SHORT from "../../../../../constants/ingredients/nutrientIngredientUnitsShort";
import roundToDecimal from "../../../../../utils/roundToDecimal";
import useRemoveRecipeIngredient from "../../../../../hooks/api/useRemoveRecipeIngredient";
import useAddRecipeIngredient from "../../../../../hooks/api/useAddRecipeIngredient";
import { RecipeFull } from "../../../../../types/recipeTypes";
import ButtonText from "../../../../shared/text/ButtonText";
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plus.svg";
import { ReactComponent as MinusIcon } from "../../../../../assets/icons/minus.svg";
import WrapLink from "../../../../shared/WrapLink";
import useGetAuthUser from "../../../../../hooks/api/useGetAuthUser";

interface Props {
  nutrient: NutrientIngredientType | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  recipe: RecipeFull;
}

const NutrientFindIngredientModal = ({
  nutrient,
  open,
  setOpen,
  recipe,
}: Props): JSX.Element | null => {
  const [searchString, setSearchString] = useState<string | null>(null);
  const [searchResults, setSearchResults] = useState<
    Fuse.FuseResult<IngredientSearchWithNutrient>[] | null
  >(null);

  useEffect(() => {
    if (!open) {
      setSearchString(null);
    }
  }, [open]);

  useEffect(() => {
    if (searchString !== null && searchString.length > 2) {
      const result = fuse.search(searchString);
      result.length = Math.min(result.length, 100);
      setSearchResults(result);
    } else {
      setSearchResults(null);
    }
  }, [searchString]);

  const getIngredientsSearchQuery =
    useGetIngredientsSearchWithNutrient(nutrient);

  const authUserQuery = useGetAuthUser();

  const removeRecipeIngredientMutation = useRemoveRecipeIngredient(
    recipe.dogId,
    recipe.id
  );
  const addRecipeIngredientMutation = useAddRecipeIngredient(
    recipe.dogId,
    recipe.id
  );

  if (nutrient === null || !getIngredientsSearchQuery.data) {
    return null;
  }

  const options: Fuse.IFuseOptions<IngredientSearchWithNutrient> = {
    includeScore: true,
    keys: ["name"],
    isCaseSensitive: false,
    shouldSort: true,
    ignoreLocation: true,
  };

  const fuse = new Fuse(getIngredientsSearchQuery.data, options);

  const title = `Find nutrients with ${NUTRIENT_INGREDIENT_NAMES[
    nutrient
  ].toLowerCase()}`;

  let ingredientsToShow: IngredientSearchWithNutrient[];
  if (searchResults === null) {
    ingredientsToShow = getIngredientsSearchQuery.data;
    ingredientsToShow.sort((a, b) => {
      return b.nutrient - a.nutrient;
    });
    ingredientsToShow = ingredientsToShow.filter(
      (ingredient) => ingredient.defaultUnit === "gram"
    );
    // just take the top 200
    ingredientsToShow.length = Math.min(ingredientsToShow.length, 200);
  } else {
    ingredientsToShow = searchResults
      .map((result) => result.item)
      .filter((ingredient) => ingredient.defaultUnit === "gram");
  }

  const handleClick = (ingredientTooHandle: IngredientSearchWithNutrient) => {
    const alreadyAdded =
      recipe.ingredients.filter(
        (ingredientApp) => ingredientApp.id === ingredientTooHandle.id
      ).length > 0;

    if (alreadyAdded) {
      removeRecipeIngredientMutation.mutate({
        ingredientId: ingredientTooHandle.id,
      });
    } else {
      if (!authUserQuery.data) {
        return;
      }

      let unitToUse: string | null = null;
      if (ingredientTooHandle.defaultUnit === "gram") {
        unitToUse = authUserQuery.data.kgOrLb === "kg" ? "gram" : "ounce";
      } else {
        unitToUse = ingredientTooHandle.defaultUnit;
      }

      addRecipeIngredientMutation.mutate({
        ingredientId: ingredientTooHandle.id,
        amount: 0,
        show: true,
        unit: unitToUse,
      });
    }
  };

  return (
    <Modal open={open} setOpen={setOpen} title={title} closable={true}>
      <StyledIntroductionText>
        The table only includes ingredients with nutrients set per 100 g.
        Nutrients in the ingredients can affect other parts of the recipe. Make
        sure to review the entire recipe if you make any changes to it.
      </StyledIntroductionText>

      <StyledLabel name="searchString">Search for ingredients</StyledLabel>
      <StyledSimpleInput
        id="searchString"
        setValue={setSearchString}
        value={searchString}
      />

      <StyledTable role="grid">
        <thead role="presentation">
          <HeadRow role="row">
            <TableHead role="columnheader">
              <StyledTextBold>Name</StyledTextBold>
            </TableHead>
            <TableHead role="columnheader">
              <StyledTextBold>{`${NUTRIENT_INGREDIENT_NAMES[nutrient]} (${NUTRIENT_INGREDIENT_UNITS_SHORT[nutrient]}/100 g)`}</StyledTextBold>
            </TableHead>
            <TableHead role="columnheader">
              <StyledTextBoldNoMargin>Add</StyledTextBoldNoMargin>
            </TableHead>
          </HeadRow>
        </thead>
        <tbody role="presentation">
          {ingredientsToShow.map((ingredient) => (
            <tr key={ingredient.id} role="row">
              <DataCell>
                <IngredientName>{ingredient.name}</IngredientName>
              </DataCell>
              <DataCell>
                <StyledText>
                  {roundToDecimal(ingredient.nutrient, 2)}
                </StyledText>
              </DataCell>
              <DataCell>
                <ClickWrapper
                  asButton={true}
                  clickHandler={() => handleClick(ingredient)}
                >
                  <StyledIcon
                    as={
                      recipe.ingredients.filter(
                        (ingredientApp) => ingredientApp.id === ingredient.id
                      ).length > 0
                        ? MinusIcon
                        : PlusIcon
                    }
                  />
                  <StyledButtonText>
                    {recipe.ingredients.filter(
                      (ingredientApp) => ingredientApp.id === ingredient.id
                    ).length > 0
                      ? "Remove"
                      : "Add"}
                  </StyledButtonText>
                </ClickWrapper>
              </DataCell>
            </tr>
          ))}
        </tbody>
      </StyledTable>

      <PurpleButton
        eventHandler={() => {
          setOpen(false);
        }}
      >
        Done
      </PurpleButton>
    </Modal>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  margin-top: ${(props) => props.theme.spacing2};
  margin-bottom: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.black4};
`;

const StyledLabel = styled(LabelForm)`
  color: ${(props) => props.theme.black4};
  margin-bottom: 0.25rem; // 4px
`;

const StyledSimpleInput = styled(SimpleInputText)`
  border-color: ${(props) => props.theme.black4};
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const HeadRow = styled.tr`
  border-bottom: 0.125rem solid ${(props) => props.theme.black1};
`;

const TableHead = styled.th`
  text-align: left;
  padding: 0;
  padding-bottom: ${(props) => props.theme.spacing1};
`;

const StyledTextBold = styled(TextBold)`
  color: ${(props) => props.theme.black4};
  margin-right: ${(props) => props.theme.spacing1};
`;

const StyledTextBoldNoMargin = styled(StyledTextBold)`
  margin-right: 0;
`;

const DataCell = styled.th`
  padding: 0;
  text-align: left;
  padding-top: ${(props) => props.theme.spacing1};
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.black4};
  margin-right: ${(props) => props.theme.spacing1};
`;

const IngredientName = styled(StyledText)`
  word-break: break-word;
`;

const PurpleButton = styled(Button)`
  background-color: ${(props) => props.theme.purple2};
  color: ${(props) => props.theme.purple4};
  width: 100%;

  &:hover {
    background-color: ${(props) => props.theme.purple1};
  }
  &:focus {
    background-color: ${(props) => props.theme.purple1};
    outline-color: ${(props) => props.theme.purple1};
  }
`;

const StyledIcon = styled(PlusIcon)`
  width: 1.5rem; // 24px
  height: 1.5rem; // 24px
  min-width: 1.5rem; // 24px
  min-height: 1.5rem; // 24px
  margin-right: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.black4};
`;

const StyledButtonText = styled(ButtonText)`
  color: ${(props) => props.theme.black4};
  white-space: nowrap;
`;

const ClickWrapper = styled(WrapLink)`
  text-decoration: none;
  display: flex;
  align-items: center;

  &:focus {
    outline-color: ${(props) => props.theme.black4};
  }
`;

export default NutrientFindIngredientModal;
