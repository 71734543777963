import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import useChangeKgOrLb from "../../../../hooks/api/useChangeKgOrLb";
import useChangeSelectedDog from "../../../../hooks/api/useChangeSelectedDog";
import useGetAuthUser from "../../../../hooks/api/useGetAuthUser";
import useGetDogs from "../../../../hooks/api/useGetDogs";
import theme from "../../../../style/theme";
import SimpleSwitcher from "../../../shared/form/simple/SimpleSwitcher";
import PlusButton from "../../../shared/PlusButton";
import Heading2 from "../../../shared/text/headings/Heading2";
import IntroductionText from "../../../shared/text/IntroductionText";
import DeleteDogTable from "../../../shared/DeleteDogTable";
import getAllowedNumberOfDogs from "../../../../services/getAllowedNumberOfDogs";
import MaxDogsModal from "./MaxDogsModal";

const Preferences = (): JSX.Element | null => {
  const [maxDogModalOpen, setMaxDogsModalOpen] = useState(false);

  const authUserQuery = useGetAuthUser();
  const changeKgOrLbMutation = useChangeKgOrLb();
  const changeSelectedDogMutation = useChangeSelectedDog();
  const getDogsQuery = useGetDogs();

  const history = useHistory();

  if (
    authUserQuery.data === undefined ||
    authUserQuery.data.kgOrLb === null ||
    getDogsQuery.data === undefined
  ) {
    return null;
  }

  let maxDogs = false;
  if (authUserQuery.data.stripeSubscription) {
    const allowedNumberOfDogs = getAllowedNumberOfDogs(
      authUserQuery.data.stripeSubscription
    );
    maxDogs = getDogsQuery.data.length === allowedNumberOfDogs;
  }

  const newDog = () => {
    changeSelectedDogMutation.mutate(
      { selectedDog: null },
      {
        onSuccess: () => {
          history.push("/dogs");
        },
      }
    );
  };

  return (
    <>
      <MaxDogsModal open={maxDogModalOpen} setOpen={setMaxDogsModalOpen} />
      <StyledIntroductionText>
        Add and remove dogs, and update your weight unit preference.
      </StyledIntroductionText>
      <StyledHeading2>Weight units</StyledHeading2>
      <WeightUnitSwitcher
        changeHandler={() => {
          const newValue = authUserQuery.data.kgOrLb === "kg" ? "lb" : "kg";
          changeKgOrLbMutation.mutate({ kgOrLb: newValue });
        }}
        value={authUserQuery.data.kgOrLb}
        valueOption1={"kg"}
        valueOption2={"lb"}
        title="Kilograms or pounds"
        id="kgsOrLbs"
        labelOption1="Kilograms"
        labelOption2="Pounds"
        color1={theme.purple3}
        color2={theme.purple1}
        colorTitle={theme.black4}
      />

      <StyledHeading2>Dogs</StyledHeading2>
      <StyledPlusButton
        clickHandler={() => {
          if (maxDogs) {
            setMaxDogsModalOpen(true);
          } else {
            newDog();
          }
        }}
        text={"Add new dog"}
      />
      <DeleteDogTable />
    </>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  margin-bottom: ${(props) => props.theme.spacing3};
  color: ${(props) => props.theme.black4};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: ${(props) => props.theme.spacing2};
  }
`;

const StyledHeading2 = styled(Heading2)`
  margin-bottom: ${(props) => props.theme.spacing2};
  color: ${(props) => props.theme.black4};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: ${(props) => props.theme.spacing1};
  }
`;

// https://github.com/styled-components/styled-components/issues/1803#issuecomment-857092410
const WeightUnitSwitcher = styled(SimpleSwitcher)`
  margin-bottom: ${(props) => props.theme.spacing3};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: ${(props) => props.theme.spacing2};
  }
` as typeof SimpleSwitcher;

const StyledPlusButton = styled(PlusButton)`
  margin-bottom: ${(props) => props.theme.spacing3};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: ${(props) => props.theme.spacing2};
  }
`;
export default Preferences;
