import React from "react";
import styled from "styled-components";
import NUTRIENT_INGREDIENT_NAMES from "../../../../constants/ingredients/nutrientIngredientNames";
import NUTRIENT_INGREDIENT_UNITS_SHORT from "../../../../constants/ingredients/nutrientIngredientUnitsShort";
import theme from "../../../../style/theme";
import {
  IngredientFull,
  NutrientIngredientType,
} from "../../../../types/ingredientTypes";
import EditableNumber from "../../../shared/form/editable/EditableNumber";
import LabelForm from "../../../shared/form/LabelForm";

interface Props {
  ingredient: IngredientFull;
  nutrient: NutrientIngredientType;
  saveValue: (amount: number, nutrient: NutrientIngredientType) => void;
}

const NutrientEditable = ({
  ingredient,
  nutrient,
  saveValue,
}: Props): JSX.Element => {
  const nutrientServerValue = ingredient[nutrient];

  const nutrientServerValueNoNull =
    nutrientServerValue === null ? undefined : nutrientServerValue;

  return (
    <>
      <StyledLabel name={nutrient}>
        {NUTRIENT_INGREDIENT_NAMES[nutrient]} (
        {NUTRIENT_INGREDIENT_UNITS_SHORT[nutrient]})
      </StyledLabel>
      <EditableNumber
        serverValue={nutrientServerValueNoNull}
        id={nutrient}
        unit={NUTRIENT_INGREDIENT_UNITS_SHORT[nutrient]}
        colorWeightUnit={theme.black4}
        placeholder={`Specify ${NUTRIENT_INGREDIENT_NAMES[nutrient]} (optional)`}
        onSave={(value) => saveValue(value, nutrient)}
        allowEmpty={true}
        allowZero={true}
      />
    </>
  );
};

const StyledLabel = styled(LabelForm)`
  color: ${(props) => props.theme.black4};
  margin-top: ${(props) => props.theme.spacing2};
  margin-bottom: 0.25rem; // 4px

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-top: ${(props) => props.theme.spacing1};
  }
`;

export default NutrientEditable;
