import roundToDecimal from "../utils/roundToDecimal";

export const generateClipPathMobile = (): string[] => {
  const sinNumbers = ["0% 100%"];
  for (let i = 0; i <= 150; i++) {
    let secondNumber = Math.sin(((2 * Math.PI) / 150) * i + Math.PI);
    secondNumber = secondNumber * 2.5 + 2.5;

    const firstNumber = (i / 150) * 100;

    sinNumbers.push(
      `${roundToDecimal(firstNumber, 2)}% ${roundToDecimal(secondNumber, 2)}%`
    );
  }

  sinNumbers.push("100% 100%");

  return sinNumbers;
};

export const generateClipPath = (): string[] => {
  const sinNumbers = ["100% 0%"];
  for (let i = 0; i <= 150; i++) {
    let firstNumber = Math.sin(((2 * Math.PI) / 150) * i);
    firstNumber = firstNumber * 5 + 5;

    const secondNumber = (i / 150) * 100;

    sinNumbers.push(
      `${roundToDecimal(firstNumber, 2)}% ${roundToDecimal(secondNumber, 2)}%`
    );
  }

  sinNumbers.push("100% 100%");

  return sinNumbers;
};
