import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useEditableLogic from "../../../../hooks/useEditableLogic";
import { allColors } from "../../../../types/frontendTypes";
import InputButtons from "./InputButtons";
import UnitLabel from "../UnitLabel";

interface Props {
  id: string;
  placeholder: string;
  serverValue: number | undefined;
  onSave: (value: number) => void;
  unit?: string;
  colorWeightUnit?: allColors;
  noDecimals?: boolean;
  top?: number;
  tabIndex?: number;
  allowZero?: boolean;
  allowEmpty?: boolean;
  className?: string;
}

const EditableNumber = ({
  id,
  placeholder,
  serverValue,
  onSave,
  unit,
  colorWeightUnit,
  noDecimals,
  top,
  tabIndex,
  allowZero,
  allowEmpty,
  className,
}: Props): JSX.Element => {
  const [inputValue, setInputValue] = useState<number | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { editing, setEditing, saveEdit, cancelEdit, handleKeys } =
    useEditableLogic<number>(
      serverValue,
      inputValue,
      setInputValue,
      onSave,
      () => {
        inputRef.current?.focus();
      },
      (a, b) => a === b,
      (value: number) => Number.isNaN(value),
      allowEmpty
    );

  useEffect(() => {
    if (!editing) {
      inputRef.current?.blur();
    }
  }, [editing]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const valueAsNumber = e.target.valueAsNumber;

    if (value.length >= 15) {
      return;
    }

    let allowedAction = false;
    if (value === "") {
      allowedAction = true;
    } else if (valueAsNumber > 0) {
      allowedAction = true;
    } else if (allowZero && valueAsNumber === 0) {
      allowedAction = true;
    }

    if (allowedAction) {
      const setValue = noDecimals ? Math.round(valueAsNumber) : valueAsNumber;

      setInputValue(setValue);
    }
  };

  return (
    <Container>
      <StyledInput
        className={className}
        type={"number"}
        value={
          inputValue !== null && !Number.isNaN(inputValue) ? inputValue : ""
        }
        editing={editing}
        hasValue={serverValue !== undefined}
        id={id}
        placeholder={placeholder}
        onClick={() => setEditing(true)}
        onBlur={saveEdit}
        ref={inputRef}
        onChange={handleChange}
        onFocus={() => setEditing(true)}
        onKeyDown={handleKeys}
        min={0}
        noEditButton={serverValue === undefined && !editing}
        tabIndex={tabIndex}
      />
      {!(serverValue === undefined && !editing) && (
        <InputButtons
          cancelEdit={cancelEdit}
          editing={editing}
          saveEdit={saveEdit}
          setEditing={setEditing}
          top={top}
        />
      )}
      {unit && colorWeightUnit && (
        <UnitLabel unit={unit} value={inputValue} color={colorWeightUnit} />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const StyledInput = styled.input<{
  editing: boolean;
  hasValue: boolean;
  noEditButton: boolean;
}>`
  display: block;
  width: 100%;
  padding: 1rem 2rem; // 16px 32px
  box-sizing: border-box;
  border-width: 0.125rem; // 2px
  border-radius: 100rem;
  border-style: solid;
  border-color: ${(props) =>
    props.editing || !props.hasValue ? props.theme.black4 : props.theme.black1};
  font-size: ${(props) => props.theme.buttonTextSize};
  font-family: "DM Sans Regular", sans-serif;
  font-weight: 400;
  outline: none;
  text-overflow: ellipsis;
  padding-right: ${(props) =>
    props.editing ? "10.75rem" : "4.9375rem"}; // 172px 79px
  padding-right: ${(props) => props.noEditButton && "2rem"}; // 32px
  color: ${(props) => props.theme.black4};
  line-height: ${(props) => props.theme.componentLineHeight};
  appearance: none;

  &::placeholder {
    color: ${(props) => props.theme.black3};
  }
  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.buttonTextSizeMobile};
  }
  @media print {
    border: none;
    padding: 0;
  }
`;

export default EditableNumber;
