import React, { useRef } from "react";
import styled from "styled-components";
import Heading3 from "../../../shared/text/headings/Heading3";
import Text from "../../../shared/text/Text";
import Tag from "../../../shared/Tag";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CustomArrowButton from "./CustomArrowButton";

const responsive = {
  desktop: {
    breakpoint: { max: 300000, min: 990 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 990, min: 0 },
    items: 1,
  },
};

interface Props {
  current: boolean;
}

const FeatureRow = ({ current }: Props): JSX.Element | null => {
  const refCard = useRef<null | HTMLDivElement>(null);

  if (current) {
    return (
      <InnerContainer>
        <StyledCarousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={false}
          infinite={false}
          keyBoardControl={true}
          itemClass="carousel-item-overwrite"
          customLeftArrow={
            <CustomArrowButton
              right={false}
              onClick={() => {
                return;
              }}
              refCard={refCard}
            />
          }
          customRightArrow={
            <CustomArrowButton
              right={true}
              onClick={() => {
                return;
              }}
              refCard={refCard}
            />
          }
        >
          <CurrentCard ref={refCard}>
            <CurrentTag>Dogs</CurrentTag>
            <StyledHeading3>Add your dogs</StyledHeading3>
            <StyledText>
              Create a profile for your dogs with their weight and activity
              level. Automatically calculate your dogs&apos; calorie needs based
              on its profile or manually set it yourself. Select a nutritional
              standard for your dog to be used when calculating the nutritional
              values when formulating recipes.
            </StyledText>
          </CurrentCard>
          <CurrentCard>
            <CurrentTag>Standards</CurrentTag>
            <StyledHeading3>Choose standard</StyledHeading3>
            <StyledText>
              Using a nutritional standard takes the guesswork out of raw
              feeding by making sure that your dog hits all of its nutritional
              needs. Choose which nutritional standard that is the best fit for
              your dog. The Mealment application supports all three of the major
              standards: NRC, AAFCO and FEDIAF.
            </StyledText>
          </CurrentCard>
          <CurrentCard>
            <CurrentTag>Recipes</CurrentTag>
            <StyledHeading3>Create recipes</StyledHeading3>
            <StyledText>
              Create recipes using selected ingredients from the US Department
              of Agriculture (USDA) database and other sources. The application
              will automatically calculate the nutritional values of the recipe
              and compare it to the selected standard. Scale the recipe with a
              specified number of days.
              {/* If you have
              enabled ratio dieting for your dog, each ingredient has been
              annotated with its ratio category (including raw meaty bones). */}
            </StyledText>
          </CurrentCard>
          <CurrentCard>
            <CurrentTag>Recipes</CurrentTag>
            <StyledHeading3>Accessible nutritional data</StyledHeading3>
            <StyledText>
              Get all nutritional information that you need when formulating
              your recipes, based on your selected standard. The application
              helps you make sense of the information by categorizing and
              highlighting the most important parts.
            </StyledText>
          </CurrentCard>
          <CurrentCard>
            <CurrentTag>Ingredients</CurrentTag>
            <StyledHeading3>Add ingredients</StyledHeading3>
            <StyledText>
              If you can&apos;t find your ingredient among the default ones, you
              can easily add it yourself. Add meats, supplements, vegetables or
              whatever ingredient that you are missing and define its
              nutritional value. Define your own units for the ingredient or use
              the default ones.
              {/* If ratio dieting is enabled, the added
              ingredient can be annotated with its ratio category. */}
            </StyledText>
          </CurrentCard>
        </StyledCarousel>
      </InnerContainer>
    );
  } else {
    return (
      // Extra div reset state between switches
      <div>
        <InnerContainer>
          <StyledCarousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            ssr={false}
            infinite={false}
            keyBoardControl={true}
            itemClass="carousel-item-overwrite"
            customLeftArrow={
              <CustomArrowButton
                right={false}
                onClick={() => {
                  return;
                }}
                refCard={refCard}
              />
            }
            customRightArrow={
              <CustomArrowButton
                right={true}
                onClick={() => {
                  return;
                }}
                refCard={refCard}
              />
            }
          >
            <ComingCard>
              <ComingTag>Social</ComingTag>
              <StyledHeading3>Share your recipes</StyledHeading3>
              <StyledText>
                No more tedious work of screenshotting your recipe and its
                nutritional values. Sharing your created recipes is as simple as
                pressing a button to generate a public link for your recipe.
                It&apos;s just as simple to import recipes shared by other
                people.
              </StyledText>
            </ComingCard>
            <ComingCard>
              <ComingTag>Standards</ComingTag>
              <StyledHeading3>Add ratio diets</StyledHeading3>
              <StyledText>
                Do you primarily feed your dog a ratio diet such as BARF or PMR?
                No problem; the Mealment application supports ratio dieting for
                your dog. You can define the categories and their associated
                percentages yourself. This can, of course, be complemented with
                a nutritional standard.
              </StyledText>
            </ComingCard>
            <ComingCard ref={refCard}>
              <ComingTag>Coming</ComingTag>
              <StyledHeading3>Nutrient fulfilment</StyledHeading3>
              <StyledText>
                Make more informed choices when picking ingredients. If your
                recipe is lacking a nutrient, you get information on which
                ingredients it can be found in. Sort ingredients with regards to
                different nutrients and easily compare the nutritional value of
                different ingredients.
              </StyledText>
            </ComingCard>
            <ComingCard>
              <ComingTag>Coming</ComingTag>
              <StyledHeading3>Balancing over time</StyledHeading3>
              <StyledText>
                Have the option to balance your dog&apos;s meals over time and
                thus lessening the need for every single meal to be balanced.
                Your feeding history is saved in a calendar which you can use to
                plan your dog&apos;s future meals. Use the calendar to optimize
                the well-being of your dog.
              </StyledText>
            </ComingCard>
            <ComingCard>
              <ComingTag>Coming</ComingTag>
              <StyledHeading3>Additional pets</StyledHeading3>
              <StyledText>
                All pets, no matter the species, deserve a healthy and fresh
                diet. Create nutritionally balanced recipes for other pets than
                just dogs. Create profiles for your cats, rats, horses and other
                pets and choose a standard that is specific to that particular
                specie.
              </StyledText>
            </ComingCard>
            <ComingCard>
              <ComingTag>Coming</ComingTag>
              <StyledHeading3>Educational info</StyledHeading3>
              <StyledText>
                Getting into a nutritional approach to raw feeding can be
                overwhelming. Take our introduction guide to raw feeding and get
                the basic knowledge to start your journey. To further ease your
                raw feeding journey there are informational texts throughout the
                application.
              </StyledText>
            </ComingCard>
            <ComingCard>
              <ComingTag>Coming</ComingTag>
              <StyledHeading3>Dog profile</StyledHeading3>
              <StyledText>
                Set a weight goal for your dog and get help achieving it.
                Register allergies and sensitivities and get notified on issues
                when formulating recipes. Specify your dog&apos;s age and, if
                relevant, its pregnancy status and use this to calculate the
                dog&apos;s calorie needs.
              </StyledText>
            </ComingCard>
          </StyledCarousel>
        </InnerContainer>
      </div>
    );
  }
};

const InnerContainer = styled.div`
  max-width: ${(props) => "calc(" + props.theme.mainWidth + " + 32px)"};
  margin-left: auto;
  margin-right: auto;
  overflow: visible;
  height: fit-content;
  padding-left: ${(props) => props.theme.spacing1};
  padding-right: ${(props) => props.theme.spacing1};

  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    padding-left: ${(props) => props.theme.spacing3};
    padding-right: ${(props) => props.theme.spacing3};
  }
  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    max-width: ${"calc(300px + 40px)"};
  }
`;

const StyledCarousel = styled(Carousel)`
  overflow: visible;
`;

const Card = styled.div`
  min-height: 27.75rem; // 444px
  height: 100%;
  border-radius: 2rem; // 32px
  padding-top: 2rem; // 32px
  padding-bottom: 2rem; // 32px
  padding-right: ${(props) => props.theme.spacing3};
  padding-left: ${(props) => props.theme.spacing3};
  text-align: center;
  box-sizing: border-box;
  margin-right: ${(props) => props.theme.spacing1};
  margin-left: ${(props) => props.theme.spacing1};

  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    padding-top: ${(props) => props.theme.spacing2};
    padding-bottom: ${(props) => props.theme.spacing2};
    padding-right: ${(props) => props.theme.spacing1};
    padding-left: ${(props) => props.theme.spacing1};
  }

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    min-height: auto;
    max-width: 18.75rem; // 300px
  }
`;

const CurrentCard = styled(Card)`
  background-color: ${(props) => props.theme.green1};
`;

const ComingCard = styled(Card)`
  background-color: ${(props) => props.theme.black1};
`;

const CurrentTag = styled(Tag)`
  background-color: ${(props) => props.theme.green2};
  color: ${(props) => props.theme.green4};
  margin-bottom: ${(props) => props.theme.spacing1};
`;

const ComingTag = styled(Tag)`
  background-color: ${(props) => props.theme.black2};
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing1};
`;

const StyledHeading3 = styled(Heading3)`
  color: ${(props) => props.theme.green4};
  margin-bottom: ${(props) => props.theme.spacing1};
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.green4};
`;

export default FeatureRow;
