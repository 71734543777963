import React from "react";
import styled from "styled-components";

interface Props {
  value: string;
  id: string;
  className?: string;
}

const NoInteractionInputText = ({
  value,
  id,
  className,
}: Props): JSX.Element => {
  return (
    <StyledInput
      className={className}
      id={id}
      value={value}
      type={"text"}
      readOnly={true}
    />
  );
};

const StyledInput = styled.input`
  display: block;
  width: 100%;
  padding: 1rem 2rem; // 16px 32px
  box-sizing: border-box;
  border-width: 0.125rem; // 2px
  border-radius: 100rem;
  border-style: solid;
  font-size: ${(props) => props.theme.buttonTextSize};
  font-family: "DM Sans Regular", sans-serif;
  font-weight: 400;
  line-height: ${(props) => props.theme.componentLineHeight};
  outline: none;
  text-overflow: ellipsis;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.buttonTextSizeMobile};
  }
`;

export default NoInteractionInputText;
