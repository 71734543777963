import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ColoredTopPartContext from "../../../contexts/coloredTopPartContext/ColoredTopPartContext";
import useGetLoginStatus from "../../../hooks/api/useGetLoginStatus";
import Button from "../../shared/Button";
import StyledLink from "../../shared/StyledLink";

interface Props {
  headerBackgroundVisible: boolean;
  setLoginModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSignupModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TopMenu = ({
  headerBackgroundVisible,
  setLoginModalOpen,
  setSignupModalOpen,
}: Props): JSX.Element => {
  const loggedInStatusQuery = useGetLoginStatus();
  const coloredTopPartContextValue = useContext(ColoredTopPartContext);

  const history = useHistory();

  return (
    <nav>
      <StyledUl>
        <li>
          <MenuItem
            headerBackgroundVisible={headerBackgroundVisible}
            coloredTopPart={!!coloredTopPartContextValue?.coloredTopPart}
            to="/pricing"
          >
            Pricing
          </MenuItem>
        </li>
        {loggedInStatusQuery.data?.loggedIn ? (
          <li>
            <StyledButtonPurple
              headerBackgroundVisible={headerBackgroundVisible}
              coloredTopPart={!!coloredTopPartContextValue?.coloredTopPart}
              eventHandler={() => {
                history.push("/dogs");
              }}
            >
              to Recipe Builder
            </StyledButtonPurple>
          </li>
        ) : (
          <>
            <li>
              <MenuItemButton
                headerBackgroundVisible={headerBackgroundVisible}
                coloredTopPart={!!coloredTopPartContextValue?.coloredTopPart}
                asButton={true}
                clickHandler={() => {
                  setLoginModalOpen(true);
                }}
              >
                Log in
              </MenuItemButton>
            </li>
            <li>
              <StyledButton
                headerBackgroundVisible={headerBackgroundVisible}
                coloredTopPart={!!coloredTopPartContextValue?.coloredTopPart}
                eventHandler={() => {
                  setSignupModalOpen(true);
                }}
              >
                Sign up
              </StyledButton>
            </li>
          </>
        )}
      </StyledUl>
    </nav>
  );
};

const MenuItem = styled(StyledLink)<{
  headerBackgroundVisible: boolean;
  coloredTopPart: boolean;
}>`
  margin-right: ${(props) => props.theme.spacing2};
  color: ${(props) =>
    props.headerBackgroundVisible || !props.coloredTopPart
      ? props.theme.green4
      : props.theme.white};
  font-family: "Nunito Bold", sans-serif;
  font-weight: 400;

  &:hover {
    color: ${(props) =>
      props.headerBackgroundVisible || !props.coloredTopPart
        ? props.theme.green3
        : props.theme.black1};
  }
  &:focus {
    color: ${(props) =>
      props.headerBackgroundVisible || !props.coloredTopPart
        ? props.theme.green3
        : props.theme.black1};

    outline-color: ${(props) =>
      props.headerBackgroundVisible || !props.coloredTopPart
        ? props.theme.green3
        : props.theme.black1};
  }

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    color: ${(props) => props.theme.green4};

    &:hover {
      color: ${(props) => props.theme.green3};
    }
    &:focus {
      color: ${(props) => props.theme.green3};
      outline-color: ${(props) => props.theme.green3};
    }
  }

  font-size: ${(props) => props.theme.navigationTextSize};
  line-height: ${(props) => props.theme.componentLineHeight};
  text-decoration: none;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    font-size: ${(props) => props.theme.navigationTextSizeMobile};
  }

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    margin-right: ${(props) => props.theme.spacing1};
  }
`;

const MenuItemButton = styled(MenuItem)`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const StyledButton = styled(Button)<{
  headerBackgroundVisible: boolean;
  coloredTopPart: boolean;
}>`
  color: ${(props) =>
    props.headerBackgroundVisible || !props.coloredTopPart
      ? props.theme.green1
      : props.theme.green4};
  background-color: ${(props) =>
    props.headerBackgroundVisible || !props.coloredTopPart
      ? props.theme.green3
      : props.theme.green2};
  font-family: "Nunito Bold", sans-serif;
  font-weight: 400;
  transition: color ${(props) => props.theme.transitionValue},
    background-color ${(props) => props.theme.transitionValue};
  padding: ${(props) => props.theme.halfSpacing1 + " " + props.theme.spacing1};
  font-size: ${(props) => props.theme.navigationTextSize};

  &:hover {
    background-color: ${(props) =>
      props.headerBackgroundVisible || !props.coloredTopPart
        ? props.theme.green4
        : props.theme.green1};
  }

  &:focus {
    background-color: ${(props) =>
      props.headerBackgroundVisible || !props.coloredTopPart
        ? props.theme.green4
        : props.theme.green1};
    outline-color: ${(props) =>
      props.headerBackgroundVisible || !props.coloredTopPart
        ? props.theme.green4
        : props.theme.green1};
  }

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    color: ${(props) => props.theme.green1};
    background-color: ${(props) => props.theme.green3};
    font-size: ${(props) => props.theme.navigationTextSizeMobile};

    &:hover {
      background-color: ${(props) => props.theme.green4};
    }
    &:focus {
      background-color: ${(props) => props.theme.green4};
      outline-color: ${(props) => props.theme.green4};
    }
  }
`;

const StyledButtonPurple = styled(StyledButton)<{
  headerBackgroundVisible: boolean;
  coloredTopPart: boolean;
}>`
  color: ${(props) =>
    props.headerBackgroundVisible || !props.coloredTopPart
      ? props.theme.purple1
      : props.theme.purple4};
  background-color: ${(props) =>
    props.headerBackgroundVisible || !props.coloredTopPart
      ? props.theme.purple3
      : props.theme.purple2};

  &:hover {
    background-color: ${(props) =>
      props.headerBackgroundVisible || !props.coloredTopPart
        ? props.theme.purple4
        : props.theme.purple1};
  }

  &:focus {
    background-color: ${(props) =>
      props.headerBackgroundVisible || !props.coloredTopPart
        ? props.theme.purple4
        : props.theme.purple1};
    outline-color: ${(props) =>
      props.headerBackgroundVisible || !props.coloredTopPart
        ? props.theme.purple4
        : props.theme.purple1};
  }

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    color: ${(props) => props.theme.purple1};
    background-color: ${(props) => props.theme.purple3};

    &:hover {
      background-color: ${(props) => props.theme.purple4};
    }
    &:focus {
      background-color: ${(props) => props.theme.purple4};
      outline-color: ${(props) => props.theme.purple4};
    }
  }
`;

const StyledUl = styled.ul`
  list-style: none;
  display: flex;
  padding-left: 0;
  margin-left: ${(props) => props.theme.halfSpacing1};
  align-items: center;
`;

export default TopMenu;
