import React, { useState } from "react";
import styled from "styled-components";
import { allColors } from "../../../types/frontendTypes";
import getTextWidth from "../../../utils/getTextWidth";
import FormText from "../text/FormText";
import FontFaceObserver from "fontfaceobserver";
import { useMediaQuery } from "react-responsive";
import theme from "../../../style/theme";

interface Props {
  value: number | null;
  unit: string;
  color: allColors;
  className?: string;
}

const UnitLabel = ({
  value,
  unit,
  color,
  className,
}: Props): JSX.Element | null => {
  const [leftValueUnitLabel, setLeftValueUnitLabel] = useState<number | null>(
    null
  );

  const isMobile = useMediaQuery({ maxWidth: theme.breakpoint1 });

  const font = new FontFaceObserver("DM Sans Regular");

  font.load().then(() => {
    if (value) {
      const leftValueUnitLabelLocal = getTextWidth(
        value.toString(),
        !isMobile ? "20px DM Sans Regular" : "18px DM Sans Regular"
      );

      if (leftValueUnitLabelLocal !== leftValueUnitLabel) {
        setLeftValueUnitLabel(leftValueUnitLabelLocal);
      }
    }
  });

  if (!value) {
    return null;
  }

  return (
    <>
      {value && (
        <LabelContainer sizeText={leftValueUnitLabel}>
          <StyledUnitLabel
            sizeText={leftValueUnitLabel}
            className={className}
            asValue={"span"}
            color={color}
          >
            {value && unit}
          </StyledUnitLabel>
        </LabelContainer>
      )}
    </>
  );
};

const LabelContainer = styled.div<{
  sizeText: number | null;
}>`
  position: absolute;
  top: 18px;
  left: ${(props) => props.sizeText && `${props.sizeText + 32 + 6}px`};
  display: ${(props) => !props.sizeText && "none"};
  pointer-events: none;
`;

const StyledUnitLabel = styled(FormText)<{
  sizeText: number | null;
  color: allColors;
}>`
  color: ${(props) => props.color};
  pointer-events: none;
`;

export default UnitLabel;
