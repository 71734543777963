import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  asValue?: "label" | "span";
  forValue?: string;
  className?: string;
  refValue?: React.MutableRefObject<HTMLLabelElement | null>;
}

const FormText = ({
  children,
  asValue,
  forValue,
  className,
  refValue,
}: Props): JSX.Element => {
  return (
    <>
      {asValue === "label" ? (
        <StyledText
          className={className}
          as={asValue}
          htmlFor={forValue}
          ref={refValue}
        >
          {children}
        </StyledText>
      ) : (
        <StyledText className={className} as={asValue} ref={refValue}>
          {children}
        </StyledText>
      )}
    </>
  );
};

const StyledText = styled.p`
  font-size: ${(props) => props.theme.buttonTextSize};
  line-height: ${(props) => props.theme.componentLineHeight};
  font-family: "DM Sans Regular", sans-serif;
  font-weight: 400;

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.buttonTextSizeMobile};
  }
`;

export default FormText;
