import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import useChangePassword from "../../../../hooks/api/useChangePassword";
import { passwordErrorMessage } from "../../../../utils/errorMessagesAuth";
import { passwordRegex } from "../../../../utils/regexPatterns";
import HideRevealPassword from "../../../shared/auth/HideRevealPassword";
import ErrorMessage from "../../../shared/form/ErrorMessage";
import LabelForm from "../../../shared/form/LabelForm";
import Input from "../../../shared/form/normal/Input";
import SubmitButton from "../../../shared/form/normal/SubmitButton";
import Modal from "../../../shared/Modal";
import IntroductionText from "../../../shared/text/IntroductionText";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface formValues {
  currentPassword: string;
  newPassword: string;
}

const ChangePasswordModal = ({ open, setOpen }: Props): JSX.Element => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [incorrectCurrentPassword, setIncorrectCurrentPassword] =
    useState(false);

  const changePasswordMutation = useChangePassword();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<formValues>();

  useEffect(() => {
    if (!open) {
      setIncorrectCurrentPassword(false);
      setShowCurrentPassword(false);
      setShowNewPassword(false);
      reset();
    }
  }, [open]);

  const submitHandler = (data: formValues) => {
    setIncorrectCurrentPassword(false);

    changePasswordMutation.mutate(
      {
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
      },
      {
        onSuccess: () => {
          setOpen(false);
        },
        onError: (error) => {
          if (error.response?.data.error === "Incorrect current password") {
            setIncorrectCurrentPassword(true);
          }
        },
      }
    );
  };

  return (
    <Modal
      title="Change password"
      open={open}
      setOpen={setOpen}
      closable={true}
    >
      <StyledIntroductionText>
        Not happy with your current password? Change to a new one.
      </StyledIntroductionText>

      {incorrectCurrentPassword && (
        <StyledErrorMessage message={"Incorrect current password"} />
      )}

      <form onSubmit={handleSubmit(submitHandler)} noValidate autoComplete="on">
        <StyledLabel name="currentPassword">Current password</StyledLabel>
        <ContainerPasswordInput>
          <StyledInput
            register={register("currentPassword", {
              required: true,
              pattern: passwordRegex,
            })}
            error={errors.currentPassword}
            errorMessage={passwordErrorMessage}
            type={showCurrentPassword ? "text" : "password"}
            autoComplete="current-password"
          />
          <HideRevealPassword
            showPassword={showCurrentPassword}
            setShowPassword={setShowCurrentPassword}
          />
        </ContainerPasswordInput>

        <StyledLabel name="newPassword">New password</StyledLabel>
        <ContainerPasswordInput>
          <StyledInput
            register={register("newPassword", {
              required: true,
              pattern: passwordRegex,
            })}
            error={errors.newPassword}
            errorMessage={passwordErrorMessage}
            type={showNewPassword ? "text" : "password"}
            autoComplete="new-password"
          />
          <HideRevealPassword
            showPassword={showNewPassword}
            setShowPassword={setShowNewPassword}
          />
        </ContainerPasswordInput>

        <ButtonContainer>
          <StyledSubmitButton text="Change password" />
        </ButtonContainer>
      </form>
    </Modal>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};
  margin-top: ${(props) => props.theme.spacing2};
`;

const ButtonContainer = styled.div``;

const StyledLabel = styled(LabelForm)`
  color: ${(props) => props.theme.black4};
  margin-bottom: 0.25rem; // 4px
`;

const ContainerPasswordInput = styled.div`
  position: relative;
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const StyledInput = styled(Input)`
  color: ${(props) => props.theme.black4};

  border-style: solid;
  border-width: 0.125rem; // 2px
  border-color: ${(props) => props.theme.black4};

  outline: none;

  padding-right: 4rem; // 64px

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${(props) => props.theme.black3};
  }
`;

const StyledSubmitButton = styled(SubmitButton)`
  background-color: ${(props) => props.theme.purple2};
  color: ${(props) => props.theme.purple4};
  width: 100%;

  &:hover {
    background-color: ${(props) => props.theme.purple1};
  }
  &:focus {
    outline-color: ${(props) => props.theme.purple1};
    background-color: ${(props) => props.theme.purple1};
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 0;
  margin-bottom: ${(props) => props.theme.spacing2};
`;

export default ChangePasswordModal;
