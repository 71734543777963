import React from "react";
import styled from "styled-components";

interface Props {
  value: string | null;
  setValue: React.Dispatch<React.SetStateAction<string | null>>;
  id: string;
  placeholder?: string;
  className?: string;
}

const SimpleInputText = ({
  value,
  setValue,
  id,
  placeholder,
  className,
}: Props): JSX.Element => {
  return (
    <StyledInput
      className={className}
      id={id}
      value={value || ""}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
      }}
      type={"text"}
      placeholder={placeholder}
    />
  );
};

const StyledInput = styled.input`
  display: block;
  width: 100%;
  padding: 1rem 2rem; // 16px 32px
  box-sizing: border-box;
  border-width: 0.125rem; // 2px
  border-radius: 100rem;
  border-style: solid;
  font-size: ${(props) => props.theme.buttonTextSize};
  font-family: "DM Sans Regular", sans-serif;
  font-weight: 400;
  line-height: ${(props) => props.theme.componentLineHeight};
  outline: none;
  text-overflow: ellipsis;
  width: 100%;
  transition: background-color ${(props) => props.theme.transitionValue};

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.buttonTextSizeMobile};
  }
`;

export default SimpleInputText;
