import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ButtonText from "../../../shared/text/ButtonText";
import WrapLink from "../../../shared/WrapLink";
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/calendar.svg";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/copy.svg";
import { ReactComponent as LinkIcon } from "../../../../assets/icons/link.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash-2.svg";
import { RecipeFull } from "../../../../types/recipeTypes";
import useGetAuthUser from "../../../../hooks/api/useGetAuthUser";
import useCreateRecipe from "../../../../hooks/api/useCreateRecipe";
import { useHistory } from "react-router-dom";
import urlSlug from "url-slug";
import DeleteModal from "./DeleteModal";
import ScaleModal from "./ScaleModal";
import ShareModal from "./ShareModal";
import { ReactComponent as MoreHorizontalIcon } from "../../../../assets/icons/more-horizontal.svg";
import { ReactComponent as PrinterIcon } from "../../../../assets/icons/printer.svg";

interface Props {
  recipe: RecipeFull;
  handlePrintRecipe: () => void;
  scaleModalOpen: boolean;
  setScaleModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const OptionsMenu = ({
  recipe,
  handlePrintRecipe,
  scaleModalOpen,
  setScaleModalOpen,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);

  const history = useHistory();

  const authUserQuery = useGetAuthUser();
  const createRecipeMutation = useCreateRecipe(authUserQuery.data?.selectedDog);

  const refDropdown = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        refDropdown.current &&
        !refDropdown.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const duplicateRecipe = (): void => {
    createRecipeMutation.mutate(
      {
        name: `${recipe.name}`,
        description: recipe.description,
        recipePublic: false,
        ingredients: recipe.ingredients.map((ingredient) => ({
          id: ingredient.id,
          amount: ingredient.amount,
          unit: ingredient.unit,
          show: ingredient.show,
        })),
      },
      {
        onSuccess: (data) => {
          if (data) {
            history.push(`/recipes/${data.id}/${urlSlug(data.name)}`);
          }
        },
      }
    );
  };

  return (
    <BigContainer>
      <DeleteModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        recipeId={recipe.id}
        dogId={authUserQuery.data?.selectedDog}
      />
      <ScaleModal
        open={scaleModalOpen}
        setOpen={setScaleModalOpen}
        ingredients={recipe.ingredients}
        recipeName={recipe.name}
      />
      <ShareModal open={shareModalOpen} setOpen={setShareModalOpen} />
      <ButtonContainer asButton={true} clickHandler={() => setOpen(true)}>
        <StyledMoreHorizontalIcon />
        <StyledButtonText>Options</StyledButtonText>
      </ButtonContainer>
      {open && (
        <Anchor>
          <Container ref={refDropdown}>
            <Triangle />
            <Options>
              <OptionContainer
                asButton={true}
                clickHandler={() => setScaleModalOpen(true)}
              >
                <StyledOptionText>Scale recipe</StyledOptionText>
                <OptionIcon />
              </OptionContainer>
              <OptionContainer
                asButton={true}
                clickHandler={() => setShareModalOpen(true)}
              >
                <StyledOptionText>Share recipe</StyledOptionText>
                <OptionIcon as={LinkIcon} />
              </OptionContainer>
              <OptionContainer asButton={true} clickHandler={handlePrintRecipe}>
                <StyledOptionText>Print recipe</StyledOptionText>
                <OptionIcon as={PrinterIcon} />
              </OptionContainer>
              <OptionContainer asButton={true} clickHandler={duplicateRecipe}>
                <StyledOptionText>Duplicate recipe</StyledOptionText>
                <OptionIcon as={CopyIcon} />
              </OptionContainer>
              <OptionContainer
                asButton={true}
                clickHandler={() => setDeleteModalOpen(true)}
              >
                <StyledOptionText>Delete recipe</StyledOptionText>
                <OptionIcon as={TrashIcon} />
              </OptionContainer>
            </Options>
          </Container>
        </Anchor>
      )}
    </BigContainer>
  );
};

const BigContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ButtonContainer = styled(WrapLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: fit-content;

  &:focus {
    outline-color: ${(props) => props.theme.black4};
  }
`;

const Anchor = styled.div`
  position: relative;
  width: 0;
  height: 0;
`;

const Container = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 20rem; // 320px
  height: fit-content;
  z-index: 3;
  margin-top: ${(props) => props.theme.spacing1};
  display: flex;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    width: 17rem; // 272px
    margin-top: ${(props) => props.theme.halfSpacing1};
  }
`;

const Triangle = styled.div`
  background-color: ${(props) => props.theme.purple1};
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  width: 1.5rem; // 24px
  height: 1rem; // 16px
  margin-left: auto;
  margin-right: ${(props) => props.theme.spacing3};
  margin-bottom: -0.25rem; // -4px
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: ${(props) => props.theme.purple1};
  border-radius: 1rem; // 16px
  padding-top: ${(props) => props.theme.halfSpacing1};
  padding-bottom: ${(props) => props.theme.halfSpacing1};
`;

const OptionContainer = styled(WrapLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.purple4};
  text-decoration: none;
  padding-right: ${(props) => props.theme.spacing2};
  padding-left: ${(props) => props.theme.spacing2};
  padding-bottom: ${(props) => props.theme.spacing1};
  padding-top: ${(props) => props.theme.spacing1};

  &:last-child {
    margin-right: 0;
  }
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.purple2};
  }
  &:focus {
    outline-color: ${(props) => props.theme.purple4};
    background-color: ${(props) => props.theme.purple2};
  }
  &:hover {
    background-color: ${(props) => props.theme.purple2};
  }
`;

const OptionIcon = styled(CalendarIcon)`
  width: ${(props) => props.theme.spacing2};
  min-width: ${(props) => props.theme.spacing2};
  height: ${(props) => props.theme.spacing2};
  min-height: ${(props) => props.theme.spacing2};
  color: ${(props) => props.theme.purple4};
`;

const StyledOptionText = styled(ButtonText)`
  word-break: break-word;
`;

const StyledButtonText = styled(ButtonText)`
  color: ${(props) => props.theme.black4};
`;

const StyledMoreHorizontalIcon = styled(MoreHorizontalIcon)`
  width: ${(props) => props.theme.spacing2};
  min-width: ${(props) => props.theme.spacing2};
  height: ${(props) => props.theme.spacing2};
  min-height: ${(props) => props.theme.spacing2};
  margin-right: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.black4};
`;

export default OptionsMenu;
