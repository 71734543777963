import React, { useContext } from "react";
import styled from "styled-components";
import ExtraFieldsModalOpenContext from "../../../../contexts/extraFieldsModalOpenContext/ExtraFieldsModalOpenContext";
import Button from "../../../shared/Button";
import Modal from "../../../shared/Modal";
import IntroductionText from "../../../shared/text/IntroductionText";

const ExtraFieldsModal = (): JSX.Element => {
  const extraFieldsModalOpenContextValue = useContext(
    ExtraFieldsModalOpenContext
  );

  return (
    <Modal
      title="You need to fill out all fields before you continue"
      open={extraFieldsModalOpenContextValue?.extraFieldsModalOpen || false}
      setOpen={extraFieldsModalOpenContextValue?.setExtraFieldsModalOpen}
      closable={true}
    >
      <StyledIntroductionText>
        We know that you are curious about the rest of the app, but you need to
        fill out all fields before you continue.
      </StyledIntroductionText>

      <PurpleButton
        eventHandler={() =>
          extraFieldsModalOpenContextValue?.setExtraFieldsModalOpen(false)
        }
      >
        Stay
      </PurpleButton>
    </Modal>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};
  margin-top: ${(props) => props.theme.spacing2};
`;

const PurpleButton = styled(Button)`
  background-color: ${(props) => props.theme.purple2};
  color: ${(props) => props.theme.purple4};
  width: 100%;

  &:hover {
    background-color: ${(props) => props.theme.purple1};
  }
  &:focus {
    background-color: ${(props) => props.theme.purple1};
    outline-color: ${(props) => props.theme.purple1};
  }
`;

export default ExtraFieldsModal;
