import adjustNutrientReqCalories from "../../../services/nutrientReqs/adjustNutrientReqCalories";
import { breedSizeType } from "../../../types/dogTypes";
import { NutrientsStandard } from "../../../types/standardTypes";

const NUTRIENT_REQ_AAFCO_GROWTH_AND_REPRODUCTION_MIN: NutrientsStandard = {
  // Macronutrients
  protein: 56.3,
  fat: 21.3,
  carbohydrate: null,
  water: null,
  ash: null,

  // Amino acids
  tryptophan: 0.5,
  threonine: 2.6,
  isoleucine: 1.78,
  leucine: 3.23,
  lysine: 2.25,
  methionine: 0.88,
  methionineAndCystine: 1.75,
  phenylalanine: 2.08,
  phenylalanineAndTyrosine: 3.25,
  valine: 1.7,
  arginine: 2.5,
  histidine: 1.1,

  // Fatty acids
  linoleicAcid: 3.3,
  alphaLinoleicAcid: 0.2,
  arachidonicAcid: null,
  linoleicToAlphaLinoleicAcids: 2.6,
  epaAndDha: 0.1,

  // Carbohydrates
  fiber: null,

  // Minerals
  calcium: 3000,
  iron: 22,
  magnesium: 150,
  phosphorus: 2500,
  calciumToPhosphorus: 1,
  potassium: 1500,
  chloride: 1100,
  sodium: 800,
  zinc: 25,
  copper: 3.1,
  zincToCopper: 8.0,
  manganese: 1.8,
  selenium: 90,
  iodine: 250,

  // Vitamins
  thiaminB1: 0.56,
  riboflavinB2: 1.3,
  niacinB3: 3.4,
  pantothenicAcidB5: 3.0,
  vitaminB6: 0.38,
  folicAcidB9: 54,
  choline: 340,
  vitaminB12: 7,
  vitaminA: 375,
  vitaminE: 8.375,
  cholecalciferolD3: 3.125,
  phylloquinoneK: null,
};

const NUTRIENT_REQ_AAFCO_GROWTH_AND_REPRODUCTION_MAX: NutrientsStandard = {
  // Macronutrients
  protein: null,
  fat: null,
  carbohydrate: null,
  water: null,
  ash: null,

  // Amino acids
  tryptophan: null,
  threonine: null,
  isoleucine: null,
  leucine: null,
  lysine: null,
  methionine: null,
  methionineAndCystine: null,
  phenylalanine: null,
  phenylalanineAndTyrosine: null,
  valine: null,
  arginine: null,
  histidine: null,

  // Fatty acids
  linoleicAcid: null,
  alphaLinoleicAcid: null,
  arachidonicAcid: null,
  linoleicToAlphaLinoleicAcids: 16,
  epaAndDha: null,

  // Carbohydrates
  fiber: null,

  // Minerals
  calcium: 6250,
  iron: null,
  magnesium: null,
  phosphorus: 4000,
  calciumToPhosphorus: 2,
  potassium: null,
  chloride: null,
  sodium: null,
  zinc: null,
  copper: null,
  zincToCopper: null,
  manganese: null,
  selenium: 500,
  iodine: 2750,

  // Vitamins
  thiaminB1: null,
  riboflavinB2: null,
  niacinB3: null,
  pantothenicAcidB5: null,
  vitaminB6: null,
  folicAcidB9: null,
  choline: null,
  vitaminB12: null,
  vitaminA: 18750,
  vitaminE: null,
  cholecalciferolD3: 18.75,
  phylloquinoneK: null,
};

export const getNutrientReqAafcoGrowthAndReproductionMin = (
  calorieRequriement: number
): NutrientsStandard => {
  const adjustedNutrients = adjustNutrientReqCalories(
    NUTRIENT_REQ_AAFCO_GROWTH_AND_REPRODUCTION_MIN,
    calorieRequriement
  );
  return adjustedNutrients;
};

export const getNutrientReqAafcoGrowthAndReproductionMax = (
  calorieRequriement: number,
  isPuppy: boolean,
  breedSize: breedSizeType
): NutrientsStandard => {
  let specificReq = NUTRIENT_REQ_AAFCO_GROWTH_AND_REPRODUCTION_MAX;
  if (isPuppy && (breedSize === "large" || breedSize === "giant")) {
    specificReq = {
      ...specificReq,
      calcium: 4500,
    };
  }

  const adjustedNutrients = adjustNutrientReqCalories(
    specificReq,
    calorieRequriement
  );
  return adjustedNutrients;
};
