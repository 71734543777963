import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const StyledSpan = styled.span`
  border-radius: 1.375rem; // 22px
  padding-top: ${(props) => props.theme.halfSpacing1};
  padding-bottom: ${(props) => props.theme.halfSpacing1};
  padding-left: ${(props) => props.theme.spacing1};
  padding-right: ${(props) => props.theme.spacing1};
  display: inline-block;

  line-height: ${(props) => props.theme.componentLineHeight};
  font-size: ${(props) => props.theme.labelTextSize};
  font-family: "DM Sans Bold", sans-serif;
  font-weight: 400;
`;

const Tag = ({ children, className }: Props): JSX.Element => {
  return <StyledSpan className={className}>{children}</StyledSpan>;
};

export default Tag;
