import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import { ChangeableIngredientMetaValuesType } from "../../types/ingredientTypes";
import kebabize from "../../utils/kebabize";

interface Input {
  value: string | null;
  property: ChangeableIngredientMetaValuesType;
}

const useChangeIngredientProperty = (
  ingredientId: string
): UseMutationResult<void, AxiosError, Input> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const dataSend = { value: data.value };
      await axios.patch(
        `${BACKEND_URL}/api/ingredients/${ingredientId}/${kebabize(
          data.property
        )}`,
        dataSend
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["ingredients", "custom"], {
          exact: true,
        });
        queryClient.invalidateQueries(["ingredients", ingredientId], {
          exact: true,
        });
        queryClient.invalidateQueries(["dogs"]);
        queryClient.invalidateQueries(["recipes"]);
        queryClient.invalidateQueries(["ingredients", "search"], {
          exact: true,
        });
      },
    }
  );
};

export default useChangeIngredientProperty;
