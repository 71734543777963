import axios, { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import {
  IngredientSearchWithNutrient,
  NutrientIngredientType,
} from "../../types/ingredientTypes";

const useGetIngredientsSearchWithNutrient = (
  nutrient: NutrientIngredientType | null
): UseQueryResult<IngredientSearchWithNutrient[], AxiosError> => {
  return useQuery<IngredientSearchWithNutrient[], AxiosError>(
    ["ingredients", "search", nutrient],
    async () => {
      const responseIngredientsSearchWithNutrient = await axios.get<
        IngredientSearchWithNutrient[]
      >(`${BACKEND_URL}/api/ingredients/search/${nutrient}`);

      return responseIngredientsSearchWithNutrient.data;
    },
    {
      enabled: nutrient !== null,
    }
  );
};

export default useGetIngredientsSearchWithNutrient;
