import React, { useState } from "react";
import styled from "styled-components";
import useChangeRecipeIngredientProperty from "../../../../hooks/api/useChangeRecipeIngredientProperty";
import useRemoveRecipeIngredient from "../../../../hooks/api/useRemoveRecipeIngredient";
import {
  IngredientDetailed,
  IngredientInstanceValuesNoIdType,
} from "../../../../types/ingredientTypes";
import { RecipeFull } from "../../../../types/recipeTypes";
import IngredientInfoModal from "./IngredientInfoModal";
import PlusButton from "../../../shared/PlusButton";
import Heading2 from "../../../shared/text/headings/Heading2";
import TextBold from "../../../shared/text/TextBold";
import AddIngredientModal from "./AddIngredientModal";
import IngredientRowDisplay from "./IngredientRowDisplay";

interface Props {
  recipe: RecipeFull;
}

const IngredientsPart = ({ recipe }: Props): JSX.Element => {
  const [addIngredientModalOpen, setAddIngredientModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [infoModalIngredient, setInfoModalIngredient] =
    useState<IngredientDetailed | null>(null);

  const changeRecipeIngredientPropertyMutation =
    useChangeRecipeIngredientProperty(recipe.dogId, recipe.id);
  const deleteRemoveIngredientMutation = useRemoveRecipeIngredient(
    recipe.dogId,
    recipe.id
  );

  const saveChangedValue = (
    ingredientId: string,
    value: number | boolean | string,
    property: IngredientInstanceValuesNoIdType
  ) => {
    changeRecipeIngredientPropertyMutation.mutate({
      ingredientId,
      value,
      property,
    });
  };

  const deleteIngredient = (ingredientId: string) => {
    deleteRemoveIngredientMutation.mutate({ ingredientId });
  };

  return (
    <>
      <AddIngredientModal
        recipe={recipe}
        open={addIngredientModalOpen}
        setOpen={setAddIngredientModalOpen}
      />
      <StyledHeading2>Ingredients</StyledHeading2>
      <StyledPlusButton
        text={"Add ingredients"}
        clickHandler={() => setAddIngredientModalOpen(true)}
      />
      <StyledTable role="grid">
        <StyledThead role="presentation">
          <HeadRow role="row">
            <TableHead role="columnheader">
              <StyledText>Name</StyledText>
            </TableHead>
            <TableHead role="columnheader">
              <StyledText>Amount</StyledText>
            </TableHead>
            <TableHead role="columnheader">
              <StyledText>Unit</StyledText>
            </TableHead>
            <TableHeadHidePrint role="columnheader">
              <StyledText>Status</StyledText>
            </TableHeadHidePrint>
            <TableHeadHidePrint role="columnheader">
              <StyledText>Remove</StyledText>
            </TableHeadHidePrint>
          </HeadRow>
        </StyledThead>
        <TableBody role="presentation">
          {recipe.ingredients
            .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
            .map((ingredient) => (
              <IngredientRowDisplay
                key={ingredient.id}
                ingredient={ingredient}
                saveChangedValue={saveChangedValue}
                deleteIngredient={deleteIngredient}
                setInfoOpen={() => {
                  setInfoModalIngredient(ingredient);
                  setInfoModalOpen(true);
                }}
              />
            ))}
        </TableBody>
      </StyledTable>
      <IngredientInfoModal
        ingredient={infoModalIngredient}
        open={infoModalOpen}
        setOpen={setInfoModalOpen}
      />
    </>
  );
};

const StyledHeading2 = styled(Heading2)`
  margin-top: ${(props) => props.theme.spacing3};
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing3};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: ${(props) => props.theme.spacing2};
  }

  @media print {
    margin-top: ${(props) => props.theme.spacing2};
    margin-bottom: ${(props) => props.theme.spacing1};
  }
`;

const StyledPlusButton = styled(PlusButton)`
  margin-bottom: ${(props) => props.theme.spacing3};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: ${(props) => props.theme.spacing2};
  }
  @media print {
    display: none;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    display: block;
  }
  @media print {
    display: table;
  }
`;

const StyledThead = styled.thead`
  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
  @media print {
    position: initial;
    left: initial;
    top: initial;
    width: initial;
    height: initial;
    overflow: initial;
  }
`;

const HeadRow = styled.tr`
  border-bottom: 0.125rem solid ${(props) => props.theme.black1};
`;

const TableHead = styled.th`
  text-align: left;
  padding: 0;
  padding-bottom: ${(props) => props.theme.spacing1};
`;

const TableHeadHidePrint = styled(TableHead)`
  @media print {
    display: none;
  }
`;

const StyledText = styled(TextBold)`
  color: ${(props) => props.theme.black4};
  margin-right: ${(props) => props.theme.spacing1};
  font-size: ${(props) => props.theme.navigationTextSize};
`;

const TableBody = styled.tbody`
  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    display: block;

    padding-bottom: ${(props) => props.theme.spacing2};
    border-bottom: 0.125rem solid ${(props) => props.theme.black1};
  }

  @media print {
    display: table-row-group;

    padding-bottom: initial;
    border-bottom: initial;
  }
`;

export default IngredientsPart;
