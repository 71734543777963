import { IngredientDetailed } from "../types/ingredientTypes";

const getInstanceUnitShort = (ingredient: IngredientDetailed): string => {
  let returnValue: string;
  if (ingredient.defaultUnit === "gram") {
    switch (ingredient.unit) {
      case "gram":
        returnValue = "g";
        break;
      case "kilogram":
        returnValue = "kg";
        break;
      case "ounce":
        returnValue = "oz";
        break;
      case "pound":
        returnValue = "lb";
        break;
      default:
        returnValue = "g";
    }
  } else if (ingredient.defaultUnitShort !== null) {
    returnValue = ingredient.defaultUnitShort;
  } else {
    returnValue = ingredient.defaultUnit;
  }

  return returnValue;
};

export default getInstanceUnitShort;
