import React, { useState } from "react";
import ButtonText from "./text/ButtonText";
import { ReactComponent as XCircleIcon } from "../../assets/icons/x-circle.svg";
import styled from "styled-components";
import TextBold from "./text/TextBold";
import Text from "./text/Text";
import WrapLink from "./WrapLink";
import useGetDogs from "../../hooks/api/useGetDogs";
import DeleteDogModal from "./DeleteDogModal";
import useGetAuthUser from "../../hooks/api/useGetAuthUser";

const DeleteDogTable = (): JSX.Element | null => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteDogId, setDeleteDogId] = useState<null | string>(null);

  const authUserQuery = useGetAuthUser();
  const getDogsQuery = useGetDogs();

  if (
    authUserQuery.data === undefined ||
    authUserQuery.data.kgOrLb === null ||
    getDogsQuery.data === undefined
  ) {
    return null;
  }

  return (
    <>
      <DeleteDogModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        dogId={deleteDogId}
        selectedDogId={authUserQuery.data.selectedDog}
        dogs={getDogsQuery.data}
      />
      <StyledTable role="grid">
        <thead role="presentation">
          <HeadRow role="row">
            <TableHead role="columnheader">
              <StyledTextBold>Name</StyledTextBold>
            </TableHead>
            <TableHead role="columnheader">
              <StyledTextBoldNoMargin>Remove</StyledTextBoldNoMargin>
            </TableHead>
          </HeadRow>
        </thead>
        <tbody role="presentation">
          {getDogsQuery.data
            .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
            .map((dog) => (
              <tr key={dog.id} role="row">
                <DataCell>
                  <DogName>{dog.name}</DogName>
                </DataCell>
                <DataCell>
                  <ClickWrapper
                    asButton={true}
                    clickHandler={() => {
                      setDeleteDogId(dog.id);
                      setDeleteModalOpen(true);
                    }}
                  >
                    <StyledXCircleIcon />
                    <StyledButtonText>Remove</StyledButtonText>
                  </ClickWrapper>
                </DataCell>
              </tr>
            ))}
        </tbody>
      </StyledTable>
    </>
  );
};

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const HeadRow = styled.tr`
  border-bottom: 0.125rem solid ${(props) => props.theme.black1};
`;

const TableHead = styled.th`
  text-align: left;
  padding: 0;
  padding-bottom: ${(props) => props.theme.spacing1};
`;

const StyledTextBold = styled(TextBold)`
  color: ${(props) => props.theme.black4};
  margin-right: ${(props) => props.theme.spacing1};
`;

const StyledTextBoldNoMargin = styled(StyledTextBold)`
  margin-right: 0;
`;

const DataCell = styled.th`
  padding: 0;
  text-align: left;
  padding-top: ${(props) => props.theme.spacing1};
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.black4};
  margin-right: ${(props) => props.theme.spacing1};
`;

const DogName = styled(StyledText)`
  word-break: break-word;
`;

const ClickWrapper = styled(WrapLink)`
  text-decoration: none;
  display: flex;
  align-items: center;

  &:focus {
    outline-color: ${(props) => props.theme.black4};
  }
`;

const StyledXCircleIcon = styled(XCircleIcon)`
  width: 1.5rem; // 24px
  height: 1.5rem; // 24px
  margin-right: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.black4};
`;

const StyledButtonText = styled(ButtonText)`
  color: ${(props) => props.theme.black4};
`;

export default DeleteDogTable;
