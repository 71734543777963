import axios, { AxiosError } from "axios";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import { RecipeFull } from "../../types/recipeTypes";

const useGetRecipe = (
  id: string,
  options?: UseQueryOptions<any, AxiosError<any>>
): UseQueryResult<RecipeFull, AxiosError> => {
  return useQuery<RecipeFull, AxiosError>(
    ["recipes", id],
    async () => {
      const response = await axios.get<RecipeFull>(
        `${BACKEND_URL}/api/recipes/${id}`
      );
      return response.data;
    },
    options
  );
};

export default useGetRecipe;
