import React from "react";
import styled from "styled-components";

interface Props {
  value: number | null;
  setValue: React.Dispatch<React.SetStateAction<number | null>>;
  id: string;
  placeholder?: string;
  noDecimals?: boolean;
  className?: string;
}

const SimpleInputNumber = ({
  value,
  setValue,
  id,
  placeholder,
  noDecimals,
  className,
}: Props): JSX.Element => {
  return (
    <StyledInput
      className={className}
      id={id}
      value={value === null ? "" : value}
      onChange={(e) => {
        if (e.target.value === "") {
          setValue(null);
        }
        if (e.target.valueAsNumber >= 0 && e.target.value.length < 15) {
          setValue(
            noDecimals
              ? Math.round(e.target.valueAsNumber)
              : e.target.valueAsNumber
          );
        }
      }}
      type={"number"}
      placeholder={placeholder}
    />
  );
};

const StyledInput = styled.input`
  display: block;
  width: 100%;
  padding: 1rem 2rem; // 16px 32px
  box-sizing: border-box;
  border-width: 0.125rem; // 2px
  border-radius: 100rem;
  border-style: solid;
  font-size: ${(props) => props.theme.buttonTextSize};
  font-family: "DM Sans Regular", sans-serif;
  font-weight: 400;
  line-height: ${(props) => props.theme.componentLineHeight};
  outline: none;
  text-overflow: ellipsis;
  width: 100%;
  transition: background-color ${(props) => props.theme.transitionValue};

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.buttonTextSizeMobile};
  }
`;

export default SimpleInputNumber;
