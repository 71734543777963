import React from "react";
import { ReactDatePickerProps } from "react-datepicker";
import MinYearDatePickerContextProvider from "../../../../contexts/minYearDatePickerContext/MinYearDatePickerContextProvider";
import CustomizedDatePickerMain from "./CustomizedDatePickerMain";
import DatePicker from "react-datepicker";
import { allColors } from "../../../../types/frontendTypes";

interface Props {
  value: Date | null;
  setValue: React.Dispatch<React.SetStateAction<Date | null>>;
  id: string;
  iconColor: allColors;
  minDate?: Date;
  className?: string;
  refValue?: React.MutableRefObject<DatePicker<
    string,
    boolean | undefined
  > | null>;
  showIcon?: boolean;
  optionalArgs?: Partial<ReactDatePickerProps>;
}

const CustomizedDatePicker = (props: Props): JSX.Element => {
  return (
    <MinYearDatePickerContextProvider>
      <CustomizedDatePickerMain {...props} />
    </MinYearDatePickerContextProvider>
  );
};

export default CustomizedDatePicker;
