import React, { useEffect, useRef, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import styled from "styled-components";
import useGetRecipe from "../../../../hooks/api/useGetRecipe";
import RecipeIngredientLogic from "../../../shared/RecipeIngredientLogic";
import WrapLink from "../../../shared/WrapLink";
import OptionsMenu from "./OptionsMenu";
import IngredientsPart from "./IngredientsPart";
import TextPart from "./TextPart";
import { ReactComponent as ArrowLeftIcon } from "../../../../assets/icons/arrow-left.svg";
import ButtonText from "../../../shared/text/ButtonText";
import NutrientsPart from "./nutrientsPart/NutrientsPart";
import { useReactToPrint } from "react-to-print";

const RecipesName = (): JSX.Element | null => {
  interface PathVars {
    recipeId: string;
    recipeName: string;
  }
  const { recipeId } = useParams<PathVars>();

  const getRecipeQuery = useGetRecipe(recipeId);

  // ------------------ Print stuff ------------------
  const [scaleModalOpen, setScaleModalOpen] = useState(false);
  const [printMode, setPrintMode] = useState(false);

  const printComponentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    onAfterPrint: () => setPrintMode(false),
    documentTitle: getRecipeQuery.data?.name,
  });

  useEffect(() => {
    if (printMode) {
      handlePrint();
    }
  }, [printMode]);

  const handlePrintShortcutRecipe = (event: KeyboardEvent) => {
    if (scaleModalOpen) {
      return;
    }

    if ((event.ctrlKey || event.metaKey) && event.key === "p") {
      event.preventDefault();
      event.stopPropagation();
      setPrintMode(true);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handlePrintShortcutRecipe);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("keydown", handlePrintShortcutRecipe);
    };
  }, [handlePrintShortcutRecipe]);
  // ------------------ Print stuff ------------------

  if (
    getRecipeQuery.isError ||
    (getRecipeQuery.isSuccess && getRecipeQuery.data === undefined)
  ) {
    return <Redirect to="/recipes" />;
  }

  if (!getRecipeQuery.isSuccess) {
    return null;
  }

  return (
    <Container ref={printComponentRef} printMode={printMode}>
      <RecipeIngredientLogic />
      <TopPart>
        <ButtonContainer to={"/recipes"}>
          <StyledArrowLeftIcon />
          <StyledButtonText>Back to recipes</StyledButtonText>
        </ButtonContainer>
        <OptionsMenu
          recipe={getRecipeQuery.data}
          handlePrintRecipe={() => setPrintMode(true)}
          scaleModalOpen={scaleModalOpen}
          setScaleModalOpen={setScaleModalOpen}
        />
      </TopPart>
      <TextPart recipe={getRecipeQuery.data} />
      <IngredientsPart recipe={getRecipeQuery.data} />
      <NutrientsPart recipe={getRecipeQuery.data} printModeRecipe={printMode} />
    </Container>
  );
};

const Container = styled.div<{ printMode: boolean }>`
  @media print {
    padding: ${(props) => props.printMode && props.theme.spacing3};
  }
`;

const TopPart = styled.div`
  padding-bottom: ${(props) => props.theme.spacing3};
  margin-bottom: ${(props) => props.theme.spacing3};
  border-bottom: 0.125rem solid ${(props) => props.theme.black1};
  display: flex;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    padding-bottom: ${(props) => props.theme.spacing2};
    margin-bottom: ${(props) => props.theme.spacing2};
  }
  @media print {
    display: none;
  }
`;

const ButtonContainer = styled(WrapLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: fit-content;
  margin-right: ${(props) => props.theme.spacing2};

  &:focus {
    outline-color: ${(props) => props.theme.black4};
  }
`;

const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
  width: ${(props) => props.theme.spacing2};
  min-width: ${(props) => props.theme.spacing2};
  height: ${(props) => props.theme.spacing2};
  min-height: ${(props) => props.theme.spacing2};
  margin-right: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.black4};
`;

const StyledButtonText = styled(ButtonText)`
  color: ${(props) => props.theme.black4};
`;

export default RecipesName;
