import axios, { AxiosError } from "axios";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import { RecipeFull } from "../../types/recipeTypes";

const useGetRecipes = (
  options?: UseQueryOptions<RecipeFull[], AxiosError<any>>
): UseQueryResult<RecipeFull[], AxiosError> => {
  return useQuery<RecipeFull[], AxiosError>(
    ["recipes"],
    async () => {
      const responseRecipes = await axios.get<RecipeFull[]>(
        `${BACKEND_URL}/api/recipes`
      );

      return responseRecipes.data;
    },
    options
  );
};

export default useGetRecipes;
