import React from "react";
import { Route } from "react-router-dom";
import Footer from "../layout/footer/Footer";
import Header from "../layout/header/Header";

interface Props {
  children: React.ReactNode;
  path?: string;
  exact?: boolean;
}

const UnprotectedRouteHandler = ({
  children,
  path,
  exact,
}: Props): JSX.Element | null => {
  return (
    <Route path={path} exact={exact ? exact : false}>
      <Header />
      <main>{children}</main>
      <Footer />
    </Route>
  );
};

export default UnprotectedRouteHandler;
