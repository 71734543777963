import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import { RecipeNoIdType } from "../../types/recipeTypes";
import kebabize from "../../utils/kebabize";

interface Input {
  value: string | undefined;
  property: RecipeNoIdType;
}

const useChangeRecipeProperty = (
  dogId: string | undefined | null,
  recipeId: string
): UseMutationResult<void, AxiosError, Input> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const dataSend = { value: data.value };
      await axios.patch(
        `${BACKEND_URL}/api/recipes/${recipeId}/${kebabize(data.property)}`,
        dataSend
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["recipes", "dogs", dogId], {
          exact: true,
        });
        queryClient.invalidateQueries(["recipes", recipeId], {
          exact: true,
        });
        queryClient.invalidateQueries(["recipes"], {
          exact: true,
        });
      },
    }
  );
};

export default useChangeRecipeProperty;
