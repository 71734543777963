import React from "react";
import styled from "styled-components";
import useChangeSelectedDog from "../../hooks/api/useChangeSelectedDog";
import useDeleteDog from "../../hooks/api/useDeleteDog";
import { DogValues } from "../../types/dogTypes";
import Button from "./Button";
import Modal from "./Modal";
import IntroductionText from "./text/IntroductionText";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dogId: string | null;
  selectedDogId: string | null;
  dogs: DogValues[];
}

const DeleteDogModal = ({
  open,
  setOpen,
  dogId,
  selectedDogId,
  dogs,
}: Props): JSX.Element => {
  const changeSelectedDogMutation = useChangeSelectedDog();
  const deleteDogMutation = useDeleteDog();

  const deleteDog = () => {
    if (dogId === null) {
      return;
    }

    if (selectedDogId === dogId) {
      let newSelectedDog: null | string = null;
      dogs.forEach((dog) => {
        if (dog.id !== dogId) {
          newSelectedDog = dog.id;
        }
      });

      changeSelectedDogMutation.mutate(
        { selectedDog: newSelectedDog },
        {
          onSuccess: () => {
            deleteDogMutation.mutate(dogId);
          },
        }
      );
    } else {
      deleteDogMutation.mutate(dogId);
    }
  };

  return (
    <Modal
      title="Do you really want to delete?"
      open={open}
      setOpen={setOpen}
      closable={true}
    >
      <StyledIntroductionText>
        If you delete this dog, it will be gone forever, along with all the
        dog&apos;s recipes.
      </StyledIntroductionText>
      <ButtonContainer>
        <PurpleButton
          eventHandler={() => {
            deleteDog();
            setOpen(false);
          }}
        >
          Delete
        </PurpleButton>
        <WhiteButton
          eventHandler={() => {
            setOpen(false);
          }}
        >
          Keep
        </WhiteButton>
      </ButtonContainer>
    </Modal>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};
  margin-top: ${(props) => props.theme.spacing2};
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const PurpleButton = styled(Button)`
  background-color: ${(props) => props.theme.purple2};
  color: ${(props) => props.theme.purple4};
  width: 50%;
  margin-right: 0.25rem;

  &:hover {
    background-color: ${(props) => props.theme.purple1};
  }
  &:focus {
    background-color: ${(props) => props.theme.purple1};
    outline-color: ${(props) => props.theme.purple1};
  }
`;

const WhiteButton = styled(Button)`
  background-color: ${(props) => props.theme.white};
  color: ${(props) => props.theme.black4};
  width: 50%;

  &:focus {
    outline-color: ${(props) => props.theme.black4};
  }
`;

export default DeleteDogModal;
