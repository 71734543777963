import React, { useContext } from "react";
import styled from "styled-components";
import { priceIdBasicMonthly } from "../../../../constants/constants";
import AuthModalContext from "../../../../contexts/authModalContext/AuthModalContext";
import useCreateStripeCheckout from "../../../../hooks/api/useCreateStripeCheckout";
import useCreateStripePortal from "../../../../hooks/api/useCreateStripePortal";
import useGetAuthUser from "../../../../hooks/api/useGetAuthUser";
import useGetLoginStatus from "../../../../hooks/api/useGetLoginStatus";
import PricePlans from "../../../shared/PricePlans";
import StyledLink from "../../../shared/StyledLink";
import Heading1 from "../../../shared/text/headings/Heading1";
import IntroductionText from "../../../shared/text/IntroductionText";

const Pricing = (): JSX.Element | null => {
  const loggedInStatusQuery = useGetLoginStatus();
  const authUserQuery = useGetAuthUser({
    enabled: !!loggedInStatusQuery?.data?.loggedIn,
  });
  const createStripeCheckoutMutation = useCreateStripeCheckout();
  const createStripePortalMutation = useCreateStripePortal();

  let showTryBasic = true;
  if (
    authUserQuery.data &&
    authUserQuery.data.stripeSubscriptionTimestamp !== null
  ) {
    showTryBasic = false;
  }

  const authModalContext = useContext(AuthModalContext);

  if (!authModalContext) {
    return null;
  }

  const handleClick = (priceId: string) => {
    if (authUserQuery.data === undefined) {
      authModalContext.setAuthModalShowing("signup");
      authModalContext.setAuthModalOpen(true);
      return;
    }

    if (authUserQuery.data.stripeSubscription !== null) {
      createStripePortalMutation.mutate();
      return;
    }

    createStripeCheckoutMutation.mutate({ priceId });
  };

  return (
    <>
      <TextContainer>
        <StyledHeading1>Pricing</StyledHeading1>
        <StyledIntroductionText>
          Recipe creator or a few carefully chosen ones? One or several dogs? We
          have plans that fit your needs.{" "}
          {showTryBasic && (
            <>
              You can{" "}
              <StyledStyledLink
                asButton={true}
                clickHandler={() => handleClick(priceIdBasicMonthly)}
              >
                try it for free
              </StyledStyledLink>{" "}
              for 14 days. No credit card required.
            </>
          )}
        </StyledIntroductionText>
      </TextContainer>
      <ContainerPricePlans>
        <PricePlans neverSubscribed={showTryBasic} handleClick={handleClick} />
      </ContainerPricePlans>
    </>
  );
};

const TextContainer = styled.div`
  max-width: ${(props) => props.theme.halfWidth};
  margin-top: ${(props) => props.theme.spacing6};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${(props) => props.theme.spacing1};
  padding-right: ${(props) => props.theme.spacing1};
  margin-bottom: ${(props) => props.theme.spacing3};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-top: ${(props) => props.theme.spacing5};
  }
`;

const StyledHeading1 = styled(Heading1)`
  margin-bottom: ${(props) => props.theme.spacing2};
  color: ${(props) => props.theme.black4};
`;

const StyledIntroductionText = styled(IntroductionText)`
  margin-top: ${(props) => props.theme.spacing2};
  color: ${(props) => props.theme.black4};
`;

const ContainerPricePlans = styled.div`
  padding-left: ${(props) => props.theme.spacing1};
  padding-right: ${(props) => props.theme.spacing1};
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props) => props.theme.mainWidth};
`;

const StyledStyledLink = styled(StyledLink)`
  color: ${(props) => props.theme.black4};

  &:hover {
    color: ${(props) => props.theme.black3};
  }
  &:focus {
    outline-color: ${(props) => props.theme.black3};
    color: ${(props) => props.theme.black3};
  }
`;

export default Pricing;
