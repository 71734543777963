import React from "react";
import styled from "styled-components";
import SmallText from "../text/SmallText";

interface Props {
  message: string;
  idForDescribed?: string;
  className?: string;
}

const ErrorMessage = ({
  message,
  idForDescribed,
  className,
}: Props): JSX.Element => {
  return (
    <StyledContainer className={className}>
      <StyledText id={"error" + idForDescribed}>{message}</StyledText>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  padding-top: ${(props) => props.theme.halfSpacing1};
  padding-bottom: ${(props) => props.theme.halfSpacing1};
  padding-left: ${(props) => props.theme.spacing1};
  padding-right: ${(props) => props.theme.spacing1};
  background-color: ${(props) => props.theme.red4};
  border-radius: 0.5rem; // 8px
  margin-top: ${(props) => props.theme.spacing1};
`;

const StyledText = styled(SmallText)`
  color: ${(props) => props.theme.black4};
`;

export default ErrorMessage;
