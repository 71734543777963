import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import theme from "../../../../style/theme";
import StyledCheckbox from "../StyledCheckbox";
import InputButtons from "./InputButtons";

interface Props {
  label: string;
  id: string;
  serverValue: boolean | undefined;
  onSave: (value: boolean) => void;
  disabled: boolean;
  className?: string;
}

const EditableCheckbox = ({
  label,
  id,
  serverValue,
  onSave,
  disabled,
  className,
}: Props): JSX.Element => {
  const [checked, setChecked] = useState(false);
  const [editing, setEditing] = useState(false);

  const checkBoxRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (serverValue !== undefined) {
      setChecked(serverValue);
    }
  }, [serverValue]);

  useEffect(() => {
    if (editing) {
      checkBoxRef.current?.focus();
    }
  }, [editing]);

  useEffect(() => {
    const blurred = (e: MouseEvent) => {
      if (!containerRef.current?.contains(e.target as Node)) {
        saveEdit();
      }
    };
    document.addEventListener("click", blurred);
    return () => document.removeEventListener("click", blurred);
  }, [checked, serverValue, editing]);

  useEffect(() => {
    const blurred = (e: FocusEvent) => {
      if (!containerRef.current?.contains(e.target as Node)) {
        saveEdit();
      }
    };
    document.addEventListener("focusin", blurred);
    return () => document.removeEventListener("focusin", blurred);
  }, [checked, serverValue, editing]);

  const handleKeys = (e: React.KeyboardEvent) => {
    if (!editing) return;
    if (e.key === "Escape") {
      e.preventDefault();
      cancelEdit();
    } else if (e.key === "Enter") {
      e.preventDefault();
      saveEdit();
    }
  };

  const saveEdit = () => {
    setEditing(false);

    if (serverValue !== undefined && checked === serverValue) {
      return;
    }
    onSave(checked);
  };

  const cancelEdit = () => {
    setChecked(serverValue !== undefined ? serverValue : false);
    setEditing(false);
  };

  return (
    <Container
      onClick={!editing ? () => setEditing(true) : undefined}
      ref={containerRef}
      className={className}
    >
      <InnerContainer>
        <StyledCheckbox
          label={label}
          value={checked}
          changeHandler={(value) => {
            setChecked(value);
          }}
          id={id}
          checkboxColor={theme.black4}
          color1={theme.white}
          color2={theme.white}
          checkboxBorderColor={editing ? theme.black4 : theme.black1}
          partialFocusHandler={() => {
            setEditing(true);
          }}
          clickHandler={() => {
            setEditing(true);
          }}
          keyDownHandler={handleKeys}
          readOnly={!editing}
          refValue={checkBoxRef}
          disabled={disabled}
        />
      </InnerContainer>
      <ButtonContainer>
        {!disabled && (
          <InputButtons
            cancelEdit={cancelEdit}
            editing={editing}
            saveEdit={saveEdit}
            setEditing={setEditing}
            notAbsolute={true}
          />
        )}
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InnerContainer = styled.div`
  display: flex;
`;

const ButtonContainer = styled.div`
  white-space: nowrap;
`;

export default EditableCheckbox;
