import React, { useContext, useEffect } from "react";
import ColoredTopPartContext from "../../../../contexts/coloredTopPartContext/ColoredTopPartContext";
import AboutSection from "./AboutSection";
import CalorieCalculatorLanding from "./CalorieCalculatorLanding";
import FeaturesSection from "./FeaturesSection";
import HeroSection from "./HeroSection";
import ScreenshotSection from "./ScreenshotSection";
import UspSection from "./UspSection";

const Landing = (): JSX.Element => {
  const coloredTopPartContextValue = useContext(ColoredTopPartContext);

  useEffect(() => {
    coloredTopPartContextValue?.setColoredTopPart(true);

    return () => {
      coloredTopPartContextValue?.setColoredTopPart(false);
    };
  }, [coloredTopPartContextValue?.setColoredTopPart]);

  return (
    <>
      <HeroSection />
      <AboutSection />
      <ScreenshotSection />
      <UspSection />
      <FeaturesSection />
      <CalorieCalculatorLanding />
    </>
  );
};

export default Landing;
