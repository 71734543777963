import {
  maxNumberOfRecipesPro,
  maxNumberOfRecipesPlus,
  maxNumberOfRecipesBasic,
} from "../constants/constants";
import { subscriptionType } from "../types/userTypes";

const getAllowedNumberOfRecipes = (subscription: subscriptionType): number => {
  switch (subscription) {
    case "basic":
      return maxNumberOfRecipesBasic;
      break;
    case "plus":
      return maxNumberOfRecipesPlus;
      break;
    case "pro":
      return maxNumberOfRecipesPro;
      break;
  }
};

export default getAllowedNumberOfRecipes;
