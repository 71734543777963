import React from "react";
import styled from "styled-components";
import NUTRIENT_STANDARD_NAMES from "../../../../../constants/ingredients/nutrientStandardNames";
import { NutrientStandardType } from "../../../../../types/standardTypes";
import Modal from "../../../../shared/Modal";
import IntroductionText from "../../../../shared/text/IntroductionText";

interface Props {
  nutrient: NutrientStandardType | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NutrientInfoModal = ({
  nutrient,
  open,
  setOpen,
}: Props): JSX.Element | null => {
  if (nutrient === null) {
    return null;
  }

  const title = NUTRIENT_STANDARD_NAMES[nutrient];

  return (
    <Modal open={open} setOpen={setOpen} title={title} closable={true}>
      {nutrient === "iodine" && (
        <StyledIntroductionText>
          Iodine data is missing from The United States Department of
          Agriculture (USDA) database, which might be why it appears low.
        </StyledIntroductionText>
      )}
      {nutrient === "chloride" && (
        <StyledIntroductionText>
          Chloride data is missing from The United States Department of
          Agriculture (USDA) database, which might be why it appears low.
        </StyledIntroductionText>
      )}
      {nutrient === "choline" && (
        <StyledIntroductionText>
          Choline is a nutrient the body can produce in the presence of vitamins
          B9 and B12. It is also found in some foods.
        </StyledIntroductionText>
      )}
    </Modal>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  margin-top: ${(props) => props.theme.spacing2};
  margin-bottom: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.black4};
`;

export default NutrientInfoModal;
