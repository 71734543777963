import React, { useContext, useEffect } from "react";
import DogBannersContext from "../../../../contexts/dogBannersContext/DogBannersContext";
import VerificationTokenMessageContext from "../../../../contexts/verificationTokenMessageContext/VerificationTokenMessageContext";
import useGetAuthUser from "../../../../hooks/api/useGetAuthUser";
import ExistingDog from "./ExistingDog";
import NewDog from "./NewDog";

const Dog = (): JSX.Element => {
  const authUserQuery = useGetAuthUser();

  const dogBannersContextValue = useContext(DogBannersContext);
  const verificationTokenMessageContextValue = useContext(
    VerificationTokenMessageContext
  );

  useEffect(() => {
    return () => {
      if (
        dogBannersContextValue &&
        dogBannersContextValue.dogBanners.length > 0
      ) {
        dogBannersContextValue.setDogBanners([]);
      }
      if (verificationTokenMessageContextValue?.verificationTokenMessage) {
        verificationTokenMessageContextValue.setVerificationTokenMessage("");
      }
    };
  }, []);

  if (authUserQuery.data?.selectedDog) {
    return <ExistingDog />;
  } else {
    return <NewDog />;
  }
};

export default Dog;
