import React from "react";
import styled from "styled-components";
import SmallTextBold from "../../text/SmallTextBold";

const CustomDatePickerDay = (dayOfMonth: number): JSX.Element => {
  return (
    <StyledSmallTextBold asValue={"span"}>{dayOfMonth}</StyledSmallTextBold>
  );
};

const StyledSmallTextBold = styled(SmallTextBold)`
  color: ${(props) => props.theme.green4};
`;

export default CustomDatePickerDay;
