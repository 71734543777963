const NUTRIENT_INGREDIENT_NAMES = {
  // Calories
  calories: "Calories",

  // Macronutrients
  protein: "Protein",
  fat: "Fat",
  carbohydrate: "Carbohydrate",
  water: "Water",
  ash: "Ash",

  // Amino acids
  tryptophan: "Tryptophan",
  threonine: "Threonine",
  isoleucine: "Isoleucine",
  leucine: "Leucine",
  lysine: "Lysine",
  methionine: "Methionine",
  cystine: "Cystine",
  phenylalanine: "Phenylalanine",
  tyrosine: "Tyrosine",
  valine: "Valine",
  arginine: "Arginine",
  histidine: "Histidine",

  // Fatty acids
  linoleicAcid: "Linoleic acid",
  alphaLinoleicAcid: "Alpha-Linolenic acid",
  arachidonicAcid: "Arachidonic acid",
  epa: "EPA",
  dha: "DHA",

  // Carbohydrates
  fiber: "Fiber",

  // Minerals
  calcium: "Calcium",
  iron: "Iron",
  magnesium: "Magnesium",
  phosphorus: "Phosphorus",
  potassium: "Potassium",
  chloride: "Chloride",
  sodium: "Sodium",
  zinc: "Zinc",
  copper: "Copper",
  manganese: "Manganese",
  selenium: "Selenium",
  iodine: "Iodine",

  // Vitamins
  thiaminB1: "Thiamin, Vitamin B1",
  riboflavinB2: "Riboflavin, Vitamin B2",
  niacinB3: "Niacin, Vitamin B3",
  pantothenicAcidB5: "Pantothenic acid, Vitamin B5",
  vitaminB6: "Pyridoxine, Vitamin B6",
  folicAcidB9: "Folic acid, Vitamin B9",
  choline: "Choline",
  vitaminB12: "Cobalamin, Vitamin B12",
  vitaminA: "Retinol, Vitamin A",
  vitaminE: "Vitamin E",
  cholecalciferolD3: "Vitamin D3, cholecalciferol",
  phylloquinoneK: "Vitamin K, phylloquinone",
};

export default NUTRIENT_INGREDIENT_NAMES;
