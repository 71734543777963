import { breedSizeType } from "../types/dogTypes";
import { kgOrLb } from "../types/userTypes";

const getValuesLabelsBreedSize = (
  weightUnit: kgOrLb
): Map<breedSizeType, string> => {
  const valuesLabelsBreedSize = new Map<breedSizeType, string>();
  valuesLabelsBreedSize.set(
    "toy",
    "Toy " + (weightUnit === "lb" ? "(0-10 lb)" : "(0-5 kg)")
  );
  valuesLabelsBreedSize.set(
    "small",
    "Small " + (weightUnit === "lb" ? "(11-25 lb)" : "(6-11 kg)")
  );
  valuesLabelsBreedSize.set(
    "medium",
    "Medium " + (weightUnit === "lb" ? "(26-45 lb)" : "(12-20 kg)")
  );
  valuesLabelsBreedSize.set(
    "large",
    "Large " + (weightUnit === "lb" ? "(46-90 lb)" : "(20-41 kg)")
  );
  valuesLabelsBreedSize.set(
    "giant",
    "Giant " + (weightUnit === "lb" ? "(91- lb)" : "(42- kg)")
  );

  return valuesLabelsBreedSize;
};

export default getValuesLabelsBreedSize;
