import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useGetIngredientsSearch from "../../../../hooks/api/useGetIngredientsSearch";
import { IngredientSearch } from "../../../../types/ingredientTypes";
import Button from "../../../shared/Button";
import LabelForm from "../../../shared/form/LabelForm";
import SimpleInputText from "../../../shared/form/simple/SimpleInputText";
import Modal from "../../../shared/Modal";
import IntroductionText from "../../../shared/text/IntroductionText";
import Fuse from "fuse.js";
import SearchResultEntry from "./SearchResultEntry";
import { RecipeFull } from "../../../../types/recipeTypes";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  recipe: RecipeFull;
}

const AddIngredientModal = ({
  open,
  setOpen,
  recipe,
}: Props): JSX.Element | null => {
  const [searchString, setSearchString] = useState<string | null>(null);
  const [searchResults, setSearchResults] = useState<
    Fuse.FuseResult<IngredientSearch>[] | null
  >(null);

  useEffect(() => {
    if (!open) {
      setSearchString(null);
    }
  }, [open]);

  useEffect(() => {
    if (searchString && searchString.length > 2) {
      const result = fuse.search(searchString);
      result.length = Math.min(result.length, 100);
      setSearchResults(result);
    } else {
      setSearchResults(null);
    }
  }, [searchString]);

  const getIngredientsSearchQuery = useGetIngredientsSearch();

  if (!getIngredientsSearchQuery.data) {
    return null;
  }

  const options: Fuse.IFuseOptions<IngredientSearch> = {
    includeScore: true,
    keys: ["name"],
    isCaseSensitive: false,
    shouldSort: true,
    ignoreLocation: true,
  };

  const fuse = new Fuse(getIngredientsSearchQuery.data, options);

  return (
    <Modal
      title="Add ingredients"
      open={open}
      setOpen={setOpen}
      closable={true}
    >
      <StyledIntroductionText>
        Most of our ingredients are sourced from The United States Department of
        Agriculture (USDA).
      </StyledIntroductionText>
      <StyledLabel name="searchString">Search for ingredients</StyledLabel>
      <StyledSimpleInput
        id="searchString"
        setValue={setSearchString}
        value={searchString}
      />
      <StyledUl>
        {searchResults &&
          searchResults.map((result) => (
            <SearchResultEntry
              key={result.item.id}
              ingredient={result.item}
              alreadyAdded={
                recipe.ingredients.filter(
                  (ingredient) => ingredient.id === result.item.id
                ).length > 0
              }
              dogId={recipe.dogId}
              recipeId={recipe.id}
            />
          ))}
      </StyledUl>
      <PurpleButton
        eventHandler={() => {
          setOpen(false);
        }}
      >
        Done
      </PurpleButton>
    </Modal>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};
  margin-top: ${(props) => props.theme.spacing2};
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const StyledLabel = styled(LabelForm)`
  color: ${(props) => props.theme.black4};
  margin-bottom: 0.25rem; // 4px
`;

const PurpleButton = styled(Button)`
  background-color: ${(props) => props.theme.purple2};
  color: ${(props) => props.theme.purple4};
  width: 100%;
  margin-top: ${(props) => props.theme.spacing2};

  &:hover {
    background-color: ${(props) => props.theme.purple1};
  }
  &:focus {
    background-color: ${(props) => props.theme.purple1};
    outline-color: ${(props) => props.theme.purple1};
  }
`;

const StyledSimpleInput = styled(SimpleInputText)`
  border-color: ${(props) => props.theme.black4};
  color: ${(props) => props.theme.black4};
`;

const StyledUl = styled.ul`
  list-style: none;
  padding: 0;
`;

export default AddIngredientModal;
