import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  className?: string;
  asValue?: "button";
  clickHandler?: () => void;
}

const ButtonText = ({
  children,
  className,
  asValue,
  clickHandler,
}: Props): JSX.Element => {
  return (
    <StyledText className={className} as={asValue} onClick={clickHandler}>
      {children}
    </StyledText>
  );
};

const StyledText = styled.p`
  font-size: ${(props) => props.theme.buttonTextSize};
  line-height: ${(props) => props.theme.componentLineHeight};
  font-family: "DM Sans Bold", sans-serif;
  font-weight: 400;

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.buttonTextSizeMobile};
  }
`;

export default ButtonText;
