import React, { useEffect, useRef, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import DogSwitcher from "./DogSwitcher";
import { selectedAppMenuItem } from "../../../types/frontendTypes";
import AppMenuItems from "./AppMenuItems";
import TopPartAppMenu from "./TopPartAppMenu";
import FocusTrap from "focus-trap-react";

interface Props {
  selectedMenuItem: selectedAppMenuItem;
  scrollTo: number;
  setScrollTo: React.Dispatch<React.SetStateAction<number>>;
}

const LeftMenu = ({
  selectedMenuItem,
  scrollTo,
  setScrollTo,
}: Props): JSX.Element => {
  const lastScrollTop = useRef(0);
  const [headerVisible, setHeaderVisible] = useState(true);

  const [topValue, setTopValue] = useState<null | number>(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const stuckLimit = 60;

  useEffect(() => {
    const handleScroll = () => {
      const scrollingUp =
        window.pageYOffset < lastScrollTop.current || window.pageYOffset <= 0;
      const withinStuckLimit = window.pageYOffset <= stuckLimit;

      setHeaderVisible(scrollingUp || withinStuckLimit);
      lastScrollTop.current = window.pageYOffset;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setMobileMenuOpen(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  useEffect(() => {
    if (mobileMenuOpen) {
      setTopValue(-window.scrollY);
    } else {
      if (topValue !== null) {
        setScrollTo(-topValue);
        setTopValue(null);
      }
    }
  }, [mobileMenuOpen]);

  useEffect(() => {
    if (topValue === null) {
      const html = document.querySelector("html");
      if (html) {
        html.style.scrollBehavior = "auto";
        window.scrollTo({
          left: 0,
          top: scrollTo,
        });
        html.style.scrollBehavior = "smooth";
      }
    }
  }, [topValue]);

  return (
    <>
      <FocusTrap active={mobileMenuOpen}>
        <StyledLeftMenu visible={headerVisible} open={mobileMenuOpen}>
          <TopPartAppMenu
            homePage={selectedMenuItem === "dogs"}
            setMobileMenuOpen={setMobileMenuOpen}
            mobileMenuOpen={mobileMenuOpen}
          />
          <DogSwitcher mobileMenuOpen={mobileMenuOpen} />
          <AppMenuItems
            selectedMenuItem={selectedMenuItem}
            mobileMenuOpen={mobileMenuOpen}
            setMobileMenuOpen={setMobileMenuOpen}
          />
        </StyledLeftMenu>
      </FocusTrap>
      {topValue !== null && <BodyStyleGlobal topValue={topValue} />}
    </>
  );
};

const StyledLeftMenu = styled.header<{ visible: boolean; open: boolean }>`
  min-width: 18.5rem; // 296px
  width: 18.5rem; // 296px
  background-color: ${(props) => props.theme.green1};
  border-top-right-radius: 2rem; // 32px
  border-bottom-right-radius: 2rem; // 32px
  padding: ${(props) => props.theme.spacing3};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
    padding-top: ${(props) => props.theme.spacing1};
    padding-bottom: ${(props) => props.theme.spacing1};
    padding-left: ${(props) =>
      "calc(" + props.theme.spacing1 + " + env(safe-area-inset-left))"};
    padding-right: ${(props) =>
      "calc(" + props.theme.spacing1 + " + env(safe-area-inset-right))"};
    box-sizing: border-box;
    transform: ${(props) =>
      props.visible || props.open ? "none" : "translateY(-100%)"};
    transition: transform ${(props) => props.theme.transitionValueHeader};

    bottom: ${(props) => props.open && "0"};
    overflow-y: ${(props) => props.open && "auto"};
  }

  @media print {
    display: none;
  }
`;

const BodyStyleGlobal = createGlobalStyle<{ topValue: number }>`
  body {
    top: ${(props) => props.topValue + "px"};
    position: fixed;
    width: 100%;
    bottom: 0;
  }
`;

export default LeftMenu;
