import React from "react";
import styled from "styled-components";

interface Props {
  asValue: "h1" | "h2" | "h3" | "h4" | "h5" | "legend" | "span";
  children: React.ReactNode;
  className?: string;
  tabIndex?: number;
  refValue?: React.MutableRefObject<HTMLHeadingElement | null> | null;
  id?: string;
}

const HeadingBase = ({
  asValue,
  children,
  className,
  tabIndex,
  refValue,
  id,
}: Props): JSX.Element => {
  return (
    <StyledBaseHeading
      className={className}
      tabIndex={tabIndex}
      ref={refValue}
      id={id}
      as={asValue}
    >
      {children}
    </StyledBaseHeading>
  );
};

const StyledBaseHeading = styled.h1`
  font-family: "Nunito Bold", sans-serif;
  line-height: ${(props) => props.theme.headingLineHeight};
  font-weight: 400;

  &:focus {
    outline: none;
  }
`;

export default HeadingBase;
