import React, { useState } from "react";
import { ReactComponent as XCircleIcon } from "../../../assets/icons/x-circle.svg";
import styled from "styled-components";
import DeleteRecipeModal from "./DeleteRecipeModal";
import useGetAuthUser from "../../../hooks/api/useGetAuthUser";
import useGetDogs from "../../../hooks/api/useGetDogs";
import useGetRecipes from "../../../hooks/api/useGetRecipes";
import TextBold from "../../shared/text/TextBold";
import Text from "../../shared/text/Text";
import WrapLink from "../../shared/WrapLink";
import ButtonText from "../../shared/text/ButtonText";
import Tag from "../../shared/Tag";

const DeleteRecipeTable = (): JSX.Element | null => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteDogId, setDeleteDogId] = useState<null | string>(null);
  const [deleteRecipeId, setDeleteRecipeId] = useState<null | string>(null);

  const authUserQuery = useGetAuthUser();
  const getDogsQuery = useGetDogs();
  const getRecipesQuery = useGetRecipes();

  if (
    authUserQuery.data === undefined ||
    authUserQuery.data.kgOrLb === null ||
    getDogsQuery.data === undefined ||
    getRecipesQuery.data === undefined
  ) {
    return null;
  }

  return (
    <>
      <DeleteRecipeModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        dogId={deleteDogId}
        recipeId={deleteRecipeId}
      />
      <StyledTable role="grid">
        <thead role="presentation">
          <HeadRow role="row">
            <TableHead role="columnheader">
              <StyledTextBold>Name</StyledTextBold>
            </TableHead>
            <TableHead role="columnheader">
              <StyledTextBoldNoMargin>Remove</StyledTextBoldNoMargin>
            </TableHead>
          </HeadRow>
        </thead>
        <tbody role="presentation">
          {getRecipesQuery.data
            .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
            .map((recipe) => (
              <tr key={recipe.id} role="row">
                <DataCell>
                  <RecipeContainer>
                    <RecipeName>{recipe.name}</RecipeName>
                    <CurrentTag>
                      {
                        getDogsQuery.data.find((dog) => dog.id === recipe.dogId)
                          ?.name
                      }
                    </CurrentTag>
                  </RecipeContainer>
                </DataCell>
                <DataCell>
                  <ClickWrapper
                    asButton={true}
                    clickHandler={() => {
                      setDeleteDogId(recipe.dogId);
                      setDeleteRecipeId(recipe.id);
                      setDeleteModalOpen(true);
                    }}
                  >
                    <StyledXCircleIcon />
                    <StyledButtonText>Remove</StyledButtonText>
                  </ClickWrapper>
                </DataCell>
              </tr>
            ))}
        </tbody>
      </StyledTable>
    </>
  );
};

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const HeadRow = styled.tr`
  border-bottom: 0.125rem solid ${(props) => props.theme.black1};
`;

const TableHead = styled.th`
  text-align: left;
  padding: 0;
  padding-bottom: ${(props) => props.theme.spacing1};
`;

const StyledTextBold = styled(TextBold)`
  color: ${(props) => props.theme.black4};
  margin-right: ${(props) => props.theme.spacing1};
`;

const StyledTextBoldNoMargin = styled(StyledTextBold)`
  margin-right: 0;
`;

const DataCell = styled.th`
  padding: 0;
  text-align: left;
  padding-top: ${(props) => props.theme.spacing1};
`;

const RecipeContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: ${(props) => props.theme.spacing1};
  align-items: flex-start;

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    flex-direction: column;
  }
`;

const RecipeName = styled(Text)`
  color: ${(props) => props.theme.black4};
  word-break: break-word;
  margin-right: ${(props) => props.theme.halfSpacing1};

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    margin-right: 0;
    margin-bottom: ${(props) => props.theme.halfSpacing1};
  }
`;

const CurrentTag = styled(Tag)`
  background-color: ${(props) => props.theme.purple1};
  color: ${(props) => props.theme.purple4};
`;

const ClickWrapper = styled(WrapLink)`
  text-decoration: none;
  display: flex;
  align-items: center;

  &:focus {
    outline-color: ${(props) => props.theme.black4};
  }
`;

const StyledXCircleIcon = styled(XCircleIcon)`
  width: 1.5rem; // 24px
  height: 1.5rem; // 24px
  margin-right: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.black4};
`;

const StyledButtonText = styled(ButtonText)`
  color: ${(props) => props.theme.black4};
`;

export default DeleteRecipeTable;
