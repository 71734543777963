import React from "react";
import styled from "styled-components";

const StyledHr = styled.hr`
  border: none;
  height: 1px;
  background-color: ${(props) => props.theme.black1};
`;

interface Props {
  className?: string;
}

const Line = ({ className }: Props): JSX.Element => {
  return <StyledHr className={className} />;
};

export default Line;
