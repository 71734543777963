import { NutrientsStandard } from "../../types/standardTypes";

const adjustNutrientReqActivityLevel = (
  nutrientsTemplate: NutrientsStandard,
  activityLevelRatio: number
): NutrientsStandard => {
  const adjustedNutrients: NutrientsStandard = {
    // Macronutrients
    protein:
      nutrientsTemplate.protein === null
        ? null
        : nutrientsTemplate.protein * activityLevelRatio,
    fat:
      nutrientsTemplate.fat === null
        ? null
        : nutrientsTemplate.fat * activityLevelRatio,
    carbohydrate:
      nutrientsTemplate.carbohydrate === null
        ? null
        : nutrientsTemplate.carbohydrate * activityLevelRatio,
    water:
      nutrientsTemplate.water === null
        ? null
        : nutrientsTemplate.water * activityLevelRatio,
    ash:
      nutrientsTemplate.ash === null
        ? null
        : nutrientsTemplate.ash * activityLevelRatio,

    // Amino acids
    tryptophan:
      nutrientsTemplate.tryptophan === null
        ? null
        : nutrientsTemplate.tryptophan * activityLevelRatio,
    threonine:
      nutrientsTemplate.threonine === null
        ? null
        : nutrientsTemplate.threonine * activityLevelRatio,
    isoleucine:
      nutrientsTemplate.isoleucine === null
        ? null
        : nutrientsTemplate.isoleucine * activityLevelRatio,
    leucine:
      nutrientsTemplate.leucine === null
        ? null
        : nutrientsTemplate.leucine * activityLevelRatio,
    lysine:
      nutrientsTemplate.lysine === null
        ? null
        : nutrientsTemplate.lysine * activityLevelRatio,
    methionine:
      nutrientsTemplate.methionine === null
        ? null
        : nutrientsTemplate.methionine * activityLevelRatio,
    methionineAndCystine:
      nutrientsTemplate.methionineAndCystine === null
        ? null
        : nutrientsTemplate.methionineAndCystine * activityLevelRatio,
    phenylalanine:
      nutrientsTemplate.phenylalanine === null
        ? null
        : nutrientsTemplate.phenylalanine * activityLevelRatio,
    phenylalanineAndTyrosine:
      nutrientsTemplate.phenylalanineAndTyrosine === null
        ? null
        : nutrientsTemplate.phenylalanineAndTyrosine * activityLevelRatio,
    valine:
      nutrientsTemplate.valine === null
        ? null
        : nutrientsTemplate.valine * activityLevelRatio,
    arginine:
      nutrientsTemplate.arginine === null
        ? null
        : nutrientsTemplate.arginine * activityLevelRatio,
    histidine:
      nutrientsTemplate.histidine === null
        ? null
        : nutrientsTemplate.histidine * activityLevelRatio,

    // Fatty acids
    linoleicAcid:
      nutrientsTemplate.linoleicAcid === null
        ? null
        : nutrientsTemplate.linoleicAcid * activityLevelRatio,
    alphaLinoleicAcid:
      nutrientsTemplate.alphaLinoleicAcid === null
        ? null
        : nutrientsTemplate.alphaLinoleicAcid * activityLevelRatio,
    arachidonicAcid:
      nutrientsTemplate.arachidonicAcid === null
        ? null
        : nutrientsTemplate.arachidonicAcid * activityLevelRatio,
    linoleicToAlphaLinoleicAcids:
      nutrientsTemplate.linoleicToAlphaLinoleicAcids,
    epaAndDha:
      nutrientsTemplate.epaAndDha === null
        ? null
        : nutrientsTemplate.epaAndDha * activityLevelRatio,

    // Carbohydrates
    fiber:
      nutrientsTemplate.fiber === null
        ? null
        : nutrientsTemplate.fiber * activityLevelRatio,

    // Minerals
    calcium:
      nutrientsTemplate.calcium === null
        ? null
        : nutrientsTemplate.calcium * activityLevelRatio,
    iron:
      nutrientsTemplate.iron === null
        ? null
        : nutrientsTemplate.iron * activityLevelRatio,
    magnesium:
      nutrientsTemplate.magnesium === null
        ? null
        : nutrientsTemplate.magnesium * activityLevelRatio,
    phosphorus:
      nutrientsTemplate.phosphorus === null
        ? null
        : nutrientsTemplate.phosphorus * activityLevelRatio,
    calciumToPhosphorus: nutrientsTemplate.calciumToPhosphorus,
    potassium:
      nutrientsTemplate.potassium === null
        ? null
        : nutrientsTemplate.potassium * activityLevelRatio,
    chloride:
      nutrientsTemplate.chloride === null
        ? null
        : nutrientsTemplate.chloride * activityLevelRatio,
    sodium:
      nutrientsTemplate.sodium === null
        ? null
        : nutrientsTemplate.sodium * activityLevelRatio,
    zinc:
      nutrientsTemplate.zinc === null
        ? null
        : nutrientsTemplate.zinc * activityLevelRatio,
    copper:
      nutrientsTemplate.copper === null
        ? null
        : nutrientsTemplate.copper * activityLevelRatio,
    zincToCopper: nutrientsTemplate.zincToCopper,
    manganese:
      nutrientsTemplate.manganese === null
        ? null
        : nutrientsTemplate.manganese * activityLevelRatio,
    selenium:
      nutrientsTemplate.selenium === null
        ? null
        : nutrientsTemplate.selenium * activityLevelRatio,
    iodine:
      nutrientsTemplate.iodine === null
        ? null
        : nutrientsTemplate.iodine * activityLevelRatio,

    // Vitamins
    thiaminB1:
      nutrientsTemplate.thiaminB1 === null
        ? null
        : nutrientsTemplate.thiaminB1 * activityLevelRatio,
    riboflavinB2:
      nutrientsTemplate.riboflavinB2 === null
        ? null
        : nutrientsTemplate.riboflavinB2 * activityLevelRatio,
    niacinB3:
      nutrientsTemplate.niacinB3 === null
        ? null
        : nutrientsTemplate.niacinB3 * activityLevelRatio,
    pantothenicAcidB5:
      nutrientsTemplate.pantothenicAcidB5 === null
        ? null
        : nutrientsTemplate.pantothenicAcidB5 * activityLevelRatio,
    vitaminB6:
      nutrientsTemplate.vitaminB6 === null
        ? null
        : nutrientsTemplate.vitaminB6 * activityLevelRatio,
    folicAcidB9:
      nutrientsTemplate.folicAcidB9 === null
        ? null
        : nutrientsTemplate.folicAcidB9 * activityLevelRatio,
    choline:
      nutrientsTemplate.choline === null
        ? null
        : nutrientsTemplate.choline * activityLevelRatio,
    vitaminB12:
      nutrientsTemplate.vitaminB12 === null
        ? null
        : nutrientsTemplate.vitaminB12 * activityLevelRatio,
    vitaminA:
      nutrientsTemplate.vitaminA === null
        ? null
        : nutrientsTemplate.vitaminA * activityLevelRatio,
    vitaminE:
      nutrientsTemplate.vitaminE === null
        ? null
        : nutrientsTemplate.vitaminE * activityLevelRatio,
    cholecalciferolD3:
      nutrientsTemplate.cholecalciferolD3 === null
        ? null
        : nutrientsTemplate.cholecalciferolD3 * activityLevelRatio,
    phylloquinoneK:
      nutrientsTemplate.phylloquinoneK === null
        ? null
        : nutrientsTemplate.phylloquinoneK * activityLevelRatio,
  };

  return adjustedNutrients;
};

export default adjustNutrientReqActivityLevel;
