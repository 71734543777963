import React, { useState } from "react";
import AuthEmailContext from "./AuthEmailContext";

interface Props {
  children: React.ReactNode;
}

const AuthEmailContextProvider = ({ children }: Props): JSX.Element => {
  const [authEmail, setAuthEmail] = useState("");

  return (
    <AuthEmailContext.Provider value={{ authEmail, setAuthEmail }}>
      {children}
    </AuthEmailContext.Provider>
  );
};

export default AuthEmailContextProvider;
