import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const IntroductionText = ({ children, className }: Props): JSX.Element => {
  return <StyledText className={className}>{children}</StyledText>;
};

const StyledText = styled.p`
  font-size: ${(props) => props.theme.introductionTextSize};
  line-height: ${(props) => props.theme.textLineHeight};
  font-family: "DM Sans Medium", sans-serif;
  font-weight: 400;

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.introductionTextSizeMobile};
  }
`;

export default IntroductionText;
