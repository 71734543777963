import adjustNutrientReqCalories from "../../../services/nutrientReqs/adjustNutrientReqCalories";
import { NutrientsStandard } from "../../../types/standardTypes";
import {
  NUTRIENT_REQ_NRC_PUPPY_4_14_MAX,
  NUTRIENT_REQ_NRC_PUPPY_4_14_MIN,
} from "./nutrientReqNrcPuppy414";

const NUTRIENT_REQ_NRC_PUPPY_AFTER_14_MIN: NutrientsStandard = {
  ...NUTRIENT_REQ_NRC_PUPPY_4_14_MIN,
  // Macronutrients
  protein: 43.8,

  // Amino acids
  tryptophan: 0.45,
  threonine: 1.58,
  isoleucine: 1.25,
  leucine: 2.05,
  lysine: 1.75,
  methionine: 0.65,
  methionineAndCystine: 1.33,
  phenylalanine: 1.25,
  phenylalanineAndTyrosine: 2.5,
  valine: 1.4,
  arginine: 1.65,
  histidine: 0.63,
};

const NUTRIENT_REQ_NRC_PUPPY_AFTER_14_MAX: NutrientsStandard = {
  ...NUTRIENT_REQ_NRC_PUPPY_4_14_MAX,
  // Amino acids
  lysine: 5,
};

export const getNutrientReqNrcPuppyAfter14Min = (
  protein: number,
  calorieRequriement: number
): NutrientsStandard => {
  let specificReq = NUTRIENT_REQ_NRC_PUPPY_AFTER_14_MIN;
  const proteinDiff = protein - 175;
  if (proteinDiff > 0) {
    specificReq = {
      ...specificReq,
      arginine: (specificReq.arginine as number) + proteinDiff * 0.01,
    };
  }

  const adjustedNutrients = adjustNutrientReqCalories(
    specificReq,
    calorieRequriement
  );

  return adjustedNutrients;
};

export const getNutrientReqNrcPuppyAfter14Max = (
  calorieRequriement: number
): NutrientsStandard => {
  const adjustedNutrients = adjustNutrientReqCalories(
    NUTRIENT_REQ_NRC_PUPPY_AFTER_14_MAX,
    calorieRequriement
  );

  return adjustedNutrients;
};
