import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../../assets/images/mealment-logotype.svg";
import WrapLink from "../../shared/WrapLink";
import { ReactComponent as MenuIcon } from "../../../assets/icons/menu.svg";
import { ReactComponent as XIcon } from "../../../assets/icons/x.svg";

interface Props {
  homePage: boolean;
  setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mobileMenuOpen: boolean;
}

const TopPartAppMenu = ({
  homePage,
  setMobileMenuOpen,
  mobileMenuOpen,
}: Props): JSX.Element => {
  return (
    <TopPart open={mobileMenuOpen}>
      <HamburgerContainer>
        <MenuLink
          asButton={true}
          clickHandler={() => {
            setMobileMenuOpen(!mobileMenuOpen);
          }}
        >
          <StyledMenuIcon as={mobileMenuOpen ? XIcon : MenuIcon} />
          <HamburgerSpan>{mobileMenuOpen ? "Close" : "Menu"}</HamburgerSpan>
        </MenuLink>
      </HamburgerContainer>
      <StyledWrapLink
        to="/dogs"
        clickHandler={() => {
          if (mobileMenuOpen) {
            setMobileMenuOpen(false);
          }
          if (homePage) {
            window.scrollTo(0, 0);
          }
        }}
      >
        <StyledLogo aria-label="Mealment logotype with a link to the start page" />
      </StyledWrapLink>
      <EmptyDiv />
    </TopPart>
  );
};

const StyledLogo = styled(Logo)`
  height: 2.5rem; // 40px
  fill: ${(props) => props.theme.green4};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    height: 2rem; // 32px
  }
`;

const StyledWrapLink = styled(WrapLink)`
  width: fit-content;

  &:focus {
    outline-color: ${(props) => props.theme.green4};
  }
  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: 0;
  }
`;

const TopPart = styled.div<{ open: boolean }>`
  margin-bottom: ${(props) => props.theme.spacing3};

  display: flex;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: ${(props) => (props.open ? "2rem" : 0)};
  }
`;

const StyledMenuIcon = styled(MenuIcon)`
  width: ${(props) => props.theme.spacing2};
  height: ${(props) => props.theme.spacing2};
  color: ${(props) => props.theme.green4};
`;

const MenuLink = styled(WrapLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;

  &:focus {
    outline-color: ${(props) => props.theme.green4};
  }
`;

const HamburgerSpan = styled.span`
  font-size: 0.75rem; // 12px
  font-family: "DM Sans Medium", sans-serif;
  font-weight: 400;
  color: ${(props) => props.theme.green4};
`;

const EmptyDiv = styled.div`
  flex: 1;
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    display: initial;
  }
`;

const HamburgerContainer = styled.div`
  display: none;
  flex: 1;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    display: initial;
  }
`;

export default TopPartAppMenu;
