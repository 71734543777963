import React from "react";
import styled from "styled-components";
import {
  IngredientFull,
  NutrientIngredientType,
} from "../../../../types/ingredientTypes";
import Heading2 from "../../../shared/text/headings/Heading2";
import Heading3 from "../../../shared/text/headings/Heading3";
import useChangeIngredientNutrient from "../../../../hooks/api/useChangeIngredientNutrient";
import NutrientEditable from "./NutrientEditable";

interface Props {
  ingredient: IngredientFull;
}

const NutrientsPartIngredient = ({ ingredient }: Props): JSX.Element => {
  const changeIngredientNutrientMutation = useChangeIngredientNutrient(
    ingredient.id
  );

  let title: string;
  if (ingredient.defaultUnit === "gram") {
    title = `Nutrients (per 100g)`;
  } else if (ingredient.defaultUnitShort !== null) {
    title = `Nutrients (per 1 ${ingredient.defaultUnitShort})`;
  } else {
    title = `Nutrients (per 1 ${ingredient.defaultUnit})`;
  }

  const saveValue = (amount: number, nutrient: NutrientIngredientType) => {
    const amountFormatted = Number.isNaN(amount) ? null : amount;
    changeIngredientNutrientMutation.mutate({
      amount: amountFormatted,
      nutrient: nutrient,
    });
  };

  return (
    <>
      <StyledHeading2>{title}</StyledHeading2>
      <StyledHeading3>Calories</StyledHeading3>
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"calories"}
      />

      <StyledHeading3>Macronutrients</StyledHeading3>
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"protein"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"fat"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"carbohydrate"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"water"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"ash"}
      />

      <StyledHeading3>Amino acids</StyledHeading3>
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"tryptophan"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"threonine"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"isoleucine"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"leucine"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"lysine"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"methionine"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"cystine"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"phenylalanine"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"tyrosine"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"valine"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"arginine"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"histidine"}
      />

      <StyledHeading3>Fatty acids</StyledHeading3>
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"linoleicAcid"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"alphaLinoleicAcid"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"arachidonicAcid"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"epa"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"dha"}
      />

      <StyledHeading3>Carbohydrates</StyledHeading3>
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"fiber"}
      />

      <StyledHeading3>Minerals</StyledHeading3>
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"calcium"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"iron"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"magnesium"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"phosphorus"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"potassium"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"chloride"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"sodium"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"zinc"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"copper"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"manganese"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"selenium"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"iodine"}
      />

      <StyledHeading3>Vitamins</StyledHeading3>
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"thiaminB1"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"riboflavinB2"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"niacinB3"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"pantothenicAcidB5"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"vitaminB6"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"folicAcidB9"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"choline"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"vitaminB12"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"vitaminA"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"vitaminE"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"cholecalciferolD3"}
      />
      <NutrientEditable
        ingredient={ingredient}
        saveValue={saveValue}
        nutrient={"phylloquinoneK"}
      />
    </>
  );
};

const StyledHeading2 = styled(Heading2)`
  margin-top: ${(props) => props.theme.spacing3};
  color: ${(props) => props.theme.black4};
`;

const StyledHeading3 = styled(Heading3)`
  margin-top: ${(props) => props.theme.spacing3};
  color: ${(props) => props.theme.black4};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-top: ${(props) => props.theme.spacing2};
  }
`;

export default NutrientsPartIngredient;
