import React from "react";
import styled from "styled-components";
import StyledLink from "../../../shared/StyledLink";
import Heading1 from "../../../shared/text/headings/Heading1";
import Text from "../../../shared/text/Text";

const Page404 = (): JSX.Element => {
  return (
    <Container>
      <TextContainer>
        <StyledHeading1>Sorry, we couldn&apos;t find that page</StyledHeading1>
        <StyledText>
          We couldn&apos;t find what your were looking for. Please, try another
          address or maybe we can help you back on track with following links.
        </StyledText>
        <ul>
          <StyledTextNoMargin asValue="li">
            Go to <StyledStyledLink to="/">start page</StyledStyledLink>.
          </StyledTextNoMargin>

          <StyledTextNoMargin asValue="li">
            Contact us at{" "}
            <StyledStyledLink href="mailto:hello@mealment.com">
              hello@mealment.com
            </StyledStyledLink>
            .
          </StyledTextNoMargin>
        </ul>
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.div`
  max-width: 45rem; // 720px
  margin-left: ${(props) => props.theme.spacing1};
  margin-right: ${(props) => props.theme.spacing1};
  margin-top: ${(props) => props.theme.spacing5};
  margin-bottom: ${(props) => props.theme.spacing5};
`;

const StyledHeading1 = styled(Heading1)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const StyledTextNoMargin = styled(StyledText)`
  margin-bottom: 0;
`;

const StyledStyledLink = styled(StyledLink)`
  color: ${(props) => props.theme.black4};

  &:hover {
    color: ${(props) => props.theme.black3};
  }
  &:focus {
    outline-color: ${(props) => props.theme.black3};
    color: ${(props) => props.theme.black3};
  }
`;

export default Page404;
