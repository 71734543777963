import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult } from "react-query";
import { BACKEND_URL } from "../../constants/constants";

interface Input {
  currentPassword: string;
  newPassword: string;
}

const useChangePassword = (): UseMutationResult<void, AxiosError, Input> => {
  return useMutation(async (input) => {
    await axios.patch(`${BACKEND_URL}/api/users/password`, {
      currentPassword: input.currentPassword,
      newPassword: input.newPassword,
    });
  });
};

export default useChangePassword;
