import { breedSizeType } from "../types/dogTypes";
import { kgOrLb } from "../types/userTypes";
import calculateWeeksSinceDate from "../utils/date/calculateWeeksSinceDate";
import checkIfPuppy from "./checkIfPuppy";
import getWeigthInKg from "./getWeigthInKg";

const calculateCaloriesNeeded = (
  birthDate: Date,
  breedSize: breedSizeType,
  weightSetting: kgOrLb,
  weight: number,
  expectedWeight: number | null | undefined,
  activityLevel: number,
  spayedOrNeutered: boolean,
  gestation: boolean,
  lactation: boolean,
  matingDate: Date | null | undefined,
  parturitionDate: Date | null | undefined,
  numberOfPuppies: number | null | undefined
): number | null => {
  const weightKgs = getWeigthInKg(weight, weightSetting);

  const metabolicWeight = weightKgs ** 0.75;

  // Handle gestation case
  if (gestation) {
    if (!matingDate) {
      return null;
    }
    const weeksSinceMating = calculateWeeksSinceDate(matingDate);
    if (weeksSinceMating > 4) {
      const result = activityLevel * metabolicWeight + 26 * weightKgs;
      return Math.round(result);
    }
  }

  // Handle lactation case
  if (lactation) {
    if (!parturitionDate || !numberOfPuppies) {
      return null;
    }
    const weeksSinceParturition = calculateWeeksSinceDate(parturitionDate);
    let l: number;
    if (weeksSinceParturition < 1) {
      l = 0.75;
    } else if (weeksSinceParturition < 2) {
      l = 0.95;
    } else if (weeksSinceParturition < 3) {
      l = 1.1;
    } else {
      l = 1.2;
    }
    const n = Math.min(numberOfPuppies, 4);
    const m = Math.max(numberOfPuppies - 4, 0);

    const result =
      activityLevel * metabolicWeight + weightKgs * (24 * n + 12 * m) * l;
    return Math.round(result);
  }

  // Handle puppy case
  const isPuppy = checkIfPuppy(birthDate, breedSize);
  if (isPuppy) {
    if (!expectedWeight) {
      return null;
    }
    let result =
      activityLevel *
      metabolicWeight *
      3.2 *
      (Math.E ** (-0.87 * (weight / expectedWeight)) - 0.1);

    // Handle spayed or neutered
    if (spayedOrNeutered) {
      result = result * 0.88889;
    }
    return Math.round(result);
  }

  // Handle normal case
  let result = activityLevel * metabolicWeight;
  // Handle spayed or neutered
  if (spayedOrNeutered) {
    result = result * 0.88889;
  }
  return Math.round(result);
};

export default calculateCaloriesNeeded;
