import React, { useRef, useState } from "react";
import styled from "styled-components";
import calculateNutrientAmountWeight from "../../../../../../services/calculateNutrientAmountWeight";
import {
  DefaultWeightUnits,
  IngredientDetailed,
  TableRowValueNestedTable,
} from "../../../../../../types/ingredientTypes";
import ButtonText from "../../../../../shared/text/ButtonText";
import Text from "../../../../../shared/text/Text";
import WrapLink from "../../../../../shared/WrapLink";
import { ReactComponent as PlusIcon } from "../../../../../../assets/icons/plus.svg";
import { ReactComponent as MinusIcon } from "../../../../../../assets/icons/minus.svg";
import NestedTable from "../NestedTable";
import { useMediaQuery } from "react-responsive";
import theme from "../../../../../../style/theme";
import TextBold from "../../../../../shared/text/TextBold";
import roundToDecimal from "../../../../../../utils/roundToDecimal";
import NUTRIENT_INGREDIENT_UNITS_SHORT from "../../../../../../constants/ingredients/nutrientIngredientUnitsShort";

interface Props {
  ingredients: IngredientDetailed[];
  calorieGoal: number;
}

const NutrientRowCalories = ({
  ingredients,
  calorieGoal,
}: Props): JSX.Element => {
  const [accordionOut, setAccordionOut] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: theme.breakpoint2 });
  const refIngredientsCellTable = useRef<HTMLTableElement | null>(null);

  const unitShort = NUTRIENT_INGREDIENT_UNITS_SHORT["calories"];

  // Nested table rows
  const removedHiddenIngredients = ingredients.filter((ingredient) => {
    return ingredient.show;
  });

  let nestedTableRows: TableRowValueNestedTable[] =
    removedHiddenIngredients.map((ingredient) => {
      const value = ingredient["calories"];
      let adjustedValue: number | null = null;
      if (value === null) {
        adjustedValue = null;
      } else if (ingredient.defaultUnit !== "gram") {
        adjustedValue = value * ingredient.amount;
      } else {
        adjustedValue = calculateNutrientAmountWeight(
          ingredient.unit as DefaultWeightUnits,
          ingredient.amount,
          value
        );
      }
      return {
        name: ingredient.name,
        value: adjustedValue,
      };
    });
  nestedTableRows = nestedTableRows.sort((a, b) => {
    const aValue = a.value === null ? -1 : a.value;
    const bValue = b.value === null ? -1 : b.value;

    return aValue > bValue ? -1 : bValue > aValue ? 1 : 0;
  });

  // Total in meal
  const nutrientValues = nestedTableRows.map((row) => {
    if (row.value === null) {
      return 0;
    } else {
      return row.value;
    }
  });

  const totalInMeal = nutrientValues.reduce(
    (total, number) => total + number,
    0
  );

  const ingredientsCellVar = (
    <IngredientsCell
      accordionOut={accordionOut}
      maxHeightOpen={refIngredientsCellTable.current?.scrollHeight || 0}
    >
      {nestedTableRows && unitShort && (
        <NestedTable
          nestedTableRows={nestedTableRows}
          refIngredientsCellTable={refIngredientsCellTable}
          unitShort={unitShort}
        />
      )}
    </IngredientsCell>
  );

  const bottomCellVar = (
    <BottomCell>
      {nestedTableRows && (
        <ClickWrapper
          asButton={true}
          clickHandler={() => setAccordionOut(!accordionOut)}
          aria-expanded={accordionOut}
          aria-controls={"more-info-content"}
        >
          <StyledIcon as={accordionOut ? MinusIcon : undefined} />
          <StyledButtonText>{accordionOut ? "Less" : "More"}</StyledButtonText>
        </ClickWrapper>
      )}
    </BottomCell>
  );

  return (
    <Container role="row" accordionOut={accordionOut}>
      <NameCell role="rowheader">
        <HiddenSpan aria-hidden={isMobile ? "false" : "true"}>
          Nutrient
        </HiddenSpan>
        <NutrientSpan as={isMobile ? TextBold : undefined} asValue={"span"}>
          Energy ({unitShort})
        </NutrientSpan>
      </NameCell>
      <DataCell>
        <HiddenSpan aria-hidden={isMobile ? "false" : "true"}>
          Result
        </HiddenSpan>
        <ResultText asValue={"span"}>
          {roundToDecimal(totalInMeal, 2)}
        </ResultText>
      </DataCell>
      <DataCell>
        <HiddenSpan aria-hidden={isMobile ? "false" : "true"}>
          Requirement
        </HiddenSpan>
        <StyledText>{calorieGoal}</StyledText>
      </DataCell>

      {isMobile ? (
        <>
          {ingredientsCellVar}
          {bottomCellVar}
        </>
      ) : (
        <>
          {bottomCellVar}
          {ingredientsCellVar}
        </>
      )}
    </Container>
  );
};

const Container = styled.tr<{
  accordionOut: boolean;
}>`
  border-bottom: 0.125rem solid ${(props) => props.theme.black1}; //2px
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    display: block;
    margin-left: -${(props) => props.theme.spacing1};
    padding-left: ${(props) => props.theme.spacing1};

    margin-right: -${(props) => props.theme.spacing1};
    padding-right: ${(props) => props.theme.spacing1};
  }
  @media print {
    display: flex;
    margin-left: 0;
    padding-left: 0;

    margin-right: 0;
    padding-right: 0;
    page-break-inside: avoid;
  }
`;

const NameCell = styled.th`
  color: ${(props) => props.theme.black4};
  padding: 0;
  text-align: left;
  padding-top: ${(props) => props.theme.spacing1};
  padding-bottom: ${(props) => props.theme.spacing1};
  flex: 1;
  display: flex;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 0;
    padding-right: 0;
  }
  @media print {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    padding-bottom: ${(props) => props.theme.spacing1};
  }
`;

const DataCell = styled.td`
  padding: 0;
  text-align: left;
  padding-top: ${(props) => props.theme.spacing1};
  padding-bottom: ${(props) => props.theme.spacing1};
  flex: 1;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.25rem; // 4px
    padding-bottom: 0;
    padding-right: 0;
  }
  @media print {
    display: table-cell;
    justify-content: flex-start;
    align-items: stretch;
    padding-top: ${(props) => props.theme.spacing1};
    padding-bottom: ${(props) => props.theme.spacing1};
  }
`;

const BottomCell = styled.td`
  padding: 0;
  padding-top: ${(props) => props.theme.spacing1};
  padding-bottom: ${(props) => props.theme.spacing1};
  flex: 1;

  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    max-width: 110px;
  }
  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    display: flex;
    justify-content: center;
    padding-top: ${(props) => props.theme.halfSpacing1};
    max-width: none;
  }
  @media print {
    display: none;
  }
`;

const IngredientsCell = styled.td<{
  maxHeightOpen: number;
  accordionOut: boolean;
}>`
  padding: 0;
  flex: 1 1 100%;
  display: block;
  padding-top: ${(props) => props.accordionOut && props.theme.halfSpacing1};
  padding-bottom: ${(props) => props.accordionOut && props.theme.spacing2};
  padding-left: ${(props) => props.theme.spacing5};
  padding-right: ${(props) => props.theme.spacing5};

  overflow-y: hidden;
  overflow-x: visible;
  transition: max-height ${(props) => props.theme.transitionValue},
    padding ${(props) => props.theme.transitionValue};
  max-height: ${(props) =>
    props.accordionOut ? `${props.maxHeightOpen}px` : "0px"};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    padding-top: ${(props) => props.accordionOut && props.theme.spacing1};
    padding-bottom: ${(props) =>
      props.accordionOut && props.theme.halfSpacing1};
    padding-left: ${(props) => props.theme.spacing3};
    padding-right: ${(props) => props.theme.spacing3};
    margin-left: -${(props) => props.theme.spacing1};
    margin-right: -${(props) => props.theme.spacing1};
  }
  @media print {
    display: none;
  }
`;

const HiddenSpan = styled(Text)`
  color: ${(props) => props.theme.black4};
  display: none;
  padding-right: ${(props) => props.theme.spacing1};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    display: block;
    margin-right: auto;
  }
  @media print {
    display: none;
  }
`;

const ResultText = styled(Text)`
  color: ${(props) => props.theme.black4};
  display: block;
  width: 7.5rem; // 120px
  text-align: center;
  font-size: ${(props) => props.theme.navigationTextSize};
  margin-right: ${(props) => props.theme.spacing1};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-right: 0;
  }
  @media print {
    margin-right: ${(props) => props.theme.spacing1};
  }
`;

const ClickWrapper = styled(WrapLink)`
  text-decoration: none;
  display: flex;
  align-items: center;

  &:focus {
    outline-color: ${(props) => props.theme.black4};
  }
`;

const StyledButtonText = styled(ButtonText)`
  color: ${(props) => props.theme.black4};
  text-align: left;
  font-size: ${(props) => props.theme.navigationTextSize};
`;

const StyledIcon = styled(PlusIcon)`
  width: 1.5rem; // 24px
  height: 1.5rem; // 24px
  color: ${(props) => props.theme.black4};
  margin-right: ${(props) => props.theme.spacing1};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-right: ${(props) => props.theme.halfSpacing1};
  }
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.black4};
  font-size: ${(props) => props.theme.navigationTextSize};
  margin-right: ${(props) => props.theme.spacing1};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-right: 0;
  }
  @media print {
    margin-right: ${(props) => props.theme.spacing1};
  }
`;

const NutrientSpan = styled(Text)`
  color: ${(props) => props.theme.black4};
  word-break: break-word;
  font-size: ${(props) => props.theme.navigationTextSize};
  margin-right: ${(props) => props.theme.spacing1};
  margin-left: ${(props) => props.theme.spacing1};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    text-align: right;
    margin-right: 0;
  }
  @media print {
    text-align: left;
    margin-right: ${(props) => props.theme.spacing1};
  }
`;

export default NutrientRowCalories;
