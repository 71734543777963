import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  className?: string;
  id?: string;
  asValue?: "span";
}

const SmallText = ({
  children,
  className,
  id,
  asValue,
}: Props): JSX.Element => {
  return (
    <StyledText className={className} id={id} as={asValue}>
      {children}
    </StyledText>
  );
};

const StyledText = styled.p`
  font-size: ${(props) => props.theme.smallTextSize};
  line-height: ${(props) => props.theme.textLineHeight};
  font-family: "DM Sans Regular", sans-serif;
  font-weight: 400;
`;

export default SmallText;
