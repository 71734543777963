import React, { useState } from "react";
import styled from "styled-components";
import useChangeKgOrLb from "../../../hooks/api/useChangeKgOrLb";
import theme from "../../../style/theme";
import { kgOrLb } from "../../../types/userTypes";
import Button from "../../shared/Button";
import SimpleSwitcher from "../../shared/form/simple/SimpleSwitcher";
import Modal from "../../shared/Modal";
import IntroductionText from "../../shared/text/IntroductionText";

interface Props {
  open: boolean;
}

const UnitPreferencesModal = ({ open }: Props): JSX.Element => {
  const [weightUnit, setWeightUnit] = useState<kgOrLb>("kg");

  const changeKgOrLbMutation = useChangeKgOrLb();

  const save = () => {
    changeKgOrLbMutation.mutate({ kgOrLb: weightUnit });
  };

  return (
    <Modal
      open={open}
      title="You need to make some unit preferences"
      closable={false}
    >
      <StyledIntroductionText>
        We would like to know what units you prefer. You can change this later
        under preferences.
      </StyledIntroductionText>
      <WeightUnitSwitcher
        changeHandler={(value: kgOrLb) => {
          setWeightUnit(value);
        }}
        value={weightUnit}
        valueOption1={"kg"}
        valueOption2={"lb"}
        title="Kilograms or pounds"
        id="kgsOrLbs"
        labelOption1="Kilograms"
        labelOption2="Pounds"
        color1={theme.purple3}
        color2={theme.purple1}
        colorTitle={theme.black4}
      />
      <StyledButton eventHandler={save}>Save unit preferences</StyledButton>
    </Modal>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  margin-top: ${(props) => props.theme.spacing2};
  margin-bottom: ${(props) => props.theme.spacing2};
  color: ${(props) => props.theme.black4};
`;

// https://github.com/styled-components/styled-components/issues/1803#issuecomment-857092410
const WeightUnitSwitcher = styled(SimpleSwitcher)`
  margin-bottom: ${(props) => props.theme.spacing2};
` as typeof SimpleSwitcher;

const StyledButton = styled(Button)`
  width: 100%;
  background-color: ${(props) => props.theme.purple2};
  color: ${(props) => props.theme.purple4};

  &:hover {
    background-color: ${(props) => props.theme.purple1};
  }
  &:focus {
    background-color: ${(props) => props.theme.purple1};
    outline-color: ${(props) => props.theme.purple1};
  }
`;

export default UnitPreferencesModal;
