import React from "react";
import styled from "styled-components";
import SmallText from "./SmallText";

interface Props {
  children: React.ReactNode;
  className?: string;
  asValue?: "span";
}

const SmallTextBold = ({
  children,
  className,
  asValue,
}: Props): JSX.Element => {
  return (
    <StyledText className={className} asValue={asValue}>
      {children}
    </StyledText>
  );
};

const StyledText = styled(SmallText)`
  font-family: "DM Sans Bold", sans-serif;
  font-weight: 400;
`;

export default SmallTextBold;
