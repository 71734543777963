import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { BACKEND_URL } from "../../constants/constants";

const useDeleteIngredient = (
  ingredientId: string
): UseMutationResult<void, AxiosError, void> => {
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation(
    async () => {
      await axios.delete(`${BACKEND_URL}/api/ingredients/${ingredientId}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["ingredients"], {
          refetchActive: false,
        });
        queryClient.invalidateQueries(["recipes"]);
        history.push("/ingredients");
      },
    }
  );
};

export default useDeleteIngredient;
