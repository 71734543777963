import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../../shared/Button";
import Heading2 from "../../../shared/text/headings/Heading2";
import IntroductionText from "../../../shared/text/IntroductionText";
import FeatureRow from "./FeatureRow";
import { ReactComponent as BookmarkSvg } from "../../../../assets/icons/bookmark.svg";
import { ReactComponent as StarSvg } from "../../../../assets/icons/star.svg";
import StyledLink from "../../../shared/StyledLink";

const FeaturesSection = (): JSX.Element => {
  const [currentFeatures, setCurrentFeatures] = useState(true);

  return (
    <StyledContainer>
      <TextContainer>
        <StyledHeading>Get to know the features</StyledHeading>
        <StyledIntroductionText>
          Here are some of the highlighted features of the Mealment application.
          If you feel that there are any features missing, contact us at{" "}
          <StyledStyledLink href="mailto:suggestions@mealment.com">
            suggestions@mealment.com
          </StyledStyledLink>
        </StyledIntroductionText>
      </TextContainer>
      <ButtonContainer>
        <LeftButton
          eventHandler={() => {
            setCurrentFeatures(true);
          }}
          selected={currentFeatures}
        >
          <StyledBookmarkSvg aria-hidden="true" />
          Current features
        </LeftButton>

        <StyledButton
          eventHandler={() => {
            setCurrentFeatures(false);
          }}
          selected={!currentFeatures}
        >
          <StyledStarSvg aria-hidden="true" />
          Coming features
        </StyledButton>
      </ButtonContainer>
      <FeatureRow current={currentFeatures} />
    </StyledContainer>
  );
};

const StyledContainer = styled.section`
  margin-top: ${(props) => props.theme.spacing6};
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-top: ${(props) => props.theme.spacing5};
  }
`;

const TextContainer = styled.div`
  text-align: center;
  max-width: ${(props) => props.theme.halfWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${(props) =>
    "calc(" + props.theme.spacing1 + " + env(safe-area-inset-left))"};
  padding-right: ${(props) =>
    "calc(" + props.theme.spacing1 + " + env(safe-area-inset-right))"};
  margin-bottom: ${(props) => props.theme.spacing4};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: ${(props) => props.theme.spacing3};
  }
`;

const StyledHeading = styled(Heading2)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.black4};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.spacing3};
  padding-left: ${(props) =>
    "calc(" + props.theme.spacing1 + " + env(safe-area-inset-left))"};
  padding-right: ${(props) =>
    "calc(" + props.theme.spacing1 + " + env(safe-area-inset-right))"};

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledButton = styled(Button)<{
  selected: boolean;
}>`
  color: ${(props) =>
    props.selected ? props.theme.green1 : props.theme.green4};
  background-color: ${(props) =>
    props.selected ? props.theme.green3 : props.theme.green1};
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${(props) =>
      props.selected ? props.theme.green4 : props.theme.green2};
  }
  &:focus {
    outline-color: ${(props) =>
      props.selected ? props.theme.green4 : props.theme.green2};
    background-color: ${(props) =>
      props.selected ? props.theme.green4 : props.theme.green2};
  }
  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    width: fit-content;
  }
`;

const LeftButton = styled(StyledButton)`
  margin-right: ${(props) => props.theme.spacing1};
  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    margin-right: 0;
    margin-bottom: ${(props) => props.theme.spacing1};
  }
`;

const StyledBookmarkSvg = styled(BookmarkSvg)`
  width: 0.8125rem; // 13px
  height: 1rem; // 16px
  margin-right: ${(props) => props.theme.halfSpacing1};
`;

const StyledStarSvg = styled(StarSvg)`
  width: 1rem; // 16px
  height: 1rem; // 16px
  margin-right: ${(props) => props.theme.halfSpacing1};
`;

const StyledStyledLink = styled(StyledLink)`
  color: ${(props) => props.theme.black4};

  &:hover {
    color: ${(props) => props.theme.black3};
  }
  &:focus {
    outline-color: ${(props) => props.theme.black3};
    color: ${(props) => props.theme.black3};
  }
`;

export default FeaturesSection;
