import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import InputButtons from "./InputButtons";
import { ReactComponent as ChevronDownIcon } from "../../../../assets/icons/chevron-down.svg";
import useEditableLogic from "../../../../hooks/useEditableLogic";

interface Props<T> {
  id: string;
  placeholder?: string;
  serverValue: T | undefined;
  onSave: (value: T) => void;
  valuesLabels: Map<T, string>;
  className?: string;
  top?: number;
  tabIndex?: number;
}

const EditableSelect = <T extends string>({
  id,
  placeholder,
  serverValue,
  onSave,
  valuesLabels,
  className,
  top,
  tabIndex,
}: Props<T>): JSX.Element => {
  const [selectValue, setSelectValue] = useState<T | null>(null);
  const selectRef = useRef<HTMLSelectElement | null>(null);

  const { editing, setEditing, saveEdit, cancelEdit, handleKeys } =
    useEditableLogic<T>(
      serverValue,
      selectValue,
      setSelectValue,
      onSave,
      () => {
        selectRef.current?.focus();
      },
      (a, b) => a === b,
      (value) => value === ""
    );

  useEffect(() => {
    const close = () => {
      if (editing) {
        setEditing(false);
      }
    };
    document.addEventListener("click", close);
    return () => document.removeEventListener("click", close);
  }, []);

  return (
    <Container>
      <StyledSelect
        className={className}
        id={id}
        value={selectValue || ""}
        onChange={(
          e: React.ChangeEvent<HTMLSelectElement> & { target: { value: T } }
        ) => {
          setSelectValue(e.target.value);
        }}
        editing={editing}
        hasValue={!!serverValue && serverValue.length > 0}
        onBlur={saveEdit}
        ref={selectRef}
        onClick={() => setEditing(true)}
        onKeyDown={handleKeys}
        onFocus={() => setEditing(true)}
        tabIndex={tabIndex}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {Array.from(valuesLabels.entries()).map((entry) => (
          <option value={entry[0]} key={entry[0]}>
            {entry[1]}
          </option>
        ))}
      </StyledSelect>
      <StyledChevronIcon
        $hasValue={!!serverValue && serverValue.length > 0}
        $editing={editing}
        role="img"
        aria-label="Chevron down icon"
      />
      {!(!serverValue && !editing) && (
        <InputButtons
          cancelEdit={cancelEdit}
          editing={editing}
          saveEdit={saveEdit}
          setEditing={setEditing}
          top={top}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const StyledSelect = styled.select<{ editing: boolean; hasValue: boolean }>`
  display: block;
  width: 100%;
  padding: 1rem 2rem; // 13px 32px
  box-sizing: border-box;
  border-width: 0.125rem; // 2px
  border-radius: 100rem;
  border-style: solid;
  border-color: ${(props) =>
    props.editing || !props.hasValue ? props.theme.black4 : props.theme.black1};
  font-size: ${(props) => props.theme.buttonTextSize};
  font-family: "DM Sans Regular", sans-serif;
  font-weight: 400;
  outline: none;
  text-overflow: ellipsis;
  padding-right: ${(props) =>
    props.editing ? "12.25rem" : "4.9375rem"}; // 196px 79px
  color: ${(props) => props.theme.black4};
  appearance: none;
  line-height: ${(props) => props.theme.componentLineHeight};
  background-color: transparent;

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.buttonTextSizeMobile};
  }
  @media print {
    border: none;
    padding: 0;
  }
`;

const StyledChevronIcon = styled(ChevronDownIcon)<{
  $editing: boolean;
  $hasValue: boolean;
}>`
  width: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.black4};

  position: absolute;
  top: ${(props) => props.theme.spacing2};
  display: ${(props) =>
    props.$editing || !props.$hasValue ? "block" : "none"};
  right: ${(props) => (props.$editing ? "10.9375rem" : "2rem")}; // 175px 32px
  pointer-events: none;
`;

export default EditableSelect;
