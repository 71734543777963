import React from "react";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";
import styled from "styled-components";
import { inputType } from "../../../../types/frontendTypes";
import ErrorMessage from "../ErrorMessage";

interface Props {
  register: UseFormRegisterReturn;
  error: FieldError | undefined;
  errorMessage: string;
  type: inputType;
  autoComplete: string;
  placeholder?: string;
  className?: string;
}

const Input = ({
  register,
  error,
  errorMessage,
  type,
  autoComplete,
  placeholder,
  className,
}: Props): JSX.Element => {
  return (
    <div>
      <StyledInput
        error={error}
        {...register}
        type={type}
        placeholder={placeholder}
        id={register.name}
        aria-invalid={!!error}
        onChange={(e) => {
          register.onChange(e);
        }}
        aria-describedby={error && "error" + register.name}
        autoComplete={autoComplete}
        className={className}
      />
      {error && (
        <ErrorMessage message={errorMessage} idForDescribed={register.name} />
      )}
    </div>
  );
};

const StyledInput = styled.input<{
  error?: FieldError;
}>`
  display: block;
  width: 100%;
  padding: 1rem 2rem; // 13px 32px
  box-sizing: border-box;
  border: none;
  border-color: ${(props) => props.error && props.theme.red3};
  border-style: ${(props) => props.error && "solid"};
  border-width: 0.125rem; // 2px
  border-radius: 100rem;
  font-size: ${(props) => props.theme.buttonTextSize};
  font-family: "DM Sans Regular", sans-serif;
  font-weight: 400;
  transition: background-color ${(props) => props.theme.transitionValue};
  position: relative;

  &:focus {
    outline-width: 0.125rem; // 2px
    outline-style: solid;
    outline-offset: 0.125rem; // 2px
  }
  && {
    border-color: ${(props) => props.error && props.theme.red3};
  }
  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.buttonTextSizeMobile};
  }
`;

export default Input;
