import axios, { AxiosError } from "axios";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { BACKEND_URL } from "../../constants/constants";
import { DogValues } from "../../types/dogTypes";

const useGetDogs = (
  options?: UseQueryOptions<any, AxiosError<any>>
): UseQueryResult<DogValues[], AxiosError> => {
  return useQuery<DogValues[], AxiosError>(
    ["dogs"],
    async () => {
      const responseDogs = await axios.get<DogValues[]>(
        `${BACKEND_URL}/api/dogs`
      );

      const treatedData = responseDogs.data.map((dog: DogValues) => {
        const newDog = { ...dog };

        newDog.birthDate = new Date(dog.birthDate);
        if (dog.matingDate) {
          newDog.matingDate = new Date(dog.matingDate);
        }
        if (dog.parturitionDate) {
          newDog.parturitionDate = new Date(dog.parturitionDate);
        }

        return newDog;
      });
      return treatedData;
    },
    options
  );
};

export default useGetDogs;
