import { NutrientsStandard } from "../../types/standardTypes";

const adjustNutrientReqCalories = (
  nutrientsTemplate: NutrientsStandard,
  calorieRequriement: number
): NutrientsStandard => {
  const calorieRatio = calorieRequriement / 1000;

  const adjustedNutrients: NutrientsStandard = {
    // Macronutrients
    protein:
      nutrientsTemplate.protein === null
        ? null
        : nutrientsTemplate.protein * calorieRatio,
    fat:
      nutrientsTemplate.fat === null
        ? null
        : nutrientsTemplate.fat * calorieRatio,
    carbohydrate:
      nutrientsTemplate.carbohydrate === null
        ? null
        : nutrientsTemplate.carbohydrate * calorieRatio,
    water:
      nutrientsTemplate.water === null
        ? null
        : nutrientsTemplate.water * calorieRatio,
    ash:
      nutrientsTemplate.ash === null
        ? null
        : nutrientsTemplate.ash * calorieRatio,

    // Amino acids
    tryptophan:
      nutrientsTemplate.tryptophan === null
        ? null
        : nutrientsTemplate.tryptophan * calorieRatio,
    threonine:
      nutrientsTemplate.threonine === null
        ? null
        : nutrientsTemplate.threonine * calorieRatio,
    isoleucine:
      nutrientsTemplate.isoleucine === null
        ? null
        : nutrientsTemplate.isoleucine * calorieRatio,
    leucine:
      nutrientsTemplate.leucine === null
        ? null
        : nutrientsTemplate.leucine * calorieRatio,
    lysine:
      nutrientsTemplate.lysine === null
        ? null
        : nutrientsTemplate.lysine * calorieRatio,
    methionine:
      nutrientsTemplate.methionine === null
        ? null
        : nutrientsTemplate.methionine * calorieRatio,
    methionineAndCystine:
      nutrientsTemplate.methionineAndCystine === null
        ? null
        : nutrientsTemplate.methionineAndCystine * calorieRatio,
    phenylalanine:
      nutrientsTemplate.phenylalanine === null
        ? null
        : nutrientsTemplate.phenylalanine * calorieRatio,
    phenylalanineAndTyrosine:
      nutrientsTemplate.phenylalanineAndTyrosine === null
        ? null
        : nutrientsTemplate.phenylalanineAndTyrosine * calorieRatio,
    valine:
      nutrientsTemplate.valine === null
        ? null
        : nutrientsTemplate.valine * calorieRatio,
    arginine:
      nutrientsTemplate.arginine === null
        ? null
        : nutrientsTemplate.arginine * calorieRatio,
    histidine:
      nutrientsTemplate.histidine === null
        ? null
        : nutrientsTemplate.histidine * calorieRatio,

    // Fatty acids
    linoleicAcid:
      nutrientsTemplate.linoleicAcid === null
        ? null
        : nutrientsTemplate.linoleicAcid * calorieRatio,
    alphaLinoleicAcid:
      nutrientsTemplate.alphaLinoleicAcid === null
        ? null
        : nutrientsTemplate.alphaLinoleicAcid * calorieRatio,
    arachidonicAcid:
      nutrientsTemplate.arachidonicAcid === null
        ? null
        : nutrientsTemplate.arachidonicAcid * calorieRatio,
    linoleicToAlphaLinoleicAcids:
      nutrientsTemplate.linoleicToAlphaLinoleicAcids,
    epaAndDha:
      nutrientsTemplate.epaAndDha === null
        ? null
        : nutrientsTemplate.epaAndDha * calorieRatio,

    // Carbohydrates
    fiber:
      nutrientsTemplate.fiber === null
        ? null
        : nutrientsTemplate.fiber * calorieRatio,

    // Minerals
    calcium:
      nutrientsTemplate.calcium === null
        ? null
        : nutrientsTemplate.calcium * calorieRatio,
    iron:
      nutrientsTemplate.iron === null
        ? null
        : nutrientsTemplate.iron * calorieRatio,
    magnesium:
      nutrientsTemplate.magnesium === null
        ? null
        : nutrientsTemplate.magnesium * calorieRatio,
    phosphorus:
      nutrientsTemplate.phosphorus === null
        ? null
        : nutrientsTemplate.phosphorus * calorieRatio,
    calciumToPhosphorus: nutrientsTemplate.calciumToPhosphorus,
    potassium:
      nutrientsTemplate.potassium === null
        ? null
        : nutrientsTemplate.potassium * calorieRatio,
    chloride:
      nutrientsTemplate.chloride === null
        ? null
        : nutrientsTemplate.chloride * calorieRatio,
    sodium:
      nutrientsTemplate.sodium === null
        ? null
        : nutrientsTemplate.sodium * calorieRatio,
    zinc:
      nutrientsTemplate.zinc === null
        ? null
        : nutrientsTemplate.zinc * calorieRatio,
    copper:
      nutrientsTemplate.copper === null
        ? null
        : nutrientsTemplate.copper * calorieRatio,
    zincToCopper: nutrientsTemplate.zincToCopper,
    manganese:
      nutrientsTemplate.manganese === null
        ? null
        : nutrientsTemplate.manganese * calorieRatio,
    selenium:
      nutrientsTemplate.selenium === null
        ? null
        : nutrientsTemplate.selenium * calorieRatio,
    iodine:
      nutrientsTemplate.iodine === null
        ? null
        : nutrientsTemplate.iodine * calorieRatio,

    // Vitamins
    thiaminB1:
      nutrientsTemplate.thiaminB1 === null
        ? null
        : nutrientsTemplate.thiaminB1 * calorieRatio,
    riboflavinB2:
      nutrientsTemplate.riboflavinB2 === null
        ? null
        : nutrientsTemplate.riboflavinB2 * calorieRatio,
    niacinB3:
      nutrientsTemplate.niacinB3 === null
        ? null
        : nutrientsTemplate.niacinB3 * calorieRatio,
    pantothenicAcidB5:
      nutrientsTemplate.pantothenicAcidB5 === null
        ? null
        : nutrientsTemplate.pantothenicAcidB5 * calorieRatio,
    vitaminB6:
      nutrientsTemplate.vitaminB6 === null
        ? null
        : nutrientsTemplate.vitaminB6 * calorieRatio,
    folicAcidB9:
      nutrientsTemplate.folicAcidB9 === null
        ? null
        : nutrientsTemplate.folicAcidB9 * calorieRatio,
    choline:
      nutrientsTemplate.choline === null
        ? null
        : nutrientsTemplate.choline * calorieRatio,
    vitaminB12:
      nutrientsTemplate.vitaminB12 === null
        ? null
        : nutrientsTemplate.vitaminB12 * calorieRatio,
    vitaminA:
      nutrientsTemplate.vitaminA === null
        ? null
        : nutrientsTemplate.vitaminA * calorieRatio,
    vitaminE:
      nutrientsTemplate.vitaminE === null
        ? null
        : nutrientsTemplate.vitaminE * calorieRatio,
    cholecalciferolD3:
      nutrientsTemplate.cholecalciferolD3 === null
        ? null
        : nutrientsTemplate.cholecalciferolD3 * calorieRatio,
    phylloquinoneK:
      nutrientsTemplate.phylloquinoneK === null
        ? null
        : nutrientsTemplate.phylloquinoneK * calorieRatio,
  };

  return adjustedNutrients;
};

export default adjustNutrientReqCalories;
