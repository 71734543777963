import axios, { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";
import { BACKEND_URL, ENVIRONMENT } from "../../constants/constants";

interface data {
  date: Date;
}

const useGetDateNow = (): UseQueryResult<data, AxiosError> => {
  return useQuery<data, AxiosError>(
    ["dateNow"],
    async () => {
      const responseDateNow = await axios.get<data>(
        `${BACKEND_URL}/api/billing/date-now`
      );

      responseDateNow.data.date = new Date(responseDateNow.data.date);

      return responseDateNow.data;
    },
    {
      enabled: ENVIRONMENT === "development",
    }
  );
};

export default useGetDateNow;
