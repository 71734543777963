import React from "react";
import styled from "styled-components";
import Text from "../text/Text";
import StyledLink from "../StyledLink";

interface Props {
  backToLogin: () => void;
  savedEmail: string;
  className?: string;
}

const ForgotPasswordAnswerPartial = ({
  className,
  backToLogin,
  savedEmail,
}: Props): JSX.Element => {
  return (
    <div className={className}>
      <StyledText>
        If an account exists for <b>{savedEmail}</b>, we have sent you an email
        for resetting your password. Check your spam folder.
      </StyledText>
      <GoBackContainer>
        <StyledText>
          <StyledStyledLink asButton={true} clickHandler={backToLogin}>
            Back to log in
          </StyledStyledLink>
        </StyledText>
      </GoBackContainer>
    </div>
  );
};

const StyledText = styled(Text)`
  color: ${(props) => props.theme.black4};
`;

const GoBackContainer = styled.div`
  text-align: center;
  margin-top: ${(props) => props.theme.spacing2};
`;

const StyledStyledLink = styled(StyledLink)`
  color: ${(props) => props.theme.black4};

  &:hover {
    color: ${(props) => props.theme.black3};
  }
  &:focus {
    outline-color: ${(props) => props.theme.black3};
    color: ${(props) => props.theme.black3};
  }
`;

export default ForgotPasswordAnswerPartial;
