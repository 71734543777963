import React from "react";

const LoadingData = (): JSX.Element => {
  return (
    <div>
      <h1>Loading...</h1>
    </div>
  );
};

export default LoadingData;
