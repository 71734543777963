import React from "react";
import styled from "styled-components";
import RecipeIngredientLogic from "../../../shared/RecipeIngredientLogic";
import WrapLink from "../../../shared/WrapLink";
import { ReactComponent as ArrowLeftIcon } from "../../../../assets/icons/arrow-left.svg";
import ButtonText from "../../../shared/text/ButtonText";
import { useParams } from "react-router-dom";
import useGetCustomIngredient from "../../../../hooks/api/useGetCustomIngredient";
import TextPartIngredient from "./TextPartIngredient";
import ActionBarPartIngredient from "./ActionBarPartIngredient";
import GeneralInfoPart from "./GeneralInfoPart";
import NutrientsPartIngredient from "./NutrientsPartIngredient";

const IngredientsName = (): JSX.Element | null => {
  interface PathVars {
    ingredientId: string;
    ingredientName: string;
  }
  const { ingredientId } = useParams<PathVars>();

  const getCustomIngredientQuery = useGetCustomIngredient(ingredientId);

  if (!getCustomIngredientQuery.isSuccess) {
    return null;
  }

  return (
    <>
      <RecipeIngredientLogic />
      <ButtonContainer to={"/ingredients"}>
        <StyledArrowLeftIcon />
        <StyledButtonText>Back to ingredients</StyledButtonText>
      </ButtonContainer>
      <TextPartIngredient ingredient={getCustomIngredientQuery.data} />
      <ActionBarPartIngredient ingredient={getCustomIngredientQuery.data} />
      <GeneralInfoPart ingredient={getCustomIngredientQuery.data} />
      <NutrientsPartIngredient ingredient={getCustomIngredientQuery.data} />
    </>
  );
};

const ButtonContainer = styled(WrapLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: fit-content;
  margin-bottom: ${(props) => props.theme.spacing3};

  &:focus {
    outline-color: ${(props) => props.theme.black4};
  }
  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: ${(props) => props.theme.spacing2};
  }
`;

const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
  width: ${(props) => props.theme.spacing2};
  min-width: ${(props) => props.theme.spacing2};
  height: ${(props) => props.theme.spacing2};
  min-height: ${(props) => props.theme.spacing2};
  margin-right: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.black4};
`;

const StyledButtonText = styled(ButtonText)`
  color: ${(props) => props.theme.black4};
`;

export default IngredientsName;
