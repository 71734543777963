import axios, { AxiosError } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { BACKEND_URL } from "../../constants/constants";

const useLogout = (): UseMutationResult<void, AxiosError, void> => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      await axios.post<void>(`${BACKEND_URL}/api/auth/logout`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["loginCheck"], { exact: true });
        queryClient.setQueryData(["loggedInUser"], undefined);
      },
    }
  );
};

export default useLogout;
