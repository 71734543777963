import React from "react";
import styled from "styled-components";
import Heading2 from "../../../shared/text/headings/Heading2";
import IntroductionText from "../../../shared/text/IntroductionText";

const AboutSection = (): JSX.Element => {
  return (
    <StyledContainer>
      <InnerContainer>
        <StyledHeading>
          The meal formulation tool you&apos;ve been waiting for
        </StyledHeading>
        <StyledIntroductionText>
          Mealment lets you create well-balanced meals for your dog according to
          your preferred standard or diet, such as NRC or BARF. The application
          helps you keep track of essential nutrients and lets you choose from
          thousands of carefully selected ingredients from the United States
          Department of Agriculture (USDA).
        </StyledIntroductionText>
      </InnerContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.section`
  padding-left: ${(props) =>
    "calc(" + props.theme.spacing1 + " + env(safe-area-inset-left))"};
  padding-right: ${(props) =>
    "calc(" + props.theme.spacing1 + " + env(safe-area-inset-right))"};
  margin-top: ${(props) => props.theme.spacing6};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-top: ${(props) => props.theme.spacing5};
  }
`;

const InnerContainer = styled.div`
  text-align: center;
  max-width: ${(props) => props.theme.halfWidth};
  margin-left: auto;
  margin-right: auto;
`;

const StyledHeading = styled(Heading2)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};
`;

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.black4};
`;

export default AboutSection;
