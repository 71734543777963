import React from "react";
import styled from "styled-components";
import { ReactComponent as ChevronDownIcon } from "../../../../assets/icons/chevron-down.svg";
import { allColors } from "../../../../types/frontendTypes";

interface Props<T> {
  value: T | "";
  changeValue: (value: string) => void;
  valuesLabels: Map<T, string>;
  id: string;
  placeholder?: string;
  placeholderColor?: allColors;
  className?: string;
}

const SimpleSelect = <T extends string>({
  value,
  changeValue,
  valuesLabels,
  id,
  placeholder,
  placeholderColor,
  className,
}: Props<T>): JSX.Element => {
  return (
    <Container>
      <StyledSelect
        className={className}
        id={id}
        value={value}
        onChange={(e) => changeValue(e.target.value)}
        hasValue={!!value}
        placeholderColor={placeholderColor}
      >
        {placeholder && placeholderColor && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {Array.from(valuesLabels.entries()).map((entry) => (
          <option value={entry[0]} key={entry[0]}>
            {entry[1]}
          </option>
        ))}
      </StyledSelect>
      <StyledChevronIcon role="img" aria-label="Chevron down icon" />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const StyledSelect = styled.select<{
  hasValue: boolean;
  placeholderColor?: allColors;
}>`
  display: block;
  width: 100%;
  padding: 1rem 2rem; // 16px 32px
  padding-right: 3.5rem; // 56px
  box-sizing: border-box;
  border-width: 0.125rem; // 2px
  border-radius: 100rem;
  border-style: solid;
  font-size: ${(props) => props.theme.buttonTextSize};
  font-family: "DM Sans Regular", sans-serif;
  font-weight: 400;
  line-height: ${(props) => props.theme.componentLineHeight};
  outline: none;
  text-overflow: ellipsis;
  width: 100%;
  transition: background-color ${(props) => props.theme.transitionValue};
  appearance: none;
  color: ${(props) =>
    !props.hasValue &&
    props.placeholderColor &&
    props.placeholderColor + "!important"};

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.buttonTextSizeMobile};
  }
`;

const StyledChevronIcon = styled(ChevronDownIcon)`
  width: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.green4};

  position: absolute;
  top: ${(props) => props.theme.spacing2};
  right: 2rem; // 32px
  pointer-events: none;
`;

export default SimpleSelect;
