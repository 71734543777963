import React, { useContext } from "react";
import styled from "styled-components";
import DogsModalCallbackContext from "../../../../contexts/dogsModalCallbackContext/DogsModalCallbackContext";
import TwoChoiceModalOpenContext from "../../../../contexts/twoChoiceModalOpenContext/TwoChoiceModalOpenContext";
import useChangeSelectedDog from "../../../../hooks/api/useChangeSelectedDog";
import useGetAuthUser from "../../../../hooks/api/useGetAuthUser";
import useGetDogs from "../../../../hooks/api/useGetDogs";
import useIsValidSubscription from "../../../../hooks/useIsValidSubscription";
import Button from "../../../shared/Button";
import Modal from "../../../shared/Modal";
import IntroductionText from "../../../shared/text/IntroductionText";

const TwoChoiceModal = (): JSX.Element => {
  const twoChoiceModalOpenContextValue = useContext(TwoChoiceModalOpenContext);
  const DogsModalCallbackContextValue = useContext(DogsModalCallbackContext);

  const changeSelectedDogMutation = useChangeSelectedDog();
  const authUserQuery = useGetAuthUser();

  const validSubscription = useIsValidSubscription(
    authUserQuery.data === undefined
      ? undefined
      : authUserQuery.data.stripeSubscription,
    authUserQuery.data === undefined
      ? undefined
      : authUserQuery.data.stripeSubscriptionTimestamp
  );

  const getDogsQuery = useGetDogs({
    enabled: !!authUserQuery.data && validSubscription,
  });

  useGetAuthUser({
    enabled: changeSelectedDogMutation.isSuccess,
    onSuccess: () => {
      if (changeSelectedDogMutation.isSuccess) {
        twoChoiceModalOpenContextValue?.setTwoChoiceModalOpen(false);
        DogsModalCallbackContextValue?.dogsModalCallback();
      }
    },
  });

  return (
    <Modal
      title="Do you really want to leave?"
      open={twoChoiceModalOpenContextValue?.twoChoiceModalOpen || false}
      setOpen={twoChoiceModalOpenContextValue?.setTwoChoiceModalOpen}
      closable={true}
    >
      <StyledIntroductionText>
        If you leave this page, the newly created dog will be discarded. Are you
        sure that you want to leave?
      </StyledIntroductionText>
      <ButtonContainer>
        <PurpleButton
          eventHandler={() =>
            twoChoiceModalOpenContextValue?.setTwoChoiceModalOpen(false)
          }
        >
          Stay
        </PurpleButton>
        <WhiteButton
          eventHandler={() => {
            const newSelected =
              getDogsQuery.data &&
              getDogsQuery.data[0] &&
              getDogsQuery.data[0].id;
            if (newSelected) {
              changeSelectedDogMutation.mutate({ selectedDog: newSelected });
            } else {
              twoChoiceModalOpenContextValue?.setTwoChoiceModalOpen(false);
              DogsModalCallbackContextValue?.dogsModalCallback();
            }
          }}
        >
          Leave
        </WhiteButton>
      </ButtonContainer>
    </Modal>
  );
};

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.black4};
  margin-bottom: ${(props) => props.theme.spacing2};
  margin-top: ${(props) => props.theme.spacing2};
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const PurpleButton = styled(Button)`
  background-color: ${(props) => props.theme.purple2};
  color: ${(props) => props.theme.purple4};
  width: 50%;
  margin-right: 0.25rem;

  &:hover {
    background-color: ${(props) => props.theme.purple1};
  }
  &:focus {
    background-color: ${(props) => props.theme.purple1};
    outline-color: ${(props) => props.theme.purple1};
  }
`;

const WhiteButton = styled(Button)`
  background-color: ${(props) => props.theme.white};
  color: ${(props) => props.theme.black4};
  width: 50%;

  &:focus {
    outline-color: ${(props) => props.theme.black4};
  }
`;

export default TwoChoiceModal;
