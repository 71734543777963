import { DefaultWeightUnits } from "../types/ingredientTypes";

const calculateNutrientAmountWeight = (
  unit: DefaultWeightUnits,
  amountIngredient: number,
  amountNutrient: number
): number => {
  let amountInGram = 0;
  if (unit === "gram") {
    amountInGram = amountIngredient;
  } else if (unit === "kilogram") {
    amountInGram = amountIngredient * 1000;
  } else if (unit === "ounce") {
    amountInGram = amountIngredient * 28.3495;
  } else if (unit === "pound") {
    amountInGram = amountIngredient * 453.592;
  }

  return amountInGram * (amountNutrient / 100);
};

export default calculateNutrientAmountWeight;
