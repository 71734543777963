import adjustNutrientReqCalories from "../../../services/nutrientReqs/adjustNutrientReqCalories";
import getWeigthInKg from "../../../services/getWeigthInKg";
import { NutrientsStandard } from "../../../types/standardTypes";
import { kgOrLb } from "../../../types/userTypes";
import calculateMonthsSinceDate from "../../../utils/date/calculateMonthsSinceDate";

const NUTRIENT_REQ_FEDIAF_LATE_GROWTH_MIN: NutrientsStandard = {
  // Macronutrients
  protein: 50.0,
  fat: 21.25,
  carbohydrate: null,
  water: null,
  ash: null,

  // Amino acids
  tryptophan: 0.53,
  threonine: 1.6,
  isoleucine: 1.25,
  leucine: 2.0,
  lysine: 1.75,
  methionine: 0.65,
  methionineAndCystine: 1.33,
  phenylalanine: 1.25,
  phenylalanineAndTyrosine: 2.5,
  valine: 1.4,
  arginine: 1.84,
  histidine: 0.63,

  // Fatty acids
  linoleicAcid: 3.25,
  alphaLinoleicAcid: 0.2,
  arachidonicAcid: 0.075,
  linoleicToAlphaLinoleicAcids: 2.6,
  epaAndDha: 0.13,

  // Carbohydrates
  fiber: null,

  // Minerals
  calcium: 2000,
  iron: 22.0,
  magnesium: 100,
  phosphorus: 1750,
  calciumToPhosphorus: 1,
  potassium: 1100,
  chloride: 830,
  sodium: 550,
  zinc: 25.0,
  copper: 2.75,
  zincToCopper: 8.0,
  manganese: 1.4,
  selenium: 100.0,
  iodine: 380,

  // Vitamins
  thiaminB1: 0.45,
  riboflavinB2: 1.05,
  niacinB3: 3.4,
  pantothenicAcidB5: 3.0,
  vitaminB6: 0.3,
  folicAcidB9: 54.0,
  choline: 425.0,
  vitaminB12: 7.0,
  vitaminA: 375,
  vitaminE: 8.375,
  cholecalciferolD3: 3.125,
  phylloquinoneK: null,
};

const NUTRIENT_REQ_FEDIAF_LATE_GROWTH_MAX: NutrientsStandard = {
  // Macronutrients
  protein: null,
  fat: null,
  carbohydrate: null,
  water: null,
  ash: null,

  // Amino acids
  tryptophan: null,
  threonine: null,
  isoleucine: null,
  leucine: null,
  lysine: 7.0,
  methionine: null,
  methionineAndCystine: null,
  phenylalanine: null,
  phenylalanineAndTyrosine: null,
  valine: null,
  arginine: null,
  histidine: null,

  // Fatty acids
  linoleicAcid: null,
  alphaLinoleicAcid: null,
  arachidonicAcid: null,
  linoleicToAlphaLinoleicAcids: 16,
  epaAndDha: null,

  // Carbohydrates
  fiber: null,

  // Minerals
  calcium: 4500,
  iron: null,
  magnesium: null,
  phosphorus: null,
  calciumToPhosphorus: 1.8,
  potassium: null,
  chloride: null,
  sodium: null,
  zinc: null,
  copper: null,
  zincToCopper: null,
  manganese: null,
  selenium: null,
  iodine: null,

  // Vitamins
  thiaminB1: null,
  riboflavinB2: null,
  niacinB3: null,
  pantothenicAcidB5: null,
  vitaminB6: null,
  folicAcidB9: null,
  choline: null,
  vitaminB12: null,
  vitaminA: 30000,
  vitaminE: null,
  cholecalciferolD3: 20,
  phylloquinoneK: null,
};

export const getNutrientReqFediafLateGrowthMin = (
  calorieRequriement: number,
  expectedWeight: number,
  weightSetting: kgOrLb,
  birthDate: Date,
  protein: number
): NutrientsStandard => {
  let specificReq = NUTRIENT_REQ_FEDIAF_LATE_GROWTH_MIN;

  const expectedWeightKgs = getWeigthInKg(expectedWeight, weightSetting);
  if (expectedWeightKgs > 15 && calculateMonthsSinceDate(birthDate) < 6) {
    specificReq = { ...specificReq, calcium: 2500 };
  }

  const proteinDiff = protein - 175;
  if (proteinDiff > 0) {
    specificReq = {
      ...specificReq,
      arginine: (specificReq.arginine as number) + proteinDiff * 0.01,
    };
  }

  const adjustedNutrients = adjustNutrientReqCalories(
    specificReq,
    calorieRequriement
  );
  return adjustedNutrients;
};

export const getNutrientReqFediafLateGrowthMax = (
  calorieRequriement: number,
  expectedWeight: number,
  weightSetting: kgOrLb,
  birthDate: Date
): NutrientsStandard => {
  let specificReq = NUTRIENT_REQ_FEDIAF_LATE_GROWTH_MAX;

  const expectedWeightKgs = getWeigthInKg(expectedWeight, weightSetting);
  if (expectedWeightKgs > 15 && calculateMonthsSinceDate(birthDate) < 6) {
    specificReq = { ...specificReq, calciumToPhosphorus: 1.6 };
  }

  const adjustedNutrients = adjustNutrientReqCalories(
    specificReq,
    calorieRequriement
  );
  return adjustedNutrients;
};
