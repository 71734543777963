import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import LoadingScreen from "../../shared/LoadingData";
import UseGetAuthUser from "../../../hooks/api/useGetAuthUser";
import useGetLoginStatus from "../../../hooks/api/useGetLoginStatus";
import UnitPreferencesModal from "./UnitPreferencesModal";
import PricingModal from "./PricingModal";
import ExceededResourceModal from "./ExceededResourceModal";
import { accountUrl } from "../../../constants/constants";
import AuthModalContext from "../../../contexts/authModalContext/AuthModalContext";
import FailedPaymentModal from "./FailedPaymentModal";
import useGetDateNowWrapper from "../../../hooks/useGetDateNowWrapper";

interface Props {
  children: React.ReactNode;
  path: string;
  exact?: boolean;
}

const ProtectedRoute = ({ children, path, exact }: Props): JSX.Element => {
  const loggedInStatusQuery = useGetLoginStatus();
  const authUserQuery = UseGetAuthUser({
    enabled: loggedInStatusQuery?.data?.loggedIn,
  });

  const authModalContext = useContext(AuthModalContext);

  const dateNow = useGetDateNowWrapper();

  useEffect(() => {
    if (authModalContext && authModalContext.authModalOpen) {
      authModalContext.setAuthModalOpen(false);
    }
  }, []);

  if (loggedInStatusQuery.isLoading) {
    return <LoadingScreen />;
  }

  if (loggedInStatusQuery.data && !loggedInStatusQuery.data.loggedIn) {
    return <Redirect to="/login" />;
  }

  if (authUserQuery.isLoading) {
    return <LoadingScreen />;
  }

  if (authUserQuery.data && !authUserQuery.data.verified) {
    return <Redirect to="/verify" />;
  }

  const atMyAccount = window.location.pathname === accountUrl;

  const showPricingModal =
    authUserQuery.data !== undefined &&
    authUserQuery.data.stripeSubscription === null &&
    !atMyAccount;

  const showFailedPaymentModal =
    authUserQuery.data !== undefined &&
    authUserQuery.data.stripeSubscription !== null &&
    authUserQuery.data.stripeSubscriptionTimestamp !== null &&
    authUserQuery.data.stripeSubscriptionTimestamp < dateNow &&
    !atMyAccount;

  let showUnitPreferencesModal = false;
  if (
    authUserQuery.data &&
    authUserQuery.data.kgOrLb === null &&
    !showPricingModal &&
    !showFailedPaymentModal &&
    !atMyAccount
  ) {
    showUnitPreferencesModal = true;
  }

  return (
    <Route path={path} exact={exact}>
      {children}
      <UnitPreferencesModal open={showUnitPreferencesModal} />
      <PricingModal open={showPricingModal} />
      <FailedPaymentModal open={showFailedPaymentModal} />
      <ExceededResourceModal user={authUserQuery.data} />
    </Route>
  );
};

export default ProtectedRoute;
