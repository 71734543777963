import adjustNutrientReqActivityLevel from "../../../services/nutrientReqs/adjustNutrientReqActivityLevel";
import adjustNutrientReqCalories from "../../../services/nutrientReqs/adjustNutrientReqCalories";
import { NutrientsStandard } from "../../../types/standardTypes";

const NUTRIENT_REQ_FEDIAF_ADULT_MIN: NutrientsStandard = {
  // Macronutrients
  protein: 45.0,
  fat: 13.75,
  carbohydrate: null,
  water: null,
  ash: null,

  // Amino acids
  tryptophan: 0.43,
  threonine: 1.3,
  isoleucine: 1.15,
  leucine: 2.05,
  lysine: 1.05,
  methionine: 1.0,
  methionineAndCystine: 1.91,
  phenylalanine: 1.35,
  phenylalanineAndTyrosine: 2.23,
  valine: 1.48,
  arginine: 1.3,
  histidine: 0.58,

  // Fatty acids
  linoleicAcid: 3.27,
  alphaLinoleicAcid: null,
  arachidonicAcid: null,
  linoleicToAlphaLinoleicAcids: 2.6,
  epaAndDha: null,

  // Carbohydrates
  fiber: null,

  // Minerals
  calcium: 1250,
  iron: 9.0,
  magnesium: 180,
  phosphorus: 1000,
  calciumToPhosphorus: 1,
  potassium: 1250,
  chloride: 380,
  sodium: 250,
  zinc: 18.0,
  copper: 1.8,
  zincToCopper: 9,
  manganese: 1.44,
  selenium: 57.5,
  iodine: 260,

  // Vitamins
  thiaminB1: 0.54,
  riboflavinB2: 1.5,
  niacinB3: 4.09,
  pantothenicAcidB5: 3.55,
  vitaminB6: 0.36,
  folicAcidB9: 64.5,
  choline: 409.0,
  vitaminB12: 8.36,
  vitaminA: 454.5,
  vitaminE: 6.03,
  cholecalciferolD3: 3.45,
  phylloquinoneK: null,
};

const NUTRIENT_REQ_FEDIAF_ADULT_MAX: NutrientsStandard = {
  // Macronutrients
  protein: null,
  fat: null,
  carbohydrate: null,
  water: null,
  ash: null,

  // Amino acids
  tryptophan: null,
  threonine: null,
  isoleucine: null,
  leucine: null,
  lysine: null,
  methionine: null,
  methionineAndCystine: null,
  phenylalanine: null,
  phenylalanineAndTyrosine: null,
  valine: null,
  arginine: null,
  histidine: null,

  // Fatty acids
  linoleicAcid: null,
  alphaLinoleicAcid: null,
  arachidonicAcid: null,
  linoleicToAlphaLinoleicAcids: 26,
  epaAndDha: null,

  // Carbohydrates
  fiber: null,

  // Minerals
  calcium: 6250,
  iron: null,
  magnesium: null,
  phosphorus: 4000,
  calciumToPhosphorus: 2,
  potassium: null,
  chloride: null,
  sodium: null,
  zinc: null,
  copper: null,
  zincToCopper: null,
  manganese: null,
  selenium: null,
  iodine: null,

  // Vitamins
  thiaminB1: null,
  riboflavinB2: null,
  niacinB3: null,
  pantothenicAcidB5: null,
  vitaminB6: null,
  folicAcidB9: null,
  choline: null,
  vitaminB12: null,
  vitaminA: 30000,
  vitaminE: null,
  cholecalciferolD3: 20,
  phylloquinoneK: null,
};

export const getNutrientReqFediafAdultMin = (
  protein: number,
  calorieRequriement: number,
  activityLevel: number
): NutrientsStandard => {
  const activityLevelRatio =
    activityLevel < 130 ? 110 / activityLevel : 110 / 130;

  let specificReq = adjustNutrientReqActivityLevel(
    NUTRIENT_REQ_FEDIAF_ADULT_MIN,
    activityLevelRatio
  );

  const proteinDiff = protein - 100;
  if (proteinDiff > 0) {
    specificReq = {
      ...specificReq,
      arginine: (specificReq.arginine as number) + proteinDiff * 0.01,
    };
  }

  const adjustedNutrients = adjustNutrientReqCalories(
    specificReq,
    calorieRequriement
  );

  return adjustedNutrients;
};

export const getNutrientReqFediafAdultMax = (
  calorieRequriement: number,
  activityLevel: number
): NutrientsStandard => {
  const activityLevelRatio =
    activityLevel < 130 ? 110 / activityLevel : 110 / 130;

  const specificReq = adjustNutrientReqActivityLevel(
    NUTRIENT_REQ_FEDIAF_ADULT_MAX,
    activityLevelRatio
  );

  const adjustedNutrients = adjustNutrientReqCalories(
    specificReq,
    calorieRequriement
  );

  return adjustedNutrients;
};
