import React, { useRef, useState } from "react";
import styled from "styled-components";
import {
  IngredientDetailed,
  IngredientInstanceValuesNoIdType,
} from "../../../../types/ingredientTypes";
import Text from "../../../shared/text/Text";
import WrapLink from "../../../shared/WrapLink";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import { ReactComponent as MinusIcon } from "../../../../assets/icons/minus.svg";
import { ReactComponent as EyeIcon } from "../../../../assets/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "../../../../assets/icons/eye-off.svg";
import { ReactComponent as XCircleIcon } from "../../../../assets/icons/x-circle.svg";
import { useMediaQuery } from "react-responsive";
import theme from "../../../../style/theme";
import LabelForm from "../../../shared/form/LabelForm";
import EditableNumber from "../../../shared/form/editable/EditableNumber";
import ButtonText from "../../../shared/text/ButtonText";
import EditableSelect from "../../../shared/form/editable/EditableSelect";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
import InfoModalButton from "../../../shared/InfoModalButton";
import getInstanceUnitShort from "../../../../services/getInstanceUnitShort";

interface Props {
  ingredient: IngredientDetailed;
  saveChangedValue: (
    ingredientId: string,
    value: number | boolean | string,
    property: IngredientInstanceValuesNoIdType
  ) => void;
  deleteIngredient: (ingredientId: string) => void;
  setInfoOpen: () => void;
}

const IngredientRowDisplay = ({
  ingredient,
  saveChangedValue,
  deleteIngredient,
  setInfoOpen,
}: Props): JSX.Element => {
  const [accordionOut, setAccordionOut] = useState(false);

  const belowBP3 = useMediaQuery({ maxWidth: theme.breakpoint3 });

  const refNameCell = useRef<HTMLTableCellElement | null>(null);
  const refIngredientRow = useRef<HTMLTableRowElement | null>(null);

  const valuesLabelsUnit = new Map<string, string>();
  if (ingredient.defaultUnit === "gram") {
    valuesLabelsUnit.set("gram", "Gram");
    valuesLabelsUnit.set("kilogram", "Kilogram");
    valuesLabelsUnit.set("ounce", "Ounce");
    valuesLabelsUnit.set("pound", "Pound");
  } else {
    valuesLabelsUnit.set(
      ingredient.unit,
      capitalizeFirstLetter(ingredient.unit)
    );
  }

  const hidden = belowBP3 && !accordionOut;

  return (
    <IngredientRow
      key={ingredient.id}
      role="row"
      maxHeightClosed={
        refNameCell.current?.scrollHeight
          ? refNameCell.current.scrollHeight - 36
          : 0
      }
      maxHeightOpen={refIngredientRow.current?.scrollHeight || 0}
      accordionOut={accordionOut}
      ref={refIngredientRow}
    >
      <NameCell role="rowheader" ref={refNameCell}>
        {belowBP3 ? (
          <>
            <PrintName>{ingredient.name}</PrintName>
            <ToggleAccordion
              asButton={true}
              clickHandler={() => setAccordionOut(!accordionOut)}
              show={ingredient.show}
            >
              <Name show={ingredient.show}>
                {`${ingredient.name}, ${
                  ingredient.amount
                } ${getInstanceUnitShort(ingredient)}`}
              </Name>

              <StyledIcon
                as={accordionOut ? MinusIcon : undefined}
                $show={ingredient.show}
              />
            </ToggleAccordion>
            <StyledInfoModalButton
              clickHandler={() => {
                setInfoOpen();
              }}
              ariaLabelIcon={"More information about the ingredient"}
              text={belowBP3 ? "Ingredient details" : undefined}
              show={ingredient.show}
              tabIndex={hidden ? -1 : undefined}
            />
          </>
        ) : (
          <NameContainer>
            <Name show={ingredient.show}>{ingredient.name}</Name>
            <StyledInfoModalButton
              clickHandler={() => {
                setInfoOpen();
              }}
              ariaLabelIcon={"More information about the ingredient"}
              text={belowBP3 ? "Ingredient details" : undefined}
              show={ingredient.show}
              tabIndex={hidden ? -1 : undefined}
            />
          </NameContainer>
        )}
      </NameCell>

      <DataCell aria-hidden={hidden ? "true" : undefined}>
        <HiddenLabel
          name={"amount"}
          aria-hidden={belowBP3 ? "false" : "true"}
          show={ingredient.show}
        >
          Amount
        </HiddenLabel>
        <EditableContainer>
          <StyledEditable
            serverValue={ingredient.amount === 0 ? NaN : ingredient.amount}
            id={"amount"}
            placeholder={"Add ingredient amount"}
            onSave={(amount) => {
              saveChangedValue(ingredient.id, amount, "amount");
            }}
            top={11}
            show={ingredient.show}
            tabIndex={hidden ? -1 : undefined}
          />
        </EditableContainer>
      </DataCell>
      <DataCell aria-hidden={hidden ? "true" : undefined}>
        <HiddenLabel
          name={"unit"}
          aria-hidden={belowBP3 ? "false" : "true"}
          show={ingredient.show}
        >
          Unit
        </HiddenLabel>
        <UnitEditableContainer>
          <StyledEditable
            as={EditableSelect}
            serverValue={ingredient.unit}
            id={"unit"}
            onSave={(unit: string) => {
              saveChangedValue(ingredient.id, unit, "unit");
            }}
            valuesLabels={valuesLabelsUnit}
            top={11}
            show={ingredient.show}
            tabIndex={hidden ? -1 : undefined}
          />
        </UnitEditableContainer>
      </DataCell>
      <BottomCell aria-hidden={hidden ? "true" : undefined}>
        <ClickWrapper
          asButton={true}
          clickHandler={() => {
            saveChangedValue(ingredient.id, !ingredient.show, "show");
          }}
          show={ingredient.show}
          tabIndex={hidden ? -1 : undefined}
        >
          <DataCellIcon
            $show={ingredient.show}
            as={ingredient.show ? EyeOffIcon : EyeIcon}
          />
          <StyledButtonText show={ingredient.show}>
            {ingredient.show ? "Hide" : "Show"}
          </StyledButtonText>
        </ClickWrapper>
      </BottomCell>
      <BottomCell aria-hidden={hidden ? "true" : undefined}>
        <RemoveWrapper
          asButton={true}
          clickHandler={() => {
            deleteIngredient(ingredient.id);
          }}
          show={ingredient.show}
          tabIndex={hidden ? -1 : undefined}
        >
          <DataCellIcon $show={ingredient.show} as={XCircleIcon} />
          <StyledButtonText show={ingredient.show}>Remove</StyledButtonText>
        </RemoveWrapper>
      </BottomCell>
    </IngredientRow>
  );
};

const IngredientRow = styled.tr<{
  maxHeightClosed: number;
  maxHeightOpen: number;
  accordionOut: boolean;
}>`
  overflow-y: hidden;
  overflow-x: visible;
  transition: max-height ${(props) => props.theme.transitionValue};

  &:first-child {
    padding-top: 0.25rem; // 4px
  }
  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    display: block;
    max-height: ${(props) =>
      props.accordionOut
        ? `${props.maxHeightOpen}px`
        : `${props.maxHeightClosed}px`};
    padding-left: 0.25rem; // 4px
    padding-right: 0.25rem; // 4px

    &:not(:first-child) {
      padding-top: ${(props) => props.theme.spacing2};
      border-top: 0.125rem solid ${(props) => props.theme.black1};
      margin-top: ${(props) => props.theme.spacing2};
    }
  }
  @media print {
    display: table-row;
    max-height: fit-content;
    padding-left: 0;
    padding-right: 0;

    &:not(:first-child) {
      padding-top: 0;
      border-top: none;
      margin-top: 0;
    }
  }
`;

const NameCell = styled.th`
  padding: 0;
  text-align: left;
  padding-top: ${(props) => props.theme.spacing1};

  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    display: block;
    padding-top: 0;
  }
  @media print {
    display: table-cell;
    padding-top: ${(props) => props.theme.spacing1};
  }
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${(props) => props.theme.spacing1};
`;

const ToggleAccordion = styled(WrapLink)<{ show: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;

  &:focus {
    outline-color: ${(props) =>
      props.show ? props.theme.black4 : props.theme.black3};
  }
  @media print {
    display: none;
  }
`;

const Name = styled(Text)<{ show: boolean }>`
  color: ${(props) => (props.show ? props.theme.black4 : props.theme.black3)};
  text-align: left;
  font-size: ${(props) => props.theme.navigationTextSize};
  word-break: break-word;

  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    font-size: ${(props) => props.theme.heading4Size};
    font-family: "Nunito Bold", sans-serif;
    line-height: ${(props) => props.theme.headingLineHeight};
    font-weight: 400;
  }
  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    font-size: ${(props) => props.theme.heading4SizeMobile};
  }
`;

const PrintName = styled(Text)`
  display: none;
  color: ${(props) => props.theme.black4};
  text-align: left;
  font-size: ${(props) => props.theme.navigationTextSize};
  word-break: break-word;
  margin-right: ${(props) => props.theme.spacing1};

  @media print {
    display: block;
  }
`;

const StyledInfoModalButton = styled(InfoModalButton)<{
  show: boolean;
}>`
  color: ${(props) => (props.show ? props.theme.black4 : props.theme.black3)};

  &:hover {
    color: ${(props) => props.theme.black3};
  }
  &:focus {
    color: ${(props) => props.theme.black3};
    outline-color: ${(props) => props.theme.black3};
  }
  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    margin-left: 0;
    margin-top: ${(props) => props.theme.spacing1};
  }

  @media print {
    display: none;
  }
`;

const DataCell = styled.td`
  padding: 0;
  text-align: left;
  padding-top: ${(props) => props.theme.spacing1};

  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    display: block;
  }
  @media print {
    display: table-cell;
  }
`;

const BottomCell = styled(DataCell)`
  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    display: inline-block;
    width: 50%;
  }
  @media print {
    display: none;
  }
`;

const HiddenLabel = styled(LabelForm)<{ show: boolean }>`
  color: ${(props) => (props.show ? props.theme.black4 : props.theme.black3)};
  display: none;
  font-size: ${(props) => props.theme.navigationTextSize};

  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    display: block;
  }
  @media print {
    display: none;
  }
`;

const StyledIcon = styled(PlusIcon)<{ $show: boolean }>`
  width: 1.5rem; // 24px
  height: 1.5rem; // 24px
  min-width: 1.5rem; // 24px
  min-height: 1.5rem; // 24px
  color: ${(props) => (props.$show ? props.theme.black4 : props.theme.black3)};
  margin-left: ${(props) => props.theme.spacing2};

  @media print {
    display: none;
  }
`;

const StyledEditable = styled(EditableNumber)<{ show: boolean }>`
  font-size: ${(props) => props.theme.navigationTextSize};
  color: ${(props) => (props.show ? props.theme.black4 : props.theme.black3)};
`;

const EditableContainer = styled.div`
  margin-right: ${(props) => props.theme.spacing1};

  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    margin-right: 0;
  }
  @media print {
    margin-right: ${(props) => props.theme.spacing1};
  }
`;

const UnitEditableContainer = styled(EditableContainer)`
  min-width: 12.5rem; // 200px

  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    min-width: 0;
  }

  @media print {
    min-width: 12.5rem; // 200px
  }
`;

const ClickWrapper = styled(WrapLink)<{ show: boolean }>`
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-right: ${(props) => props.theme.spacing1};

  &:focus {
    outline-color: ${(props) =>
      props.show ? props.theme.black4 : props.theme.black3};
  }
  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    margin-right: 0;
    margin-bottom: 4px;
  }
`;

const RemoveWrapper = styled(ClickWrapper)`
  margin-right: 0;

  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    margin-left: auto;
  }
`;

const StyledButtonText = styled(ButtonText)<{ show: boolean }>`
  color: ${(props) => (props.show ? props.theme.black4 : props.theme.black3)};
  font-size: ${(props) => props.theme.navigationTextSize};
`;

const DataCellIcon = styled(EyeIcon)<{ $show: boolean }>`
  width: 1.5rem; // 24px
  height: 1.5rem; // 24px
  margin-right: ${(props) => props.theme.spacing1};
  color: ${(props) => (props.$show ? props.theme.black4 : props.theme.black3)};

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    width: 1rem; // 16px
    height: 1rem; // 16px
    margin-right: ${(props) => props.theme.halfSpacing1};
  }
`;

export default IngredientRowDisplay;
