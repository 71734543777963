const getDateNumberOfWeeksBack = (numberOfWeeksBack: number): Date => {
  const today = new Date();
  const diffTime = Math.abs(
    today.getTime() - numberOfWeeksBack * 7 * 24 * 60 * 60 * 1000
  );
  const returnDate = new Date(diffTime);
  returnDate.setHours(0);
  returnDate.setMinutes(0);
  returnDate.setSeconds(0);
  returnDate.setMilliseconds(0);

  return returnDate;
};

export default getDateNumberOfWeeksBack;
