import React, { useState } from "react";
import styled from "styled-components";
import useGetAuthUser from "../../../../hooks/api/useGetAuthUser";
import useGetRecipesDog from "../../../../hooks/api/useGetRecipesDog";
import generateRecipeDesc from "../../../../services/generateRecipeDesc";
import PlusButton from "../../../shared/PlusButton";
import RecipeIngredientLogic from "../../../shared/RecipeIngredientLogic";
import ButtonText from "../../../shared/text/ButtonText";
import Heading4 from "../../../shared/text/headings/Heading4";
import IntroductionText from "../../../shared/text/IntroductionText";
import SmallText from "../../../shared/text/SmallText";
import Text from "../../../shared/text/Text";
import WrapLink from "../../../shared/WrapLink";
import { ReactComponent as RightArrowSvg } from "../../../../assets/icons/arrow-right.svg";
import useCreateRecipe from "../../../../hooks/api/useCreateRecipe";
import { useHistory } from "react-router-dom";
import urlSlug from "url-slug";
import getAllowedNumberOfRecipes from "../../../../services/getAllowedNumberOfRecipes";
import useGetRecipes from "../../../../hooks/api/useGetRecipes";
import MaxRecipesModal from "./MaxRecipesModal";

const Recipes = (): JSX.Element => {
  const [maxRecipesModalOpen, setMaxRecipesModalOpen] = useState(false);

  const authUserQuery = useGetAuthUser();
  const getRecipesDogQuery = useGetRecipesDog(authUserQuery.data?.selectedDog, {
    enabled: !!authUserQuery.data,
  });
  const getRecipesQuery = useGetRecipes({
    enabled: !!authUserQuery.data,
  });

  const createRecipeMutation = useCreateRecipe(authUserQuery.data?.selectedDog);

  const history = useHistory();

  let maxRecipes = false;
  if (authUserQuery.data?.stripeSubscription && getRecipesQuery.data) {
    const allowedNumberOfRecipes = getAllowedNumberOfRecipes(
      authUserQuery.data.stripeSubscription
    );
    maxRecipes = getRecipesQuery.data.length === allowedNumberOfRecipes;
  }

  const createNewRecipe = (): void => {
    createRecipeMutation.mutate(
      { name: "Untitled recipe", recipePublic: false },
      {
        onSuccess: (data) => {
          if (data) {
            history.push(`/recipes/${data.id}/${urlSlug(data.name)}`);
          }
        },
      }
    );
  };

  return (
    <>
      <RecipeIngredientLogic />
      <MaxRecipesModal
        open={maxRecipesModalOpen}
        setOpen={setMaxRecipesModalOpen}
      />
      <IntroButtonContainer>
        <StyledIntroductionText>
          This is a list of your saved recipes. You can create new recipes by
          either starting from scratch or duplicating an existing recipe.
        </StyledIntroductionText>
        <StyledPlusButton
          clickHandler={() => {
            if (maxRecipes) {
              setMaxRecipesModalOpen(true);
            } else {
              createNewRecipe();
            }
          }}
          text={"Add new recipe"}
        />
      </IntroButtonContainer>
      <RecipeList>
        {getRecipesDogQuery.data &&
          getRecipesDogQuery.data
            .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
            .map((recipe) => (
              <RecipeContainer key={recipe.id}>
                <RecipeLink
                  to={`/recipes/${recipe.id}/${urlSlug(recipe.name)}`}
                >
                  <TextContainer>
                    <StyledHeading4 asValue={"h2"}>
                      {recipe.name}
                    </StyledHeading4>
                    <Description>
                      {generateRecipeDesc(recipe.ingredients)}
                    </Description>
                    <NumberIngredients>
                      {recipe.ingredients.length} ingredients
                    </NumberIngredients>
                  </TextContainer>
                  <ArrowPart>
                    <StyledButtonText>Show recipe</StyledButtonText>
                    <StyledRightArrowSvg />
                  </ArrowPart>
                </RecipeLink>
              </RecipeContainer>
            ))}
      </RecipeList>
    </>
  );
};

const IntroButtonContainer = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing3};
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: ${(props) => props.theme.spacing2};
  }
`;

const StyledIntroductionText = styled(IntroductionText)`
  color: ${(props) => props.theme.black4};
  max-width: ${(props) => props.theme.halfWidth};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: ${(props) => props.theme.spacing2};
  }
`;

const StyledPlusButton = styled(PlusButton)`
  margin-left: ${(props) => props.theme.spacing3};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-left: 0;
  }
`;

const RecipeList = styled.ul`
  list-style: none;
  padding: 0;
`;

const RecipeContainer = styled.li`
  background-color: ${(props) => props.theme.purple1};
  border-radius: 2rem; // 32px
  &:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing2};
  }

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    &:not(:last-child) {
      margin-bottom: ${(props) => props.theme.spacing1};
    }
  }
`;

const RecipeLink = styled(WrapLink)`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing2} ${(props) => props.theme.spacing3};
  text-decoration: none;
  border-radius: 2rem; // 32px

  &:focus {
    outline-color: ${(props) => props.theme.purple1};
  }
  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    flex-direction: column;
    padding-top: ${(props) => props.theme.spacing2};
    padding-bottom: ${(props) => props.theme.spacing2};
    padding-left: ${(props) => props.theme.spacing2};
    padding-right: ${(props) => props.theme.spacing2};
  }
`;

const TextContainer = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: ${(props) => props.theme.spacing1};
  }
`;

const StyledHeading4 = styled(Heading4)`
  color: ${(props) => props.theme.purple4};
  margin-bottom: ${(props) => props.theme.spacing1};
`;

const Description = styled(Text)`
  color: ${(props) => props.theme.purple4};
  margin-bottom: ${(props) => props.theme.spacing1};
  max-width: 37.5rem; // 600px
`;

const NumberIngredients = styled(SmallText)`
  color: ${(props) => props.theme.purple4};
`;

const ArrowPart = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.theme.spacing2};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    align-self: flex-end;
  }
`;

const StyledButtonText = styled(ButtonText)`
  color: ${(props) => props.theme.purple4};
  white-space: nowrap;
`;

const StyledRightArrowSvg = styled(RightArrowSvg)`
  color: ${(props) => props.theme.purple4};
  width: 1.5rem; // 24px
  height: 1.5rem; // 24px
  margin-left: ${(props) => props.theme.spacing1};
  transition: margin-left ${(props) => props.theme.transitionValue},
    margin-right ${(props) => props.theme.transitionValue};

  ${RecipeLink}:hover & {
    margin-left: 2rem; // 32px
    margin-right: -${(props) => props.theme.spacing1};
  }
`;

export default Recipes;
