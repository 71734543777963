import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import theme from "../../../../../style/theme";
import Heading3 from "../../../../shared/text/headings/Heading3";
import TextBold from "../../../../shared/text/TextBold";

interface Props {
  title: string;
  children: React.ReactNode;
  className?: string;
}

const NutritionalTable = ({
  title,
  children,
  className,
}: Props): JSX.Element => {
  const isMobile = useMediaQuery({ maxWidth: theme.breakpoint2 });

  return (
    <Container className={className}>
      <StyledHeading3>{title}</StyledHeading3>
      <StyledTable role="grid">
        <StyledThead role="presentation">
          <HeadRow role="row">
            <TableHead role="columnheader">
              <StyledTextMarginLeft>Nutrient</StyledTextMarginLeft>
            </TableHead>
            <TableHead role="columnheader">
              <StyledText>Total in meal</StyledText>
            </TableHead>
            <TableHead role="columnheader">
              <StyledText>Requirements</StyledText>
            </TableHead>
            <MoreInfoCell role="columnheader">
              <MoreInfoText>
                {isMobile ? "Ingredients" : "More information"}
              </MoreInfoText>
            </MoreInfoCell>
            <InvisbleTableHead role="columnheader">
              {isMobile ? "More information" : "Ingredients"}
            </InvisbleTableHead>
          </HeadRow>
        </StyledThead>
        <TableBody role="presentation">{children}</TableBody>
      </StyledTable>
    </Container>
  );
};

const Container = styled.div``;

const StyledHeading3 = styled(Heading3)`
  margin-bottom: ${(props) => props.theme.spacing2};
  color: ${(props) => props.theme.black4};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-bottom: ${(props) => props.theme.spacing1};
  }
  @media print {
    margin-bottom: ${(props) => props.theme.spacing1};
    page-break-inside: avoid;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    display: block;
  }
  @media print {
    display: table;
  }
`;

const StyledThead = styled.thead`
  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  @media print {
    position: initial;
    left: initial;
    top: initial;
    width: initial;
    height: initial;
    overflow: initial;
    display: table-row-group;
  }
`;

const HeadRow = styled.tr`
  border-bottom: 0.125rem solid ${(props) => props.theme.black2};
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
`;

const TableHead = styled.th`
  text-align: left;
  padding: 0;
  padding-bottom: ${(props) => props.theme.spacing1};
  flex: 1;
`;

const MoreInfoCell = styled(TableHead)`
  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    max-width: 110px;
  }
  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    max-width: none;
  }

  @media print {
    display: none;
  }
`;

const StyledText = styled(TextBold)`
  color: ${(props) => props.theme.black4};
  margin-right: ${(props) => props.theme.spacing1};
  font-size: ${(props) => props.theme.navigationTextSize};
`;

const StyledTextMarginLeft = styled(StyledText)`
  margin-left: ${(props) => props.theme.spacing1};
`;

const MoreInfoText = styled(StyledText)`
  margin-right: 0;
`;

const InvisbleTableHead = styled.th`
  display: none;
`;

const TableBody = styled.tbody`
  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    display: block;
  }

  @media print {
    display: table-row-group;
  }
`;

export default NutritionalTable;
