import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../style/theme";
import Button from "./Button";
import SimpleSwitcher from "./form/simple/SimpleSwitcher";
import Tag from "./Tag";
import Heading2 from "./text/headings/Heading2";
import IntroductionText from "./text/IntroductionText";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import TextBold from "./text/TextBold";
import Text from "./text/Text";
import SmallText from "./text/SmallText";
import {
  priceProMonthly,
  priceProYearly,
  pricePlusMonthly,
  pricePlusYearly,
  priceBasicMonthly,
  priceBasicYearly,
  priceIdProMonthly,
  priceIdProYearly,
  priceIdPlusMonthly,
  priceIdPlusYearly,
  priceIdBasicMonthly,
  priceIdBasicYearly,
} from "../../constants/constants";
import StyledLink from "./StyledLink";

type timeSetting = "monthly" | "yearly";

interface Props {
  neverSubscribed: boolean;
  handleClick: (priceId: string) => void;
}

const PricePlans = ({
  neverSubscribed,
  handleClick,
}: Props): JSX.Element | null => {
  const [time, setTime] = useState<timeSetting>("monthly");

  return (
    <Container>
      <SwitcherContainer>
        <StyledSimpleSwitcher
          changeHandler={(value: timeSetting) => {
            setTime(value);
          }}
          value={time}
          valueOption1={"monthly"}
          valueOption2={"yearly"}
          title="Payment"
          id="monthlyOrYearly"
          labelOption1="Monthly"
          labelOption2="Yearly"
          color1={theme.purple3}
          color2={theme.purple1}
          colorTitle={theme.black4}
        />
      </SwitcherContainer>
      <PlanContainer>
        <Plan>
          <NameTag>Basic</NameTag>
          <TextContainer>
            <StyledHeading2>
              ${time === "monthly" ? priceBasicMonthly : priceBasicYearly}
            </StyledHeading2>
            <PriceText>monthly</PriceText>
          </TextContainer>
          {time === "yearly" && (
            <StyledSmallText>
              ${priceBasicYearly * 12} billed yearly
            </StyledSmallText>
          )}
          <StyledUl>
            <StyledLi>
              <StyledCheckIcon />
              <StyledTextBold>5 recipes</StyledTextBold>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledTextBold>1 dog</StyledTextBold>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Choose standard</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Calorie calculator</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Unit preferences</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Big database with over 700 ingredients</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Add your own ingredients</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Highlights of nutritional deficiencies</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Detailed breakdown of nutrients</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Hide/show functionality for ingredients</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Scale recipe</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Duplicate recipe</StyledText>
            </StyledLi>
          </StyledUl>
          <PurpleButton
            eventHandler={() =>
              handleClick(
                time === "monthly" ? priceIdBasicMonthly : priceIdBasicYearly
              )
            }
          >
            {neverSubscribed ? "Try Basic for free" : "Get Basic"}
          </PurpleButton>
        </Plan>

        <Plan>
          <NameTag>Plus</NameTag>
          <TextContainer>
            <StyledHeading2>
              ${time === "monthly" ? pricePlusMonthly : pricePlusYearly}
            </StyledHeading2>
            <PriceText>monthly</PriceText>
          </TextContainer>
          {time === "yearly" && (
            <StyledSmallText>
              ${pricePlusYearly * 12} billed yearly
            </StyledSmallText>
          )}
          <StyledUl>
            <StyledLi>
              <StyledCheckIcon />
              <StyledTextBold>20 recipes</StyledTextBold>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledTextBold>3 dogs</StyledTextBold>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Choose standard</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Calorie calculator</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Unit preferences</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Big database with over 700 ingredients</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Add your own ingredients</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Highlights of nutritional deficiencies</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Detailed breakdown of nutrients</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Hide/show functionality for ingredients</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Scale recipe</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Duplicate recipe</StyledText>
            </StyledLi>
          </StyledUl>
          <PurpleButton
            eventHandler={() =>
              handleClick(
                time === "monthly" ? priceIdPlusMonthly : priceIdPlusYearly
              )
            }
          >
            Get Plus
          </PurpleButton>
        </Plan>

        <Plan>
          <NameTag>Pro</NameTag>
          <TextContainer>
            <StyledHeading2>
              ${time === "monthly" ? priceProMonthly : priceProYearly}
            </StyledHeading2>
            <PriceText>monthly</PriceText>
          </TextContainer>
          {time === "yearly" && (
            <StyledSmallText>
              ${priceProYearly * 12} billed yearly
            </StyledSmallText>
          )}
          <StyledUl>
            <StyledLi>
              <StyledCheckIcon />
              <StyledTextBold>50 recipes</StyledTextBold>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledTextBold>6 dogs</StyledTextBold>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Choose standard</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Calorie calculator</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Unit preferences</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Big database with over 700 ingredients</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Add your own ingredients</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Highlights of nutritional deficiencies</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Detailed breakdown of nutrients</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Hide/show functionality for ingredients</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Scale recipe</StyledText>
            </StyledLi>
            <StyledLi>
              <StyledCheckIcon />
              <StyledText>Duplicate recipe</StyledText>
            </StyledLi>
          </StyledUl>
          <PurpleButton
            eventHandler={() =>
              handleClick(
                time === "monthly" ? priceIdProMonthly : priceIdProYearly
              )
            }
          >
            Get Pro
          </PurpleButton>
        </Plan>
      </PlanContainer>
      <EnterprisePlan>
        <LeftContainer>
          <EnterpriseTag>Enterprise</EnterpriseTag>
          <EnterpriseText>
            Do you need a custom plan? Contact us at{" "}
            <StyledStyledLink href="mailto:sales@mealment.com">
              sales@mealment.com
            </StyledStyledLink>{" "}
            about the enterprise plan.
          </EnterpriseText>
          <EnterpriseButton asA={true} href="mailto:sales@mealment.com">
            Get in touch
          </EnterpriseButton>
        </LeftContainer>
        <RightContainer>
          <EnterpriseUl>
            <StyledLi>
              <EnterpriseCheckIcon />
              <EnterpriseTextBold>Custom number of recipes</EnterpriseTextBold>
            </StyledLi>
            <StyledLi>
              <EnterpriseCheckIcon />
              <EnterpriseTextBold>Custom number of dogs</EnterpriseTextBold>
            </StyledLi>
            <StyledLi>
              <EnterpriseCheckIcon />
              <EnterpriseTextBold>Faster support</EnterpriseTextBold>
            </StyledLi>
          </EnterpriseUl>
        </RightContainer>
      </EnterprisePlan>
    </Container>
  );
};

const Container = styled.div``;

const SwitcherContainer = styled.div`
  max-width: 20rem; // 320px
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${(props) => props.theme.spacing3};

  @media (max-width: ${(props) => props.theme.breakpoint1}) {
    max-width: none;
    width: fit-content;
  }
`;

// https://github.com/styled-components/styled-components/issues/1803#issuecomment-857092410
const StyledSimpleSwitcher = styled(SimpleSwitcher)`` as typeof SimpleSwitcher;

const PlanContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin-bottom: ${(props) => props.theme.spacing3};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    flex-direction: column;
    align-items: center;
  }
`;

const Plan = styled.div`
  flex: 1 1 0px;
  background-color: ${(props) => props.theme.purple1};
  border-radius: 2rem; // 32px
  padding: 2rem ${(props) => props.theme.spacing3};
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-width: 25.625rem; // 410px

  &:not(:last-child) {
    margin-right: 2rem; // 32px
  }

  @media (max-width: ${(props) => props.theme.breakpoint3}) {
    &:not(:last-child) {
      margin-right: ${(props) => props.theme.spacing1};
      padding-right: 2rem; // 32px
      padding-left: 2rem; // 32px
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: ${(props) => props.theme.spacing2};
    }
  }
`;

const NameTag = styled(Tag)`
  background-color: ${(props) => props.theme.purple2};
  color: ${(props) => props.theme.purple4};
  margin-bottom: ${(props) => props.theme.spacing1};
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

const StyledHeading2 = styled(Heading2)`
  color: ${(props) => props.theme.purple4};
  margin-right: 0.6875rem; // 11px
`;

const PriceText = styled(IntroductionText)`
  color: ${(props) => props.theme.purple4};
`;

const StyledUl = styled.ul`
  width: 100%;
  list-style: none;
  padding-left: 0;
  margin-bottom: ${(props) => props.theme.spacing2};
  margin-top: ${(props) => props.theme.spacing1};
`;

const StyledLi = styled.li`
  display: flex;

  &:not(:last-child) {
    margin-bottom: ${(props) => props.theme.halfSpacing1};
  }
`;

const StyledCheckIcon = styled(CheckIcon)`
  stroke: ${(props) => props.theme.purple3};
  width: 1.5rem; // 24px
  height: 1.5rem; // 24px
  min-width: 1.5rem; // 24px
  min-height: 1.5rem; // 24px
  margin-right: ${(props) => props.theme.halfSpacing1};
  margin-top: 0.125rem; // 2px;
`;

const StyledTextBold = styled(TextBold)`
  color: ${(props) => props.theme.purple4};
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.purple4};
`;

const PurpleButton = styled(Button)`
  background-color: ${(props) => props.theme.purple3};
  color: ${(props) => props.theme.purple1};
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  display: block;

  &:hover {
    background-color: ${(props) => props.theme.purple4};
  }
  &:focus {
    background-color: ${(props) => props.theme.purple4};
    outline-color: ${(props) => props.theme.purple4};
  }
`;

const EnterprisePlan = styled.div`
  background-color: ${(props) => props.theme.purple4};
  border-radius: 2rem; // 32px
  padding: 2rem ${(props) => props.theme.spacing3};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    flex-direction: column;
    max-width: 25.625rem; // 410px
    margin-left: auto;
    margin-right: auto;
  }
`;

const LeftContainer = styled.div`
  margin-right: ${(props) => props.theme.spacing3};

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    margin-right: 0;
    margin-bottom: ${(props) => props.theme.spacing2};
  }
`;

const EnterpriseTag = styled(Tag)`
  background-color: ${(props) => props.theme.purple1};
  color: ${(props) => props.theme.purple4};
  margin-bottom: ${(props) => props.theme.spacing1};
`;

const EnterpriseText = styled(IntroductionText)`
  margin-bottom: ${(props) => props.theme.spacing1};
  color: ${(props) => props.theme.purple1};
  max-width: ${(props) => props.theme.widthSmallText};
`;

const EnterpriseButton = styled(Button)`
  background-color: ${(props) => props.theme.purple2};
  color: ${(props) => props.theme.purple4};
  display: block;
  text-align: center;
  max-width: 20.3125rem; // 325px

  &:hover {
    background-color: ${(props) => props.theme.purple1};
  }
  &:focus {
    background-color: ${(props) => props.theme.purple1};
    outline-color: ${(props) => props.theme.purple1};
  }

  @media (max-width: ${(props) => props.theme.breakpoint2}) {
    max-width: none;
  }
`;

const RightContainer = styled.div``;

const EnterpriseUl = styled(StyledUl)`
  margin-bottom: 0;
`;

const EnterpriseCheckIcon = styled(StyledCheckIcon)`
  stroke: ${(props) => props.theme.purple2};
`;

const EnterpriseTextBold = styled(TextBold)`
  color: ${(props) => props.theme.purple1};
`;

const StyledSmallText = styled(SmallText)`
  color: ${(props) => props.theme.purple4};
  margin-top: ${(props) => props.theme.halfSpacing1};
`;

const StyledStyledLink = styled(StyledLink)`
  color: ${(props) => props.theme.purple1};

  &:hover {
    color: ${(props) => props.theme.purple2};
  }
  &:focus {
    outline-color: ${(props) => props.theme.purple2};
    color: ${(props) => props.theme.purple2};
  }
`;

export default PricePlans;
