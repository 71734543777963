import React from "react";
import styled from "styled-components";
import InnerHTML from "dangerously-set-html-content";

const PrivacyPolicy = (): JSX.Element => {
  return (
    <Container>
      <InnerHTML
        html={`<div class="section__content">  <div name="termly-embed" data-id="78c65551-8e4e-4bb7-9864-0ad3cb1cd5df" data-type="iframe"></div>  <script src="https://app.termly.io/embed-policy.min.js"></script> </div>`}
      />
    </Container>
  );
};

const Container = styled.div`
  max-width: ${(props) => props.theme.halfWidth};
  margin-top: ${(props) => props.theme.spacing2};
  margin-left: auto;
  margin-right: auto;
`;

export default PrivacyPolicy;
