import { useContext } from "react";
import DogsModalCallbackContext from "../contexts/dogsModalCallbackContext/DogsModalCallbackContext";
import ExtraFieldsModalOpenContext from "../contexts/extraFieldsModalOpenContext/ExtraFieldsModalOpenContext";
import OneChoiceModalOpenContext from "../contexts/oneChoiceModalOpenContext/OneChoiceModalOpenContext";
import TwoChoiceModalOpenContext from "../contexts/twoChoiceModalOpenContext/TwoChoiceModalOpenContext";
import calculateCaloriesNeeded from "../services/calculateCaloriesNeeded";
import useGetAuthUser from "./api/useGetAuthUser";
import useGetDog from "./api/useGetDog";
import useGetDogs from "./api/useGetDogs";
import useGetLoginStatus from "./api/useGetLoginStatus";
import useIsValidSubscription from "./useIsValidSubscription";

const useLeavingDogLogic = (): ((
  callback: () => void,
  to: string | null
) => void) => {
  const loggedInStatusQuery = useGetLoginStatus();
  const authUserQuery = useGetAuthUser({
    enabled: !!loggedInStatusQuery?.data?.loggedIn,
  });
  const getDogQuery = useGetDog(authUserQuery.data?.selectedDog, {
    enabled: !!authUserQuery.data?.selectedDog,
  });
  const getDogsQuery = useGetDogs({
    enabled: !!authUserQuery.data?.selectedDog,
  });

  const twoChoiceModalOpenContextValue = useContext(TwoChoiceModalOpenContext);
  const dogsModalCallbackContextValue = useContext(DogsModalCallbackContext);
  const extraFieldsModalOpenContextValue = useContext(
    ExtraFieldsModalOpenContext
  );
  const oneChoiceModalOpenContextValue = useContext(OneChoiceModalOpenContext);

  const validSubscription = useIsValidSubscription(
    authUserQuery.data === undefined
      ? undefined
      : authUserQuery.data.stripeSubscription,
    authUserQuery.data === undefined
      ? undefined
      : authUserQuery.data.stripeSubscriptionTimestamp
  );

  return (callback, to) => {
    // If the user is not on dog page, do nothing
    if (window.location.pathname !== "/dogs") {
      callback();
      return;
    }

    if (!validSubscription) {
      callback();
      return;
    }

    // If the user is creating a new dog
    if (authUserQuery.data?.selectedDog === null) {
      // If the user has other dogs
      const dogExists = !!(getDogsQuery.data && getDogsQuery.data[0]);
      if (dogExists) {
        twoChoiceModalOpenContextValue &&
          twoChoiceModalOpenContextValue.setTwoChoiceModalOpen(true);
        dogsModalCallbackContextValue &&
          dogsModalCallbackContextValue.setDogsModalCallback(() => callback);
        return;
      }

      // If the user has no other dogs and is leaving for rec/ingr
      if (to === "/recipes" || to === "/ingredients") {
        oneChoiceModalOpenContextValue &&
          oneChoiceModalOpenContextValue.setOneChoiceModalOpen(true);

        return;
      }

      // If the user has no other dogs and is leaving for other pages
      twoChoiceModalOpenContextValue &&
        twoChoiceModalOpenContextValue.setTwoChoiceModalOpen(true);
      dogsModalCallbackContextValue &&
        dogsModalCallbackContextValue.setDogsModalCallback(() => callback);

      return;
    }

    // If base dog already created but extra fields not filled out and leaving for rec/ingr
    if (authUserQuery.data && authUserQuery.data.kgOrLb && getDogQuery.data) {
      const caloriesNeeded = calculateCaloriesNeeded(
        getDogQuery.data?.birthDate,
        getDogQuery.data?.breedSize,
        authUserQuery.data?.kgOrLb,
        getDogQuery.data?.weight,
        getDogQuery.data?.expectedWeight,
        getDogQuery.data?.activityLevel,
        getDogQuery.data?.spayedOrNeutered,
        getDogQuery.data?.pregnancyStatus === "gestation",
        getDogQuery.data?.pregnancyStatus === "lactation",
        getDogQuery.data?.matingDate,
        getDogQuery.data?.parturitionDate,
        getDogQuery.data?.numberOfPuppies
      );

      if (
        caloriesNeeded === null &&
        (to === "/recipes" || to === "/ingredients")
      ) {
        extraFieldsModalOpenContextValue &&
          extraFieldsModalOpenContextValue.setExtraFieldsModalOpen(true);

        return;
      }
    }

    callback();
  };
};

export default useLeavingDogLogic;
