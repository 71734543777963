import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import theme from "../../../../style/theme";
import FormLabel from "../../text/FormLabel";
import StyledCheckbox from "../StyledCheckbox";
import InputButtons from "./InputButtons";

interface Props {
  id1: string;
  id2: string;
  onSave: (value1: boolean, value2: boolean) => void;
  serverValue1: boolean | undefined;
  serverValue2: boolean | undefined;
  label1: string;
  label2: string;
  bigLabel: string;
  disabled: boolean;
  className?: string;
}

const EditableMultiCheckbox = ({
  id1,
  id2,
  onSave,
  serverValue1,
  serverValue2,
  label1,
  label2,
  bigLabel,
  disabled,
  className,
}: Props): JSX.Element => {
  const [checked1, setChecked1] = useState<boolean>(false);
  const [checked2, setChecked2] = useState<boolean>(false);
  const [editing, setEditing] = useState(false);

  const checkBoxRef1 = useRef<HTMLInputElement | null>(null);
  const checkBoxRef2 = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (serverValue1 !== undefined) {
      setChecked1(serverValue1);
    }
  }, [serverValue1]);

  useEffect(() => {
    if (serverValue2 !== undefined) {
      setChecked2(serverValue2);
    }
  }, [serverValue2]);

  // useEffect(() => {
  //   if (editing) {
  //     checkBoxRef.current?.focus();
  //   }
  // }, [editing]);

  useEffect(() => {
    const blurred = (e: MouseEvent) => {
      if (!containerRef.current?.contains(e.target as Node) && editing) {
        saveEdit();
      }
    };
    document.addEventListener("click", blurred);
    return () => document.removeEventListener("click", blurred);
  }, [checked1, checked2, serverValue1, serverValue2, editing]);

  useEffect(() => {
    const focusBlurred = (e: FocusEvent) => {
      if (!containerRef.current?.contains(e.target as Node) && editing) {
        saveEdit();
      }
    };
    document.addEventListener("focusin", focusBlurred);
    return () => document.removeEventListener("focusin", focusBlurred);
  }, [checked1, checked2, serverValue1, serverValue2, editing]);

  const handleKeys = (e: React.KeyboardEvent) => {
    if (!editing) return;
    if (e.key === "Escape") {
      e.preventDefault();
      cancelEdit();
    } else if (e.key === "Enter") {
      e.preventDefault();
      saveEdit();
    }
  };

  const saveEdit = () => {
    setEditing(false);

    if (
      serverValue1 !== undefined &&
      checked1 === serverValue1 &&
      serverValue2 !== undefined &&
      checked2 === serverValue2
    ) {
      return;
    }
    onSave(checked1, checked2);
  };

  const cancelEdit = () => {
    setChecked1(serverValue1 !== undefined ? serverValue1 : false);
    setChecked2(serverValue2 !== undefined ? serverValue2 : false);
    setEditing(false);
  };

  return (
    <Container
      onClick={!editing ? () => setEditing(true) : undefined}
      ref={containerRef}
      className={className}
    >
      <LabelContainer>
        <StyledFormLabel asValue={"legend"} isDisabled={disabled}>
          {bigLabel}
        </StyledFormLabel>

        <ButtonContainer>
          {!disabled && (
            <InputButtons
              cancelEdit={cancelEdit}
              editing={editing}
              saveEdit={saveEdit}
              setEditing={setEditing}
              notAbsolute={true}
            />
          )}
        </ButtonContainer>
      </LabelContainer>
      <InnerContainer>
        <StyledCheckboxMarginBottom
          label={label1}
          value={checked1}
          changeHandler={(value) => {
            setChecked1(value);
          }}
          id={id1}
          checkboxColor={theme.black4}
          color1={theme.white}
          color2={theme.white}
          checkboxBorderColor={editing ? theme.black4 : theme.black1}
          partialFocusHandler={() => {
            setEditing(true);
          }}
          clickHandler={() => {
            setEditing(true);
          }}
          keyDownHandler={handleKeys}
          readOnly={!editing}
          refValue={checkBoxRef1}
          disabled={disabled || checked2}
        />
        <StyledCheckbox
          label={label2}
          value={checked2}
          changeHandler={(value) => {
            setChecked2(value);
          }}
          id={id2}
          checkboxColor={theme.black4}
          color1={theme.white}
          color2={theme.white}
          checkboxBorderColor={editing ? theme.black4 : theme.black1}
          partialFocusHandler={() => {
            setEditing(true);
          }}
          clickHandler={() => {
            setEditing(true);
          }}
          keyDownHandler={handleKeys}
          readOnly={!editing}
          refValue={checkBoxRef2}
          disabled={disabled || checked1}
        />
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFormLabel = styled(FormLabel)<{ isDisabled?: boolean }>`
  color: ${(props) =>
    props.isDisabled ? props.theme.black3 : props.theme.black4};
  transition: color ${(props) => props.theme.transitionValue};
  margin-bottom: ${(props) => props.isDisabled && "0.25rem"}; //4px
  word-break: break-word;
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledCheckboxMarginBottom = styled(StyledCheckbox)`
  margin-bottom: ${(props) => props.theme.spacing1};
`;

const ButtonContainer = styled.div`
  white-space: nowrap;
`;

export default EditableMultiCheckbox;
