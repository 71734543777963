import React from "react";
import { authViews } from "../../types/frontendTypes";

const AuthModalContext = React.createContext<{
  authModalOpen: boolean;
  setAuthModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  authModalShowing: authViews;
  setAuthModalShowing: React.Dispatch<React.SetStateAction<authViews>>;
} | null>(null);

export default AuthModalContext;
