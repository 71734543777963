import React, { useState } from "react";
import TwoChoiceModalOpenContext from "./DogBannersContext";

interface Props {
  children: React.ReactNode;
}

const DogBannersContextProvider = ({ children }: Props): JSX.Element => {
  const [dogBanners, setDogBanners] = useState<string[]>([]);

  return (
    <TwoChoiceModalOpenContext.Provider value={{ dogBanners, setDogBanners }}>
      {children}
    </TwoChoiceModalOpenContext.Provider>
  );
};

export default DogBannersContextProvider;
